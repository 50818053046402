import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'displayOrder' })
export class DisplayOrderPipe implements PipeTransform {

    transform<T extends { displayOrder: number }>(value: T[] = []): T[] {

        return value
            .slice()
            .sort((a, b) => a.displayOrder - b.displayOrder);
    }

}
