<div class="icon-input">
    <button
        #trigger
        type="button"
        class="select-menu-button"
        rdcAppsButton
        (click)="onOpenTagMenu(trigger, false)"
        (keydown.arrowDown)="onOpenTagMenu(trigger)">
        <ng-container [ngSwitch]="selectedTagCount">
            <span *ngSwitchCase="0">Tags</span>
            <span *ngSwitchCase="1">1 tag</span>
            <span *ngSwitchDefault>{{ selectedTagCount }} tags</span>
        </ng-container>
    </button>
    <rdc-apps-icon>expand_more</rdc-apps-icon>
</div>
<ng-template #menu>
    <div cdkMenu class="tag-filter-menu" [formGroup]="filterGroup">
        <ng-container formGroupName="tags">
            <button
                class="clear-tag-selection"
                *ngIf="selectedTagCount > 1"
                rdcAppsContextMenuItem
                cdkMenuItem
                [itemClosesMenuOnClick]="false"
                (cdkMenuItemTriggered)="tagsArray.reset()">
                Clear all
            </button>
            <label
                *ngFor="let tag of tagsArray.controls; let i = index"
                rdcAppsContextMenuItem
                cdkMenuItemCheckbox
                [itemClosesMenuOnClick]="false"
                [formArrayName]="i"
                (click)="onSelectTag(i)"
                (keydown.enter)="onSelectTag(i)"
                (keydown.space)="onSelectTag(i)"
            >
                <span class="rdc-tag-filter-rounded" [style.background]="tag.value.hexaDecimalColour"></span>

                <span style="user-select: none;">{{ tag.value.label }}</span>

                <div [class.on]="tag.value.checked" class="rdc-tag-filter-rounded selected-tick">
                    <rdc-apps-icon size="smallest">check</rdc-apps-icon>
                </div>
            </label>
        </ng-container>
    </div>
</ng-template>


