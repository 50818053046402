import { createAction, props } from '@ngrx/store';

import { FiltersDateRangeEntity } from './filters-date-range.models';

export const getFiltersDateRange = createAction(
    '[FiltersDateRange Page] Get'
);

export const loadFiltersDateRangeSuccess = createAction(
    '[FiltersDateRange/API] Load FiltersDateRange Success',
    props<{ filtersDateRange: FiltersDateRangeEntity }>()
);

export const loadFiltersDateRangeFailure = createAction(
    '[FiltersDateRange/API] Load FiltersDateRange Failure',
    props<{ error: string }>()
);
