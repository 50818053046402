import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import {
    AirportOriginEffects,
    CloudExportEffects,
    CloudRefreshEffects, CloudRouteServedEffects,
    QueryViaDataPointEffects,
    SalesforceEffects,
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/effects';
import { AutocompleteStoreModule } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/autocomplete';
import { DataPointsStoreModule } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points';
import { DefaultPreferencesStoreModule } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/default-preferences';
import { ErrorStoreModule } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/error';
import { GrowthStoreModule } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/growth';
import { QueryStoreModule } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/query';
import { RiskStoreModule } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/risk';
import { StudyTemplateStoreModule } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/study-templates';
import { TagsStoreModule } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/tags';
import { UserDetailsStoreModule } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/user-details';
import { UserSettingsStoreModule } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/user-settings';
import { ApexLayoutModule } from '@rdc-apps/rdc-apex/src/lib/shell/ui/apex-layout';
import { ActivityEffects } from '@rdc-apps/rdc-shared/src/lib/data-access/effects';
import { ActivityService } from '@rdc-apps/rdc-shared/src/lib/data-access/services';
import { AppLoadingStoreModule } from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';
import { NetworkStoreModule } from "network-store";

import { apexRoutes } from './apex-routes';

@NgModule({
    imports: [
        ApexLayoutModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(apexRoutes),
        StoreModule.forRoot(
            {},
            {
                runtimeChecks: {
                    strictActionImmutability: true,
                    strictStateImmutability: true,
                },
            }
        ),
        EffectsModule.forRoot([
            SalesforceEffects,
            QueryViaDataPointEffects,
            ActivityEffects,
            CloudRefreshEffects,
            CloudRouteServedEffects,
            CloudExportEffects,
            AirportOriginEffects
        ]),
        StoreRouterConnectingModule.forRoot(),
        GoogleTagManagerModule.forRoot({
            id: 'GTM-T6DDPM6',
        }),
        AutocompleteStoreModule,
        QueryStoreModule,
        ErrorStoreModule,
        TagsStoreModule,
        StudyTemplateStoreModule,
        DataPointsStoreModule,
        DefaultPreferencesStoreModule,
        UserDetailsStoreModule,
        UserSettingsStoreModule,
        AppLoadingStoreModule,
        GrowthStoreModule,
        RiskStoreModule,
        NetworkStoreModule
    ],
    providers: [
        GoogleTagManagerService,
        ActivityService,
    ],
    exports: [ RouterModule ],
})
export class ApexShellModule {}
