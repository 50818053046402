import { map, Observable } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { RdcResponseDto } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

import { DataPointsEntity } from './data-points.models';

@Injectable({
    providedIn: 'root',
})
export class DataPointsService extends RdcBaseHttpService {

    constructor(
        @Inject(appEnvironment) private environment: AppEnvironment
    ) {
        super();
    }

    get(): Observable<DataPointsEntity> {
        // eslint-disable-next-line max-len
        return this.http.get<RdcResponseDto<DataPointsEntity>>(`${ this.environment.api.baseUrl }/configurations/query-builder-options`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data)
            );
    }
}
