<ng-container *ngIf="rdcIconDefs.includes(rdcIcon) else materialIcon">
    <img *ngIf="sideBarIcon"
         alt="rdc icon"
         src="/assets/rdc-shared/icons/{{ rdcIcon }}.svg"
         [@animSidebarSvg]="sideBarExpanded ? 'min' : 'max'"
         [class]="size">

    <img *ngIf="!sideBarIcon"
         alt="rdc icon"
         src="/assets/rdc-shared/icons/{{ rdcIcon }}.svg"
         [class]="size">
</ng-container>

<ng-template #materialIcon>

    <i *ngIf="!sideBarIcon"
       [class]="size"
       [ngClass]="{
            'material-symbols-sharp' : fill,
            'spin' : spin,
            'material-symbols-outlined' : !fill,
            'bold': weight === 'bold',
            'semi-bold': weight === 'semi-bold'
        }">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </i>

    <i *ngIf="sideBarIcon"
       [@animSidebarIcon]="{ value: sideBarExpanded ? 'min' : 'max', params: { size: size === 'xxxs' ? '12px' : '16px' }}"
       [class]="size"
       [ngClass]="{
            'material-symbols-sharp' : fill,
            'spin' : spin,
            'material-symbols-outlined' : !fill,
            'bold': weight === 'bold',
            'semi-bold': weight === 'semi-bold'
        }">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </i>

</ng-template>

<!-- content to be projected -->
<ng-template #content>
    <ng-content></ng-content>
</ng-template>

