import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { TagEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/tags';
import { ColorPickerService } from "ngx-color-picker";

@Component({
    selector: 'rdc-apps-tag-management-item',
    templateUrl: './tag-management-item.component.html',
    styleUrls: [ './tag-management-item.component.scss' ],
    providers: [ ColorPickerService ],
})
export class TagManagementItemComponent implements AfterViewInit {

    @Input() controls!: AbstractControl<TagEntity>;
    @Input() index!: number;
    @Input() tags: TagEntity[] = [];
    @Input() defaultColours: string[] = [];

    @Output() deleteTag = new EventEmitter<TagEntity>();
    @Output() tagLabel = new EventEmitter<Event>();
    @Output() tagColour = new EventEmitter<TagEntity>();

    @ViewChild('tagManagementItem') tagManagementItem!: ElementRef<HTMLInputElement>;

    validationMessages = {
        required: 'Tag name is required',
        notUniqueTagName: 'The tag name need to be unique',
    };
    deleteMode = false;
    toggle = false;

    get label(): FormControl {
        return this.controls.get('label') as FormControl;
    }

    ngAfterViewInit(): void {
        this.tagManagementItem.nativeElement.focus();
    }

    onTagLabel(event: Event): void {
        this.tagManagementItem.nativeElement.value = (event.target as HTMLInputElement).value.trim();

        this.tagLabel.emit(event);
    }

    onDeleteTag(tag: TagEntity): void {
        this.deleteMode = false;
        this.deleteTag.emit(tag);
    }

    onCancel(): void {
        this.deleteMode = false;
    }

    onColourPickerClose(tag: TagEntity): void {
        const isNotSameColour = tag.hexaDecimalColour !== this.controls.value.hexaDecimalColour;

        if (isNotSameColour) {
            this.tagColour.emit(tag);
        }
    }

}
