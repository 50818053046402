import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RdcMenuItemModule } from '@rdc-apps/rdc-shared/src/lib/directives/rdc-menu-item';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';

import { OldAutocompleteComponent } from './old-autocomplete.component';


@NgModule({
    imports: [
        CommonModule,
        IconModule,
        CdkMenuModule,
        RdcMenuItemModule,
    ],
    declarations: [
        OldAutocompleteComponent,
    ],
    exports: [
        OldAutocompleteComponent,
    ],
})
export class AutocompleteModule {}
