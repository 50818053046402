import { firstValueFrom, takeUntil, tap } from 'rxjs';

import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    createSalesforceLead,
    createSalesforceLeadSuccess
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/actions';
import { getUserDetailsData } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/user-details';
import { RegisteredInterestService } from '@rdc-apps/rdc-shared/src/lib/data-access/local-storage';

import { RdcComponentUtils } from './RdcComponentUtils';

@Directive()
export abstract class ApexSalesforceLeadGenerator extends RdcComponentUtils implements OnInit, OnDestroy {

    itemName = 'unspecified';

    generatingLead = false;

    constructor(
        protected store: Store,
        protected actions: Actions,
        protected regInterestService: RegisteredInterestService
    ) {
        super(actions);
    }

    ngOnInit(): void {
        this.actions.pipe(
            ofType(createSalesforceLeadSuccess),
            takeUntil(this.componentDestroyed$),
            tap(() => {
                this.generatingLead = false;
                this.regInterestService.set(this.itemName, true);
            })
        ).subscribe();
    }

    get registered(): boolean {
        return this.regInterestService.registeredInterestIn(this.itemName);
    }

    public async onGenerateLead(callToAction: string, description: string): Promise<void> {

        const user = await firstValueFrom(
            this.store.select(getUserDetailsData('userDetailsState'))
        );

        if (!user) {
            return;
        }

        this.generatingLead = true;

        this.store.dispatch(createSalesforceLead({
            lead: {
                callToAction,
                campaignId: '7016M000001DdgLQAS',
                company: user.organisation.name,
                description,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                leadSource: 'App',
                leadSourceDetail: 'APX',
                productOfInterest: 'APX',
                recordType: '0126M000000B9xF',
            },
        }));
    }

}
