import { Observable, skipWhile, take } from 'rxjs';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { initDataPoints } from './data-points.actions';
import { DataPointsEntity } from './data-points.models';
import * as dataPointsReducer from './data-points.reducer';
import { getDataPoints } from './data-points.selectors';

@Injectable({
    providedIn: 'root',
})
export class DataPointsResolver  {

    constructor(private store$: Store<{ dataPoints: dataPointsReducer.DataPointsState }>) {}

    resolve(): Observable<DataPointsEntity | undefined> {

        this.store$.dispatch(initDataPoints());

        return this.store$.select(getDataPoints('dataPointState'))
            .pipe(
                skipWhile((dataPoints) => !dataPoints),
                take(1)
            );
    }
}
