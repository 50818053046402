<div class="rdc-chart-scatter-group-by" [class.template]="inTemplateForm">
    <label [ngClass]="{ 'text-hidden' : inTemplateForm }">
        <span>Group by</span>
        <rdc-apps-select
            controlName="groupBy"
            [options]="groupByOptions"
            [controlGroup]="queryRequestFormGroup('chartProperties','scatter')"
            (valueSelected)="templateEventService.progress()">
        </rdc-apps-select>
    </label>

    <div *ngIf="!inTemplateForm"
         class="rdc-chart-scatter-matching-series"
         [formGroup]="queryRequestFormGroup('adjustments')">
        <label class="rdc-checkbox">
            <input type="checkbox" formControlName="includeOnlyWhereResultsMatch">
            <span>Only show matching series results</span>
        </label>
    </div>
</div>
