import { createFeatureSelector, createSelector } from '@ngrx/store';

import { USER_DETAILS_FEATURE_KEY, UserDetailsState } from './user-details.reducer';

// Lookup the 'UserDetails' feature state managed by NgRx
export const getUserDetailsState = createFeatureSelector<UserDetailsState>(
    USER_DETAILS_FEATURE_KEY
);

export const getUserDetailsLoading = createSelector(
    getUserDetailsState,
    (state: UserDetailsState) => state.loading
);

export const getUserDetailsLoaded = createSelector(
    getUserDetailsState,
    (state: UserDetailsState) => state.loaded
);

export const getUserDetailsError = createSelector(
    getUserDetailsState,
    (state: UserDetailsState) => state.error
);

export const getUserDetailsData = (id: string) => createSelector(
    getUserDetailsState,
    (state: UserDetailsState) => state.entities[id]?.userDetails
);

export const runApexTrialMode = (id: string) => createSelector(
    getUserDetailsState,
    (state: UserDetailsState): boolean => !!state.entities[id]?.userDetails?.organisation.isOnTrial
);

