import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

export const xAxisSubOptions: Map<string, Partial<RepoItem<string | number>>[]> = new Map()
    .set('quarter', [
        { label: 'Q1 (Jan - Mar)', code: 'q1' },
        { label: 'Q2 (Apr - Jun)', code: 'q2' },
        { label: 'Q3 (Jul - Sep)', code: 'q3' },
        { label: 'Q4 (Oct - Dec)', code: 'q4' },
    ])
    .set('season', [
        { label: 'Summer', code: 'summer' },
        { label: 'Winter', code: 'winter' },
    ])
    .set('relativeYears', [
        { label: '2 years ago', code: '2YearsAgo' },
        { label: 'Last year', code: 'LastYear' },
        { label: 'This year', code: 'ThisYear' },
    ])
    .set('emissionsScheme', [
        { label: 'CORSIA', code: 'corsia' },
        { label: 'EU ETS', code: 'euEts' },
        { label: 'UK ETS', code: 'ukEts' },
        { label: 'Swiss ETS', code: 'swissEts' },
    ])
    // populated in ngOnInit in component
    .set('calendarMonth', [])
    .set('year', []);

export const xAxisOptionsForType: Map<string,Partial<RepoItem<string>>[]> = new Map()
    .set('timePeriod', [
        { label: 'Month', code: 'calendarMonth', isDefault: true },
        { label: 'Quarter', code: 'quarter' },
        { label: 'Season', code: 'season' },
        { label: 'Year', code: 'year' },
    ])
    .set('origin', [
        { label: 'Airport', code: 'airport', isDefault: true },
        { label: 'City', code: 'city' },
        { label: 'Sub Region', code: 'subRegion' },
        { label: 'Region', code: 'region' },
        { label: 'Country', code: 'country' },
        { label: 'Continent', code: 'continent' },
    ])
    .set('destination', [
        { label: 'Airport', code: 'airport', isDefault: true },
        { label: 'City', code: 'city' },
        { label: 'Sub Region', code: 'subRegion' },
        { label: 'Region', code: 'region' },
        { label: 'Country', code: 'country' },
        { label: 'Continent', code: 'continent' },
    ])
    .set('airline', [
        { label: 'Airline', code: 'airline', isDefault: true },
        { label: 'Alliance', code: 'airlineAlliance' },
        { label: 'Airline Type', code: 'airlineType' },
    ])
    .set('aircraft', [
        { label: 'Model', code: 'model', isDefault: true },
        { label: 'Family', code: 'family' },
    ])
    .set('aircraft', [
        { label: 'Model', code: 'model', isDefault: true },
        { label: 'Family', code: 'family' },
    ])
    .set('emissionsScheme', [
        { label: 'Emissions Scheme', code: 'emissionsScheme', isDefault: true },
    ])
    .set('originDestination', [
        { label: 'Origin Destination', code: 'originDestination', isDefault: true },
    ]);

// basically exactly the same a part from time period

export const seriesTimePeriodOptsForXMonth = [
    { label: 'Years', code: 'year' },
    { label: 'Relative years', code: 'relativeYears' },
];

export const seriesTimePeriodOptsForXYear = [
    { label: 'Quarters', code: 'quarter' },
    { label: 'Seasons', code: 'season' },
];

export const xAxisTypeOptions: Partial<RepoItem<string>>[] = [
    { label: 'Sector length', code: 'sectorLength' },
    { label: 'Time-period', code: 'timePeriod' },
    { label: 'Origin', code: 'origin' },
    { label: 'Destination', code: 'destination' },
    { label: 'Airline', code: 'airline' },
    { label: 'Aircraft', code: 'aircraft' },
    { label: 'Emissions Scheme', code: 'emissionsScheme' },
];

export const studyBuilderRelativeOpts: Partial<RepoItem<string>>[] = [
    { label: 'Last 12 months', code: 'last12Months', displayOrder: 1 },
    { label: 'Last 6 months', code: 'last6Months', displayOrder: 2 },
    { label: 'Last 3 months', code: 'last3Months', displayOrder: 3 },
    { label: 'Last month', code: 'last1Month', displayOrder: 4 },
    { label: 'Current month', code: 'currentMonth', displayOrder: 5 },
    { label: 'Next month', code: 'next1Month', displayOrder: 6 },
    { label: 'Next 3 months', code: 'next3Months', displayOrder: 7 },
    { label: 'Next 6 months', code: 'next6Months', displayOrder: 8 },
    { label: 'Next 12 months', code: 'next12Months', displayOrder: 9 },
];

export const chartBuilderChartOpts: Partial<RepoItem<string>>[] = [
    { label: 'Column', code: 'column', displayOrder: 1 },
    { label: 'Line', code: 'line', displayOrder: 2 },
    { label: 'Area', code: 'area', displayOrder: 3 },
];

export const scatterGroupByOptions: Partial<RepoItem<string>>[] = [
    { code: 'origin.airport', label: 'Origin Airport' },
    { code: 'origin.city', label: 'Origin City' },
    { code: 'origin.country', label: 'Origin Country' },
    { code: 'origin.continent', label: 'Origin Continent' },
    { code: 'destination.airport', label: 'Destination Airport' },
    { code: 'destination.city', label: 'Destination City' },
    { code: 'destination.country', label: 'Destination Country' },
    { code: 'destination.continent', label: 'Destination Continent' },
    { code: 'primaryCarrier.airline', label: 'Airline' },
];
