import { createAction, props } from '@ngrx/store';

import { QueryEntity } from './query.models';

// fetch stored queries, init history
export const initQueries = createAction('[Queries] Init');

export const setActiveQuery = createAction(
    '[Query] Set active query',
    props<{ id: string | null | undefined }>()
);

export const saveQuery = createAction(
    '[Query] Save query',
    props<{ query: QueryEntity; templateId?: string | null }>()
);

export const saveQuerySuccess = createAction(
    '[Query] Save query success',
    props<{ query: QueryEntity; isNew: boolean, id: string; statusCode: number; templateId?: string | null }>()
);

export const updateQueryLastRun = createAction(
    '[Query] Update last run time',
    props<{ studyId: string }>()
);

export const saveQueryFailure = createAction(
    '[Query] Save query failed',
    props<{ error: string }>()
);

export const deleteQuery = createAction(
    '[Query] Delete query',
    props<{ studyId: string }>()
);

export const deleteQuerySuccess = createAction(
    '[Query] Delete query success',
    props<{ studyId: string }>()
);

export const deleteQueryFailure = createAction(
    '[Query] Delete query failed',
    props<{ error: string }>()
);

export const loadQueriesSuccess = createAction(
    '[Query/API] Load Queries Successful',
    props<{ queries: QueryEntity[] }>()
);

export const loadQueriesFailure = createAction(
    '[Query/API] Load Query Failure',
    props<{ error: string }>()
);

export const clearQuery = createAction(
    '[Query/API] Clear Query'
);
