import { Injectable } from '@angular/core';
import { TagEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/tags';

@Injectable({ providedIn: 'root' })
export abstract class QueryTagsService<T> {

    tagsFromQueries(queries: T[]): TagEntity[] {
        const tagsFromQueries: TagEntity[] = [];

        queries.forEach((query: any): void => {
            if (query['tags']?.length) {
                tagsFromQueries.push(...query.tags);
            }
        });

        return tagsFromQueries
            .filter((tag,index,array): boolean =>
                array.findIndex((tagEntity): boolean =>
                    (tagEntity.label === tag.label)) === index).sort((a,b): number => {
                if (a.lastUpdatedUtc && b.lastUpdatedUtc) {
                    return a.lastUpdatedUtc > b.lastUpdatedUtc ? 1 : -1;
                }

                return 0;
            });
    }
}
