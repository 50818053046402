import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as FiltersDateRangeActions from './filters-date-range.actions';
import { FiltersDateRangeEntity } from './filters-date-range.models';

export const FILTERS_DATE_RANGE_FEATURE_KEY = 'filtersDateRange';

export interface FiltersDateRangeState {
    range: FiltersDateRangeEntity | null;
    loaded: boolean; // has the FiltersDateRange list been loaded
    error?: string | null; // last known error (if any)
}

export interface FiltersDateRangePartialState {
    readonly [FILTERS_DATE_RANGE_FEATURE_KEY]: FiltersDateRangeState;
}

export const filtersDateRangeAdapter: EntityAdapter<FiltersDateRangeEntity> =
    createEntityAdapter<FiltersDateRangeEntity>();

export const initialFiltersDateRangeState: FiltersDateRangeState =
    filtersDateRangeAdapter.getInitialState({
        range: null,
        // set initial required properties
        loaded: false,
    });

const reducer = createReducer(
    initialFiltersDateRangeState,
    on(FiltersDateRangeActions.getFiltersDateRange, (state) => ({
        ...state,
        loaded: false,
        error: null,
    })),
    on(
        FiltersDateRangeActions.loadFiltersDateRangeSuccess,
        (state, { filtersDateRange }) => ({
            ...state,
            loaded: true,
            range: filtersDateRange,
        })),
    on(
        FiltersDateRangeActions.loadFiltersDateRangeFailure,
        (state, { error }) => ({ ...state, error })
    )
);

export function filtersDateRangeReducer(
    state: FiltersDateRangeState | undefined,
    action: Action
) {
    return reducer(state, action);
}
