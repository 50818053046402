<section>

    <div class="rdc-chart-series-type" [formGroup]="queryFormGroup('chartProperties')">

        <label
            class="rdc-checkbox"
            rdcAppsTooltip
            [tooltipTemplate]="singleSeriesTooltip"
            [tooltipDelay]="250"
            [tooltipPosition]="'above'"
            [tooltipOptions]="{ panelClass: 'tooltip-hover' }"
        >
            <input type="radio" value="single" formControlName="type">
            <span>Single (one set of data)</span>
        </label>

        <label
            class="rdc-checkbox"
            rdcAppsTooltip
            [tooltipTemplate]="multiSeriesTooltip"
            [tooltipDelay]="250"
            [tooltipPosition]="'above'"
            [tooltipOptions]="{ panelClass: 'tooltip-hover' }"
        >
            <input type="radio" value="comparison" formControlName="type">
            <span>Multiple (compare two or more sets of data)</span>
        </label>

        <ng-template #singleSeriesTooltip>
            <div class="series-tooltip">
                <div class="text-tooltip">
                    One set of data, e.g. one line or one set of columns all of the same colour
                    <img ngSrc="/assets/rdc-apex/images/single-series.svg" height="21" width="136" alt="">
                </div>
            </div>
        </ng-template>

        <ng-template #multiSeriesTooltip>
            <div class="series-tooltip">
                <div class="text-tooltip">
                    Compare two or more sets of data, e.g. two lines or two columns in different colours
                    <img ngSrc="/assets/rdc-apex/images/multi-series.svg" height="20" width="136" alt="">
                </div>
            </div>
        </ng-template>

    </div>

    <section *ngIf="isSingleSeries || showChartTypeSelection" [formGroup]="chartRequest">
        <div class="rdc-chart-single-series">

            <label *ngIf="chartRequest.get('queryRequest.chartProperties.type')?.value === 'single'"
                   class="rdc-checkbox">
                <div class="rdc-chart-series-colour">
                    <button
                        [cdkMenuTriggerFor]="colourPickerMenu"
                        [cdkMenuPosition]="overlayPositions.get('right')"
                        [style.background]="queryFormGroup('chartProperties','singleSeriesColour').value || '#43E08C'"
                        (click)="setColourPickerControl(queryFormGroup('chartProperties','singleSeriesColour'))">
                    </button>
                </div>
                <span>Colour</span>
            </label>

            <ng-container *ngIf="showChartTypeSelection">
                <label class="rdc-checkbox">
                    <input type="radio" formControlName="studyType" value="bar">
                    <span>Column</span>
                </label>

                <label class="rdc-checkbox">
                    <input type="radio" formControlName="studyType" value="line">
                    <span>Line</span>
                </label>

                <label class="rdc-checkbox">
                    <input type="radio" formControlName="studyType" value="area">
                    <span>Area</span>
                </label>
            </ng-container>
        </div>
    </section>

    <section class="rdc-series-definitions"
             *ngIf="queryFormGroup('chartProperties','seriesDefinitions')"
             [formGroup]="queryFormGroup('chartProperties','seriesDefinitions')">

        <div class="rdc-series-definition" *ngFor="let control of getFormArrayControls('seriesDefinitions') let index = index">

            <div class="rdc-series-definition-label">
                <span class="rdc-sdl-letter">{{ seriesLetters[index] }}</span>
                <span class="rdc-sdl-text">Comparison metric</span>
            </div>

            <label class="rdc-series-definition-type text-hidden">
                <span>Type</span>
                <rdc-apps-select
                    controlName="type"
                    placeholder="Select metric category"
                    [controlGroup]="control"
                    [options]="seriesDefTypeOptions[index]"
                    (valueSelected)="onMetricCategory($event, index)"
                ></rdc-apps-select>
            </label>

            <label class="rdc-series-definition-partition text-hidden" *ngIf="![ 'emissionsScheme', 'origin', 'destination' ].includes(control.value.type)">
                <ng-container>
                    <span>Show on X-axis</span>
                    <rdc-apps-select
                        controlName="partition"
                        placeholder="Select metric"
                        [controlGroup]="control"
                        [disabled]="!control.value.type"
                        [options]="xAxisOptionsOverrides[index] || xAxisOptionsForType.get(control.value.type) || []"
                    ></rdc-apps-select>
                </ng-container>
            </label>

            <div class="rdc-series-definition-clear">
                <button
                    rdcAppsButton
                    rdcAppsIconButton
                    display="compact"
                    [dialogCloseIconBtn]="true"
                    (click)="onRemoveComparison(index, control.value.type)"
                >
                    <rdc-apps-icon size="sm">close</rdc-apps-icon>
                </button>
            </div>

        </div>


        <div class="rdc-series-definition rdc-add-series-definition"
             *ngIf="getFormArrayControls('seriesDefinitions').length < 2">

            <div class="rdc-series-definition-label">
                <span class="rdc-sdl-letter">{{ seriesLetters[getFormArrayControls('seriesDefinitions').length] }}</span>
                <span class="rdc-sdl-text">Additional comparison metric (optional)</span>
            </div>

            <button
                rdcAppsButton
                (click)="onAddComparison()">
                Add metric
            </button>
        </div>

    </section>

    <div *ngIf="queryFormGroup('chartProperties','series')"
         [formGroup]="queryFormGroup('chartProperties','series')">

        <span class="rdc-lined-title">Add series (4 max)</span>

        <section class="rdc-chart-series-list">

            <ng-container *ngFor="let control of getFormArrayControls('series'); let index = index">

                <div class="rdc-chart-series" [formArrayName]="index">

                    <div class="rdc-chart-series-colour">
                        <button
                            #cpSeriesTrigger="cdkMenuTriggerFor"
                            [cdkMenuTriggerFor]="colourPickerMenu"
                            [cdkMenuPosition]="overlayPositions.get('right')"
                            (click)="setColourPickerControl(control.get('colour'))"
                            [style.background]="control.value.colour || '#43E08C'">
                        </button>
                    </div>

                    <rdc-apps-select
                        *ngIf="chartRequest.value.studyType !== 'scatter'"
                        class="rdc-chart-series-chart-type"
                        controlName="chartType"
                        display="compact"
                        [controlGroup]="control"
                        [options]="chartBuilderChartOpts">
                    </rdc-apps-select>

                    <label class="text-hidden compact rdc-chart-series-label">
                        <span>Label</span>
                        <input type="text" formControlName="label">
                    </label>

                    <div class="rdc-chart-series-attributes" *ngFor="let attributeControl of getFormArrayControls('seriesDefinitions'); let index = index">

                        <div class="rdc-series-definition-label">
                            <span class="rdc-sdl-letter">{{ seriesLetters[index] }}</span>
                        </div>

                        <ng-container [ngSwitch]="true">

                            <ng-container *ngSwitchCase="!!selectOptionsForComparison[index]?.length && getFormArrayControls('seriesDefinitions').at(index)?.value?.type === 'timePeriod'">

                                <rdc-apps-select
                                    *ngIf="control.get('timePeriodSelection') as tpSelection"
                                    class="rdc-chart-series-time-period-selection"
                                    display="compact"
                                    controlName="selected"
                                    [controlGroup]="tpSelection"
                                    [options]="selectOptionsForComparison[index]">
                                </rdc-apps-select>

                            </ng-container>

                            <ng-container *ngSwitchCase="!!selectOptionsForComparison[index]?.length && getFormArrayControls('seriesDefinitions').at(index)?.value?.type === 'emissionsScheme'">

                                <rdc-apps-select
                                    *ngIf="control.get('emissionsSchemeSelection')"
                                    class="rdc-chart-series-time-period-selection"
                                    display="compact"
                                    controlName="emissionsSchemeSelection"
                                    [controlGroup]="control"
                                    [options]="selectOptionsForComparison[index]">
                                </rdc-apps-select>

                            </ng-container>

                            <ng-container *ngSwitchDefault>

                                <div class="rdc-chart-series-autocomplete">
                                    <ng-container *ngIf="attributeControl.value.partition && getAttributeValue(control, index) else selectValue">

                                        <button
                                            class="rdc-chart-series-autocomplete-selection"
                                            rdcAppsButton
                                            display="compact"
                                            (click)="onRemoveAttributeSelection(control, index)">
                                            <span>{{ getAttributeValue(control, index)?.label }}</span>
                                            <rdc-apps-icon size="xs">close</rdc-apps-icon>
                                        </button>

                                    </ng-container>

                                    <ng-template #selectValue>

                                        <ng-container [ngTemplateOutlet]="dropDownFilters.get(attributeControl.value.partition) ? selectable : autoCompletable"></ng-container>
                                        <ng-template #selectable>
                                            <rdc-apps-select
                                                display="compact"
                                                overrideSelectedLabel="Add series filter"
                                                [filterable]="true"
                                                [options]="dropDownFilters.get(attributeControl.value.partition) || []"
                                                (valueSelected)="onSeriesAutocompleteSelect(control, index, $event)">
                                            </rdc-apps-select>
                                        </ng-template>
                                        <ng-template #autoCompletable>

                                            <rdc-apps-select
                                                *ngIf="showSeriesCompare(attributeControl.value.type)"
                                                placeholder="Filter routes"
                                                selectProperty="iataCode"
                                                filterHelp="Search by location"
                                                display="compact"
                                                [filterable]="true"
                                                [filterOnEnteredCharacters]="true"
                                                [showValidation]="false"
                                                [options]="mappedForSelect"
                                                [retainSelected]="true"
                                                [staticOption]="attributeControl.value.type === 'origin' ? allOriginValue : allDestinationValue"
                                                [showStaticOption]="showStaticOption"
                                                (valueSelected)="onSelected(control, index, $event)"
                                                (autocomplete)="onMultiAutocomplete($event)"
                                            ></rdc-apps-select>

                                            <rdc-apps-autocomplete
                                                *ngIf="!showSeriesCompare(attributeControl.value.type)"
                                                display="compact"
                                                placeholder="Search {{ attributeControl.value.type }}"
                                                [disabled]="!attributeControl.value.partition"
                                                [options]="(autoCompleteResults$ | async) || []"
                                                (valueSelected)="onSeriesAutocompleteSelect(control, index, $event)"
                                                (autocomplete)="onAutocomplete(attributeControl.value.partition, $event)">
                                            </rdc-apps-autocomplete>
                                        </ng-template>

                                    </ng-template>
                                </div>

                            </ng-container>

                        </ng-container>

                    </div>

                    <div>
                        <button class="rdc-chart-series-add-remove"
                                rdcAppsButton
                                rdcAppsIconButton
                                display="compact"
                                [disabled]="getFormArrayControls('series').length === 4"
                                (click)="onAddSeries(index)">
                            <rdc-apps-icon>add</rdc-apps-icon>
                        </button>

                        <button class="rdc-chart-series-add-remove"
                                rdcAppsButton
                                rdcAppsIconButton
                                display="compact"
                                [disabled]="getFormArrayControls('series').length === 1"
                                (click)="onRemoveSeries(index)">
                            <rdc-apps-icon>remove</rdc-apps-icon>
                        </button>
                    </div>

                </div>

            </ng-container>

        </section>

    </div>

</section>

<!-- need this behavior as it is better than ngx-colorpicker -->
<ng-template #colourPickerMenu>

    <div cdkMenu class="cdk-from-center-left colours with-margin" style="position:relative;">
        <div class="colours-item">
            <span
                type="button"
                [cpPresetColors]="userColours"
                [colorPicker]="colourPickerControl?.value || '#000'"
                [cpEyeDropper]="true"
                [cpDialogDisplay]="'inline'"
                [cpToggle]="true"
                [cpHeight]="'256px'"
                [cpWidth]="'148px'"
                [cpAlphaChannel]="'disabled'"
                [cpPositionRelativeToArrow]="true"
                (colorPickerChange)="colourPickerControl?.setValue($event)"
                [style.background]="colourPickerControl?.value || '#43E08C'">
            </span>
        </div>
    </div>


</ng-template>
