import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as StudyTemplateActions from './study-templates.actions';
import { StudyTemplate, TemplateStatus } from './study-templates.models';

export const STUDY_TEMPLATE_FEATURE_KEY = 'study_templates';

export interface StudyTemplateState extends EntityState<StudyTemplate> {
    status: TemplateStatus;
    error?: string | null; // last known error (if any)
}

export interface StudyTemplatePartialState {
    readonly [STUDY_TEMPLATE_FEATURE_KEY]: StudyTemplateState;
}

export const studyTemplateAdapter: EntityAdapter<StudyTemplate> =
    createEntityAdapter<StudyTemplate>({
        selectId: (model) => model.templateId,
    });

export const initialStudyTemplateState: StudyTemplateState =
    studyTemplateAdapter.getInitialState({
        status: TemplateStatus.INITIAL,
    });

const reducer = createReducer(
    initialStudyTemplateState,
    on(StudyTemplateActions.fetchStudyTemplates, (state) => {

        if (state.status === TemplateStatus.FETCHED) {
            return state;
        }

        return { ...state, status: TemplateStatus.FETCHING };
    }),

    on(StudyTemplateActions.fetchStudyTemplatesSuccess, (state, { results }) =>
        studyTemplateAdapter.upsertMany(results, { ...state, status: TemplateStatus.FETCHED })
    ),

    on(StudyTemplateActions.fetchStudyTemplatesFailure, (state, { error }) =>
        studyTemplateAdapter.setAll([], { ...state, error, status: TemplateStatus.INITIAL })
    )
);

export function studyTemplatesReducer(
    state: StudyTemplateState | undefined,
    action: Action
) {
    return reducer(state, action);
}
