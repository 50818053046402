<div class="rdc-template-xaxis"  [formGroup]="getAsFormGroup('chartProperties')">

    <ng-container *ngIf="getAsFormGroup('chartProperties')">

        <label class="text-hidden" *ngIf="xAxisTypeOptions.length > 1">
            <span>Type</span>
            <rdc-apps-select
                controlName="type"
                [options]="xAxisTypeOptions"
                [controlGroup]="getAsFormGroup('chartProperties.xAxis')">
            </rdc-apps-select>
        </label>

        <label class="text-hidden" *ngIf="getAsFormGroup('chartProperties.xAxis.partition') && xAxisOptionsForType.length > 1">
            <span>Show on X-axis</span>
            <rdc-apps-select
                controlName="partition"
                [options]="xAxisOptionsForType"
                [controlGroup]="getAsFormGroup('chartProperties.xAxis')"
                (valueSelected)="templateEventService.progress()">
            </rdc-apps-select>
        </label>

    </ng-container>

    <ng-container *ngIf="['calendarMonth', 'year'].includes(xAxisPartitionValue)">


        <ng-container *ngIf="getAsFormGroup('filters.timePeriod.specific') as fts">

            <!--  START YEAR END YEAR OPTIONS  -->
            <ng-container *ngIf="xAxisPartitionValue === 'year'">

                <label>
                    <span>Start year</span>
                    <rdc-apps-select
                        controlName="startYear"
                        [options]="startYearOpts"
                        [controlGroup]="fts">
                    </rdc-apps-select>
                </label>

                <label>
                    <span>End year</span>
                    <rdc-apps-select
                        controlName="endYear"
                        [options]="endYearOpts"
                        [controlGroup]="fts"
                        (valueSelected)="templateEventService.progress()">
                    </rdc-apps-select>
                </label>

            </ng-container>

        </ng-container>

    </ng-container>

</div>



