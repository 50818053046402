import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RegisteredInterestService {

    private storage: Storage = window.localStorage;

    private storageKey = 'rdc-registered-interest';

    set(key: string, value: boolean): void {

        const current = this.storageObject;

        this.storage.setItem(
            this.storageKey,
            JSON.stringify({
                ...current,
                [key]: value,
            }));
    }

    get storageObject(): Record<string, boolean> {
        return JSON.parse(this.storage.getItem(this.storageKey) || '{}');
    }

    registeredInterestIn(key: string): boolean {
        return this.storageObject[key] || false;
    }
}
