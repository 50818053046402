import { AppletItem } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';

export const appletGrowthCloudItems: AppletItem[] = [
    {
        icon: 'n-icon-benchmark',
        content: 'Benchmark analysis',
        subText: 'Growth at benchmark airports',
        type: 'link',
        launch: 'benchmark',
        iconSize: 'xxxs',
        disabled: false,
    },
    {
        icon: 'n-icon-plane',
        content: 'Market analysis',
        subText: 'Growth on networks or markets',
        type: 'link',
        launch: 'discover',
        iconSize: 'sm',
        disabled: false,
    },
    {
        icon: 'add_circle',
        content: 'Manual add',
        subText: 'Add routes to Target list',
        type: 'modal',
        launch: '/',
        iconSize: 'md-lg',
        disabled: false,
        noBg: true
    },
];

export const appletRiskCloudItems: AppletItem[] = [
    {
        icon: 'n-icon-plane',
        content: 'Airport analysis',
        subText: 'Identify weakest routes at an airport',
        type: 'link',
        launch: 'routes',
        iconSize: 'sm',
        disabled: false,
    },
    {
        icon: 'n-icon-benchmark',
        content: 'Benchmark analysis',
        subText: 'Compare weak routes at benchmarks',
        type: 'link',
        launch: 'benchmark',
        iconSize: 'xxxs',
        disabled: false,
    },
    {
        icon: 'add_circle',
        content: 'Manual add',
        subText: 'Add routes to Watchlist',
        type: 'modal',
        launch: '/',
        iconSize: 'md-lg',
        disabled: false,
        noBg: true
    },
];

// TODO populate cloudItems with correct content
export const appletNetworkCloudItems: AppletItem[] = [
    {
        icon: 'n-icon-plane',
        content: 'Market analysis',
        subText: 'An airline\'s performance at airport vs other airports',
        type: 'link',
        activityCode: 'rdc.q.apex.network.market.new',
        activityLaunchSource: 'Network.Home',
        launch: 'market',
        iconSize: 'sm',
        disabled: false,
    },
    {
        icon: 'location_on',
        content: 'Destination analysis',
        subText: 'An airline\'s performance to destinations from an airport',
        activityCode: 'rdc.q.apex.network.destination.new',
        activityLaunchSource: 'Network.Home',
        type: 'link',
        launch: 'destination',
        iconSize: 'xxs',
        disabled: false,
        fill: true,
    },
    {
        icon: 'timer',
        content: 'Time analysis',
        subText: 'An airline\'s performance at an airport over time',
        activityCode: 'rdc.q.apex.network.time.new',
        activityLaunchSource: 'Network.Home',
        type: 'link',
        launch: 'time',
        iconSize: 'xxs',
        disabled: false,
        fill: true,
    },
];
