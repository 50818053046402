import { takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getLoadingMessage } from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';

@Component({
    standalone: true,
    selector: 'rdc-apps-loading-overlay',
    templateUrl: './loading-overlay.component.html',
    styleUrls: [ './loading-overlay.component.scss' ],
    imports: [ CommonModule ],
})
export class LoadingOverlayComponent extends RdcComponentUtils implements OnInit {

    @Input() position: 'fixed' | 'absolute' = 'absolute';

    @Input() left = 0;

    @Input() backgroundStyle = 'radial-gradient(rgba(255,255,255,1), rgba(255,255,255,0.25))';

    @Input() placeContent = 'center center';

    @Input() size: 'sm' | 'md' = 'md';

    @Input() message = '';

    loadingMessage = 'Loading...';

    constructor(
        private store$: Store
    ) {
        super();
    }

    ngOnInit(): void {
        if(this.message) {
            this.loadingMessage = this.message;

            return;
        }

        this.store$.select(getLoadingMessage)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((message) => {
                setTimeout(() => this.loadingMessage = message);
            });
    }
}
