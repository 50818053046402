import { FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ApexValidators {
    static uniqueTagName(value: string, tagIndex: number, formArray: FormArray): ValidatorFn {

        return (): ValidationErrors | null => {

            let uniqueTagName = true;

            formArray.controls.forEach((control, controlIndex): void => {
                if (tagIndex !== controlIndex) {
                    uniqueTagName = uniqueTagName && value.toLowerCase() !== control.value.label.toLowerCase();

                    return;
                }
            });

            return uniqueTagName ? null : { notUniqueTagName: true };
        };
    }
}
