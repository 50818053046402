/**
 * Interface for the 'UserDetails' data
 */
export interface UserDetailsEntity {
    userDetails?: UserDetails;
}

export interface UserDetails {
    organisation: UserDetailsOrganisation;
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    jobTitle: string;
}

export interface UserDetailsOrganisation {
    name: string;
    isOnTrial: boolean;
    organisationId: string;
    activeCloudCodes: string[];
    experience: Experience;
}

export interface UpdateUserDetails {
    firstName: string;
    lastName: string;
    jobTitle: string;
}

export type Experience = 'Full' | 'Limited';
