import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccordionItemComponent } from './accordion-item.component';

@NgModule({
    imports: [ CommonModule ],
    declarations: [ AccordionItemComponent ],
    exports: [ AccordionItemComponent ],
})
export class AccordionItemModule {}
