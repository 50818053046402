import { take } from "rxjs";

import { Dialog, DialogRef } from "@angular/cdk/dialog";
import { CdkMenuTrigger } from "@angular/cdk/menu";
import { Overlay } from "@angular/cdk/overlay";
import {
    AfterContentChecked,
    Component,
    ContentChild,
    inject,
    Input,
    OnChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {
    connectedAbove,
    connectedBelow,
    connectedLeft,
    connectedRight,
    overlayPositions,
    RdcOverlayPosition
} from '@rdc-apps/rdc-shared/src/lib/constants';
import { ButtonModule } from "@rdc-apps/rdc-shared/src/lib/directives/button";
import { MenuButtonDirective } from '@rdc-apps/rdc-shared/src/lib/directives/menu-button';
import { IconModule } from "@rdc-apps/rdc-shared/src/lib/ui/icon";

@Component({
    standalone: true,
    selector: 'rdc-apps-menu-button',
    templateUrl: './menu-button.component.html',
    styleUrls: [ './menu-button.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [
        ButtonModule,
        CdkMenuTrigger,
        IconModule
    ]
})
export class MenuButtonComponent implements OnChanges, AfterContentChecked {

    @ViewChild('noMenu', { static: true }) noMenu!: TemplateRef<unknown>;

    @ContentChild(MenuButtonDirective) button: MenuButtonDirective | null = null;

    @Input() menu: TemplateRef<unknown> | null = null;

    @Input() disabled: boolean | undefined | null = null;

    @Input() menuPosition: RdcOverlayPosition = 'below';

    @Input() carrotPosition: RdcOverlayPosition | '' = '';

    overlayPositions = overlayPositions;

    btnDisabled = false;

    private readonly dialog = inject(Dialog);
    private readonly overlay = inject(Overlay);

    dialogRef!: DialogRef | null;

    ngOnChanges(): void {
        this.isDisabled();
    }

    ngAfterContentChecked(): void {
        this.isDisabled();
    }

    private isDisabled(): void {
        // eslint-disable-next-line
        if (this.disabled === true || this.disabled === false) {
            this.btnDisabled = this.disabled;

            return;
        }

        this.btnDisabled = !!this.button?.disabled;
    }


    onOpenMenu(selectTrigger: HTMLButtonElement | HTMLInputElement, focus = true, restoreFocus = true) {
        event?.preventDefault();

        this.dialogRef?.close();

        const chosenDefaultPosition = overlayPositions.get(this.menuPosition) || [ connectedBelow ];

        this.dialogRef = this.dialog.open(this.menu || this.noMenu, {
            backdropClass: 'none',
            panelClass: [ 'rdc-select-component-dialog' ],
            maxHeight: 250,
            minWidth: 150,
            autoFocus: focus ? 'first-tabbable' : 'non_existing_element',
            restoreFocus,
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(selectTrigger)
                .withPositions([
                    chosenDefaultPosition[0],
                    connectedAbove,
                    connectedRight,
                    connectedLeft
                ]),
        });

        this.dialogRef.closed
            .pipe(take(1))
            .subscribe(() => {
                this.dialogRef = null;
            });
    }

}
