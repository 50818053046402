import { createAction, props } from '@ngrx/store';

import { LoadingEntity } from './app-loading.models';

export const appLoading = createAction(
    '[App/Loading] Add loading key',
    props<{ loading: LoadingEntity }>()
);

export const appLoaded = createAction(
    '[App/Loading] Add loaded key',
    props<{ key: string }>()
);
