import { of, map } from 'rxjs';

import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from "@ngrx/router-store/data-persistence";
import { Store } from "@ngrx/store";
import { httpErrorAction } from "@rdc-apps/rdc-apex/src/lib/shared/data-access/store/error";
import { ToastType } from "@rdc-apps/rdc-shared/src/lib/data-access/models";
import { ToastService } from "@rdc-apps/rdc-shared/src/lib/ui/toast";

import {
    getAirlinesNetworkAction,
    getAirlinesNetworkFailureAction,
    getAirlinesNetworkSuccessAction,
    availabilityNetworkAction,
    availabilityNetworkFailureAction,
    availabilityNetworkSuccessAction,
    loadNetworkQueriesAction,
    loadNetworkQueriesSuccessAction,
    saveAsNetworkQueryAction,
    saveNetworkQueriesFailureAction,
    saveNetworkQueryAction,
    saveNetworkQuerySuccessAction,
    getDestinationsNetworkAction,
    getDestinationsNetworkSuccessAction,
    getDestinationsNetworkFailureAction,
    getAvailableYearsNetworkAction,
    getAvailableYearsNetworkSuccessAction,
    getAvailableYearsNetworkNetworkFailureAction,
    saveAsDestNetworkQueryAction,
    getDestAvailableYearsNetworkSuccessAction, getNetworkDataYTD, getNetworkDataYTDSuccess, getNetworkDataYTDFail
} from './network.actions';
import { NetworkService } from "./network.service";

@Injectable()
export class NetworkEffects {

    private actions$ = inject(Actions);
    private store$ = inject(Store);
    private networkService = inject(NetworkService);
    private toast = inject(ToastService);

    load$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(loadNetworkQueriesAction),
                fetch({
                    run: () => this.networkService.load()
                        .pipe(
                            map((networkQueries) => loadNetworkQueriesSuccessAction({ networkQueries }))
                        ),
                    onError: (action, response) =>
                        of(httpErrorAction({ action, response })),
                })
            )
        );

    getYTD$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(getNetworkDataYTD),
                fetch({
                    run: (params) => this.networkService.getTYDData(params)
                        .pipe(
                            map((YTDData) => getNetworkDataYTDSuccess({ YTDData }))
                        ),
                    onError: () => getNetworkDataYTDFail(),
                })
            )
        );

    save$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(saveNetworkQueryAction),
                fetch({
                    run: ( { networkQuery, newQuery }) => this.networkService.save(networkQuery)
                        .pipe(
                            map((studyId) => {
                                if (newQuery && networkQuery.studyType === 'destination') {
                                    this.store$.dispatch(saveAsNetworkQueryAction({ networkQuery, studyId }));

                                    this.toast.simpleToast(ToastType.DEFAULT, `New study saved as: ${ networkQuery.studyName }, You are now editing: ${ networkQuery.studyName }`, 8000);
                                } else if(newQuery) {
                                    this.store$.dispatch(saveAsDestNetworkQueryAction({ networkQuery, studyId }));

                                    this.toast.simpleToast(ToastType.DEFAULT, `New study saved as: ${ networkQuery.studyName }, You are now editing: ${ networkQuery.studyName }`, 8000);
                                }

                                this.toast.simpleToast(ToastType.SUCCESS, 'Study saved successfully', 5000);

                                return saveNetworkQuerySuccessAction({ networkQuery, studyId })
                            })
                        ),
                    onError: () =>
                        of(saveNetworkQueriesFailureAction({ error: 'Something went wrong processing your request, please try again later' })),
                })
            )
    );

    availability$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(availabilityNetworkAction),
                fetch({
                    run: ({ airlineId, originAirportId,  timeframe }) => this.networkService.availability(airlineId, originAirportId, timeframe)
                        .pipe(
                            map((response) => availabilityNetworkSuccessAction({ availability: response }))
                        ),
                    onError: () =>
                        of(availabilityNetworkFailureAction({ error: 'Something went wrong processing your request, please try again later' })),
                })
            )
    );

    airlines$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(getAirlinesNetworkAction),
                fetch({
                    run: () => this.networkService.airlines()
                        .pipe(
                            map((response) => getAirlinesNetworkSuccessAction({ results: response }))
                        ),
                    onError: () =>
                        of(getAirlinesNetworkFailureAction({ error: 'Something went wrong processing your request, please try again later' })),
                })
            )
    );

    destinations$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(getDestinationsNetworkAction),
                fetch({
                    run: ({ originAirportId,  airlineId, applet }) => this.networkService.destinations(originAirportId, airlineId, applet)
                        .pipe(
                            map((response) => getDestinationsNetworkSuccessAction({ results: response }))
                        ),
                    onError: () =>
                        of(getDestinationsNetworkFailureAction({ error: 'Something went wrong processing your request, please try again later' })),
                })
            )
    );

    availableYears$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(getAvailableYearsNetworkAction),
                fetch({
                    run: ({ availableYears, applet }) => this.networkService.availableYears(availableYears, applet)
                        .pipe(
                            map((response) => {

                                if(response.years) {
                                    return getDestAvailableYearsNetworkSuccessAction({ availableYears: response.years });
                                }

                                return getAvailableYearsNetworkSuccessAction({ availableYears: response });
                            })
                        ),
                    onError: () =>
                        of(getAvailableYearsNetworkNetworkFailureAction({ error: 'Something went wrong processing your request, please try again later' })),
                })
            )
    );
}
