import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
    QbFilterAutocompleteOption
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/autocomplete';

import { ChartSeriesType } from './chart-series-type';

export interface SeriesTypeDefinition {
    type: FormControl<string | null>;
    partition: FormControl<string | null>;
}

export interface SeriesDefTimePeriodSelection {
    partition: FormControl<string | null>;
    selected: FormControl<string | number | null>;
}

export interface SeriesDefinition {
    label: FormControl<string | null>;
    colour: FormControl<string | null>;
    attributeSelections: FormArray<FormControl<SeriesAttributeSelection | null>>;
    timePeriodSelection?: FormGroup<SeriesDefTimePeriodSelection>;
}

export interface SeriesAttributeSelection extends QbFilterAutocompleteOption {
    type: FormControl<string | null>;
    partition: FormControl<string | null>;
    selected: QbFilterAutocompleteOption;
}

export interface UQBSeries {
    type: FormControl<ChartSeriesType | null>;
    seriesDefinitions?: FormArray<FormGroup<SeriesTypeDefinition>>;
    series?: FormArray<FormGroup<SeriesDefinition>>;
}
