import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

export const tableExportTypes: Partial<RepoItem<string>>[] = [
    { label: 'CSV', code: 'csv' },
    { label: 'TSV', code: 'tsv' },
];

export const chartExportTypes: Partial<RepoItem<string>>[] = [
    { label: 'PNG', code: 'image/png' },
    { label: 'JPEG', code: 'image/jpeg' },
    { label: 'SVG', code: 'image/svg+xml' },
    { label: 'PDF', code: 'application/pdf' },
];
