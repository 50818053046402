import { animate, animateChild, query, state, style, transition, trigger } from '@angular/animations';
import { easing } from '@rdc-apps/rdc-shared/src/lib/constants';

export const dropInOut = trigger('dropInOut', [
    state('*', style({
        opacity: 1,
        height: '*',
    })),
    state('void', style({
        opacity: 0,
        height: '0px',
    })),
    transition('void <=> *', [
        animate(`500ms ${ easing.standard }`),
        query('@*', [
            animateChild(),
        ], { optional: true }),
    ]),
]);
