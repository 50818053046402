import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on, Action } from '@ngrx/store';

import * as ErrorActions from './error.actions';

export const ERROR_FEATURE_KEY = 'error';

export interface ErrorState {
    error: HttpErrorResponse;
}

export interface ErrorPartialState {
    readonly [ERROR_FEATURE_KEY]: ErrorState;
}
export const initialErrorState: ErrorState = {
    error: undefined as unknown as HttpErrorResponse,
};

const reducer = createReducer(
    initialErrorState,
    on(ErrorActions.httpErrorAction, (state, { response }) => ({
        error: response,
    })),
    on(ErrorActions.clearHttpErrorAction, () => initialErrorState)
);

export function errorReducer(state: ErrorState | undefined, action: Action) {
    return reducer(state, action);
}
