import { take } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TempUserLoginService } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/services';

@Component({
    selector: 'rdc-apps-temp-user-login',
    templateUrl: './temp-user-login.component.html',
    styleUrls: [ './temp-user-login.component.scss' ],
})
export class TempUserLoginComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private tempUserLoginService: TempUserLoginService
    ) {}

    ngOnInit(): void {
        // BELOW IS TEMP UNTIL CORRECT AUTHORIZATION IS IN PLACE
        const { userId } = this.route.snapshot.params;
        this.tempUserLoginService.login(userId)
            .pipe(
                take(1)
            )
            .subscribe();
    }
}
