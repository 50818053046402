import { FormBuilder, FormGroup, ɵFormGroupValue } from '@angular/forms';
import { hideAircraftGroupCodes } from "@rdc-apps/rdc-apex/src/lib/shared/constants";
import {
    ApexStudy,
    ChartStudyQueryRequest,
    QbFilterSelectedItem,
    qbOriginDestination,
    QueryRequestDataPoints
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { DataPoint, DataPointsEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points';
import { RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

export type TableDataPoints = Record<string, Record<string, boolean>>;

export class QueryBuilderUtils {

    static chartBuilderShouldHideAircraft(
        form: ɵFormGroupValue<ChartStudyQueryRequest>,
        datapoints: DataPoint[],
    ): boolean {
        if(!Object.hasOwn(form.dataPoints as any, 'dataPointCodes')) {
            return false;
        }

        const dataPoint = datapoints.find(({ code }) => form.dataPoints?.dataPointCodes?.includes(code));

        if(!dataPoint) {
            return false;
        }

        return dataPoint.datapointGroups.some(({ datapointGroupCode }) => hideAircraftGroupCodes.includes(datapointGroupCode));
    }

    static tableBuilderShouldHideAircraft(form: ɵFormGroupValue<ChartStudyQueryRequest>): boolean {

        const groups = Object.keys(form.dataPoints as TableDataPoints);

        const groupsWithDataPointsSelected = groups.filter((group) => Object.values((form.dataPoints as TableDataPoints)[group]).some((val) => val));

        return groupsWithDataPointsSelected.some((group) => hideAircraftGroupCodes.includes(group));
    }

    static isSavedStudy(chartRequest: FormGroup<ApexStudy>): boolean {
        return chartRequest.get('studyId')?.value === '00000000-0000-0000-0000-000000000000';
    }

    static timePeriodInXAxis(chartRequest: FormGroup<ApexStudy>): boolean {
        return chartRequest.get('queryRequest.chartProperties.xAxis.type')?.value === 'timePeriod';
    }

    static getTypeOfQuery(study: ɵFormGroupValue<ApexStudy>): { type: 'chart' | 'table' | 'scatter'; activityCode: 'multi' | 'table' | 'scatter' } {

        if (study.studyType === 'table') {
            return { type: 'table', activityCode: 'table' };
        }

        return {
            type: (study.studyType === 'scatter') ? 'scatter' : 'chart',
            activityCode: (study.studyType === 'scatter') ? 'scatter' : 'multi',
        };
    }

    static isTableStudy(study: ɵFormGroupValue<ApexStudy>): boolean {
        if (study.studyType === 'table') {
            return true;
        }

        return false;
    }

    static dataPointLabel(dataPointsData?: DataPointsEntity, dataPoints?: ɵFormGroupValue<QueryRequestDataPoints>): string {

        if (!dataPointsData) {
            return 'None';
        }

        const dataPointCode = (dataPoints?.dataPointCodes || [])[0];

        return dataPointsData?.datapoints
            .find(({ code }) => code === dataPointCode)?.label || 'None';
    }

    static isInDataPointGroups(groupNames: string[], datapointCode: string, dataPoints?: DataPointsEntity): boolean {

        const dataPoint = dataPoints?.datapoints.find(({ code }) => code === datapointCode);

        const groups = dataPoints?.datapointsGroups.filter(({ code }) => dataPoint?.datapointGroups.find(({ datapointGroupCode }) => datapointGroupCode ===  code));

        if (!groups?.length) {
            return false;
        }

        return groups
            .map(({ code }) => code)
            .some((code) => groupNames.includes(code));
    }

    static dropDownsForGroup(groupName: string, dataPointsData: DataPointsEntity | null | undefined): RepoItem<string>[] {
        return dataPointsData?.dropdowns?.dropdownOptionGroups
            ?.find((group) => group.code === groupName)
            ?.dropdownOptions || [];
    }

    static addAutoCompleteFilters(formBuilder: FormBuilder, form: FormGroup, autocompleteTypeFilters: string[], dataPointsData: DataPointsEntity | null | undefined): void {
        const formGroup = form.get('filters') as FormGroup;
        autocompleteTypeFilters.forEach((key) => {
            formGroup.addControl(key, formBuilder.group({
                type: formBuilder.control<qbOriginDestination>('all'),
                selected: formBuilder.array<QbFilterSelectedItem>([]),
            }) as any);
        });
    }

    static onlyDataPointGroupChecked(key: string, datapointGroups?: Record<string, Record<string, boolean>>): boolean {
        if (!datapointGroups) {
            return false;
        }

        const groups = { ...datapointGroups };

        delete groups['popular'];

        const groupChecked = JSON.stringify(groups[key] || '').includes('true');
        const othersChecked = JSON.stringify({ ...groups, [key]: null }).includes('true');

        return groupChecked && !othersChecked;
    }

}
