export const columnSettingsProperties = [
    'flex',
    'width',
    'minWidth',
    'widthOverride',
    'isNumerical',
    'actionable',
    'hidden',
    'sortAlias',
    'sortable',
    'movable',
    'backgroundColour',
    'headerTemplate',
    'grouping',
];
