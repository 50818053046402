import { map } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import {
    exportCloudRouteAction, exportCloudRouteFailureAction,
    exportCloudRouteSuccessAction
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/actions';
import { CloudExportService } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/services';

@Injectable()
export class CloudExportEffects {

    private actions$ = inject(Actions);
    private cloudExportService = inject(CloudExportService);

    export$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(exportCloudRouteAction),
                fetch({
                    run: ({ routes, exportData, cloudType }) => this.cloudExportService.export(routes, exportData, cloudType)
                        .pipe(
                            map((buffer) => exportCloudRouteSuccessAction({ buffer, exportData, cloudType }))
                        ),
                    onError: () => exportCloudRouteFailureAction({ error: 'Error exporting' }),
                })
            )
    );

    exportDownload$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(exportCloudRouteSuccessAction),
                fetch({
                    run: ({ buffer, exportData, cloudType }) => this.cloudExportService.downloadExportedFile(buffer, exportData, cloudType),
                })
            )
    );

}
