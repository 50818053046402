import { Injectable } from '@angular/core';
import { BaseCloudEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { TagEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/tags';

@Injectable({ providedIn: 'root' })
export class RouteTagsService {

    routeTags(routes: BaseCloudEntity[]): TagEntity[] {
        const routeTags: TagEntity[] = [];

        routes.forEach((route): void => {
            if (route.tags?.length) {
                routeTags.push(...route.tags);
            }
        });

        return routeTags
            .filter((tag,index,array): boolean =>
                array.findIndex((tagEntity): boolean =>
                    (tagEntity.label === tag.label)) === index).sort((a,b): number => {
                if (a.lastUpdatedUtc && b.lastUpdatedUtc) {
                    return a.lastUpdatedUtc > b.lastUpdatedUtc ? 1 : -1;
                }

                return 0;
            });
    }

}
