import { map, Observable } from "rxjs";

import { inject, Injectable } from "@angular/core";
import { appEnvironment } from "@rdc-apps/rdc-apex/src/lib/shared/environment";
import { RdcBaseHttpService } from "@rdc-apps/rdc-apex/src/lib/shared/utilities";
import { RdcResponseDto, RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

import {
    NetworkResponse,
    NetworkEntity,
    Availability,
    AvailableYears,
    AvailableYearsResponse
} from "./network.models";

@Injectable({ providedIn: 'root' })
export class NetworkService extends RdcBaseHttpService {

    environment = inject(appEnvironment);

    load(): Observable<NetworkEntity[]> {
        return this.http.get<RdcResponseDto<NetworkResponse>>(`${this.environment.api.baseUrl}/Study/network/load`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.studies)
            );
    }

    save(query: NetworkEntity): Observable<string> {
        return this.http.put<RdcResponseDto<{ studyId: string }>>(`${this.environment.api.baseUrl}/Study/network/save`, query,{ headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.studyId)
            );
    }

    availability(airlineId: number, originAirportId: number, timeframe: string): Observable<Availability> {

        return this.http.post<RdcResponseDto<Availability>>(`${this.environment.api.baseUrl}/network/timeperiod-availability`, { airlineId, originAirportId, timeframe },{ headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data)
            );
    }

    airlines(): Observable<RepoItem<string>[]> {
        return this.http.get<RdcResponseDto<{ results: RepoItem<string>[] }>>(`${ this.environment.api.baseUrl }/network/airlines`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.results)
            );
    }


    destinations(originAirportId: number, airlineId: number, applet?: 'destination' | 'time'): Observable<RepoItem<string>[]> {

        let url = `${this.environment.api.baseUrl}/Autocomplete/${originAirportId}/${airlineId}/destinationCountries`;

        if(applet === 'destination') {
            url = `${this.environment.api.baseUrl}/Autocomplete/destination/${originAirportId}/${airlineId}/destinationCountries`
        }

        return this.http.get<RdcResponseDto<{ results: RepoItem<string>[] }>>(url,{ headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.results)
            );
    }

    availableYears(availableYears: AvailableYears, applet: 'destination' | 'time' = 'time'): Observable<AvailableYearsResponse> {
        return this.http.post<RdcResponseDto<AvailableYearsResponse>>(`${this.environment.api.baseUrl}/Network/${applet}/available-years`, availableYears, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data)
            );
    }
}
