import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { CloudPopupModule } from '@rdc-apps/rdc-apex/src/lib/shared/directives/cloud-popup';
import { TooltipModule } from '@rdc-apps/rdc-shared/src/lib/directives/tooltip';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { SidebarItemComponent } from '@rdc-apps/rdc-shared/src/lib/ui/sidebar-item';

@Component({
    selector: 'rdc-apps-cloud-item',
    standalone: true,
    imports: [ CommonModule, CloudPopupModule, IconModule, RouterLinkActive, TooltipModule, RouterLink ],
    templateUrl: './cloud-item.component.html',
    styleUrls: [ './cloud-item.component.scss' ],
})
export class CloudItemComponent extends SidebarItemComponent {

    @Input() cloudOnboarded!: boolean | undefined;
    @Input() cloudActive!: boolean;
    @Input() cloudContent!: string;
    @Input() sidebarMenuTriggered!: boolean;
    @Input() sidebarMenuOpen!: boolean;

    @Output() cloudTrigger = new EventEmitter();

}
