import { catchError, of } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { appEnvironment } from "@rdc-apps/rdc-apex/src/lib/shared/environment";
import { RdcFeature } from "@rdc-apps/rdc-shared/src/lib/constants";

export const featureEnabledGuard = (feature: RdcFeature): CanActivateFn => () => {

        const router = inject(Router);

        const http = inject(HttpClient);

        const env = inject(appEnvironment);

        return new Promise((resolve) => http.get<{ features: { name: string; enabled: boolean }[] }>(`${ env.api.featureUrl }/ui/feature-flags/apex-ng`)
                .pipe(
                    catchError(() => of({
                        features: [
                            { name: feature, enabled: false },
                        ]
                    })),
                )
                .subscribe(({ features }) => {

                    const featureFlag = features.find(({ name }) => name === feature);

                    resolve(featureFlag?.enabled ? true : router.parseUrl('/error'));
                }));

    }



