import { TitleCasePipe } from '@angular/common';
import { ɵFormGroupValue } from '@angular/forms';
import { rdcTrialYearStart } from "@rdc-apps/rdc-apex/src/lib/shared/constants";
import { QueryRequestTimePeriodSpecific } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { FiltersDateRangeEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/filters-date-range';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

export class QbFiltersDateRangeUtils {

    private months = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
    ];

    static trialMinYear = 2018;

    private dateRangeLimits: FiltersDateRangeEntity = {
        startYear: 2017,
        endYear: new Date().getFullYear(),
        startMonth: 'february',
        endMonth: 'november',
    };

    private trialLimits: FiltersDateRangeEntity = {
        startYear: rdcTrialYearStart,
        endYear: rdcTrialYearStart + 1,
        startMonth: 'january',
        endMonth: 'december',
    };

    private titleCasePipe: TitleCasePipe;

    constructor(private limits?: FiltersDateRangeEntity | null | undefined, onTrial?: boolean) {
        this.dateRangeLimits = onTrial ? this.trialLimits : limits || this.dateRangeLimits;

        this.titleCasePipe = new TitleCasePipe();
    }

    get endYear(): number {
        return this.dateRangeLimits.endYear;
    }

    get selectableYearOptions(): number[] {
        const selectableYears: number[] = [];

        const startDate = this.dateRangeLimits.startYear;
        const endDate = this.dateRangeLimits.endYear;

        for (let year = startDate; year <= endDate; year++) {
            selectableYears.push(year);
        }

        return selectableYears.reverse();
    }

    selectableYearsAsRepoItems(startYear = 0): Partial<RepoItem<number>>[] {
        return this.selectableYearOptions
            .map((year) => ({
                label: `${year}`,
                code: year,
            }))
            .filter(({ code }) => code >= startYear);
    }

    get calendarMonths(): string[] {
        return this.months;
    }

    calendarMonthsRepoItems(
        startMonth = 'january',
        includeNextYr = true,
        year = 2000,
        startAndEndSameYr = false,
    ): Partial<RepoItem<string>>[] {

        if(startAndEndSameYr) {

            let monthsInNextYr: Partial<RepoItem<string>>[] = [];

            const monthsInCurrentYr = this.months
                .filter((m, index) => index >= this.months.findIndex((month) => month === startMonth))
                .map((month) => ({
                    code: month,
                    label: this.titleCasePipe.transform(month),
                }));

            if(includeNextYr) {
                monthsInNextYr = this.months
                    .filter((m, index) => index < this.months.findIndex((month) => month === startMonth))
                    .map((month) => ({
                        code: month,
                        label: this.titleCasePipe.transform(`following ${ month }`),
                    }));

                if(monthsInNextYr.length) {
                    return [
                        ...monthsInCurrentYr,
                        ...this.applyDateLimitsToMonths(monthsInNextYr, year + 1)
                    ];
                }
            }

            return this.applyDateLimitsToMonths(monthsInCurrentYr, year);
        }

        const monthsAsRepoItems = this.months
            .map((month) => ({
                label: this.titleCasePipe.transform(month),
                code: month,
            }));

        return this.applyDateLimitsToMonths(monthsAsRepoItems, year);
    }


    private applyDateLimitsToMonths(items: Partial<RepoItem<string>>[], year = 2000): Partial<RepoItem<string>>[] {
        return items.filter((month, i) => {

            const { startMonth, endMonth, startYear: stYear, endYear: enYear } = this.dateRangeLimits;

            if(stYear === year) {
                const indexOfStartMonth = items.findIndex((mo) => mo.code === startMonth.toLowerCase());

                if(indexOfStartMonth < 0) {
                    return true;
                }

                return i >= indexOfStartMonth;
            }

            if(enYear === year) {
                const indexOfEndMonth = items.findIndex((mo) => mo.code === endMonth.toLowerCase());

                if(indexOfEndMonth < 0) {
                    return true;
                }

                return i <= indexOfEndMonth;
            }

            return true;
        });
    }

    rangeSpansTwoYears(selectedMonths: ɵFormGroupValue<QueryRequestTimePeriodSpecific>): boolean {
        const startMonthIndex = this.months.findIndex((month) => selectedMonths.startMonth === month);
        const endMonthIndex = this.months.findIndex((month) => selectedMonths.endMonth === month);

        return endMonthIndex < startMonthIndex;
    }

    endMonthInNextYr(startMonth = 'january', endMonth = 'january'): boolean {
        return this.calendarMonths.indexOf(endMonth) < this.calendarMonths.indexOf(startMonth);
    }
}
