import { animate, style, transition, trigger } from '@angular/animations';

export const slideOutAnimationSpeed = 500;
export const slideOutAnimation = trigger('slideOut', [
    transition(':leave',[
        animate(`${slideOutAnimationSpeed}ms ease-in-out`, style( {
            height: 0,
            opacity: 0,
            margin: 0,
        })),
    ]),
]);
