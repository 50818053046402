import { Injectable } from '@angular/core';
import { RouteOption } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';

@Injectable({ providedIn: 'root' })
export class FilteredBenchmarkRoutesService {

    filteredBenchMarkRoutes(searchValue: string, benchmarkAirports: RouteOption[]): RouteOption[] {
        return benchmarkAirports.filter((route) =>
            route.label.toLowerCase().includes(searchValue.toLowerCase()) ||
            route.iataCode.toLowerCase().includes(searchValue.toLowerCase()) ||
            route.icaoCode.toLowerCase().includes(searchValue.toLowerCase()));
    }

}
