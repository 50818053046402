import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataPoint } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points';

@Pipe({
    name: 'qpFilter',
})
export class DpFilterPipe implements PipeTransform {

    transform(
        items: KeyValue<string, FormControl<boolean>>[],
        dataPoints: DataPoint[],
        filter: string
    ): KeyValue<string, FormControl<boolean>>[] {
        if (!items) {
            return [];
        }

        if (!filter || filter.length === 0) {
            return items;
        }

        const filterToLower = filter.toLowerCase();

        return items.filter((item) =>
            dataPoints.find((dp) => {
                const codeMatches = dp.code.toLowerCase().includes(filterToLower);
                const labelMatches = dp.label.toLowerCase().includes(filterToLower);

                return dp.code === item.key && (labelMatches || codeMatches);
            })
        );
    }
}
