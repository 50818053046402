import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    standalone: true,
    name: 'templateStrValue',
})
export class TemplateStringValuePipe implements PipeTransform {

    transform(templateString: string): { key: string; label: string } | null {

        if (!templateString) {
            return null;
        }

        const splitString = templateString.split('|');

        return {
            key: splitString[0].trim(),
            label: splitString[1] || splitString[0],
        };
    }

}
