import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TagsFilterPipeModule } from '@rdc-apps/rdc-apex/src/lib/shared/pipes/tags-filter';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { DialogItemModule } from '@rdc-apps/rdc-shared/src/lib/directives/dialog-item';
import { RdcMenuItemModule } from '@rdc-apps/rdc-shared/src/lib/directives/rdc-menu-item';
import { TooltipModule } from '@rdc-apps/rdc-shared/src/lib/directives/tooltip';
import { RdcFilterPipe } from "@rdc-apps/rdc-shared/src/lib/pipes/filter";
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { TagFilterComponent } from '@rdc-apps/rdc-shared/src/lib/ui/tag-filter';

import { CompactSavedQueriesMenuComponent } from './compact-saved-queries-menu.component';

@NgModule({
    imports: [
        CommonModule,
        CdkMenuModule,
        RdcMenuItemModule,
        IconModule,
        FormsModule,
        ReactiveFormsModule,
        RouterLink,
        ButtonModule,
        DialogItemModule,
        TagsFilterPipeModule,
        TooltipModule,
        RdcFilterPipe,
        TagFilterComponent,
    ],
    declarations: [ CompactSavedQueriesMenuComponent ],
    exports: [ CompactSavedQueriesMenuComponent ],
})
export class CompactSavedQueriesMenuModule {}
