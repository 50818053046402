import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as appLoadingActions from './app-loading.actions';
import { LoadingEntity } from './app-loading.models';

export const APP_LOADING_FEATURE_KEY = 'app-loading';

export type AppLoadingState = EntityState<LoadingEntity>;

export interface AppLoadingPartialState {
    readonly [APP_LOADING_FEATURE_KEY]: LoadingEntity;
}

export const appLoadingAdapter: EntityAdapter<LoadingEntity> =
    createEntityAdapter<LoadingEntity>({
        selectId: (model) => model.key,
    });

export const initialAppLoadingState: AppLoadingState = appLoadingAdapter.getInitialState();

const reducer = createReducer(
    initialAppLoadingState,
    on(appLoadingActions.appLoading, (state, { loading }) =>
        appLoadingAdapter.setOne(loading, { ...state })
    ),
    on(appLoadingActions.appLoaded, (state, { key }) =>
        appLoadingAdapter.removeOne(key, { ...state })
    )
);

export function appLoadingReducer(
    state: AppLoadingState | undefined,
    action: Action
) {
    return reducer(state, action);
}
