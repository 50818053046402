import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RdcMenuCarrotComponent } from './rdc-menu-carrot.component';

@NgModule({
    imports: [ CommonModule ],
    declarations: [ RdcMenuCarrotComponent ],
    exports: [ RdcMenuCarrotComponent ],
})
export class RdcMenuCarrotModule {}
