import { map, Observable } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { RdcResponseDto } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

import { UserSettings } from './user-settings.models';
@Injectable({ providedIn: 'root' })
export class UserSettingsService extends RdcBaseHttpService {

    constructor(
        @Inject(appEnvironment) private environment: AppEnvironment
    ) {
        super();
    }

    get(): Observable<UserSettings> {
        // eslint-disable-next-line max-len
        return this.http.get<RdcResponseDto<UserSettings>>(`${this.environment.api.baseUrl}/Account/user/userSettings`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data)
            );
    }

    set(userSettings: UserSettings): Observable<UserSettings> {
        // eslint-disable-next-line max-len
        return this.http.put<RdcResponseDto<UserSettings>>(`${this.environment.api.baseUrl}/Account/user/userSettings`, userSettings, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data)
            );
    }
}
