import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class BreadcrumbBuilder {

    generateAdditionalContent(value: string): void {

        setTimeout(() => {
            const element = document.querySelector('.breadcrumb-container span');

            if (element) {
                element.textContent += ` - ${value}`;
            }
        });

    }
}
