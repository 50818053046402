import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

export interface AutocompleteResults {
    results: AutocompleteOption[];
}

export interface QbFilterAutocompleteOption {
    id: number;
    label: string;
    icaoCode: string;
    iataCode: string;
}

export interface AutocompleteOption extends Partial<RepoItem<string | number>> {
    id: string | number;
    label: string;
    type?: string;
    dataType?: string;
}

export interface AutocompleteDispatch {
    query: string;
    filter: string;
}

export interface MultiAutocompleteDispatch {
    query: string;
    filters: string[];
    limit?: number
}

