<rdc-apps-header title="Map preset colours"></rdc-apps-header>
<p>These colours are available to use for the Network map.</p>

<div class="map-colours-container">
    <rdc-apps-loading-overlay *ngIf="resetting" message="Resetting map colours..."></rdc-apps-loading-overlay>
    <div class="colours">
        <div class="colours-item" *ngFor="let key of keys; let index = index">
            <button
                rdcAppsButton
                rdcAppsTooltip
                tooltipPosition="right"
                [tooltipTemplate]="tooltipTemplates[key.type]"
                [tooltipOptions]="{ maxWidth: '155px' }"
                [(colorPicker)]="mapColourFormGroup.controls[key.type].value"
                [cpPresetColors]="[]"
                [disabled]="resetting"
                [cpAlphaChannel]="'disabled'"
                [cpPosition]="'top'"
                [cpPositionOffset]="'50%'"
                [cpHeight]="'256px'"
                [cpWidth]="'148px'"
                [cpPresetLabel]="'Presets'"
                [cpPositionRelativeToArrow]="true"
                [style.background-color]="mapColourFormGroup.controls[key.type].value"
                (colorPickerChange)="mapColourFormGroup.controls[key.type].patchValue($event)"
            ></button>
            <span>{{ key.label }}</span>
        </div>
        <a *ngIf="!resetting" (click)="onReset()">Reset to defaults</a>
    </div>

    <div id="settings-colour-map"></div>
</div>


<ng-template #landColour>
    <div class="text-tooltip">
        <h4>Land</h4>
        <p>The colour of the land on the generated map</p>
    </div>
</ng-template>

<ng-template #seaColour>
    <div class="text-tooltip">
        <h4>Sea</h4>
        <p>The colour of the sea on the generated map</p>
    </div>
</ng-template>

<ng-template #servedColour>
    <div class="text-tooltip">
        <h4>Routes</h4>
        <p>The colour of airports and route lines that are served from the chosen origin</p>
    </div>
</ng-template>

<ng-template #unservedColour>
    <div class="text-tooltip">
        <h4>Custom</h4>
        <p>The colour of airports and route lines that are unserved from the chosen origin</p>
    </div>
</ng-template>

<ng-template #borderColour>
    <div class="text-tooltip">
        <h4>Border lines</h4>
        <p>The colour of country and land borders</p>
    </div>
</ng-template>
