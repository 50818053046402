<ng-container *ngIf="dataPointsData">
    <rdc-apps-data-points-group-pills
        [style.display]="showGroupPills ? 'flex' : 'none'"
        *ngIf="dataPoints"
        [form]="dataPointsFormGroup"
        [studyType]="studyType"
        [dataPointsData]="dataPointsData.datapoints"
        [dataPointsMapped]="dataPointsMapped"
        [basicBuilder]="basicBuilder"
        (selectedDataPoints)="selectedDataPoints($event)"
        (salesforceLead)="salesforceLead.emit($event)"
    ></rdc-apps-data-points-group-pills>

    <rdc-apps-data-points-manual-selection
        *ngIf="dataPoints"
        [form]="dataPointsFormGroup"
        [studyType]="studyType"
        [showValidation]="showValidation"
        [perXOptionsForm]="perXOptionsFormGroup"
        [selectedDataPointsArray]="selectedDataPointsArray"
        [dataPointsMapped]="dataPointsMapped"
        [dataPointsData]="dataPointsData"
        [dataPoints]="dataPointsData.datapoints"
        [basicBuilder]="basicBuilder"
        (dataPointsSummaryText)="dataPointsSummaryText.emit($event)"
        (selectedDataPoints)="selectedDataPoints($event)"
        (salesforceLead)="salesforceLead.emit($event)"
    ></rdc-apps-data-points-manual-selection>
</ng-container>
