import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as UserSettingsActions from './user-settings.actions';
import { UserSettingsEntity } from './user-settings.models';

export const USER_SETTINGS_FEATURE_KEY = 'userSettings';

export interface UserSettingsState extends EntityState<UserSettingsEntity> {
    selectedId?: string | number; // which UserSettings record has been selected
    loaded: boolean; // has the UserSettings list been loaded
    error?: string | null; // last known error (if any)
}

export interface UserSettingsPartialState {
    readonly [USER_SETTINGS_FEATURE_KEY]: UserSettingsState;
}

export const userSettingsAdapter: EntityAdapter<UserSettingsEntity> =
    createEntityAdapter<UserSettingsEntity>({
        selectId: () => 'userSettingsState',
    });

export const initialUserSettingsState: UserSettingsState =
    userSettingsAdapter.getInitialState({
        // set initial required properties
        loaded: false,
    });

const reducer = createReducer(
    initialUserSettingsState,
    on(UserSettingsActions.getUserSettings, (state) => ({
        ...state,
        loaded: false,
        error: null,
    })),
    on(
        UserSettingsActions.getUserSettingsSuccess,
        UserSettingsActions.setUserUserSettingsSuccess,
        (state, { userSettings }) =>
            userSettingsAdapter.setOne({ userSettings }, { ...state, loaded: true })
    ),
    on(UserSettingsActions.getUserSettingsFailure, (state, { error }) => ({
        ...state,
        error,
    }))
);

export function userSettingsReducer(
    state: UserSettingsState | undefined,
    action: Action
) {
    return reducer(state, action);
}
