import { animate, group, state, style, transition, trigger } from "@angular/animations";

export const dataViewBtnCollapse = trigger('dataViewBtnCollapse', [
    state('open', style({
        opacity: 0,
        height: '*',
    })),
    state('closed', style({
        opacity: 0,
        height: 0,
    })),
    transition('open <=> closed', [
        group([
            animate(`250ms ease-in-out`),
        ]),
    ]),
]);

export const dataViewHideData = trigger('dataViewHideData', [
    state('open', style({
        opacity: 1,
    })),
    state('closed', style({
        opacity: 0,
    })),
    transition('open <=> closed', [
        group([
            animate(`250ms ease-in-out`),
        ]),
    ]),
]);
