<div
    *ngIf="control"
    class="validated-control"
    [class.validated-control--invalid]="showInvalid"
>
    <ng-content></ng-content>

    <div
        *ngIf="showToolTip && showInvalid"
        class="validated-control__tooltip"

    >
        <rdc-apps-icon
            rdcAppsTooltip
            tooltipPosition="right"
            [attr.id]="id"
            [tooltipText]="firstErrorMessage"
            size="xxs"
        >
            info
        </rdc-apps-icon>
    </div>
</div>
