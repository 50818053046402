import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SummaryGroupOrderModule } from '@rdc-apps/rdc-apex/src/lib/shared/pipes/summary-group-order';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { TooltipModule } from '@rdc-apps/rdc-shared/src/lib/directives/tooltip';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { PanelModule } from '@rdc-apps/rdc-shared/src/lib/ui/panel';
import { SelectAutocompleteModule } from "@rdc-apps/rdc-shared/src/lib/ui/select";
import { StepperModule } from '@rdc-apps/rdc-shared/src/lib/ui/stepper';
import { ValidatedControlModule } from '@rdc-apps/rdc-shared/src/lib/ui/validated-control';

import { OnboardingComponent } from './onboarding.component';

@NgModule({
    imports: [
        CommonModule,
        CdkStepperModule,
        StepperModule,
        ReactiveFormsModule,
        ValidatedControlModule,
        IconModule,
        ButtonModule,
        PanelModule,
        TooltipModule,
        SummaryGroupOrderModule,
        SelectAutocompleteModule,
    ],
    declarations: [
        OnboardingComponent,
    ],
})
export class OnboardingModule {}
