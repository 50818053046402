import { filter, Observable, of, Subscription, takeUntil } from 'rxjs';

import { Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input, OnChanges, OnInit,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    createTagsSuccess, getTagCount,
    getTagsLoading, setStudyTagsSuccess,
    TagEntity
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/tags';
import { OldAutocompleteComponent } from '@rdc-apps/rdc-shared/src/lib/ui/autocomplete';
import { ColorPickerService } from 'ngx-color-picker';
import { v4 as uuid } from 'uuid';

type cpPosition = 'auto' | 'top' | 'bottom' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

@Component({
    selector: 'rdc-apps-tag-creator',
    templateUrl: './tag-creator.component.html',
    styleUrls: [ './tag-creator.component.scss' ],
    providers: [ ColorPickerService ],
})
export class TagCreatorComponent extends OldAutocompleteComponent<TagEntity> implements OnInit, OnChanges {

    @Input() set tags(tags: TagEntity[]) {
        // clear listTags and colors array
        this.listTags = [];
        // populate listTags array
        this.listTags = tags.map((tag) => ({ ...tag }));
    }
    @Input() tagDisabled = false;
    @Input() colorPickerPosition: cpPosition = 'bottom';

    @Output() tagAdded: EventEmitter<TagEntity[]> = new EventEmitter<TagEntity[]>();

    @Output() userTagChange: EventEmitter<TagEntity[]> = new EventEmitter<TagEntity[]>();

    @Output() tagsRemoved: EventEmitter<string[]> = new EventEmitter<string[]>();

    @Input() focusOnEvent: { focusEmitter: EventEmitter<string>; identifier: string } | undefined = undefined;

    @ViewChild('tagsContainer', { static: true, read: ElementRef }) tagsContainer!: ElementRef;

    tagDefaultColor = '#f6f8fa';
    listTags: TagEntity[] = [];
    showDuplicateTagError = false;
    showEmptyTagError = false;
    hideAddTagButton: boolean | undefined;
    color: string[] = [];

    private focusEmitterSubscription: Subscription | undefined;


    presetColors: string[] = [
        '#B0EF0F',
        '#3FEF0F',
        '#0FEF4E',
        '#0FEFBF',
        '#0FB0EF',
        '#0F3FEF',
        '#4E0FEF',
        '#BF0FEF',
        '#EF0FB0',
        '#EF0F3F',
        '#EF4E0F',
        '#EFBF0F',
    ];

    constructor(
        private colorPickerService: ColorPickerService,
        private store: Store,
        actions: Actions,
        overlay: Overlay,
        overlayPositionBuilder: OverlayPositionBuilder,
        viewContainerRef: ViewContainerRef
    ) {
        super(
            overlay,
            overlayPositionBuilder,
            viewContainerRef,
            actions
        );
    }

    tagsLoading$: Observable<boolean> = of(false);
    tagCount$: Observable<number> = of(0);

    created = false;

    ngOnChanges(): void {
        this.focusEmitterSubscription?.unsubscribe();

        this.focusEmitterSubscription = this.focusOnEvent?.focusEmitter
            .pipe(
                takeUntil(this.componentDestroyed$),
                filter((identifier) => identifier !== undefined && this.focusOnEvent?.identifier === identifier)
            )
            .subscribe(() => {
                this.autocompleteInput?.nativeElement.focus()
            });
    }

    ngOnInit(): void {

        this.tagsLoading$ = this.store.select(getTagsLoading);
        this.tagCount$ = this.store.select(getTagCount);

        this.onActions([ createTagsSuccess ], ({ tags }) => {
            this.listTags = this.listTags.slice();

            if (this.listTags.find(({ id }) => id === tags[0].id)) {
                return;
            }

            this.tagAdded.emit(tags);
            this.created = true;
        });

        this.onActions([ setStudyTagsSuccess ], () => {
            setTimeout((): void => {
                if (!this.created) {
                    return;
                }
                this.created = false;

                const index = this.listTags.length - 1;

                const generatedTagHtml: HTMLElement | null = document.getElementById(`tag-${index}`);

                if (generatedTagHtml) {
                    generatedTagHtml.click();
                }
            });
        });

    }

    get disableAddTag(): boolean {
        return this.listTags.length >= 12;
    }

    onAddTag(value: string): void {
        const listTagsLength = this.listTags.length;

        if (listTagsLength === 12) {
            return;
        }

        const tag: TagEntity = {
            id: uuid(),
            label: value,
            hexaDecimalColour: this.presetColors[listTagsLength],
        };

        // check if the newly created tag is not an existing tag
        if (this.tagIndex(tag.label) !== -1) {
            this.showDuplicateTagError = true;
            this.autocompleteInput.nativeElement.focus();

            return;
        }

        // generate new tag
        if (value) {
            this.generateTag(tag);
            this.onReset();
        } else {
            this.showEmptyTagError = true;
            this.autocompleteInput.nativeElement.focus();
        }
    }

    onSelectTag(tag: TagEntity): void {
        const listTagsLength = this.listTags.length;

        if (listTagsLength === 12) {
            return;
        }

        this.overlayRef?.dispose();
        // check if the selected tag is not an existing tag
        if (this.tagIndex(tag.label) !== -1) {
            this.showDuplicateTagError = true;
            this.showEmptyTagError = false;

            setTimeout((): void => {
                this.autocompleteInput.nativeElement.value = tag.label;
                this.autocompleteInput.nativeElement.focus();
            });

            return;
        }

        this.showDuplicateTagError = false;
        this.showEmptyTagError = false;

        this.tagAdded.emit([ tag ]);

        this.onReset();
    }

    onClearTags(): void {
        this.autocompleteInput.nativeElement.value = '';

        this.showDuplicateTagError = false;

        this.tagsRemoved.emit(this.listTags.map(({ id }) => id));

        this.onReset();
    }

    onRemoveTag(tag: TagEntity): void {
        this.tagsRemoved.emit([ tag.id ]);
    }

    onInput(value: string): void {
        if (this.showEmptyTagError) {
            this.showEmptyTagError = false;
        }

        if (this.tagIndex(value) !== 0 && this.showDuplicateTagError) {
            this.showDuplicateTagError = false;
        }

        this.hideAddTagButton = this.autocompleteResults?.filter(({ id }): boolean =>
            !this.listTags.find((tag): boolean => id === tag.id)).some((tag): boolean => tag.label.toLowerCase() === value.toLowerCase());

        this.suggestFor(value);
    }

    onFocusIn(): void {
        this.autocomplete.emit('');

        if (!this.autocompleteInput.nativeElement.value) {
            this.showDuplicateTagError = false;
        }
    }

    onClearInput(): void {
        this.showDuplicateTagError = false;
        this.showEmptyTagError = false;
        this.overlayRef?.dispose();
        this.onReset();
    }

    override onReset(blur = true) {
        this.autocompleteInput.nativeElement.value = '';
        this.inFocus = false;

        if (blur) {
            this.autocompleteInput.nativeElement.blur();
        }
    }

    onColorPicker(color: string, index: number): void {
        // only change and set tag color if chosen color is not equal to the set preset color
        if (this.listTags[index].hexaDecimalColour !== this.color[index]) {
            this.listTags[index].hexaDecimalColour = color;
            this.color[index] = color;
        }

        this.userTagChange.emit(this.listTags);
    }

    setColor(color: string): string {
        // invert color based on color picker color selection
        const hsva = this.colorPickerService.stringToHsva(color);

        if (hsva) {
            // calculate color contrast
            const y = this.colorPickerService.hsvaToRgba(hsva);
            const r = y.r * 255;
            const g = y.g * 255;
            const b = y.b * 255;
            const yiq = (r * 299 + g * 587 + b * 114) / 1000;

            return (yiq >= 128) ? '#000000' : '#ffffff';
        }

        return '#000';
    }

    private tagIndex(value: string): number {
        return this.listTags.map((tag): string => tag.label).indexOf(value);
    }

    private generateTag(tag: TagEntity): void {
        this.overlayRef?.dispose();

        this.userTagChange.emit([ tag ]);
    }

    tagsWithAssignedExcluded(value: string): TagEntity[] {
        return value.trim().length === 1
            ? this.autocompleteResults = []
            : this.autocompleteResults?.filter(({ id }) => !this.listTags.find((tag) => id === tag.id)) || [];
    }
}
