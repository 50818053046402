import { map, Observable } from "rxjs";

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";

@Injectable({ providedIn: 'root' })
export class EmailGuard implements CanActivate {

    constructor(
        private router: Router,
        private oidcSecurityService: OidcSecurityService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.oidcSecurityService.isAuthenticated$
            .pipe(
                map(({ isAuthenticated }) => {
                    sessionStorage.setItem('emailRedirect', `${state.url}`);

                    if (isAuthenticated) {
                        return true;
                    }

                    this.router.navigate([ 'authorize' ]);

                    return false;
                })
            );
    }
}
