/**
 * Interface for the 'Query' data
 */
import {
    ChartSeriesType,
    QueryBuilderType,
    SeriesAttributeSelection
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { TagEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/tags';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

export interface QueryResultSet {
    totalCount: number;
    unfilteredRowCount: number;
    hasMore: boolean;
    tableColumns: { code: string; label: string; dataPointLabel?: string; dataPointCode?: string }[];
    tableRows: string[][];
    studyModel: QueryEntity,
    chartResponse?: ChartResultSet;
    dataCompleteness?: string[][];
}

export interface NetworkAppletResultsSets<T> {
    tableResponses?: TableResultSet;
    tableResponse?: TableResultSetWithRankedRows;
    chartResponses: T;
    studyDatapoints: StudyDatapoint[];
    allDatapointCodes: string[]
}

export interface TimeResultsSet {
    tableResponses: TimeTableResultSet;
    chartResponses: TimeChartResultSet;
}

export interface TimeTableResultSet {
    month: QueryResultSet,
    quarter: QueryResultSet,
    season: QueryResultSet,
    total: QueryResultSet,
}

export interface TimeChartResultSet {
    month?: QueryResultSet[],
    quarter?: QueryResultSet[],
    season?: QueryResultSet[],
    seasonTotal?: QueryResultSet[],
    year?: QueryResultSet[],
    total?: QueryResultSet[],
    absolute?: QueryResultSet[],
}

export interface TableResultSet {
   absolute?: QueryResultSet;
   total?: QueryResultSet;
   month?: QueryResultSet;
   season?: QueryResultSet;
   seasonTotal?: QueryResultSet;
   quarter?: QueryResultSet;
   // destination applet
   tableMonths?: QueryResultSet,
   tableQuarters?: QueryResultSet,
   tableSeasons?: QueryResultSet,
   totalResponse?: QueryResultSet,
   totalSeasonResponse?: QueryResultSet,
}

export interface TableResultSetWithRankedRows extends TableResultSet {
    rankedRows?: string[][];
}

export interface StudyDatapoint {
    code: string;
    yAxis: string;
    isComplete: boolean;
}

export interface ChartResultSet {
    xAxisLabel: string;
    yAxisLabel: string;
    rows: string[][];
    tableRows: string[][];
    columns: { code: string; label: string; colour: string; year?: string }[];
}

export interface MarketChartResultSet {
    datapointCode: string;
    response: {
        tableRows: string[][];
        chartResponse: {
            xAxisLabel: string;
            yAxisLabel: string;
            rows: string[][];
            columns: { code: string; label: string; colour: string }[];
        }
    }
}


// NOTE: not final, will change when API implemented
export interface QueryEntity extends Partial<RepoItem<unknown>>{
    studyId: string;
    cloudId: string;
    studyType: QueryBuilderType;
    studyName: string;
    tags: TagEntity[];
    pinned: boolean;
    createdUtc: string;
    lastRunUtc: string;
    lastUpdatedUtc: string;
    isCloudStudy: boolean
    isNetworkAppletStudy: boolean
    queryRequest: CompleteQuery;
}

export interface QueryXAxis {
    type: string;
    partition?: string;
    partitionOptions: {
        timeframe: 'specific' | 'relative';
        startMonth?: string;
        endMonth?: string;
        endYear?: number;
        startYear?: number;
        relativeMonths?: string;
    };
}

export interface QuerySeriesItem {
    name: string;
    label: string;
    colour: string;
    chartType: string;
    seriesType: string;
    timePeriodSelection?: {
        partition: string | null;
        selected: string | number | null;
    };
    attributeSelections: (SeriesAttributeSelection | null)[];
}

export interface StudySorting {
    dataPointAttributeCode: string;
    sortDirection: 'descending' | 'ascending';
}

export interface CompleteQuery {
    dataPoints?: any;
    dataPoint?: string;
    chartProperties?: StudyChartProperties;
    resultSorting?: StudySorting | null;
    filters: {
        cabinTypes?: {
            types: string[];
        };
        emissionScheme?: {
            types: string[];
        };
        schedulesFilters?: {
            nonStopServices: boolean;
            franchisePartners: boolean;
        };
        timePeriod?: {
            type: string;
            flow?: string;
            specific?: {
                startYear: number;
                startMonth?: string;
                endYear: number;
                endMonth?: string;
                type: string;
                flow: string;
                year?: number;
                yearStartsMonth?: string;
                months?: Record<string, boolean | null>;
                seasons?: Record<string, boolean | null>;
                quarters?: Record<string, boolean | null>;
            };
            relative?: {
                relativeMonths: string;
            };
        };
        origin?: {
            type: string | null;
            selected: Partial<RepoItem<string>>[];
        };
        destination?: {
            type: string | null;
            selected: Partial<RepoItem<string>>[];
        };
        aircraft?: {
            type: string | null;
            selected: Partial<RepoItem<string>>[];
        };
        airline?: {
            type: string | null;
            selected: Partial<RepoItem<string>>[];
        };
    };
    summaries?: Record<string, boolean | string | null>;
    adjustments?: Record<string, boolean | string | null>;
}

export interface StudyChartProperties {
    xAxis: QueryXAxis;
    seriesDefinitions?: { type: string; partition: string }[];
    type: ChartSeriesType;
    scatter?: {
        groupBy: string;
    };
    series: QuerySeriesItem[];
    startMonth?: string;
    singleSeriesColour?: string;
    year?: number;
}

export interface RsiStudyEntity {
    PlannedOriginAirportId: number;
    BenchmarkAirports: number[];
    FilterContinentIds: number[];
    FilterCountryIds: number[];
    TargetAirlines: number[];
    Metric: string;
}
export interface RsiDiscoverStudyEntity {
    PlannedOriginAirportId: number;
    FilterContinentId: number | null;
    FilterCountryId: number | null;
    TargetAirlineId: number | null;
    Metric: string;
}

export interface RsiRiskRouteStudyEntity {
    OriginAirportId: number;
    FilterContinentIds: number[],
    BenchmarkAirports?: number[],
    TargetAirlines: number[],
    FilterCountryIds:  number[],
    Metric: string,
}

export interface MarketStudyEntity {
    airlineId: number;
    originAirportId: number | null;
    versus: string | null;
    timeframe: string | null;
}

export interface TimeStudyEntity {
    studyId: string;
    airline: Partial<RepoItem<unknown>>;
    originAirport: Partial<RepoItem<unknown>>;
    destinationCountryId: number;
    startYear: number;
    endYear: number;
    networkTimeAnalysisType: string
}


export interface DestinationResultsSet {
    chartResponses: QueryResultSet[];
    studyDatapoints: StudyDatapoint[];
    tableMonths: QueryResultSet;
    tableQuarters: QueryResultSet;
    tableSeasons: QueryResultSet;
    // destination specific
    totalResponse: DestQueryResultSet;
    totalSeasonResponse: DestQueryResultSet;
}

export interface DestQueryResultSet {
    absolute: QueryResultSet,
    rankedRows: string[][];
}


export interface NetworkDestinationQuery {
    studyId: string,
    airline: RepoItem<string>,
    originAirport: RepoItem<string>,
    destinationAirport?: Partial<RepoItem<string>>,
    destinationCountryId?: string | number | null | undefined,
    focusYear: number,
    comparisonYear: number,
    segment: string,
}
