// eslint-disable-next-line no-shadow
import { ɵFormGroupValue } from '@angular/forms';
import { ApexStudy } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';

// eslint-disable-next-line no-shadow
export enum TemplateStatus {
    INITIAL= 0,
    FETCHING = 1,
    FETCHED= 2,
}

export interface RdcTemplateResponse {
    templates: StudyTemplate[];
}

export interface TemplateSetup {
    template: StudyTemplate;
    source: string;
}

export interface StudyTemplate {
    templateId: string;
    code: string;
    label: string;
    description: string;
    templateText: string;
    templateOptions: StudyTemplateOptions;
    templateStudyModel: ɵFormGroupValue<ApexStudy>;
    createdUtc: string;
    lastUpdatedUtc: string;
}

export interface StudyTemplateOptions {
    comparisonLimits?: { index: number; isLocked: boolean; limit: number }[];
    filterLimits?: StudyTemplateFilterLimits[];
    xAxisLimits?: {
        availableTypeCodes?: string[];
        availablePartitionCodes?: string[];
    };
    seriesLimits?: {
        availableTypeCodes?: string[];
        availablePartitionCodes?: string[];
    };
    maxDateRange?: number;
}

export interface StudyTemplateFilterLimits {
    filter: string;
    limit?: number;
    isLocked?: boolean;
}
