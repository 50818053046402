import { takeUntil } from 'rxjs';

import { CdkMenuTrigger } from '@angular/cdk/menu';
import { KeyValue } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges, OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Store } from "@ngrx/store";
import { QueryBuilderType } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import {
    DataPoint,
    DataPointsData, dataPointSelectedAction,
    DataPointsSelection
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points';
import { overlayPositions } from '@rdc-apps/rdc-shared/src/lib/constants';
import { TooltipsService } from '@rdc-apps/rdc-shared/src/lib/data-access/local-storage';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';

@Component({
    selector: 'rdc-apps-data-points-group-pills',
    templateUrl: './data-points-group-pills.component.html',
    styleUrls: [ './data-points-group-pills.component.scss' ],
})
export class DataPointsGroupPillsComponent extends RdcComponentUtils implements OnInit, AfterViewInit, OnChanges {

    @Input() form!: FormGroup;
    @Input() dataPointsMapped!: DataPointsData[];
    @Input() studyType!: FormControl;
    @Input() dataPointsData!: { label: string; code: string; description?: string }[];
    @Input() basicBuilder!: boolean;

    @Output() selectedDataPoints = new EventEmitter<DataPointsSelection>();
    @Output() salesforceLead = new EventEmitter<string>();

    @ViewChildren('buttonElement') buttonElement: QueryList<ElementRef<HTMLDivElement>> = new QueryList<ElementRef<HTMLDivElement>>();
    @ViewChildren('openSelect') openSelect!: QueryList<CdkMenuTrigger>;

    overlayPositions = overlayPositions;
    dataPointDescriptions: Map<string, string> = new Map<string, string>();
    dataPointFormGroup!: FormGroup;
    controlFormGroup!: KeyValue<string, FormGroup<Record<string, FormControl<boolean>>>>;

    readonly LOCKED_ICON_SRC = '/assets/rdc-apex/images/lock.svg';

    constructor(public tooltips: TooltipsService, private store$: Store) {
        super();
    }

    get studyTypeTable(): boolean {
        return this.studyType.value === QueryBuilderType.Table;
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['form'] && !changes['form'].firstChange) {
            Object.entries(this.form.controls).forEach((control, index): void => {
                const htmlElement = this.buttonElement.get(index)?.nativeElement;

                if (htmlElement) {

                    this.form.get(control[0])?.valueChanges
                        .pipe(
                            takeUntil(this.componentDestroyed$),
                        )
                        .subscribe((value) => {

                            this.addRemoveSelectedClass(this.buttonElement.get(index)?.nativeElement, value);

                            this.selectedDataPoints.emit({ dataPointsGroupKey: control[0], value });

                            if (control[0] !== 'popular') {
                                const popularButtonHtmlElement = document.getElementById('popular') || undefined;

                                this.form.get('popular' as string)?.patchValue(value);

                                this.addRemoveSelectedClass(
                                    popularButtonHtmlElement,
                                    this.form.get('popular' as string)?.value
                                );
                            }

                            if (control[0] === 'popular') {
                                this.patchGroupsByPopularValue(value);
                            }
                        });
                }
            });
        }
    }

    ngOnInit(): void {
        this.dataPointsData?.forEach((dataPoint): Map<string, string> =>
            this.dataPointDescriptions.set(dataPoint.code, dataPoint.description || 'No description')
        );
    }

    ngAfterViewInit(): void {
        if (!this.studyTypeTable) {
            setTimeout(() => {
                const formValue = (this.form.controls['dataPointCodes'] as FormArray).controls[0]?.value;
                const filteredMappedDataPoints = this.dataPointsMapped.filter((dataPoints): boolean =>
                    dataPoints.dataPointsData.some((dataPoint): boolean =>
                        dataPoint.code === formValue))[0];

                this.setGroupPillStyle(
                    filteredMappedDataPoints,
                    (this.form.controls['dataPointCodes'] as FormArray).controls[0]?.value
                );

                this.form.valueChanges
                    .pipe(
                        takeUntil(this.componentDestroyed$)
                    )
                    .subscribe(() => {
                        this.setGroupPillStyle(
                            filteredMappedDataPoints,
                            (this.form.controls['dataPointCodes'] as FormArray).controls[0]?.value
                        );
                    });
            }, 500);
        }
    }

    groupLocked(dataPoint: DataPointsData): boolean {
        return dataPoint.dataPointsData.every((data): boolean => !data.isEnabled);
    }

    dataPointLocked(dataPoint: DataPointsData, index: number): boolean {
        return dataPoint.dataPointsData[index] ? !dataPoint.dataPointsData[index].isEnabled : false;
    }

    upgradeDataPoints(dataPoint: DataPointsData): boolean {
        return dataPoint.dataPointsData.some((dataPointData): boolean => !dataPointData.isEnabled);
    }

    getFullName(key: string): string {
        return this.dataPointsData.find((value: { code: string }) => value.code === key)?.label || 'Unknown';
    }

    allCheckedControls(groupKey: string, controls: KeyValue<string, FormControl<boolean>>[]): boolean {
        return this.allChecked(groupKey, controls);
    }

    onAddAll(groupKey: string, controls: KeyValue<string, FormControl<boolean>>[]): void {
        const setTo = !this.allChecked(groupKey, controls);

        const patch: Record<string, Record<string, boolean>> = { [groupKey]: {} };

        controls.forEach((control): void => {
            patch[groupKey][control.key] = setTo;
        });

        const groupValues = Object.values(patch);

        this.form.get(`${groupKey}`)?.patchValue(groupValues[0]);
        this.form.get(`${groupKey}`)?.markAsTouched();
    }

    showAddAllButton(dataPoint: DataPointsData): boolean {
        return dataPoint.dataPointsData.every((dp): boolean => dp.isEnabled) && dataPoint.dataPointsData.length > 1;
    }

    onGroupSelection(dataPoint: DataPointsData): void {
        this.dataPointFormGroup = this.form.get(dataPoint.code) as FormGroup;
        this.controlFormGroup = { key: dataPoint.code, value: this.dataPointFormGroup };
    }

    allChecked(formGroupName: string, dataPointControls: KeyValue<string, FormControl<boolean>>[]): boolean {
        return dataPointControls
            .map((keyVal): boolean => keyVal.value.value)
            .filter((checked: boolean): boolean => checked).length === dataPointControls.length;
    }

    hasControl(dataPoint: string, controlKey: string): boolean {
        return Boolean(this.form.get(`${dataPoint}.${controlKey}`));
    }

    addRemoveSelectedClass(htmlElement: HTMLElement | undefined, values: Partial<{ [key: string]: boolean }>): void {
        const allSelected = Object.values(values).every((value): boolean | undefined => value);
        const someSelected =  Object.values(values).some((value): boolean | undefined => value);

        if (allSelected) {
            htmlElement?.classList.add('all-selected');
        } else {
            htmlElement?.classList.remove('all-selected');
        }

        if (someSelected) {
            htmlElement?.classList.add('some-selected');
        } else {
            htmlElement?.classList.remove('some-selected');
        }
    }

    patchGroupsByPopularValue(value: Record<string, boolean>): void {
        const keys = Object.keys(value);
        const dataPointsArray: DataPointsData[] = [];

        keys.forEach((key): void => {
            const mappedDataPoint = this.dataPointsMapped.filter((mapped): string | undefined =>
                mapped.dataPointsData.find((point): boolean => point.code === key)?.code)
                .filter((point) => point.code !== 'popular');

            dataPointsArray.push(...mappedDataPoint);
        });

        const filteredDataPoints = [ ...new Set(dataPointsArray) ];

        filteredDataPoints.forEach((filteredDataPoint: DataPointsData): void => {
            this.form.get(filteredDataPoint.code)?.patchValue(value, { emitEvent: false });
            this.addRemoveSelectedClass(
                document.getElementById(filteredDataPoint.code) || undefined,
                this.form.get(filteredDataPoint.code)?.value
            );

            this.selectedDataPoints.emit({
                dataPointsGroupKey: filteredDataPoint.code,
                value: this.form.get(filteredDataPoint.code)?.value,
            });
        });

        // fix for the bug in summaries component when switching between
        // basic and extended qb
        this.store$.dispatch(dataPointSelectedAction());
    }

    isChecked(formValue: string[] | undefined, dataPointCode: string): boolean {
        return formValue ? formValue[0] === dataPointCode : false;
    }

    onChange(dataPointsData: DataPointsData, dataPoint: DataPoint, closeIndex = -1): void {

        if (closeIndex >= 0) {
            this.openSelect.get(closeIndex)?.close();
        }

        // patch value to form
        (this.form.controls['dataPointCodes'] as FormArray).controls[0]?.patchValue(dataPoint.code);

        this.setGroupPillStyle(dataPointsData, dataPoint.code);

        this.form.markAsTouched();
    }

    setGroupPillStyle(dataPointsData: DataPointsData, dataPointCode: string): void {
        const filteredMappedDataPoints = this.dataPointsMapped.filter((dataPoints): boolean =>
            dataPoints.dataPointsData.some((dataPoint): boolean =>
                dataPoint.code === dataPointCode));

        // remove existing class on each group button
        this.buttonElement.forEach((elementRef): void => elementRef.nativeElement.classList.remove('some-selected'));

        // loop through filtered mapped data points array and apply class
        filteredMappedDataPoints.forEach((dataPointData): void => {
            this.buttonElement.find((elementRef): boolean =>
                elementRef.nativeElement.id === dataPointData.code)?.nativeElement.classList.add('some-selected');
        });

        if (dataPointsData) {

            this.selectedDataPoints.emit({
                dataPointsGroupKey: dataPointsData.code,
                value: { [dataPointCode]: true },
            });
        }
    }

}
