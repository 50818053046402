import { debounce, interval, Subject } from "rxjs";

import { ChangeDetectorRef, inject, Pipe, PipeTransform, Signal, signal, WritableSignal } from "@angular/core";
import { getValForTruncatedNumber } from "functions";

import {
    RdcDataTableColumn,
    RdcDataTableRowState,
    RdcDataTableRowStateOptions,
    RdcDataTableSort
} from './table.component';

@Pipe({ name: 'clientTableSort', standalone: true })
export class TableClientFilterPipe implements PipeTransform {

    pipeOutput!: WritableSignal<string[][]>;

    defaultOutputSignal = signal<string[][]>([]);

    run = new Subject<{
        rows: string[][],
        columns: RdcDataTableColumn[],
        sorting: RdcDataTableSort | undefined | null,
        sortOnServer: boolean,
        sortedArr?: WritableSignal<string[][]>
    }>();

    constructor() {
        this.run
            .pipe(debounce(() => interval(50)))
            .subscribe((a) => {

                const { rows, columns, sorting, sortOnServer } = a;

                const endsWithNumSpaceSingleChar = /[0-9]\s[a-z]$/i;

                const colIndex = columns.findIndex(({ code }) => code === sorting?.columnCode);

                if(sortOnServer || !sorting || colIndex < 0) {
                    this.pipeOutput.set([ ...rows ]); // do nothing

                    return;
                }

                const isNumerical = columns[colIndex]?.isNumerical;

                this.pipeOutput.set([ ...rows ].sort((rowA, rowB) => {

                    if (isNumerical) {

                        let rowAToRawNumberString = 0;
                        let rowBToRawNumberString = 0;

                        try {
                            // remove all non 0-9 characters from number strings ('92,000.50' becomes '9200050')
                            // so it can be converted with Number() function
                            rowBToRawNumberString = Number(rowB[colIndex]
                                .replace(/[^\d.-]/g, '') // remove any non-numerical related chars
                                .replaceAll('.', '')); // remove any instances of '.' in the case of point-formatting

                            rowAToRawNumberString = Number(rowA[colIndex]
                                .replace(/[^\d.-]/g, '')
                                .replaceAll('.', ''));

                        } catch {
                            //
                        }

                        if(endsWithNumSpaceSingleChar.test(rowA[colIndex])) {
                            rowAToRawNumberString = getValForTruncatedNumber(rowA[colIndex], rowAToRawNumberString);
                        }
                        if(endsWithNumSpaceSingleChar.test(rowB[colIndex])) {
                            rowBToRawNumberString = getValForTruncatedNumber(rowB[colIndex], rowBToRawNumberString);
                        }

                        if(sorting?.sortDirection === 'descending') {
                            return rowBToRawNumberString - rowAToRawNumberString;
                        }

                        // else descending
                        return rowAToRawNumberString - rowBToRawNumberString;
                    }

                    if(sorting?.sortDirection === 'ascending') {
                        return (rowA[colIndex] || '')
                            .replace(/\[[^()]*\]/, '')
                            .localeCompare(rowB[colIndex]?.replace(/\[[^()]*\]/, ''));
                    }

                    // else descending
                    return (rowB[colIndex] || '')
                        .replace(/\[[^()]*\]/, '')
                        .localeCompare(rowA[colIndex]?.replace(/\[[^()]*\]/, ''));
                }));
            });
    }

    transform(
        rows: string[][],
        columns: RdcDataTableColumn[],
        sorting: RdcDataTableSort | undefined | null,
        sortOnServer: boolean,
        sortedArr?: WritableSignal<string[][]>,
    ): Signal<string[][]> {
        this.pipeOutput = sortedArr || this.defaultOutputSignal;

        this.run.next({
            sortedArr,
            rows,
            columns,
            sorting,
            sortOnServer,
        });

        return this.pipeOutput;
    }

}

@Pipe({ name: 'formatIfNumerical', standalone: true })
export class FormatNumericalPipe implements PipeTransform {

    transform(value: string, isNumerical: boolean | undefined): { isNegative: boolean; value: string } {

        if(!value || !isNumerical || !value.length || !value.startsWith('-') || value === '-') {
            return {
                isNegative: false,
                value,
            };
        }

        return {
            isNegative: true,
            value: `(${ value })`.replace('-', ''),
        }
    }

}

@Pipe({ name: 'isSortingBy', standalone: true })
export class SortingByColumnPipe implements PipeTransform {
    transform(sorting: RdcDataTableSort | undefined | null, column: RdcDataTableColumn): { icon: string } | undefined {
        if(!sorting) {
            return undefined;
        }

        if([ column.code, column.sortAlias ].includes(sorting.columnCode)) {
            return { icon: sorting.sortDirection === 'descending' ? 'n-icon-sort-desc' : 'n-icon-sort-asc' };
        }

        return undefined;
    }
}

@Pipe({ name: 'rowsForColumn', standalone: true })
export class RowsForColumnPipe implements PipeTransform {

    transform(parsedRows: string[][], colIndex: number): string[] {
        return parsedRows.map((row) => row[colIndex]);
    }
}

@Pipe({ name: 'getRowState', standalone: true })
export class RowStatePipe implements PipeTransform {

    transform(rowStates: RdcDataTableRowState, row: string[], pipeTrigger: number): RdcDataTableRowStateOptions {
        return rowStates.get(row[0]) || { label: undefined, state: undefined };
    }
}

@Pipe({ name: 'hasGroupsAndWidth', standalone: true })
export class HasGroupsAndWidthPipe implements PipeTransform {

    transform(groups: { label: string; code: string; width: number; headerTemplate?: any }[]): boolean {
        return !!groups.some(({ label, headerTemplate }) => label || headerTemplate) && groups.every(({ width }) => !!width);
    }
}

@Pipe({ name: 'combined', standalone: true })
export class HeightCombined implements PipeTransform {

    cdr = inject(ChangeDetectorRef);

    transform(calculatedHeight: number, templateContainer: HTMLDivElement, active: boolean, vph: number, subRows?: unknown[]): number {

        this.cdr.detectChanges();

        const actionContHeight = templateContainer.getBoundingClientRect().height;

        if(subRows) {
            const subRowPx = (subRows?.length * 32);

            return subRowPx + actionContHeight;
        }

        let combined = (calculatedHeight + actionContHeight);

        if(active) {
            combined += 1;
        }

        if(combined < vph) {
            return combined;
        }

        return vph - 1;
    }
}
