<div
    *ngIf="controls"
    class="tag-management-item"
    [ngClass]="{ 'delete': deleteMode }"
>
    <ng-container *ngIf="!deleteMode">
        <div class="tag-management-item-colour-picker-wrapper">
            <div class="tag-management-item-colour-picker">
                <div
                    #ignoredInput
                    [(colorPicker)]="tags[index].hexaDecimalColour"
                    [cpPresetColors]="defaultColours"
                    [cpAlphaChannel]="'disabled'"
                    [cpDisableInput]="true"
                    [cpPosition]="'bottom'"
                    [cpPositionOffset]="'-135%'"
                    [cpPositionRelativeToArrow]="true"
                    [cpPresetLabel]="'Tags'"
                    [cpWidth]="'106px'"
                    [style.background-color]="tags[index].hexaDecimalColour"
                    [cpIgnoredElements]="[ ignoredButton, ignoredInput ]"
                    [(cpToggle)]="toggle"
                    (colorPickerClose)="onColourPickerClose(tags[index])"
                ></div>
                <button #ignoredButton (click)="toggle=!toggle"></button>
            </div>
        </div>

        <rdc-apps-validated-control
            controlName="label"
            [control]="label"
            [showValidation]="true"
            [validationMessages]="validationMessages"
        >
            <div class="form-group">
                <input
                    #tagManagementItem
                    class="tag-management-item-input"
                    type="text"
                    [value]="label.value"
                    (change)="onTagLabel($event)"
                >
            </div>
        </rdc-apps-validated-control>

        <div class="tag-management-item-actions">
            <button
                display="compact"
                rdcAppsButton
                rdcAppsIconButton
                type="button"
                (click)="deleteMode = true"
            >
                <rdc-apps-icon>close</rdc-apps-icon>
            </button>
        </div>
    </ng-container>

    <ng-container *ngIf="deleteMode">
        <div class="tag-management-item-delete">
            <p>Remove tag everywhere?</p>
            <div class="tag-management-item-delete-actions">
                <button
                    class="cancel"
                    display="compact"
                    rdcAppsButton
                    type="button"
                    (click)="onCancel()"
                >
                    Cancel
                </button>
                <button
                    class="confirm"
                    display="compact"
                    rdcAppsButton
                    type="button"
                    (click)="onDeleteTag(controls.value)"
                >
                    OK
                </button>
            </div>
        </div>
    </ng-container>
</div>
