import { map, Observable } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { RdcResponseDto } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

import { AutocompleteOption, AutocompleteResults } from './autocomplete.models';

@Injectable({ providedIn: 'root' })
export class AutocompleteService extends RdcBaseHttpService {

    constructor(
        @Inject(appEnvironment) private environment: AppEnvironment
    ) {
        super();
    }

    get(filter: string, query: string): Observable<AutocompleteOption[]> {
        return this.http.get<RdcResponseDto<AutocompleteResults>>(`${ this.environment.api.baseUrl }/autocomplete`, {
            headers: this.getTokenHeaders(),
            params: {
                type: filter.toLowerCase(),
                term: query,
                limit: 10,
            },
        }).pipe(map((response) => response.success.data.results));
    }

    post(filters: string[], query: string, limit = 10): Observable<AutocompleteOption[]> {
        return this.http.post<RdcResponseDto<AutocompleteResults>>(`${ this.environment.api.baseUrl }/autocomplete`,{
            types: filters,
            term: query,
            limit,
        }, {
            headers: this.getTokenHeaders(),
        }).pipe(map((response) => response.success.data.results));
    }

}
