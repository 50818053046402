export interface RdcErrorDetailDto {
    field: string;
    errors: string[];
}

export interface RdcErrorDto {
    error?: {
        message: string;
    };
    errors?: Record<string, string[]>;
    message?: string;
    details: RdcErrorDetailDto[];
    extraData?: Record<string, unknown>;
}

export interface RdcSuccessDto<T> {
    message: string;
    data: T;
}

export interface RdcResponseDto<T> {
    success: RdcSuccessDto<T>;
    error: RdcErrorDto;
    statusCode: string;
    status: number;
}
