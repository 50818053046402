import { map, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import {
    createSalesforceLead,
    createSalesforceLeadSuccess,
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/actions';
import { SalesforceLeadService } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/services';
import { httpErrorAction } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/error';
import { ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { ToastService } from '@rdc-apps/rdc-shared/src/lib/ui/toast';

@Injectable()
export class SalesforceEffects {
    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createSalesforceLead),
            fetch({
                run: ({ lead }) =>
                    this.salesforceLeadService.createSalesforceLead(lead).pipe(
                        map(() => {
                            this.toast.simpleToast(
                                ToastType.SUCCESS,
                                'Request to upgrade received! We will be in touch shortly.',
                                5000
                            );

                            return createSalesforceLeadSuccess();
                        })
                    ),
                onError: (action, response) => {
                    this.toast.simpleToast(
                        ToastType.ERROR,
                        'Something went wrong processing your request, please try again later.',
                        5000
                    );

                    return of(httpErrorAction({ action, response }));
                },
            })
        )
    );

    constructor(
        private actions$: Actions,
        private toast: ToastService,
        private salesforceLeadService: SalesforceLeadService
    ) {}
}
