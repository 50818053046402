import { createAction, props } from '@ngrx/store';

import { GrowthCreateRoute, GrowthUpdateRoute, GrowthEntity } from './growth.models';

export const getGrowthRouteAction= createAction(
    '[Growth Route] Get'
);

export const getGrowthRouteSuccessAction = createAction(
    '[Growth Route] Get Success',
    props<{ routes: GrowthEntity[] }>()
);

export const getGrowthRouteFailureAction= createAction(
    '[Growth Route] Get Failure'
);

export const createGrowthRouteAction= createAction(
    '[Growth Route] Create',
    props<{ route?: GrowthCreateRoute, routesSet?: GrowthCreateRoute[]; dispatchId?: string }>()
);


export const createGrowthRouteSuccessAction = createAction(
    '[Growth Route] Create Success',
    props<{ routes: GrowthEntity[]; dispatchId?: string }>()
);

export const createGrowthFailureAction= createAction(
    '[Growth Route] Create  Failure'
);

export const updateGrowthRouteAction= createAction(
    '[Growth Route] Update',
    props<{ route: GrowthUpdateRoute }>()
);

export const updateGrowthRouteSuccessAction= createAction(
    '[Growth Route] Update Success',
    props<{ routes: GrowthEntity[] }>()
);

export const deleteGrowthRouteAction= createAction(
    '[Growth Route] Delete',
    props<{ routeId: string }>()
);

export const deleteGrowthRouteSuccessAction= createAction(
    '[Growth Route] Delete Success',
    props<{ routes: GrowthEntity[] }>()
);

export const refreshGrowthRouteAction= createAction(
    '[Growth Route] Refresh'
);

export const refreshGrowthRouteSuccessAction= createAction(
    '[Growth Route] Refresh Success',
    props<{ routes: GrowthEntity[] }>()
);
