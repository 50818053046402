import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { RdcMenuItemModule } from '@rdc-apps/rdc-shared/src/lib/directives/rdc-menu-item';
import { TooltipModule } from '@rdc-apps/rdc-shared/src/lib/directives/tooltip';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { ColorPickerModule } from 'ngx-color-picker';

import { TagCreatorComponent } from './tag-creator.component';


@NgModule({
    imports: [
        ButtonModule,
        CommonModule,
        IconModule,
        CdkMenuModule,
        RdcMenuItemModule,
        ReactiveFormsModule,
        ColorPickerModule,
        TooltipModule,
    ],
    declarations: [ TagCreatorComponent ],
    exports: [ TagCreatorComponent ],
})
export class TagCreatorModule {}
