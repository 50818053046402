import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { RdcCarrotModule } from '@rdc-apps/rdc-shared/src/lib/directives/rdc-carrot';
import { TooltipModule } from '@rdc-apps/rdc-shared/src/lib/directives/tooltip';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';

import { DataPointsGroupPillsComponent } from './data-points-group-pills.component';

@NgModule({
    imports: [ CommonModule, IconModule, CdkMenuModule, RdcCarrotModule, ButtonModule, ReactiveFormsModule, TooltipModule ],
    declarations: [ DataPointsGroupPillsComponent ],
    exports: [
        DataPointsGroupPillsComponent,
    ],
})
export class DataPointsGroupPillsModule {}
