import { Observable, of, take, takeUntil } from 'rxjs';

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    createTags,
    deleteTags, deleteTagsSuccess,
    getAllTags,
    getTags,
    TagEntity
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/tags';
import { ApexValidators } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { TagsManagementFormGroup } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';
import { v4 as uuid } from 'uuid';

@Component({
    selector: 'rdc-apps-tag-management',
    templateUrl: './tag-management.component.html',
    styleUrls: [ './tag-management.component.scss' ],
})
export class TagManagementComponent extends RdcComponentUtils implements OnInit {

    @Input() form!: FormGroup;

    @ViewChild('tagManagementContainer') tagManagementContainer!: ElementRef<HTMLElement>;

    getTags$: Observable<TagEntity[]> = of([]);

    maxTags = 12;
    showMaxTagsLimit = false;
    tagsArray: TagEntity[] = [];
    defaultColours: string[] = [
        '#B0EF0F',
        '#3FEF0F',
        '#0FEF4E',
        '#0FEFBF',
        '#0FB0EF',
        '#0F3FEF',
        '#4E0FEF',
        '#BF0FEF',
        '#EF0FB0',
        '#EF0F3F',
        '#EF4E0F',
        '#EFBF0F',
    ];

    constructor(
        private store$: Store,
        private actions: Actions
    ) {
        super(actions);

        this.store$.dispatch(getTags());

        this.getTags$ = this.store$.select(getAllTags);
    }

    get tagsFormArray(): FormArray {
        return this.form.get('tags') as FormArray;
    }

    ngOnInit(): void {
        this.getTags$
            .pipe(
                take(1)
            )
            .subscribe((tags) => {
                this.tagsFormArray.clear();
                this.tagsArray = [];

                tags.forEach((tagItem): void => {
                    const tag = new FormGroup<TagsManagementFormGroup>({
                        id: new FormControl(tagItem.id, { nonNullable: true }),
                        label: new FormControl(tagItem.label, { nonNullable: true }),
                        hexaDecimalColour: new FormControl(tagItem.hexaDecimalColour, { nonNullable: true }),
                    });
                    this.tagsFormArray.push(tag);
                    this.tagsArray.push(tagItem);
                });

                this.tagsArray = JSON.parse(JSON.stringify(this.tagsArray));
            });

        this.tagsFormArray.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$)
            )
            .subscribe((tags) => {
                this.tagsArray = JSON.parse(JSON.stringify(tags));
            });
    }

    onAddManagementTag(): void {
        if (this.tagsFormArray.invalid) {
            return;
        }

        const tagsLength = this.tagsFormArray.length;
        const tag = new FormGroup<TagsManagementFormGroup>({
            id: new FormControl(`${uuid()}`, { nonNullable: true }),
            label: new FormControl(`Tag ${tagsLength + 1}`, {
                validators: [
                    Validators.required,
                    ApexValidators.uniqueTagName(`Tag ${tagsLength + 1}`, tagsLength, this.tagsFormArray),
                ],
                nonNullable: true,
            }),
            hexaDecimalColour: new FormControl(this.defaultColours[tagsLength], { nonNullable: true }),
        });

        if (tagsLength < this.maxTags) {
            this.tagsFormArray.push(tag);

            if (tag.valid) {
                this.store$.dispatch(createTags({ tags: [ tag.value as TagEntity ] }));
                this.tagsArray.push(tag.value as TagEntity);
            }
        } else {
            this.showMaxTagsLimit = true;
            this.tagManagementContainer.nativeElement.scrollTop = 0;
        }

    }

    onTagLabelValue(event: Event, tagIndex: number): void {
        const tagLabel = (event.target as HTMLInputElement).value.trim();

        this.tagsFormArray.controls[tagIndex].get('label')
            ?.setValidators( [
                Validators.required,
                ApexValidators.uniqueTagName(tagLabel, tagIndex, this.tagsFormArray),
            ]);

        this.tagsFormArray.controls[tagIndex].get('label')?.patchValue(tagLabel);

        this.tagsFormArray.controls.forEach((control, controlIndex): void => {

            if (tagIndex !== controlIndex && control.value.label) {
                control.get('label')?.setErrors(null);
            }
        });

        if (this.tagsFormArray.valid) {
            this.store$.dispatch(createTags({ tags: this.tagsFormArray.value }));
        }

    }

    onTagColour(tag: TagEntity, tagIndex: number): void {
        if (this.tagsFormArray.valid) {
            this.tagsFormArray.controls[tagIndex].get('hexaDecimalColour')?.patchValue(tag.hexaDecimalColour);

            this.store$.dispatch(createTags({ tags: [ tag ] }));
        }
    }

    onDeleteTag(tagId: TagEntity, index: number): void {
        this.showMaxTagsLimit = false;

        this.tagsFormArray.removeAt(index);

        this.store$.dispatch(deleteTags({ tagIds: [ tagId.id ] }));

        const filteredDuplicateTag = this.tagsFormArray.controls.filter((tag): boolean => tag.value.label === tagId.label)[0];

        if (filteredDuplicateTag) {
            filteredDuplicateTag.get('label')?.setErrors(null);

            this.onActions([ deleteTagsSuccess ], () => {
                this.store$.dispatch(createTags({ tags: [ filteredDuplicateTag?.value ] }));
            });
        }
    }
}
