import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TempUserLoginComponent } from './temp-user-login.component';

@NgModule({
    imports: [ CommonModule ],
    declarations: [ TempUserLoginComponent ],
})
export class TempUserLoginModule {}
