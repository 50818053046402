import { Observable, takeUntil } from 'rxjs';

import { DIALOG_DATA } from '@angular/cdk/dialog';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup, ɵFormGroupValue } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { QueryBuilderUtils } from '@rdc-apps/rdc-apex/src/lib/query-builder/utilities';
import {
    getSelectedId,
    QueryDataPointsConversionService,
    QueryEntity,
    QueryTagsService
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/query';
import { TagEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/tags';
import { TagsFormGroup } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';

@Component({
    selector: 'rdc-apps-compact-saved-queries-menu',
    templateUrl: './compact-saved-queries-menu.component.html',
    styleUrls: [ './compact-saved-queries-menu.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class CompactSavedQueriesMenuComponent extends RdcComponentUtils implements OnInit, AfterViewInit {

    @Input() limitHeight = true;
    @Input() filterable = true;
    @Input() showActive = false;
    @Input() queries: QueryEntity[] | null = null;

    @ViewChild('filter', { static: true }) filter!:  ElementRef<HTMLInputElement>;

    queryList: QueryEntity[] = [];

    selectedId$: Observable<string | undefined | null>;

    filtersForm: FormGroup<{ tags: FormArray<FormGroup<TagsFormGroup>> }> = new FormGroup({
        tags: new FormArray<FormGroup<TagsFormGroup>>([]),
    });

    filterTags: ɵFormGroupValue<TagsFormGroup>[] = [];
    tagsFromQueries: TagEntity[] | null = null;

    constructor(
        public router: Router,
        private store$: Store,
        private queryTagsService: QueryTagsService<QueryEntity>,
        @Inject(DIALOG_DATA) public data?: {
            filterable?: boolean;
            limitHeight?: boolean;
            activityTopBar: boolean;
            queries: QueryEntity[];
        }
    ) {
        super();
        this.selectedId$ = this.store$.select(getSelectedId);

        if (data) {
            this.limitHeight = typeof data.limitHeight !== 'undefined' ? data.limitHeight : this.limitHeight;
            this.filterable = typeof data.filterable !== 'undefined' ? data.filterable : this.filterable;
        }
    }

    ngOnInit(): void {
        this.queryList = this.queries || this.data?.queries || [];

        this.tagsFromQueries = this.queryTagsService.tagsFromQueries(this.queryList);
    }

    ngAfterViewInit(): void {
        this.filtersForm.get('tags')?.valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((tags) => this.filterTags = tags || []);
    }

    onRunQuery(query: QueryEntity): void {
        const queryType = QueryBuilderUtils.getTypeOfQuery(query as never);

        this.router.navigate(
            [ '/', 'query-builder', queryType.type, query.studyId ],
            {
                queryParams: {
                    ts: Date.now(),
                },
            });
    }

    onClear(): void {
        this.filter.nativeElement.value = '';
        this.filtersForm.reset();
    }

    onClearInput(): void {
        this.filter.nativeElement.value = '';
    }
}
