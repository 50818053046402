<section
    class="rdc-query-autocomplete-filter"
    *ngFor="let filter of autocompleteTypeFilters">

    <div *ngIf="!hideFilters.includes(filter)" class="rdc-query-autocomplete-filter-options">

        <label>
            <span>{{ filter | titlecase }}</span>
            <rdc-apps-select
                controlName="type"
                [maskSelected]="disabledControls.includes(filter) ? { label: 'Defined by series' } : null"
                [showValidation]="false"
                [disabled]="disabledControls.includes(filter)"
                [options]="optionsForFilter.get(filter) || []"
                [controlGroup]="filtersFormGroup(filter)"
                (valueSelected)="onFilterTypeChange(filter)">
            </rdc-apps-select>
        </label>

        <ng-container *ngIf="filtersFormGroup(filter, 'type')?.value !== 'all'">

            <label class="rdc-query-autocomplete-filter-options-type text-hidden">
                <span>{{ filter | titlecase }}</span>
                <ng-container [ngTemplateOutlet]="dropDownFilters.get(filtersFormGroup(filter).value.type) ? selectable : autoCompletable"></ng-container>
                <ng-template #selectable>
                    <rdc-apps-select
                        placeholder="Add filter"
                        [forceInvalid]="invalidAndTouched(filter)"
                        [filterable]="true"
                        [options]="dropDownFilters.get(filtersFormGroup(filter).value.type) || []"
                        (valueSelected)="onSelect(filter, $event)">
                    </rdc-apps-select>
                </ng-template>
                <ng-template #autoCompletable>
                    <rdc-apps-autocomplete
                        placeholder="Filter {{ filter }}"
                        [showValidation]="false"
                        [focusOnEvent]="{ focusEmitter: optionChangedEvent, identifier: filter }"
                        [options]="autoCompleteResults$ | async"
                        (autocomplete)="onAutocomplete(filtersFormGroup(filter, 'type').value, $event)"
                        (valueSelected)="onSelect(filter, $event)">
                    </rdc-apps-autocomplete>
                </ng-template>
            </label>

        </ng-container>

    </div>


    <div class="rdc-query-autocomplete-filter-selections">

        <div *ngIf="this.filtersFormGroup(filter, 'selected').invalid">
            <small class="error">* Select one or more items using the search input</small>
        </div>

        <article *ngFor="let value of filterGroupSelected(filter); let index = index">
            <span>{{ value.label }}</span>
            <button (click)="onRemoveSelected(filter, index)">
                <rdc-apps-icon size="sm">remove</rdc-apps-icon>
            </button>
        </article>

    </div>

</section>


