<div class="rdc-toast-padding" [ngClass]="{ closing }">
    <ng-container #container></ng-container>
</div>


<ng-template #defaultToast
             let-body="body"
             let-type="type">
    <div class="rdc-toast {{ type }}" [ngClass]="{ closing }">
        <span *ngIf="type === 'loading'" class="rdc-loading-spinner"></span>
        <div [innerHTML]="body"></div>
    </div>
</ng-template>
