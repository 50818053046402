/**
 * Interface for the 'Tags' data
 */

export interface TagsResults {
    results: TagEntity[];
}

export interface TagEntity {
    [key: string]: string | number | boolean | undefined;
    id: string;
    label: string;
    hexaDecimalColour: string;
    userId?: string;
    createdDateUtc?: string;
    lastUpdatedUtc?: string;
    checked?: boolean;
}
