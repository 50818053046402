import { filter, takeUntil } from 'rxjs';

import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { KeyValue } from '@angular/common';
import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NavigationStart, Router } from "@angular/router";
import { Actions } from '@ngrx/effects';
import { QueryBuilderType } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import {
    DataPoint,
    DataPointsData,
    DataPointsEntity,
    DataPointsSelection, DataPointsType
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points';
import { clearQuery } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/query';
import { TemplateEventService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { overlayPositions } from '@rdc-apps/rdc-shared/src/lib/constants';
import { TooltipsService } from '@rdc-apps/rdc-shared/src/lib/data-access/local-storage';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';

@Component({
    selector: 'rdc-apps-data-points-manual-selection',
    templateUrl: './data-points-manual-selection.component.html',
    styleUrls: [ './data-points-manual-selection.component.scss' ],
})
export class DataPointsManualSelectionComponent extends RdcComponentUtils implements OnChanges, OnInit, OnDestroy, AfterViewInit {

    @Input() form!: FormGroup;
    @Input() studyType!: FormControl;
    @Input() perXOptionsForm!: FormGroup;
    @Input() selectedDataPointsArray: DataPointsSelection[] = [];
    @Input() dataPointsMapped!: DataPointsData[];
    @Input() dataPointsData!: DataPointsEntity | null | undefined ;
    @Input() dataPoints!: DataPoint[];
    @Input() showValidation = true;
    @Input() basicBuilder!: boolean;

    @Output() dataPointsSummaryText = new EventEmitter<string[]>();
    @Output() selectedDataPoints = new EventEmitter<DataPointsSelection>();
    @Output() salesforceLead = new EventEmitter<string>();

    @ViewChild('manualSelectionContainer') manualSelectionContainer!: ElementRef<HTMLElement>;
    @ViewChild('manualSelectionDataPointsLabels') manualSelectionDataPointsLabels!: ElementRef<HTMLElement>;
    @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
    @ViewChild('manualSelectionDropdown', { static: true }) manualSelectionDropdown!: TemplateRef<any>;

    dataPointFormGroup: FormGroup = new FormGroup({});
    controlFormGroups: KeyValue<string, FormGroup<Record<string, FormControl<boolean>>>>[] = [];
    dataPointDescriptions: Map<string, string> = new Map<string, string>();

    dataPointLabels: string[] = [];
    observer!: ResizeObserver;
    dialogRef!: DialogRef | null;
    placeholderText!: string;

    readonly LOCKED_ICON_SRC = '/assets/rdc-apex/images/lock.svg';
    readonly QUERY_BUILDER_TYPE = QueryBuilderType;

    constructor(
        public tooltips: TooltipsService,
        public dialog: Dialog,
        private router: Router,
        private renderer: Renderer2,
        private overlay: Overlay,
        private templateEventService: TemplateEventService,
        private changeDetectorRef: ChangeDetectorRef,
        actions: Actions,
    ) {
        super(actions);

        this.renderer.listen('window', 'click', (event: Event): void => {

            const htmlElement = event.target as HTMLElement;
            const targetedHtmlElements = [
                this.manualSelectionContainer.nativeElement,
                this.manualSelectionDataPointsLabels.nativeElement,
                this.searchInput.nativeElement,
            ].includes(htmlElement);

            if (targetedHtmlElements) {
                this.searchInput.nativeElement.focus();
            }
        });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe(() => {
                this.selectedDataPointsArray = [];
                this.labelBuilder()
            });
    }

    get studyTypeTable(): boolean {
        return this.studyType.value === QueryBuilderType.Table;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['form'] && !changes['form'].firstChange) {
            this.buildDataPointsLabels();
        }

        // when dialog is open retain input focus
        if (this.dialog.openDialogs.length && changes['selectedDataPointsArray']) {
            this.searchInput?.nativeElement.focus();
            this.removeSearchValues();
        }
    }

    ngOnInit(): void {

        this.templateEventService.toggleDataPoints
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(() => {

                this.searchInput.nativeElement.focus();

                this.onToggleDropdown(this.manualSelectionContainer.nativeElement);
            });

        this.placeholderText = `Select${!this.studyTypeTable ? ' a' : ''} data point${this.studyTypeTable ? 's' : ''} to work with...`;

        if (!this.studyTypeTable) {
            this.form.valueChanges
                .pipe(
                    takeUntil(this.componentDestroyed$)
                )
                .subscribe((dataPointCodesValue) => {

                if (!dataPointCodesValue.dataPointCodes[0]) {

                    this.placeholderText = `Select${!this.studyTypeTable ? ' a' : ''} data point${this.studyTypeTable ? 's' : ''} to work with...`;

                    return;
                }

                this.placeholderText = '';

                this.labelBuilder();
            });
        }

        this.onActions([ clearQuery ], () => {
            setTimeout(() => this.resetDataPointsForm());
        });
    }

    ngAfterViewInit(): void {
        this.dataPoints.forEach((dataPoint): Map<string, string> =>
            this.dataPointDescriptions.set(dataPoint.code, dataPoint.description || 'No description')
        );

        this.dataPointsMapped.sort((a, b): number => (a.displayOrder - b.displayOrder))
            .forEach((dataPoints): void => {
                this.dataPointFormGroup = this.form.get(dataPoints.code) as FormGroup;

                const formGroup = { key: dataPoints.code, value: this.dataPointFormGroup };

                this.controlFormGroups.push(formGroup);
            });

        this.observer = new ResizeObserver((): void => {
            // trigger window resize event to re-calculate dropdown position
            window.dispatchEvent(new Event('resize'));
        });

        this.observer.observe(this.manualSelectionDataPointsLabels.nativeElement);

        if (!this.studyTypeTable) {
            setTimeout(() => {
                this.labelBuilder();
            }, 500)
        }
    }

    dataPointLabel(label: string): string {
        return this.dataPoints.find((value: { code: string }): boolean => value.code === label)?.label || 'Unknown';
    }

    dataPointTitle(key: string): string {
        return this.dataPointsMapped.find((mapped): boolean => mapped.code === key)?.label || 'Group';
    }

    upgradeDataPoints(index: number): boolean {
        return this.dataPointsMapped[index].dataPointsData.some((dataPoint): boolean => !dataPoint.isEnabled);
    }

    perXOptionLabel(key: string): string {
        return this.dataPointsData?.perOptions.find((option): boolean => option.code === key)?.label || 'Unknown';
    }

    onClear(label: string): void {
        this.patchDataPointValue(label);

        this.onResetFilter();

        this.searchInput.nativeElement.focus();
    }

    onClearAll(): void {
        this.onResetFilter();
        this.resetDataPointsForm();

        this.searchInput.nativeElement.focus();
        this.searchInput.nativeElement.removeAttribute('size');
    }

    onToggleDropdown(triggerElement: HTMLElement): void {
        this.openDataPointsDropdown(triggerElement);

        this.searchInput.nativeElement.focus();
    }

    patchDataPointValue(dataPointLabel: string): void {
        const dataPointCode = this.dataPoints.filter((datapoint): boolean =>
            datapoint.tabularVisible).find(({ label }): boolean =>label === dataPointLabel)?.code;
        const dataPointSelection = this.selectedDataPointsArray.find((selection): boolean =>
            Object.keys(selection.value).includes(dataPointCode || ''));

        if (dataPointSelection) {
            this.form.markAsTouched();

            this.form.get(`${dataPointSelection.dataPointsGroupKey}.${dataPointCode}`)?.patchValue(false);
            this.form.get(`popular.${dataPointCode}`)?.patchValue(false);

            if (!this.studyTypeTable) {
                this.deleteChartDataPoints();
            }
        }
    }

    resetDataPointsForm(): void {
        const formValues = Object.entries(this.form.value);
        const patch: DataPointsType = {};

        formValues.forEach((formValue): void => {
            const [ formKey, value ] = formValue;
            patch[formKey] = Object.keys(value as { [key: string]: boolean })
                .reduce((acc, key): object => ({
                    ...acc,
                    [key]: false,
                }), {});
        });

        if (!this.studyTypeTable) {
            this.deleteChartDataPoints();
        } else {
            this.form.patchValue(patch);
        }
    }

    onInput(event: Event, triggerElement: HTMLElement): void {
        const input = event.target as HTMLInputElement;
        this.searchInput.nativeElement.setAttribute('size', input.value.length.toString());

        if (input.value.length) {
            this.searchInput.nativeElement.style.minWidth = 'auto';
        } else {
            this.searchInput.nativeElement.removeAttribute('style');
        }

        this.openDataPointsDropdown(triggerElement);
    }

    onClearDataPointsLabels(): void {
        if (!this.searchInput.nativeElement.value.length) {
            if (this.dataPointLabels.length) {
                const dataPointsLabelsArray = [ ...this.dataPointLabels ];
                const toBeDeletedLabel = dataPointsLabelsArray.splice(dataPointsLabelsArray.length - 1, 1);
                const focusedElement = document.getElementById(`${toBeDeletedLabel}`);

                focusedElement?.focus();
            }
        }
    }

    onLabelFocus(): void {
        const dataPointsLabelsArray = [ ...this.dataPointLabels ];
        const toBeDeletedLabel = dataPointsLabelsArray.splice(dataPointsLabelsArray.length - 1, 1);
        const focusedElement = document.getElementById(`${toBeDeletedLabel}`);

        focusedElement?.focus();

        if (document.activeElement === focusedElement) {
            const deletedLabel = this.dataPointLabels.splice(this.dataPointLabels.length - 1, 1);

            this.patchDataPointValue(deletedLabel.toString());

            this.onResetFilter();
        }

        const lastLabelsArrayItem = dataPointsLabelsArray.pop();
        const nextFocusedElement = document.getElementById(`${lastLabelsArrayItem}`);

        if (nextFocusedElement) {
            nextFocusedElement?.focus();

            return;
        }

        this.searchInput.nativeElement.focus();
    }

    closeDialog(): void {
        this.dialog.getDialogById('data-points-dropdown')?.close();
        this.dialogRef = null;
    }

    onResetFilter(): void {
        if (this.searchInput && this.searchInput.nativeElement.value) {
            this.searchInput.nativeElement.value = '';
            this.searchInput.nativeElement.removeAttribute('style');
        }

        this.closeDialog();
    }

    openDataPointsDropdown(triggerElement: HTMLElement): void {
        this.closeDialog();

        this.dialogRef = this.dialog.open(this.manualSelectionDropdown, {
            id: 'data-points-dropdown',
            backdropClass: [ '' ],
            panelClass: [ 'rdc-menu-dialog.no-animate' ],
            width: `${this.manualSelectionContainer.nativeElement.offsetWidth + 1}px`,
            autoFocus: 'data-points-manual-selection-input',
            positionStrategy: this.overlay.position()
                .flexibleConnectedTo(triggerElement)
                .withLockedPosition()
                .withPositions(overlayPositions.get('below') || []),
        });
    }

    buildDataPointsLabels(): void {
        if (!this.studyTypeTable) {
            this.labelBuilder();
        } else {
            this.form.valueChanges
                .pipe(
                    takeUntil(this.componentDestroyed$)
                )
                .subscribe(() => {
                    this.labelBuilder();
                });
        }
    }

    onChange(dataPointsGroupKey: string, dataPointCode: string, closeDialogRef?: boolean): void {

        this.templateEventService.progress();

        if (closeDialogRef) {
            this.dialogRef?.close();
        }

        // patch value to form
        (this.form.controls['dataPointCodes'] as FormArray).controls[0]?.patchValue(dataPointCode);

        this.selectedDataPoints.emit({
            dataPointsGroupKey,
            value: { [dataPointCode]: true },
        });

        this.removeSearchValues();
    }

    isChecked(formValue: string[] | undefined, dataPointCode: string): boolean {
        return formValue ? formValue[0] === dataPointCode : false;
    }

    labelBuilder(): void {
        let dataPointsLabels: string[] = [];
        let selectedDataPointKeys: string[] = [];

        setTimeout(() => {
            this.selectedDataPointsArray.forEach((selectedDataPoint): void => {
                selectedDataPointKeys = Object.keys(selectedDataPoint.value).filter((key): boolean =>
                    selectedDataPoint.value[key]);

                dataPointsLabels = [ ...new Set([ ...dataPointsLabels, ...selectedDataPointKeys ]) ];
            });

            this.dataPointLabels = [];

            dataPointsLabels.forEach((label): void => {
                this.dataPointLabels.push(this.dataPointLabel(label));
            });

            this.dataPointsSummaryText.emit(this.dataPointLabels.length ? this.dataPointLabels : [] as string[]);
        });
    }

    deleteChartDataPoints(): void {
        (this.form.controls['dataPointCodes'] as FormArray).controls[0]?.patchValue('');
        this.dataPointLabels.splice(this.dataPointLabels.length - 1, 1);
    }

    removeSearchValues(): void {
        if (this.searchInput && this.searchInput.nativeElement) {
            this.searchInput.nativeElement.value = '';
            this.searchInput.nativeElement.removeAttribute('size');
        }
    }

    override ngOnDestroy(): void {
        this.closeDialog();

        if (this.observer) {
            this.observer.disconnect();
        }

        super.ngOnDestroy();
    }
}
