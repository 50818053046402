import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ApexStudy } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { DataPoint, QbDropDowns } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points';
import { SummaryGroupOrderModule } from '@rdc-apps/rdc-apex/src/lib/shared/pipes/summary-group-order';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { TooltipModule } from '@rdc-apps/rdc-shared/src/lib/directives/tooltip';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';

@Component({
    standalone: true,
    selector: 'rdc-apps-query-builder-additional-filters',
    templateUrl: './query-builder-additional-filters.component.html',
    styleUrls: [ './query-builder-additional-filters.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [ CommonModule, ButtonModule, CdkMenuModule, ReactiveFormsModule, IconModule, SummaryGroupOrderModule, TooltipModule ],
})
export class QueryBuilderAdditionalFiltersComponent implements OnInit, OnChanges {

    @Input() chartRequest!: FormGroup<ApexStudy>;

    @Input() dropDowns!: QbDropDowns | null | undefined;

    @Input() dataPoints: DataPoint[] = [];

    @Output() init = new EventEmitter<void>();

    emissionsSchemes: RepoItem<string>[] = [];

    cabinTypes: RepoItem<string>[] = [];

    cabinLabel = 'Economy';
    emissionLabel = 'All';
    displayAdditionalFilters = false;

    hideEmissionsScheme = false;

    cabinToolTip = 'Cabin type filters cannot be applied when data points from groups other than <strong>FARES</strong> are selected';
    emissionsToolTip = 'Emissions scheme filter cannot be applied when data points from groups other than <strong>SUSTAINABILITY</strong> are selected';
    emissionsToolTipSeries = 'Global emissions scheme filters are currently applied within your series selection';
    schedulesToolTip = 'Non-stop and operating carrier filters cannot be applied by when data points from groups other than <strong>SCHEDULES</strong> are selected';

    ngOnChanges(): void {
        this.emissionsSchemes = this.dropDownsForGroup('emissionsSchemeFilter');
        this.cabinTypes = this.dropDownsForGroup('cabin');

        this.enableDisableControls(this.chartRequest.get('queryRequest.dataPoints.dataPointCodes')?.value || []);
    }

    ngOnInit(): void {
        this.chartRequest.get('queryRequest.dataPoints.dataPointCodes')?.valueChanges
            .subscribe((dataPointCodes) => this.enableDisableControls(dataPointCodes));

        this.chartRequest.get('queryRequest.chartProperties')?.valueChanges
            .subscribe(() =>
                this.enableDisableControls(this.chartRequest.get('queryRequest.dataPoints.dataPointCodes')?.value || [])
            );

        this.init.emit();
    }

    private enableDisableControls(dataPointCodes: string[]): void {
        const selectedDp = this.dataPoints.filter((dataPoint) => dataPointCodes.includes(dataPoint.code));

        const seriesDefPath = 'queryRequest.chartProperties.seriesDefinitions';

        const seriesDefinitions = this.chartRequest.get(seriesDefPath)?.value;

        this.hideEmissionsScheme = !!seriesDefinitions?.find((def) => def.type === 'emissionsScheme');

        const emissionSchemes = this.filtersFormGroup<FormArray>('emissionScheme', 'types');
        const cabinTypes = this.filtersFormGroup<FormArray>('cabinTypes', 'types');
        const scheduleFilters = this.filtersFormGroup('schedulesFilters');

        emissionSchemes.enable();
        cabinTypes.enable();
        scheduleFilters.enable();

        if (this.notAllDataPointsBelongToGroup('sustainability', selectedDp, dataPointCodes)) {
            this.resetFormArray('emissionScheme', emissionSchemes, 'all');
        }

        if (this.notAllDataPointsBelongToGroup('pricing', selectedDp, dataPointCodes) || this.hideEmissionsScheme) {
            this.resetFormArray('cabinTypes', cabinTypes, 'economy');
        }

        if (this.notAllDataPointsBelongToGroup('schedules', selectedDp, dataPointCodes)) {
            scheduleFilters.setValue({ nonStopServices: true, franchisePartners: true });
            scheduleFilters.disable();
        }

    }

    private resetFormArray(name: string, array: FormArray, defaultVal: string): void {
        array.clear();
        array.push(new FormControl(defaultVal));
        array.disable();
        this.setLabelFor(name, array);
    }

    private notAllDataPointsBelongToGroup(groupName: string, selectedDp: DataPoint[], dataPointCodes: string[]): boolean {
        const countThatMatchGroup = selectedDp.filter(
            (dataPoint) => dataPoint.datapointGroups.find((group) => group.datapointGroupCode === groupName)
        ).length;

        return countThatMatchGroup !== dataPointCodes.length;
    }

    dropDownsForGroup(groupName: string): RepoItem<string>[] {
        return this.dropDowns?.dropdownOptionGroups
            .find((group) => group.code === groupName)
            ?.dropdownOptions || [];
    }

    filtersFormGroup<T = FormGroup>(...additionalPaths: string[]): T {
        const path = additionalPaths.length ? `queryRequest.filters.${ additionalPaths.join('.') }` : 'queryRequest.filters';

        return this.chartRequest.get(path) as T;
    }

    get additionalFiltersInvalid(): boolean {
        return this.filtersFormGroup('cabinTypes')?.invalid
            || this.filtersFormGroup('emissionScheme')?.invalid
            || this.filtersFormGroup('scheduleFilters')?.invalid;
    }

    isSelected(arrayName: string, type: RepoItem<string>): boolean {
        return !!this.filtersFormGroup<FormArray>(arrayName, 'types').value
            .find((code: string) => code === type.code);
    }

    toggleInArray(arrayName: string, type: RepoItem<string>): void {

        const formGroup = this.filtersFormGroup<FormArray>(arrayName, 'types');

        if (arrayName === 'emissionScheme' && [ 'all', 'noScheme' ].includes(type.code)) {
            formGroup.clear();
            formGroup.push(new FormControl(type.code));

            this.setLabelFor(arrayName, formGroup);

            return;
        }

        const indexOfSingleSelect = formGroup.value.findIndex((code: string) => [ 'all', 'noScheme' ].includes(code));
        const existingIndex = formGroup.value.findIndex((code: string) => code === type.code);

        formGroup.markAsTouched();

        if (indexOfSingleSelect >= 0) {
            formGroup.removeAt(indexOfSingleSelect);
        }

        if (existingIndex >= 0) {
            formGroup.removeAt(existingIndex);

            this.setLabelFor(arrayName, formGroup);

            return;
        }

        formGroup.push(new FormControl(type.code));

        this.setLabelFor(arrayName, formGroup);
    }

    private setLabelFor(formArrayName: string, formGroup: FormArray): void {
        const selections = formGroup.value;

        if (formArrayName === 'cabinTypes') {
            this.cabinLabel = selections.length > 1 ? `${ selections.length } selected` : selections[0];

            return;
        }

        const firstLabel = this.emissionsSchemes.find(({ code }) => code === selections[0])?.label;

        this.emissionLabel = selections.length > 1 ? `${ selections.length } selected` : firstLabel || selections[0];
    }

}
