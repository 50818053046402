import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RdcDialogItemDirective, RdcMenuItemDirective } from './dialog-item.directive';

@NgModule({
    declarations: [
        RdcDialogItemDirective,
        RdcMenuItemDirective,
    ],
    imports: [ CommonModule ],
    exports: [
        RdcDialogItemDirective,
        RdcMenuItemDirective,
    ],
})
export class DialogItemModule {}
