import { CdkMenuItem, CdkMenuModule } from "@angular/cdk/menu";
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "@rdc-apps/rdc-shared/src/lib/directives/button";
import { DialogItemModule } from "@rdc-apps/rdc-shared/src/lib/directives/dialog-item";
import { RdcFilterPipe } from "@rdc-apps/rdc-shared/src/lib/pipes/filter";
import { IconModule } from "@rdc-apps/rdc-shared/src/lib/ui/icon";
import { NgScrollbar } from "ngx-scrollbar";

import { SelectAutoCompleteComponent } from "./autocomplete.component";
import { SelectComponent } from "./select.component";
import { RemoveEmptyCatPipe, SelectFilterPipe, VirtualScrollHeightPipe } from "./select.pipes";




@NgModule({
    declarations: [
        SelectComponent,
        SelectAutoCompleteComponent,
    ],
    imports: [
        CommonModule,
        ButtonModule,
        DialogItemModule,
        CdkMenuModule,
        CdkMenuItem,
        ReactiveFormsModule,
        NgScrollbar,
        IconModule,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        VirtualScrollHeightPipe,
        RemoveEmptyCatPipe,
        RdcFilterPipe,
        SelectFilterPipe,
    ],
    exports: [
        SelectComponent,
        SelectAutoCompleteComponent,
    ],
})
export class SelectAutocompleteModule {}
