<div class="table-builder-dialog">

    <button
        rdcAppsButton
        rdcAppsDialogItem
        rdcAppsIconButton
        [dialogCloseIconBtn]="true"
        display="compact">
        <rdc-apps-icon size="sm">close</rdc-apps-icon>
    </button>

    <div class="table-builder-body">
        <h4>Choose your query builder</h4>

        <p>
            Basic = fewer inputs, more assumptions<br/>
            Extended = more inputs, fewer assumptions
        </p>
    </div>

    <div class="table-builder-buttons">
        <button
            rdcAppsButton
            rdcAppsDialogItem
            colour="primary"
            (click)="basic.emit(true)"
        >
            Basic builder
        </button>

        <button
            rdcAppsButton
            rdcAppsDialogItem
            colour="primary"
            (click)="basic.emit(false)"
        >
            Extended builder
        </button>
    </div>

</div>
