import { distinctUntilChanged, filter, Subscription, take, takeUntil } from 'rxjs';

import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { connectedAbove, connectedBelow, connectedLeft, connectedRight, } from '@rdc-apps/rdc-shared/src/lib/constants';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';

@Component({
    selector: 'rdc-apps-select',
    templateUrl: './select.component.html',
    styleUrls: [ './select.component.scss' ],
})
export class SelectComponent extends RdcComponentUtils implements OnInit, OnChanges, OnDestroy {

    @ViewChild('template') template!: TemplateRef<unknown>;

    @ViewChild('textTrigger') textTrigger!: ElementRef<HTMLInputElement> | undefined;

    @ViewChild('vsViewport') vsViewport!: CdkVirtualScrollViewport;

    @Input() filterable = false;

    /* Makes it look like the given value is selected value but DOES NOT actually set or change the value:
        - you must ensure you pass the actual value to the control in the parent component (if used in a form)
        - this is useful in circumstances where we have an autocomplete so the options are dynamic (so no options to find the selected value)
        - this is also useful when we do not have the options on load and want to show the value (autocomplete a stored airport for example)
        - pass the form value (RepoItem) don't forget to update it accordingly with (valueSelected/valueCleared) when you have made a
          change
     */
    @Input() maskSelected: { label: string; [key: string]: any } | Partial<RepoItem<unknown>> | undefined | null = undefined;

    @Input() options: Partial<RepoItem<unknown>>[] | undefined | null = [];

    @Input() forceInvalid = false;

    /* Only for use when the options are static and no control is given but you may want to extract the chosen value for
       some other use, a sort field for example (though a form is still recommended)  */
    @Input() retainSelected: boolean | undefined = undefined;

    @Input() markAsTouched: boolean | undefined = undefined;

    @Input() controlGroup: FormGroup | AbstractControl = new FormGroup({
        rdcSelectSelected: new FormControl<unknown>(null, Validators.required),
    });

    @Input() controlName = 'rdcSelectSelected';

    /* Only required when using as a select in a form, not required as an autocomplete
       the property from the options to assign to the form and filter by in the options;
       options that have a code closer to the filter term will appear closer to the top  */
    @Input() selectProperty = 'code';

    /* Additional properties to filter by */
    @Input() filterByProps: string[] = [];

    @Input() placeholder = 'Please select...';

    @Input() display: 'default' | 'compact' = 'default';

    @Input() defaultSelected = '';

    @Input() disabled: boolean | null | undefined = false;

    @Input() showValidation = true;

    @Input() filterHelp = '';

    @Input() filterHelpHeight = 0;

    @Input() filterOnEnteredCharacters = false;

    @Input() showStaticOption = false;

    @Input() staticOption!: Partial<RepoItem<unknown>>;

    @Input() focusOnEvent: { focusEmitter: EventEmitter<string>; identifier: string } | undefined = undefined;

    @Output() autocomplete = new EventEmitter<string>();

    @Output() valueSelected = new EventEmitter<Partial<RepoItem<unknown>>>();

    @Output() clearSelected = new EventEmitter<void>();

    @Output() inputBlur = new EventEmitter<void>();

    @Output() menuClosed = new EventEmitter<Partial<RepoItem<unknown>> | null>();

    private dialog = inject(Dialog);

    private overlay = inject(Overlay);

    private cdr = inject(ChangeDetectorRef);

    private allowFocus = true;

    public dialogRef: DialogRef | undefined;

    public preventMenuEntry = true;

    private focusTimeout: any = 0;

    private preventMenuTimeout: any  = 0;

    private focusEmitterSubscription: Subscription | undefined;

    private controlValueChangeSub: Subscription | undefined;

    protected filterMode: 'default' | 'autocomplete' = 'default';

    public storedSelection = new FormGroup({
        selected: new FormControl<Partial<RepoItem<unknown>> | null>(null),
    });

    public filterText = new FormGroup({
        input: new FormControl<string>('', { nonNullable: true }),
    });


    constructor() {
        super();
    }

    group(): FormGroup {
        return this.controlGroup as FormGroup;
    }

    ngOnInit(): void {
        this.filterText.get('input')?.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                distinctUntilChanged((before,after) => before === after)
            )
            .subscribe((text) => {
                if(this.shouldBlockAutoComplete) {
                    this.options = [];
                    this.dialogRef?.close();

                    return;
                }
                this.autocomplete.emit(text);
            });
    }

    ngOnChanges(): void {
        this.filterHelpHeight = (this.filterHelp ? this.filterHelpHeight || 25 : 0);

        this.focusEmitterSubscription?.unsubscribe();
        this.controlValueChangeSub?.unsubscribe();

        this.focusEmitterSubscription = this.focusOnEvent?.focusEmitter
            .pipe(
                takeUntil(this.componentDestroyed$),
                filter((identifier) => this.focusOnEvent?.identifier === identifier)
            )
            .subscribe(() => this.textTrigger?.nativeElement.focus());

        this.controlValueChangeSub = this.controlGroup.get(this.controlName)?.valueChanges
            .pipe(
                // debounce(() => interval(25)),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe((code) => this.handleSelectionAndFormUpdates(code));

        const valueOnLoad = this.controlGroup.get(this.controlName)?.value;
        const control = this.controlGroup.get(this.controlName);

        if(valueOnLoad && !this.options?.find((opt) => opt[this.selectProperty] === valueOnLoad)) {
            control?.patchValue(null, { emitEvent: false });

            this.storedSelection.patchValue({ selected: null });

            this.markTouched(control);
        } else {
            this.handleSelectionAndFormUpdates(valueOnLoad);
        }

        this.vsViewport?.checkViewportSize();

        this.preventMenuEntry = true;

        clearTimeout(this.preventMenuTimeout);

        this.preventMenuTimeout = window.setTimeout(() => {
            this.preventMenuEntry = false;
        },250);

        if (this.disabled) {
            this.filterText.get('input')?.disable();

            return;
        }
        this.filterText.get('input')?.enable();
    }

    private markTouched(control: AbstractControl | null | undefined): void {

        const markAsTouched = this.markAsTouched ?? true;

        if(!markAsTouched) {
            return;
        }

        control?.markAsTouched();
    }

    onEnterModalSelection(selectTrigger: HTMLButtonElement | HTMLInputElement): void {
        if (this.preventMenuEntry) {
            return;
        }

        this.onDialog(selectTrigger);
    }

    onDialog(selectTrigger: HTMLButtonElement | HTMLInputElement, focus = true, restoreFocus = true) {
        event?.preventDefault();

        // in autocomplete instances, do not open the menu unless criteria is met
        if(this.shouldBlockAutoComplete) {
            return;
        }

        this.allowFocus = false;

        this.dialogRef?.close();

        this.markTouched(this.controlGroup.get(this.controlName));

        clearTimeout(this.focusTimeout);

        this.dialogRef = this.dialog.open(this.template, {
            backdropClass: 'none',
            width: `${ selectTrigger.offsetWidth }px`,
            panelClass: [ 'rdc-select-component-dialog' ],
            maxHeight: 250,
            autoFocus: focus ? 'first-tabbable' : 'non_existing_element',
            restoreFocus,
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(selectTrigger)
                .withPositions([
                    connectedBelow,
                    connectedAbove,
                    connectedRight,
                    connectedLeft
                ]),
        });

        this.dialogRef.closed
            .pipe(take(1))
            .subscribe(() => {
                this.menuClosed.emit(this.storedSelection.value.selected);

                this.dialogRef = undefined;

                // stop immediate reopening in refocus instances
                this.focusTimeout = window.setTimeout(() => {
                    this.allowFocus = true;
                }, 25);
            });
    }

    onDialogOnInput(textTrigger: HTMLInputElement): void {

        this.markTouched(this.controlGroup.get(this.controlName));

        if (this.dialogRef || !this.allowFocus) {
            return;
        }

        this.onDialog(textTrigger, false);
    }

    onDialogOnFocus(textTrigger: HTMLInputElement): void {

        this.markTouched(this.controlGroup.get(this.controlName));

        if(!this.shouldBlockAutoComplete) {
            this.autocomplete.emit(textTrigger.value);
        }

        if (!this.options?.length || !this.allowFocus) {
            return;
        }

        this.onDialog(textTrigger, false, false);
    }

    onItemSelected(option: Partial<RepoItem<unknown>>): void {
        this.valueSelected.emit(option);

        this.filterText.reset();

        const noControlAndNotSpecifiedRetain = this.retainSelected === undefined && this.controlName === 'rdcSelectSelected';
        const specifiedNoRetain = String(this.retainSelected) === 'false';

        if (noControlAndNotSpecifiedRetain || specifiedNoRetain) {
            return;
        }

        if (this.showStaticOption) {
            this.showStaticOption = false;
        }

        this.controlGroup.patchValue({
            [this.controlName]: option[this.selectProperty],
        });

        // this.markTouched(this.controlGroup);
        this.markTouched(this.controlGroup.get(this.controlName));
    }

    onReSelect(): void {

        this.clearSelected.emit();

        this.storedSelection.reset();

        this.controlGroup.get(this.controlName)?.reset();

        this.markTouched(this.controlGroup.get(this.controlName));

        this.cdr.detectChanges();

        setTimeout(() => {
            this.textTrigger?.nativeElement?.focus();
        });
    }

    private handleSelectionAndFormUpdates(code: string): void {

        const completeOptions: Partial<RepoItem<unknown>>[] = [];

        // merge all options into one list
        this.options?.forEach((option) => {
            if (option['options']?.length) {
                completeOptions.push(...option['options']);

                return;
            }
            completeOptions.push(option);
        });

        const selectedOption = completeOptions.find((opt) => opt[this.selectProperty] === code);

        this.storedSelection.patchValue({
            selected: code ? selectedOption : null,
        });
    }

    onClearFilter(): void {
        this.filterText.reset();

        if (this.textTrigger?.nativeElement) {
            this.textTrigger.nativeElement.focus();
        }
    }

    focusFirstItem(menu: HTMLDivElement) {
        this.cdr.detectChanges();

        setTimeout(() => {
            const event = new KeyboardEvent('keydown', {
                bubbles: true,
                code: "ArrowDown",
                key: "ArrowDown",
                keyCode: 40,
                type: "keydown",
            } as KeyboardEvent);

            menu.dispatchEvent(event);
        });
    }

    displayScrollViewport(options: any[], text: string ): boolean {
        return options.length && text.length >= 2 || !options.length && text.length >= 2;
    }

    get staticSelection (): boolean {
        return this.filterText.getRawValue().input.length < 2 && this.showStaticOption && this.retainSelected || false;
    }

    private get shouldBlockAutoComplete(): boolean {
        const isAutoComplete = this.filterMode === 'autocomplete';
        const filterLessThanTwo = this.filterText.getRawValue().input.length < 2;

        return isAutoComplete && filterLessThanTwo;
    }

    onBlur(): void {
        if(this.dialogRef) {
            return;
        }

        this.inputBlur.emit();
    }
}
