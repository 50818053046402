import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    DATA_POINTS_FEATURE_KEY,
    DataPointsState
} from './data-points.reducer';

// Lookup the 'DataPoints' feature state managed by NgRx
export const getDataPointsState = createFeatureSelector<DataPointsState>(
    DATA_POINTS_FEATURE_KEY
);

export const getDataPointsLoaded = createSelector(
    getDataPointsState,
    (state: DataPointsState) => state.loaded
);

export const getDataPointsError = createSelector(
    getDataPointsState,
    (state: DataPointsState) => state.error
);

export const getDataPoints = (id: string) => createSelector(
    getDataPointsState,
    (state: DataPointsState) => state.entities[id]
);

export const getDataPointsDropDownData = (id: string) => createSelector(
    getDataPoints(id),
    (dataPoints) => {
        const distanceUnits = dataPoints?.dropdowns.dropdownOptionGroups
            .find((dropdownOptionGroup): boolean =>
                dropdownOptionGroup.code === 'distanceUnits')?.dropdownOptions || [];

        const directionality = dataPoints?.dropdowns.dropdownOptionGroups
            .find((dropdownOptionGroup): boolean =>
                dropdownOptionGroup.code === 'directionality')?.dropdownOptions || [];

        const reportingLevel = dataPoints?.dropdowns.dropdownOptionGroups
            .find((dropdownOptionGroup): boolean =>
                dropdownOptionGroup.code === 'reportingLevel')?.dropdownOptions || [];

        const currency = dataPoints?.dropdowns.currencies || [];

        return { distanceUnits, directionality, currency, reportingLevel };
    }
);
