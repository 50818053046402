import { Icon } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

export interface AppletItem {
    icon: string;
    iconSize: Icon;
    content: string;
    subText: string;
    type: string;
    disabled: boolean;
    fill?: boolean;
    activityCode?: string;
    activityLaunchSource?: string;
    noBg?: boolean;
    launch?: string;
}
