import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/header';
import { TagManagementItemModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/tag-management-item';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { PanelModule } from '@rdc-apps/rdc-shared/src/lib/ui/panel';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { TagManagementComponent } from './tag-management.component';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HeaderModule,
        TagManagementItemModule,
        ButtonModule,
        PanelModule,
        NgScrollbarModule,
    ],
    declarations: [ TagManagementComponent ],
    exports: [
        TagManagementComponent,
    ],
})
export class TagManagementModule {}
