import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DisplayOrderPipe } from './summary-group-order.pipe';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        DisplayOrderPipe,
    ],
    exports: [
        DisplayOrderPipe,
    ],
})
export class SummaryGroupOrderModule {}
