import { createAction, props } from '@ngrx/store';
import { PresetColor } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';

import { MapColourPreset, PreferencesGroup, PreferencesSelection, UserPreferences } from './default-preferences.models';

export const getAirportGroups = createAction(
    '[AirportGroups/API] Get Airport Groups'
);

export const getAirportGroupsSuccess = createAction(
    '[AirportGroups/API] Get Airports Groups Success',
    props<{ groups: PreferencesGroup[] }>()
);

export const getAirportGroupsFailure = createAction(
    '[AirportGroups/API] Get Airport Groups Failure',
    props<{ error: string }>()
);

export const createAirportGroup = createAction(
    '[AirportGroups/API] Create Airport Group',
    props<{ group: PreferencesGroup }>()
);

export const createAirportGroupSuccess = createAction(
    '[AirportGroups/API] Create Airport Group Success',
    props<{ groups: PreferencesGroup[] }>()
);

export const createAirportGroupsFailure = createAction(
    '[AirportGroups/API] Create Airport Groups Failure',
    props<{ error: string }>()
);

export const deleteAirportGroup = createAction(
    '[AirportGroups/API] Delete Airport Group',
    props<{ groupId: string }>()
);

export const deleteAirportGroupSuccess = createAction(
    '[AirportGroups/API] Delete Airport Group Success',
    props<{ groups: PreferencesGroup[] }>()
);

export const deleteAirportGroupsFailure = createAction(
    '[AirportGroups/API] Delete Airport Groups Failure',
    props<{ error: string }>()
);

export const getAirlineGroups = createAction(
    '[AirlineGroups/API] Get Airline Groups'
);

export const getAirlineGroupsSuccess = createAction(
    '[AirlineGroups/API] Get Airline Groups Success',
    props<{ groups: PreferencesGroup[] }>()
);

export const getAirlineGroupsFailure = createAction(
    '[AirlineGroups/API] Get Airline Groups Failure',
    props<{ error: string }>()
);

export const createAirlineGroup = createAction(
    '[AirlineGroups/API] Create Airline Group',
    props<{ group: PreferencesGroup }>()
);

export const createAirlineGroupSuccess = createAction(
    '[AirlineGroups/API] Create Airline Group Success',
    props<{ groups: PreferencesGroup[] }>()
);

export const createAirlineGroupsFailure = createAction(
    '[AirlineGroups/API] Delete Airport Groups Failure',
    props<{ error: string }>()
);

export const deleteAirlineGroup = createAction(
    '[AirlineGroups/API] Delete Airline Group',
    props<{ groupId: string }>()
);

export const deleteAirlineGroupSuccess = createAction(
    '[AirlineGroups/API] Delete Airline Group Success',
    props<{ groups: PreferencesGroup[] }>()
);

export const deleteAirlineGroupsFailure = createAction(
    '[AirlineGroups/API] Delete Airport Groups Failure',
    props<{ error: string }>()
);

export const getDefaultPreferences = createAction(
    '[UserPreferences/API] Get Units Measurements'
);

export const getDefaultPreferencesSuccess = createAction(
    '[UserPreferences/API] Get Units Measurements Success',
    props<{ unitsMeasurements: UserPreferences }>()
);

export const getDefaultPreferencesFailure = createAction(
    '[UserPreferences/API] Get Units Measurements Failure',
    props<{ error: string }>()
);

export const updateDefaultPreferences = createAction(
    '[UserPreferences/API] Update Units Measurements',
    props<{ unitsMeasurements: UserPreferences }>()

);

export const updateUnitsMeasurementsSuccess = createAction(
    '[UserPreferences/API] Update Units Measurements Success',
    props<{ unitsMeasurements: UserPreferences }>()
);

export const updateUnitsMeasurementsFailure = createAction(
    '[UserPreferences/API] Update Units Measurements Failure',
    props<{ error: string }>()
);

export const updateMapColours = createAction(
    '[UserColoursMap/API] Update Map Colour',
    props<{ mapColours: MapColourPreset }>()
);

export const updateMapColourSuccess = createAction(
    '[UserColoursMap/API] Update Map Colour Success',
    props<{ mapColours: MapColourPreset }>()
);

export const updatePresetColour = createAction(
    '[UserColourPreset/API] Update Preset Colour',
    props<{ colours: PresetColor[] }>()
);

export const updatePresetColourSuccess = createAction(
    '[UserColourPreset/API] Update Preset Colour Success',
    props<{ unitsMeasurements: UserPreferences }>()
);

export const getCloudDefaults = createAction(
    '[CloudDefaults/API] Get Cloud Defaults',
);

export const getCloudDefaultsSuccess = createAction(
    '[CloudDefaults/API] Get Cloud Defaults Success',
    props<{ originAirport: PreferencesSelection, airlineId: PreferencesSelection }>()
);

export const getCloudDefaultsFailure = createAction(
    '[CloudDefaults/API] Get Cloud Defaults Failure',
    props<{ error: string }>()
);

export const createCloudDefaults = createAction(
    '[CloudDefaults/API] Create Cloud Defaults',
    props<{ originAirportId?: number, airlineId?: number }>()
);

export const createCloudDefaultsSuccess = createAction(
    '[CloudDefaults/API] Create Cloud Defaults Success',
    props<{ originAirport: PreferencesSelection, airlineId: PreferencesSelection }>()
);

export const createCloudDefaultsFailure = createAction(
    '[CloudDefaults/API] Create Cloud Defaults Failure',
    props<{ error: string }>()
);
