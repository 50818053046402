import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {

    private errorPagePath!: string;

    constructor(private router: Router) {}

    setErrorPagePath(path: string): void {
        this.errorPagePath = path;
    }

    get errorPath(): string {
        return this.errorPagePath;
    }

    handleErrorRedirect(url: string): void {
        const queryParams = url ? { url } : {};

        this.router.navigate([ this.errorPagePath ], {
            queryParams,
        });
    }
}
