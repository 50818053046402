/**
 * Interface for the 'DataPoints' data
 */
import { QueryBuilderType } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

export interface DataPointGroup {
    id: string;
    code: string;
    label: string;
    displayOrder: number;
}

export interface DataPoint {
    id: string;
    code: string;
    label: string;
    description: string;
    isEnabled: boolean;
    availableTypes: QueryBuilderType[];
    datapointGroups: { datapointGroupCode: string; datapointDisplayOrder: number }[];
    tabularVisible: boolean;
    visibleInBasicQueryBuilder: boolean;
}

export interface DataPointsData {
    label: string;
    code: string;
    displayOrder: number;
    dataPointsData: DataPoint[];
}

export interface DataPointsEntity {
    datapointsGroups: DataPointGroup[];
    datapoints: DataPoint[];
    summaryGroups: QbOutputSummaryGroup[];
    outputGroups: QbOutputSummaryGroup[];
    dropdowns: QbDropDowns;
    perOptions: RepoItem<string>[];
}

export interface QbOutputSummaryGroup extends RepoItem<string> {
    summaries?: SummaryOutputRepoItem[];
    outputs?: SummaryOutputRepoItem[];
}

export interface QbDropDowns {
    dropdownOptionGroups: QbDropDownOptionGroup[];
    currencies: RepoItem<string>[];
    airlineTypes: RepoItem<string>[];
    aircraftModels: RepoItem<string>[];
    aircraftFamilies: RepoItem<string>[];
}

export interface QbDropDownOptionGroup extends RepoItem<string> {
    dropdownOptions: RepoItem<string>[];
}

export interface SummaryOutputRepoItem extends RepoItem<string> {
    datapointCodes: string[];
}

export interface DataPointsDropdownData {
    currency: RepoItem<string>[];
    directionality: RepoItem<string>[];
    reportingLevel: RepoItem<string>[];
    distanceUnits: RepoItem<string>[];
}

export interface DataPointsSelection {
    dataPointsGroupKey: string;
    value: { [key: string]: boolean };
}

export type DataPointsType = Record<string, Record<string, boolean>>;

