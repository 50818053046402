<div class="map-dialog">
    <header>
        <h2><rdc-apps-icon [rdcIcon]="'n-icon-network-fill'"></rdc-apps-icon>cloud access required</h2>
    </header>

    <div class="map-dialog-content">
        <h3>Network map is enabled as part of Network cloud</h3>

        <img src="/assets/rdc-apex/images/mapplet-image.png">

        <p>
            With Network map you can quickly draw maps for scheduled services from any airport,
            add custom routes and fully customise colours - perfect for presentations.
        </p>

        <button
            rdcAppsButton
            rdcAppsIconButton
            colour="primary"
            (click)="cloudLink.emit()"
        >
            More about Network cloud <rdc-apps-icon>arrow_right_alt</rdc-apps-icon>
        </button>
    </div>
</div>

