import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownOptionsForSummaryModule } from '@rdc-apps/rdc-apex/src/lib/shared/pipes/dropdown-options-for-summary';
import { SummaryGroupOrderModule } from '@rdc-apps/rdc-apex/src/lib/shared/pipes/summary-group-order';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { SelectAutocompleteModule } from "@rdc-apps/rdc-shared/src/lib/ui/select";
import { DisplayCodesComponent } from "display-codes";

import { QbSummariesComponent } from './qb-summaries.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IconModule,
        SummaryGroupOrderModule,
        DropdownOptionsForSummaryModule,
        SelectAutocompleteModule,
        DisplayCodesComponent,
    ],
    declarations: [ QbSummariesComponent ],
    exports: [
        QbSummariesComponent,
    ],
})
export class QbSummariesModule {}
