// eslint-disable-next-line max-len
export type RdcOverlayPosition = 'above' | 'below' | 'above-right' | 'above-left' | 'below-right' | 'below-left' | 'below-center-left' | 'below-center-right' | 'above-center-right' | 'above-center-left' | 'left' | 'right';
export const connectedAbove = { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' } as ConnectedPosition;
export const connectedBelow = { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' } as ConnectedPosition;
export const connectedLeft = { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' } as ConnectedPosition;
export const connectedRight = { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' } as ConnectedPosition;
export const overlayPositions: Map<string, ConnectedPosition[]> = new Map()
    .set('above', [ connectedAbove ])
    .set('below', [ connectedBelow ])
    .set('above-right', [ { originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'bottom' } ])
    .set('above-left', [ { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'bottom' } ])
    .set('below-left', [ { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'top' } ])
    .set('below-center-left', [ { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' } ])
    .set('above-center-left', [ { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' } ])
    .set('above-center-right', [ { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' } ])
    .set('below-center-right', [ { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' } ])
    .set('below-right', [ { originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'top' } ])
    .set('left', [ connectedLeft ])
    .set('right', [ connectedRight ]);

import { ConnectedPosition } from '@angular/cdk/overlay';

