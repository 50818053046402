import { map, Observable } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { RdcResponseDto } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

import { UpdateUserDetails, UserDetails } from './user-details.models';


@Injectable({ providedIn: 'root' })
export class UserDetailsService extends RdcBaseHttpService {

    constructor(
        @Inject(appEnvironment) private environment: AppEnvironment,
    ) {
        super();
    }

    get(): Observable<UserDetails> {
        // eslint-disable-next-line max-len
        return this.http.get<RdcResponseDto<UserDetails>>(`${ this.environment.api.baseUrl }/Account/user/details`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data)
            );
    }

    update(updateUserDetails: UpdateUserDetails): Observable<UserDetails> {
        // eslint-disable-next-line max-len
        return this.http.patch<RdcResponseDto<UserDetails>>(`${ this.environment.api.baseUrl }/Account/user/details`, updateUserDetails,  { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data)
            );
    }

}
