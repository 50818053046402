import { catchError, EMPTY, map, Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    AppEnvironment,
    appEnvironment,
} from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcResponseDto } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

@Injectable({ providedIn: 'root' })
export class TempUserLoginService {

    constructor(
        private http: HttpClient,
        private router: Router,
        @Inject(appEnvironment) private env: AppEnvironment
    ) {}

    // ALL BELOW IS TEMP CODE WILL BE REPLACED
    login(userId: string): Observable<Observable<never>> {
        // eslint-disable-next-line max-len
        return this.http.post<RdcResponseDto<{ ssoLoginUrl: string }>>(`${this.env.api.baseUrl}/account/users/sso/single-login/redirect-url/issue`,
            { userId }, { headers: this.getTokenHeaders() }
        ).pipe(
            map((response) => {
                const { ssoLoginUrl } = response.success.data;
                const serverTokenUrl = ssoLoginUrl.split('&returnUrl=')[0];

                window.location.href = `https://dev-identity.rdcaviation.com/${serverTokenUrl}&returnUrl=${this.env.authRedirect}`;

                return EMPTY;

            }),
            catchError(() => {
                this.router.navigate([ 'authorize' ]);

                return EMPTY;
            })
        );
    }

    protected getTokenHeaders(): HttpHeaders {
        const token = '628xyMZIOOKzZ3sPPoOMhyhZZwZ1';
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', `Bearer ${token}`);

        return httpHeaders;
    }

}
