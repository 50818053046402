import { createAction, props } from '@ngrx/store';
import { RouteServed, ServedRoute } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';

export const queueCloudRouteServedAction= createAction(
    '[Route Served] Queue',
    props<{ served: { originAirport: number; destinationAirport: number }; cloudType: string }>()
);

export const executeCloudRouteServedAction= createAction(
    '[Route Served] Execute'
);

export const executeCloudRouteServedSuccessAction= createAction(
    '[Route Served] Execute Success',
    props<{ routeServed: RouteServed }>()
);

export const executeCloudRouteServedFailureAction= createAction(
    '[Route Served] Execute Failure',
    props<{ served: ServedRoute }>()
);
