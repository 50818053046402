import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CloudPopupDirective } from './cloud-popup.directive';

@NgModule({
    imports: [ CommonModule ],
    declarations: [ CloudPopupDirective ],
    exports: [ CloudPopupDirective ],
})
export class CloudPopupModule {}
