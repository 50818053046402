import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[rdcAppsIconButton]',
})
export class RdcIconButtonDirective {

    @Input() dialogCloseIconBtn = false;

    @HostBinding('class.btn-close-dialog') get closesDialog() {
        return this.dialogCloseIconBtn;
    }

    @HostBinding('class.btn-icon') get iconBtn() {
        return true;
    }
}
