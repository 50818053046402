import {
    Component,
    EventEmitter,
    Input, OnChanges,
    Output, SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

@Component({
    selector: 'rdc-apps-accordion-item',
    templateUrl: './accordion-item.component.html',
    styleUrls: [ './accordion-item.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class AccordionItemComponent implements OnChanges {

    @Input() open = false;
    @Input() titleText = 'Accordion item';
    @Input() invalid: boolean | null | undefined = false;
    @Input() subText: string | null = null;
    @Input() disabled: boolean | undefined | null = false;
    @Input() spacer = 0;
    @Input() hide: boolean | undefined | null = false;
    @Input() inverse: boolean | undefined | null = false;

    @Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('template', { static: true }) template: TemplateRef<HTMLTemplateElement> | null = null;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['open']) {
            this.toggled.emit(changes['open'].currentValue);
        }
    }

    onOpen(): void {
        this.open = true;
        this.toggled.emit(this.open);
    }

    onClose(): void {
        this.open = false;
        this.toggled.emit(this.open);
    }
}
