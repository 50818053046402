export const APP_STUDY_BUILDER = 'study_builder';
export const APP_TEMPLATE_BUILDER = 'template_builder';
export const APP_USER_PREFERENCES = 'user_preferences';
export const APP_RUNNING_STUDY = 'running_study';
export const APP_EXPORTING_STUDY = 'exporting_study';
export const APP_STUDY_TEMPLATES = 'exporting_templates';
export const APP_CHECK_ROUTE = 'checking_route';
export const APP_REFRESH_ROUTE = 'refresh_route';
export const APP_EXPORTING_ROUTE = 'exporting_route';
export const APP_DATE_RANGE = 'date_range';

