import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';

import { DefaultPreferencesItemComponent } from './default-preferences-item.component';


@NgModule({
    imports: [ CommonModule, IconModule, ButtonModule ],
    declarations: [ DefaultPreferencesItemComponent ],
    exports: [
        DefaultPreferencesItemComponent,
    ],
})
export class DefaultPreferencesItemModule {}
