import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as DefaultPreferencesActions from './default-preferences.actions';
import { DefaultPreferencesEntity, PreferencesSelection } from './default-preferences.models';

export const DEFAULT_PREFERENCES_FEATURE_KEY = 'defaultPreferences';

export interface DefaultPreferencesState
    extends EntityState<DefaultPreferencesEntity> {
    selectedId?: string | number; // which DefaultPreferences record has been selected
    airportLoaded: boolean; // has the DefaultPreferences list been loaded
    airlineLoaded: boolean; // has the DefaultPreferences list been loaded
    unitsLoaded: boolean;
    cloudOriginAirport: PreferencesSelection | undefined;
    cloudOriginAirline: PreferencesSelection | undefined;
    error?: string | null; // last known error (if any)
}

export interface DefaultPreferencesPartialState {
    readonly [DEFAULT_PREFERENCES_FEATURE_KEY]: DefaultPreferencesState;
}

export const defaultPreferencesAdapter: EntityAdapter<DefaultPreferencesEntity> =
    createEntityAdapter<DefaultPreferencesEntity>({
        selectId: () => 'defaultPreferencesState',
    });

export const initialDefaultPreferencesState: DefaultPreferencesState =
    defaultPreferencesAdapter.getInitialState({
        // set initial required properties
        airportLoaded: false,
        airlineLoaded: false,
        unitsLoaded: false,
        cloudOriginAirport: undefined,
        cloudOriginAirline: undefined,
    });

const reducer = createReducer(
    initialDefaultPreferencesState,
    on(DefaultPreferencesActions.getAirportGroups, (state) => ({
        ...state,
        airportLoaded: false,
        error: null,
    })),
    on(
        DefaultPreferencesActions.getAirportGroupsSuccess,
        (state, { groups }) =>
            defaultPreferencesAdapter.upsertOne({ userAirportGroups: groups }, {
                ...state,
                airportLoaded: true,
            })
    ),
    on(
        DefaultPreferencesActions.getAirportGroupsFailure,
        (state, { error }) => ({ ...state, error })
    ),
    on(
        DefaultPreferencesActions.createAirportGroupSuccess,
        (state, { groups }) =>
            defaultPreferencesAdapter.upsertOne({ userAirportGroups: groups }, {
                ...state,
                airportLoaded: true,
            })
    ),
    on(
        DefaultPreferencesActions.deleteAirportGroupSuccess,
        (state, { groups }) =>
            defaultPreferencesAdapter.upsertOne({ userAirportGroups: groups }, {
                ...state,
                airportLoaded: true,
            })
    ),
    on(DefaultPreferencesActions.getAirlineGroups, (state) => ({
        ...state,
        airlineLoaded: false,
        error: null,
    })),
    on(
        DefaultPreferencesActions.getCloudDefaultsSuccess,
        DefaultPreferencesActions.createCloudDefaultsSuccess,(state, { originAirport, airlineId }) => ({
        ...state,
        cloudOriginAirport: originAirport,
        cloudOriginAirline: airlineId,
    })),
    on(DefaultPreferencesActions.getAirlineGroupsSuccess, (state, { groups }) =>
        defaultPreferencesAdapter.upsertOne({ userAirlineGroups: groups }, {
            ...state,
            airlineLoaded: true,
        })
    ),
    on(
        DefaultPreferencesActions.getAirlineGroupsFailure,
        DefaultPreferencesActions.getCloudDefaultsFailure,
        DefaultPreferencesActions.createCloudDefaultsFailure,
        (state, { error }) => ({ ...state, error })
    ),
    on(DefaultPreferencesActions.createAirlineGroupSuccess,(state, { groups }) =>
        defaultPreferencesAdapter.upsertOne({ userAirlineGroups: groups }, {
            ...state,
            airlineLoaded: true,
        })),
    on(DefaultPreferencesActions.deleteAirlineGroupSuccess, (state, { groups }) =>
        defaultPreferencesAdapter.upsertOne({ userAirlineGroups: groups }, {
            ...state,
            airlineLoaded: true,
        })
    ),
    on(DefaultPreferencesActions.updateUnitsMeasurementsSuccess, (state, { unitsMeasurements }) =>
        defaultPreferencesAdapter.upsertOne({ unitsMeasurements }, {
            ...state,
            unitsLoaded: true,
        })
    ),
    on(DefaultPreferencesActions.getDefaultPreferences, (state) => ({
        ...state,
        unitsLoaded: false,
        error: null,
    })),
    on(DefaultPreferencesActions.getDefaultPreferencesSuccess, (state, { unitsMeasurements }) =>
        defaultPreferencesAdapter.upsertOne({ unitsMeasurements }, {
            ...state,
            unitsLoaded: true,
        })
    ),
    on(DefaultPreferencesActions.updatePresetColourSuccess, (state, { unitsMeasurements }) =>
        defaultPreferencesAdapter.upsertOne({ unitsMeasurements }, {
            ...state,
            unitsLoaded: true,
        })
    ),
    on(DefaultPreferencesActions.updateMapColourSuccess, (state, { mapColours }) => {

        const { defaultPreferencesState } = defaultPreferencesAdapter.getSelectors().selectEntities(state);

        return defaultPreferencesAdapter.upsertOne({
            unitsMeasurements: {
                ...defaultPreferencesState?.unitsMeasurements || {},
                mapColours
            }
            }, {
            ...state,
            unitsLoaded: true,
        });
    })
);

export function defaultPreferencesReducer(
    state: DefaultPreferencesState | undefined,
    action: Action
) {
    return reducer(state, action);
}
