import { map, Observable } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { RdcResponseDto } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

import { FiltersDateRangeEntity } from './filters-date-range.models';


@Injectable({ providedIn: 'root' })
export class FiltersDateRangeService extends RdcBaseHttpService {

    constructor(
        @Inject(appEnvironment) private environment: AppEnvironment,
    ) {
        super();
    }

    get(): Observable<FiltersDateRangeEntity> {
        return this.http.get<RdcResponseDto<FiltersDateRangeEntity>>(
            `${ this.environment.api.baseUrl }/configurations/query-date-range`,
            { headers: this.getTokenHeaders() }
        ).pipe(map((response) => response.success.data));
    }

}
