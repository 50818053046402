import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { ButtonModule } from "@rdc-apps/rdc-shared/src/lib/directives/button";
import { DialogItemModule } from "@rdc-apps/rdc-shared/src/lib/directives/dialog-item";
import { IconModule } from "@rdc-apps/rdc-shared/src/lib/ui/icon";

@Component({
    standalone: true,
    selector: 'rdc-apps-table-builder-dialog',
    templateUrl: './table-builder-dialog.component.html',
    styleUrl: './table-builder-dialog.component.scss',
    imports: [ CommonModule, DialogItemModule, ButtonModule, IconModule ],
})
export class TableBuilderDialogComponent {

    @Output() basic: EventEmitter<boolean> = new EventEmitter();

}
