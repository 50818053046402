export const bannerCloudItems: { link: string, imageUrl: string; cloud: string }[] = [
    {
        link: '/growth',
        imageUrl: '/assets/rdc-apex/images/apex-cloud-banner-growth.png',
        cloud: 'growth',
    },
    {
        link: '/risk',
        imageUrl: '/assets/rdc-apex/images/apex-cloud-banner-risk.png',
        cloud: 'risk',
    },
    {
        link: '/network',
        imageUrl: '/assets/rdc-apex/images/apex-cloud-banner-network.png',
        cloud: 'network',
    },
    {
        link: '/sustainability',
        imageUrl: '',
        cloud: 'sustainability',
    },
    {
        link: '/performance',
        imageUrl: '',
        cloud: 'performance',
    }
]
