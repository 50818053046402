import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    DEFAULT_PREFERENCES_FEATURE_KEY,
    DefaultPreferencesState
} from './default-preferences.reducer';

// Lookup the 'DefaultPreferences' feature state managed by NgRx
export const getDefaultPreferencesState =
    createFeatureSelector<DefaultPreferencesState>(
        DEFAULT_PREFERENCES_FEATURE_KEY
    );

export const getDefaultPreferencesAirportLoaded = createSelector(
    getDefaultPreferencesState,
    (state: DefaultPreferencesState) => state.airportLoaded
);

export const getDefaultPreferencesAirlineLoaded = createSelector(
    getDefaultPreferencesState,
    (state: DefaultPreferencesState) => state.airlineLoaded
);

export const getDefaultPreferencesError = createSelector(
    getDefaultPreferencesState,
    (state: DefaultPreferencesState) => state.error
);

export const getDefaultPreferencesAirport = (id: string) => createSelector(
    getDefaultPreferencesState,
    (state: DefaultPreferencesState) => state.entities[id]?.userAirportGroups
);

export const getDefaultPreferencesAirline = (id: string) => createSelector(
    getDefaultPreferencesState,
    (state: DefaultPreferencesState) => state.entities[id]?.userAirlineGroups
);

export const getCloudOriginAirport = createSelector(
    getDefaultPreferencesState,
    (state: DefaultPreferencesState) => state.cloudOriginAirport
);

export const getCloudOriginAirline = createSelector(
    getDefaultPreferencesState,
    (state: DefaultPreferencesState) => state.cloudOriginAirline
);

export const getDefaultPreferencesUnitsMeasurements = (id: string) => createSelector(
    getDefaultPreferencesState,
    (state: DefaultPreferencesState) => state.entities[id]?.unitsMeasurements
);

export const getUserHighchartsFormatting = (id: string) => createSelector(
    getDefaultPreferencesState,
    (state: DefaultPreferencesState) => {
        switch (state.entities[id]?.unitsMeasurements?.tableOutput) {
            case 'commas': {
                return {
                    thousandsSep: ',',
                    decimalPoint: '.',
                };
            }
            default: {
                return {
                    thousandsSep: '.',
                    decimalPoint: ',',
                };
            }
        }
    });

export const getPresetColours = (id: string) => createSelector(
    getDefaultPreferencesState,
    (state: DefaultPreferencesState) => {
        const colours = state.entities[id]?.unitsMeasurements;

        return colours?.presetColours;
    }
);

export const getMapPresetColours = (id: string) => createSelector(
    getDefaultPreferencesState,
    (state: DefaultPreferencesState) => {
        const colours = state.entities[id]?.unitsMeasurements;

        return colours?.mapColours;
    }
);
