import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { easing } from '@rdc-apps/rdc-shared/src/lib/constants';

export const expandCollapse = trigger('expandCollapse', [
    state('expanded', style({
        height: '*',
    })),
    state('*', style({
        height: '0px',
    })),
    transition('* <=> *', [
        group([
            animate(`250ms ${easing.standard}`),
            query('@*', [
                animateChild(),
            ], { optional: true }),
        ]),
    ]),
]);

