import { filter, Observable, take, takeUntil } from 'rxjs';

import { Dialog } from '@angular/cdk/dialog';
import { CdkMenuTrigger } from '@angular/cdk/menu';
import {
    ConnectedPosition,
    FlexibleConnectedPositionStrategy,
    Overlay,
    OverlayPositionBuilder
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, Input, OnInit, TemplateRef } from '@angular/core';
import { RdcMenuCarrotComponent } from '@rdc-apps/rdc-shared/src/lib/ui/rdc-menu-carrot';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';

@Directive({ selector: '[rdcAppsCarrot]' })
export class RdcCarrotDirective extends RdcComponentUtils implements OnInit {

    @Input() rdcCarrotDialogId = '';

    @Input() rdcCarrotMenuTrigger!: CdkMenuTrigger;
    @Input() rdcCarrotClasses: string[] = [];
    @Input() cdkMenuTriggerFor!: TemplateRef<any>;
    @Input() cdkMenuPosition!: ConnectedPosition[] | undefined;
    @Input() rdcCarrotPosition!: ConnectedPosition[] | undefined;

    constructor(
        private elementRef: ElementRef,
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private dialog: Dialog
    ) {
        super();
    }

    ngOnInit(): void {
        // apply carrot to cdk-dialog
        if (this.rdcCarrotDialogId) {

            this.dialog.afterOpened
                .pipe(
                    takeUntil(this.componentDestroyed$),
                    filter((dialogRef) => dialogRef.id === this.rdcCarrotDialogId)
                )
                .subscribe((dialogRef) => {
                    const dialogPositionStrategy = (dialogRef.config.positionStrategy as FlexibleConnectedPositionStrategy).positions;

                    this.carrotUntilClose(dialogPositionStrategy, dialogRef.closed);
                });

            return;
        }

        // apply carrot to cdk-menu
        if (this.rdcCarrotMenuTrigger) {

            this.rdcCarrotMenuTrigger.opened
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe(() => {

                    this.carrotUntilClose(
                        this.rdcCarrotPosition || this.cdkMenuPosition || [],
                        this.rdcCarrotMenuTrigger.closed,
                        true
                    );

                });

            return;
        }

    }

    private carrotUntilClose(position: ConnectedPosition[], closeEvent: Observable<void>, cdkMenuCarrot = false): void {

        setTimeout(() => {

            const positionStrategy = this.overlayPositionBuilder
                .flexibleConnectedTo(this.elementRef)
                .withPositions(position);

            const overlayRef = this.overlay.create({
                positionStrategy,
                panelClass: [ ...this.rdcCarrotClasses ],
            });

            overlayRef.hostElement.classList.add('rdc-menu-carrot');

            if (cdkMenuCarrot) {
                overlayRef.hostElement.classList.add('cdk-menu-carrot');
            }

            const carrotPortal = new ComponentPortal(RdcMenuCarrotComponent);

            const ref = overlayRef.attach(carrotPortal);

            if(this.menuOverlaysCarrot) {
                overlayRef.dispose(); // do not show it

                return;
            }

            ref.instance.position = position;

            closeEvent
                .pipe(take(1))
                .subscribe(() => {
                    overlayRef.dispose();
                });

        });

    }

    /* Returns true if the top of the menu is lower (closer to top of the screen) than the trigger
     elements bottom position with the height of the carrot element added to it (10px as of writing) */
    private get menuOverlaysCarrot(): boolean {

        const position = this.rdcCarrotPosition || this.cdkMenuPosition || [];

        if(position[0]?.originY !== 'bottom') {
            return false;
        }

        const menuY = this.rdcCarrotMenuTrigger.getMenu()?.nativeElement.getBoundingClientRect().y || 0;

        const triggerY = this.elementRef.nativeElement.getBoundingClientRect().y;

        const triggerHeight = this.elementRef.nativeElement.offsetHeight;

        return menuY < ((triggerY + triggerHeight) + 10)
    }

}
