import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterLinkActive, RouterLink, RouterLinkWithHref } from '@angular/router';
import { TooltipModule } from '@rdc-apps/rdc-shared/src/lib/directives/tooltip';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';

import { SidebarItemComponent } from './sidebar-item.component';

@NgModule({
    imports: [
        CommonModule,
        RouterLinkActive,
        TooltipModule, RouterLink,
        IconModule,
        BrowserAnimationsModule,
        RouterLinkWithHref,
    ],
    declarations: [ SidebarItemComponent ],
    exports: [ SidebarItemComponent ],
})
export class SidebarItemModule {}
