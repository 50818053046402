import { createAction, props } from '@ngrx/store';
import { SalesforceLead } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';

export const createSalesforceLead = createAction(
    '[Salesforce] Create Lead',
    props<{ lead: SalesforceLead }>()
);

export const createSalesforceLeadSuccess = createAction(
    '[Salesforce] Create Lead Successful'
);
