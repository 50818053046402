import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export abstract class RdcBaseHttpService {

    protected http = inject(HttpClient);
    protected oidcSecurityService = inject(OidcSecurityService);

    protected getTokenHeaders(): HttpHeaders {
        const token = this.oidcSecurityService.getAccessToken();
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', `Bearer ${token}`);

        return httpHeaders;
    }

}
