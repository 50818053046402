import { createAction, props } from '@ngrx/store';

import { RiskCreateRoute, RiskEntity, RiskUpdateRoute } from './risk.models';

export const getRiskRouteAction= createAction(
    '[Risk Route] Get'
);

export const getRiskRouteSuccessAction = createAction(
    '[Risk Route] Get Success',
    props<{ routes: RiskEntity[] }>()
);

export const getRiskRouteFailureAction= createAction(
    '[Risk Route] Get Failure',
    props<{ error: any }>()
);

export const createRiskRouteAction= createAction(
    '[Risk Route] Create',
    props<{ routesSet: RiskCreateRoute[]; dispatchId?: string }>()
);

export const createRiskRouteSuccessAction = createAction(
    '[Risk Route] Create Success',
    props<{ routes: RiskEntity[]; dispatchId?: string }>()
);

export const createRiskFailureAction= createAction(
    '[Risk Route] Create Failure'
);

export const deleteRiskRouteAction= createAction(
    '[Risk Route] Delete',
    props<{ routeId: string }>()
);

export const deleteRiskRouteSuccessAction= createAction(
    '[Risk Route] Delete Success',
    props<{ routes: RiskEntity[] }>()
);

export const updateRiskRouteAction= createAction(
    '[Risk Route] Update',
    props<{ route: RiskUpdateRoute }>()
);

export const updateRiskRouteSuccessAction= createAction(
    '[Risk Route] Update Success',
    props<{ routes: RiskEntity[] }>()
);

export const deleteRiskFailureAction= createAction(
    '[Risk Route] Delete Failure'
);

export const refreshRiskRouteAction= createAction(
    '[Risk Route] Refresh'
);

export const refreshRiskRouteSuccessAction= createAction(
    '[Risk Route] Refresh Success',
    props<{ routes: RiskEntity[] }>()
);
