import { combineLatest, firstValueFrom, Observable, of, takeUntil } from 'rxjs';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from "@ngrx/store";
import { QbFiltersDateRangeUtils } from '@rdc-apps/rdc-apex/src/lib/query-builder/utilities';
import { autocompleteTypeFilters } from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import {
    QbFilterSelectedItem,
    qbOriginDestination,
    QueryBuilderFilters,
    QueryBuilderType
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import {
    AutocompleteDispatch,
    AutocompleteOption
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/autocomplete';
import { DataPointsEntity, QbDropDowns } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points';
import { FiltersDateRangeEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/filters-date-range';
import { runApexTrialMode } from "@rdc-apps/rdc-apex/src/lib/shared/data-access/store/user-details";
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';

@Component({
    selector: 'rdc-apps-qb-form-filters',
    templateUrl: './qb-form-filters.component.html',
    styleUrls: [ './qb-form-filters.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class QbFormFiltersComponent extends RdcComponentUtils implements OnInit, OnDestroy {

    @Input() form: FormGroup = new FormGroup({});

    @Input() autocompleteResults$!: Observable<AutocompleteOption[]>;

    @Input() dataPointsData!: DataPointsEntity | null | undefined;

    @Input() filterDateRanges!: FiltersDateRangeEntity | null;

    @Output() autocomplete: EventEmitter<AutocompleteDispatch> = new EventEmitter<AutocompleteDispatch>();

    @Output() initialised: EventEmitter<void> = new EventEmitter<void>();

    @Output() selectedTimePeriod: EventEmitter<RepoItem<string>> = new EventEmitter<RepoItem<string>>();

    @Output() timePeriodYearChange: EventEmitter<void> = new EventEmitter<void>();

    autocompleteTypeFilters = autocompleteTypeFilters;

    formGroup!: FormGroup<QueryBuilderFilters>;

    appendToFormGroup: FormGroup = new FormGroup({});

    dropDowns: QbDropDowns | undefined = undefined;

    dateRangeUtils!: QbFiltersDateRangeUtils;

    cabinTypeLabel = 'Economy';
    emissionLabel = 'All';

    cabinTypeDefault = [ 'economy' ];
    emissionsDefault = [ 'all' ];

    schedulesFiltersDefault = {
        franchisePartners: true,
        nonStopServices: true,
    };

    cabinToolTip = 'Cabin type filters cannot be applied when data points from groups other than <strong>FARES</strong> are selected';
    emissionsToolTip = 'Emissions scheme filter cannot be applied when data points from groups other than <strong>SUSTAINABILITY</strong> are selected';
    schedulesToolTip = 'Non-stop and operating carrier filters cannot be applied by when data points from groups other than <strong>SCHEDULES</strong> are selected';

    relativeMonthOpts: RepoItem<string>[] = [];

    additionalFiltersOpen = false;
    disabledCabinTypeInput = false;

    chartQueryTypes = Object.values(QueryBuilderType).slice(1);

    apexTrialMode$!: Observable<boolean>;

    constructor(
        private fb: FormBuilder,
        private store$: Store
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.apexTrialMode$ = this.store$.select(runApexTrialMode('userDetailsState'));

        const isTrialMode = await firstValueFrom(this.apexTrialMode$);

        this.dateRangeUtils = new QbFiltersDateRangeUtils(this.filterDateRanges, isTrialMode);

        this.dropDowns = this.dataPointsData?.dropdowns;

        this.appendToFormGroup = this.form.get('queryRequest') as FormGroup;

        this.relativeMonthOpts = this.dropDownsForGroup('timePeriodRelative');

        // base form, constant form elements
        this.appendToFormGroup.setControl('filters',
            this.fb.group({
                cabinTypes: this.fb.group({
                    types: this.fb.array([
                        new FormControl('economy'),
                    ]),
                }),
                emissionScheme: this.fb.group({
                    types: this.fb.array([
                        new FormControl('all'),
                    ]),
                }),
                schedulesFilters: this.fb.group({
                    franchisePartners: this.fb.control(true),
                    nonStopServices: this.fb.control(true),
                }),
            })
        );

        this.formGroup = this.appendToFormGroup.get('filters') as FormGroup<QueryBuilderFilters>;

        /* DYNAMIC FORM ELEMENTS */
        this.addAutoCompleteFilters();

        this.form.get('studyType')?.valueChanges.subscribe(() => {
            this.handleDynamicFilterOpts();
        });

        combineLatest([
            this.formGroup.get('cabinTypes.types')?.valueChanges || of([]),
            this.formGroup.get('emissionScheme.types')?.valueChanges || of([]),
        ]).subscribe(([ cabinTypeArr, emissionArr ]: string[][]) => {
            this.setFareEmissionValidation(cabinTypeArr, 'cabinTypes');
            this.setFareEmissionValidation(emissionArr,'emissionScheme');
            this.emissionLabel = this.typeArrayLabel(emissionArr, 'emissionsSchemeFilter');
            this.cabinTypeLabel = this.typeArrayLabel(cabinTypeArr, 'cabin');
        });
    }

    override ngOnDestroy() {
        super.ngOnDestroy();

        this.initialised.complete();
    }

    dropDownsForGroup(groupName: string): RepoItem<string>[] {
        return this.dropDowns?.dropdownOptionGroups
            ?.find((group) => group.code === groupName)
            ?.dropdownOptions || [];
    }

    private addAutoCompleteFilters(): void {
        this.autocompleteTypeFilters.forEach((key) => {

            this.formGroup.addControl(key, this.fb.group({
                type: this.fb.control<qbOriginDestination>('all'),
                selected: this.fb.array<QbFilterSelectedItem>([]),
            }) as any);

            this.formGroup.get(`${key}.type`)?.valueChanges
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe((type: string) => {
                    this.onPatchMatchingSummaryProp(key, type);
                });
        });
    }

    private handleDynamicFilterOpts(): void {
        combineLatest([
            this.appendToFormGroup.get('dataPoints')?.valueChanges || of(null),
            this.appendToFormGroup.get('dataPoint')?.valueChanges || of(null),
        ]).subscribe(([ dataPoints, dataPointCode ]) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let onlySchedules = false;
            let onlyPricing = false;
            let onlySustain = false;

            if (dataPoints) {
                const { ...filteredDataPoints } = dataPoints;

                onlySchedules = this.onlyDataPointGroupChecked( 'schedules', filteredDataPoints);
                onlyPricing = this.onlyDataPointGroupChecked( 'pricing', filteredDataPoints);
                onlySustain = this.onlyDataPointGroupChecked( 'sustainability', filteredDataPoints);
            }

            const dataPoint = this.dataPointsData?.datapoints.find((dp) => dp.code === dataPointCode);

            this.enableDisableGroupIf(
                onlyPricing || (dataPoint?.datapointGroups.map(({ datapointGroupCode }) => datapointGroupCode).includes('pricing') || false),
                'cabinTypes.types',
                this.cabinTypeDefault
            );
            this.enableDisableGroupIf(
                onlySchedules || (dataPoint?.datapointGroups.map(({ datapointGroupCode }) => datapointGroupCode).includes('schedules') || false),
                'schedulesFilters',
                this.schedulesFiltersDefault
            );
            this.enableDisableGroupIf(
                onlySustain || (dataPoint?.datapointGroups.map(({ datapointGroupCode }) => datapointGroupCode).includes('sustainability') || false),
                'emissionScheme.types',
                this.emissionsDefault
            );
        });
    }

    private enableDisableGroupIf(shouldBeDisplayed: boolean, groupName: string, defaultState: string[] | Record<string, boolean>): void {

        const abstractControl = this.formGroup.get(groupName);

        if (shouldBeDisplayed) {
            abstractControl?.enable();

            return;
        }

        if (abstractControl instanceof FormArray) {
            abstractControl.clear();

            (defaultState as string[]).forEach((str) => abstractControl.push(new FormControl(str, Validators.required)));
        } else {
            abstractControl?.patchValue(defaultState);
        }

        abstractControl?.disable();
    }

    private onlyDataPointGroupChecked(key: string, datapointGroups?: Record<string, Record<string, boolean>>): boolean {
        if (!datapointGroups) {
            return false;
        }

        const groups = { ...datapointGroups };

        delete groups['popular'];

        const groupChecked = JSON.stringify(groups[key] || '').includes('true');
        const othersChecked = JSON.stringify({ ...groups, [key]: null }).includes('true');

        return groupChecked && !othersChecked;
    }

    onFilterTypeToggle(option: RepoItem<string>, path: string) {
        const typeArray = this.formGroup.get<string>(`${path}.types`) as FormArray;

        typeArray.markAsTouched();

        if (path === 'emissionScheme' && [ 'all', 'noScheme' ].includes(option.code)) {
            typeArray.clear();
        } else if (path === 'emissionScheme') {

            const allIndex = typeArray.controls.findIndex((ctrl) => ctrl.value === 'all');
            const noneIndex = typeArray.controls.findIndex((ctrl) => ctrl.value === 'noScheme');

            if (allIndex >= 0) {
                typeArray.removeAt(allIndex);
            }
            if (noneIndex >= 0) {
                typeArray.removeAt(noneIndex);
            }
        }

        if (this.inFilterTypeFormArray(option.code, path)) {

            const ind = typeArray.controls.findIndex((control) => control.value === option.code);

            typeArray.removeAt(ind);

            return;
        }

        typeArray.push(new FormControl(option.code));
    }

    inFilterTypeFormArray(val: string, path: string, disabledCabinType?: boolean): boolean {
        const typeArray = this.formGroup.get<string>(`${path}.types`) as FormArray;

        // submit cabin types when non fare datapoint is selected alongside a fare datapoint
        if (disabledCabinType) {

            const filteredCabinControls = typeArray.controls.filter((control): boolean => control.value !== val);

            filteredCabinControls.forEach((): void => typeArray.removeAt(1));
        }

        return !!typeArray.controls.find((control) => control.value === val);
    }

    typeArrayLabel(typeArray: string[], groupCode: string): string {
        if (!typeArray) {
            return 'Unset';
        }

        if (typeArray?.length === 1) {
            return this.dropDownsForGroup(groupCode).find((ct) => ct.code === typeArray[0])?.label || '';
        }

        return `${typeArray.length > 0 ? typeArray.length : 'None' } selected`;
    }

    get additionalFiltersInvalid(): boolean {
        const list = [ 'cabinTypes', 'emissionScheme' ];

        return !!list.find((group) => this.formGroup.get(group)?.invalid);
    }

    onPatchMatchingSummaryProp(filter: string, value: string): void {

        if (this.form.get('studyType')?.value !== QueryBuilderType.Table) {
            return;
        }

        if (filter === 'airline') {
            this.appendToFormGroup.get('summaries.primaryCarrier')?.patchValue(value);
        }

        this.appendToFormGroup.get(`summaries.${filter}`)?.patchValue(value);
    }

    setFareEmissionValidation(array: string[], control: string): void {
        if (!array.length) {
            this.formGroup.get(`${control}.types`)?.setErrors({ errors: 'No selection applied.' });
        } else {
            this.formGroup.get(`${control}.types`)?.clearValidators();
        }
    }
}
