import { QueryBuilderType } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { QueryEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/query';
import { RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

import { rdcTrialYearStart } from "./trial-year-start";


export function queryBuilderDefaultState(isOnTrial?: boolean, datapoints?: string[]): QueryEntity {

    return {
        studyId: '00000000-0000-0000-0000-000000000000', // new study
        cloudId: '605A6567-9842-4BB4-A505-8C84985E0A8D', // query builder cloud id
        pinned: false,
        studyType: QueryBuilderType.Table,
        studyName: 'New query',
        createdUtc: '',
        lastRunUtc: '',
        lastUpdatedUtc: '',
        tags: [],
        isCloudStudy: false,
        isNetworkAppletStudy: false,
        queryRequest: {
            dataPoints: {
                ...(datapoints ? { dataPointCodes: datapoints } : undefined)
            },
            filters: {
                timePeriod: {
                    type: 'specific',
                    specific: {
                        endYear: isOnTrial ? rdcTrialYearStart : (new Date().getFullYear() - 1),
                        startYear: isOnTrial ? rdcTrialYearStart : (new Date().getFullYear() - 1),
                        startMonth: 'january',
                        endMonth: 'december',
                    } as any,
                },
                destination: {
                    type: 'all',
                    selected: [],
                },
                origin: {
                    type: 'all',
                    selected: [],
                },
                aircraft: {
                    type: 'all',
                    selected: [],
                },
                airline: {
                    type: 'all',
                    selected: [],
                },
            },
            summaries: {
                aircraft: 'all',
                destination: 'all',
                emissionsScheme: 'all',
                operatingCarrier: 'all',
                primaryCarrier: 'all',
                origin: 'all',
                timePeriod: 'all',
            }
        },
    };
}

export function queryBuilderCloudAppletState(appletStorageState: { airline: RepoItem<string>[], destination: RepoItem<string>[], originAirport: RepoItem<string>[] }): QueryEntity {

    return {
        studyId: '00000000-0000-0000-0000-000000000000', // new study
        cloudId: '605A6567-9842-4BB4-A505-8C84985E0A8D', // query builder cloud id
        pinned: false,
        studyType: QueryBuilderType.Table,
        studyName: 'New query',
        createdUtc: '',
        lastRunUtc: '',
        lastUpdatedUtc: '',
        tags: [],
        isCloudStudy: false,
        isNetworkAppletStudy: false,
        queryRequest: {
            dataPoints: {
                dataPointCodes: [ 'modelledFare', 'profit', 'profitMargin', 'departures', 'averageCapacity', 'apexInsightOverall' ]
            },
            filters: {
                timePeriod: {
                    type: 'relative',
                    flow: "full",
                    relative: {
                        relativeMonths: 'last12Months'
                    }
                },
                destination: {
                    type: appletStorageState.destination.length ? (appletStorageState.destination[0].type || appletStorageState.destination[0].dataType?.toLowerCase()) || '' : 'all',
                    selected: [ ...appletStorageState.destination ],
                },
                origin: {
                    type: appletStorageState.originAirport.length ? 'airport' : 'all',
                    selected: [ ...appletStorageState.originAirport ],
                },
                aircraft: {
                    type: 'all',
                    selected: [],
                },
                airline: {
                    type: appletStorageState.airline.length ? 'airline' : 'all',
                    selected: [ ...appletStorageState.airline ],
                },
            },
            summaries: {
                aircraft: 'all',
                destination: 'airport',
                emissionsScheme: 'all',
                operatingCarrier: 'all',
                primaryCarrier: appletStorageState.airline.length ? 'airline' : 'all',
                origin: 'airport',
                timePeriod: 'all',
            }
        },
    };
}
