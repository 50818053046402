import { debounce, firstValueFrom, interval, Observable, Subscription, takeUntil } from 'rxjs';

import { TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { QbFiltersDateRangeUtils } from '@rdc-apps/rdc-apex/src/lib/query-builder/utilities';
import {
    rdcTrialYearStart,
    showByOpts,
    specificQuarterOptions,
    specificTimeFrameOptions,
    timePeriodOptions
} from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import {
    qbSpecificPeriod,
    QbSpecificQuarters,
    QbSpecificTimeFilters,
    qbTimePeriod,
    QbTimePeriodFilters
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { clearQuery, QueryEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/query';
import { runApexTrialMode } from "@rdc-apps/rdc-apex/src/lib/shared/data-access/store/user-details";
import { CustomValidators } from '@rdc-apps/rdc-shared/src/lib/custom-validators';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { keyValueKeepOrder, RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';

@Component({
    selector: 'rdc-apps-qb-filter-timeframe',
    templateUrl: './qb-filter-timeframe.component.html',
    styleUrls: [ './qb-filter-timeframe.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class QbFilterTimeframeComponent extends RdcComponentUtils implements OnInit {

    @Input() studyForm!: FormGroup;
    @Input() relativeMonthOpts: RepoItem<string>[] = [];
    @Input() dateRangeUtils!: QbFiltersDateRangeUtils;
    @Input() basicBuilder!: boolean;

    @Output() initialised: EventEmitter<void> = new EventEmitter<void>();
    @Output() selectedTimePeriod: EventEmitter<RepoItem<string>> = new EventEmitter<RepoItem<string>>();

    keepOrder = keyValueKeepOrder;

    showByOpts = showByOpts;

    monthChangesSubscription!: Subscription;
    startYrChangeSub!: Subscription | undefined;
    endYrChangeSub!: Subscription | undefined;

    specificTimeFrameOptions: { label: string; code: string }[] = [];
    selectableStartYearOptions: Partial<RepoItem<number>>[] = [];
    selectableEndYearOptions: Partial<RepoItem<number>>[] = [];
    selectableSpecificStartMonths: Partial<RepoItem<string>>[] = [];
    selectableSpecificEndMonths: Partial<RepoItem<string>>[] = [];

    filtersFormGroup: FormGroup = new FormGroup({});
    appendToFormGroup: FormGroup = new FormGroup({});

    titleCasePipe = new TitleCasePipe();

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private store: Store,
        actions: Actions
    ) {
        super(actions);
    }

    hideFullTimeFrame = false;
    timePeriodSubscription!: Subscription | undefined;

    subscriptions: (Subscription | undefined)[] = [];

    apexTrialMode$!: Observable<boolean>;

    isOnTrial!: boolean;

    ngOnInit(): void {
        localStorage.removeItem('previousSpecific');
        localStorage.removeItem('previousRelative');

        this.apexTrialMode$ = this.store.select(runApexTrialMode('userDetailsState'));

        this.appendToFormGroup = this.studyForm.get('queryRequest') as FormGroup;

        this.selectableStartYearOptions = this.dateRangeUtils.selectableYearsAsRepoItems();

        this.specificTimeFrameOptions = specificTimeFrameOptions.map((opt) => ({
            label: this.titleCasePipe.transform(opt),
            code: opt,
        }));

        this.filtersFormGroup = this.appendToFormGroup.get('filters') as FormGroup;

        this.addTimePeriodControl();

        // set time period to specific when clearing all values
        this.onActions([ clearQuery ], () => {
            localStorage.removeItem('previousSpecific');
            this.handleTimePeriodType('specific', this.isOnTrial)
        });

        this.filtersFormGroup.valueChanges
            .pipe(debounce(()=> interval(0)))
            .subscribe(({ timePeriod }) => {

                this.selectableSpecificStartMonths = this.dateRangeUtils.calendarMonthsRepoItems(
                    'january',
                    false,
                    timePeriod.specific?.startYear,
                    timePeriod.specific?.startYear === timePeriod.specific?.endYear
                );

                this.selectableSpecificEndMonths = this.dateRangeUtils.calendarMonthsRepoItems(
                    timePeriod.specific?.startMonth,
                    false,
                    timePeriod.specific?.endYear,
                    timePeriod.specific?.startYear === timePeriod.specific?.endYear
                );

                this.selectableEndYearOptions = this.dateRangeUtils.selectableYearsAsRepoItems(timePeriod.specific?.startYear);

                this.cdr.detectChanges();
            });

        this.studyForm?.valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((form: QueryEntity) => {

                this.filtersFormGroup = this.appendToFormGroup.get('filters') as FormGroup;

                const chartProps = form.queryRequest.chartProperties;

                if (form.queryRequest.filters.timePeriod?.flow === 'full') {
                    return;
                }

                if (chartProps?.xAxis?.type === 'timePeriod' || chartProps?.seriesDefinitions?.find(({ type }) => type === 'timePeriod')) {

                    this.hideFullTimeFrame = true;

                    return;
                }

                this.hideFullTimeFrame = false;

                this.addTimePeriodControl();
            });
    }

    private async addTimePeriodControl(): Promise<void> {

        const onTrial = await firstValueFrom(this.apexTrialMode$);
        this.isOnTrial = onTrial;

        this.filtersFormGroup.setControl('timePeriod', this.fb.group({
            type: this.fb.control<qbTimePeriod>('specific', CustomValidators.inArray(timePeriodOptions)),
            flow: this.fb.control('full'),
        }), { emitEvent: false });

        // set the default for this
        this.handleTimePeriodType('specific', onTrial);

        // listen to changes, change form accordingly
        this.timePeriodSubscription?.unsubscribe();

        this.timePeriodSubscription = this.filtersFormGroup.get('timePeriod.type')?.valueChanges.subscribe(async (timePeriodType) => {

            if (timePeriodType === 'relative') {
                this.handleTimePeriodType(timePeriodType, onTrial)
            }

        });

        this.initialised.emit();
    }

    onTimePeriodType(timePeriodType: string): void {
        localStorage.setItem(`${timePeriodType === 'specific' ? 'previousRelative' : 'previousSpecific' }`, JSON.stringify(this.filtersFormGroup.get('timePeriod')?.value));

        this.handleTimePeriodType(timePeriodType, this.isOnTrial)
    }

    handleTimePeriodType(timePeriodType: string | null, onTrial: boolean): void {

        const group = (this.filtersFormGroup.get('timePeriod') as FormGroup<QbTimePeriodFilters>);

        timePeriodOptions.forEach((timePeriodOpt) => group.removeControl(timePeriodOpt));

        switch (timePeriodType) {
            case 'relative': {
                const relativeMonthOpts = this.relativeMonthOpts.map((opt) => opt.code);

                group.setControl('relative', this.fb.group({
                    relativeMonths: this.fb.control('last12Months', CustomValidators.inArray(relativeMonthOpts)),
                }));


                if (localStorage.getItem('previousRelative')) {
                    const relativePrevValue = JSON.parse(localStorage.getItem('previousRelative') || '');

                    if (relativePrevValue.type === 'relative') {
                        group.patchValue(relativePrevValue, { emitEvent: false });
                    }
                }

                break;
            }
            case 'specific': {

                group.setControl('specific', this.fb.group<QbSpecificTimeFilters>({
                    type: this.fb.control('months', CustomValidators.inArray(specificTimeFrameOptions)),
                    startYear: this.fb.control(
                        onTrial ? rdcTrialYearStart : new Date().getFullYear() - 1,
                        CustomValidators.inArray(this.dateRangeUtils.selectableYearOptions)
                    ),
                    endYear: this.fb.control(
                        onTrial ? rdcTrialYearStart : new Date().getFullYear() - 1,
                        CustomValidators.inArray(this.dateRangeUtils.selectableYearOptions)
                    ),
                }));

                if (localStorage.getItem('previousSpecific')) {
                    const specificPrevValue = JSON.parse(localStorage.getItem('previousSpecific') || '');

                    group.patchValue(specificPrevValue, { emitEvent: false });

                    this.handleTimePeriodSpecifiedType(specificPrevValue.specific.type);
                } else {
                    /* DYNAMIC FORM ELEMENTS FOR THIS DYNAMIC FORM ELEMENT */
                    // set the default state
                    this.handleTimePeriodSpecifiedType('months');
                }

                // react to changes to this control
                const sub = group.get('specific.type')?.valueChanges.subscribe((specificType) => this.handleTimePeriodSpecifiedType(specificType));

                this.subscriptions.push(sub);

                break;
            }
        }
        this.cdr.detectChanges();
    }

    private handleTimePeriodSpecifiedType(specificType: qbSpecificPeriod): void {
        const specificTimePeriodFormGroup = this.filtersFormGroup.get('timePeriod.specific') as FormGroup<QbSpecificTimeFilters>;

        this.monthChangesSubscription?.unsubscribe();
        this.startYrChangeSub?.unsubscribe();
        this.endYrChangeSub?.unsubscribe();

        [ ...specificTimeFrameOptions, 'startMonth', 'endMonth' ].forEach((control) =>
            specificTimePeriodFormGroup?.removeControl(control as never)
        );

        switch (specificType) {
            case 'months': {
                const { startYear, endYear } = specificTimePeriodFormGroup.value;

                this.selectableSpecificStartMonths = this.dateRangeUtils.calendarMonthsRepoItems(
                    'january',
                    false,
                    startYear || 2000,
                    (startYear || 2000) === (endYear || 2060)
                );

                this.selectableSpecificEndMonths = this.dateRangeUtils.calendarMonthsRepoItems(
                    'january',
                    false,
                    endYear || 2060,
                    (startYear || 2000) === (endYear || 2060)
                );

                // validators set by multiselect component
                specificTimePeriodFormGroup.addControl(
                    'startMonth',
                    this.fb.control(this.selectableSpecificStartMonths[0].code, Validators.required)
                );
                // validators set by multiselect component
                specificTimePeriodFormGroup.addControl(
                    'endMonth',
                    this.fb.control(this.selectableSpecificEndMonths[this.selectableSpecificEndMonths.length - 1].code, Validators.required)
                );

                break;
            }
            case 'quarters': {
                specificTimePeriodFormGroup.addControl(
                    'quarters',
                    this.fb.group({} as QbSpecificQuarters, { validators: CustomValidators.atLeastOneChecked() })
                );

                specificQuarterOptions.forEach((quarter) => {
                    (specificTimePeriodFormGroup.get('quarters') as FormGroup<QbSpecificQuarters>)
                        .setControl(quarter, this.fb.control(true));
                });

                break;
            }
            case 'seasons': {
                specificTimePeriodFormGroup.addControl('seasons', this.fb.group({
                    summer: this.fb.control(true),
                    winter: this.fb.control(true),
                }, { validators: CustomValidators.atLeastOneChecked() }));

                break;
            }
        } // end switch
    }

    getAsFormGroup(path: string): FormGroup {
        return this.filtersFormGroup.get(path) as FormGroup;
    }

    getSpecificOptionControls(path: string): Record<string, AbstractControl<boolean>> {
        const completePath = `timePeriod.specific.${path}`;

        return (this.filtersFormGroup.get(completePath) as FormGroup).controls;
    }

    onSelectedTimePeriod(period: Partial<RepoItem<unknown>>) {
        this.selectedTimePeriod.emit(period as RepoItem<string>);
    }
}
