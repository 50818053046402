import { trigger, animate, transition, state, style, group, query, animateChild } from '@angular/animations';
import { easing } from '@rdc-apps/rdc-shared/src/lib/constants';


export const shrinkSidebarItem = trigger('shrinkSidebarItem', [
    state('max', style({
        width: '184px',
    })),
    state('min', style({
        width: '40px',
    })),
    transition('* <=> *', [
        group([
            animate(`250ms ${easing.standard}`),
            query('@*', [
                animateChild(),
            ], { optional: true }),
        ]),
    ]),
]);

export const animSidebarIcon = trigger('animSidebarIcon', [
    state('max', style({
        fontSize: '24px',
    })),
    state('min', style({
        fontSize: '{{size}}',
    }), { params: { size: '16px' } }),
    transition('* <=> *', [
        animate(`250ms ${easing.standard}`),
    ]),
]);

export const animSidebarSvg = trigger('animSidebarSvg', [
    state('max', style({
        width: '24px',
        height: '24px',
        padding: '1px',
    })),
    state('min', style({
        width: '18px',
        height: '18px',
    })),
    transition('* <=> *', [
        animate(`250ms ${easing.standard}`),
    ]),
]);

export const animSidebarAccCont = trigger('animSidebarAccCont', [
    state('max', style({
        width: '32px',
        height: '32px',
    })),
    state('min', style({
        width: '24px',
        height: '24px',
    })),
    transition('* <=> *', [
        animate(`250ms ${easing.standard}`),
    ]),
]);
