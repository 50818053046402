import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RdcMenuItemDirective } from './rdc-menu-item.directive';

@NgModule({
    declarations: [ RdcMenuItemDirective ],
    exports: [ RdcMenuItemDirective ],
    imports: [ CommonModule ],
})
export class RdcMenuItemModule {}
