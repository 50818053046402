import { Pipe, PipeTransform } from '@angular/core';
import { orderByClosestCodeToFilter } from "@rdc-apps/rdc-shared/src/lib/constants";
import { RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

@Pipe({
    name: 'rdcFilter',
    standalone: true,
})
export class RdcFilterPipe implements PipeTransform {

    transform<T extends Partial<RepoItem<unknown>>>(options: T[], filter: string, ...additionalProps: string[]): T[] {

        const filterPipe = new SimpleFilterPipe();

        if(options.length && options[0]['options']) {

            const filteredCompleteOptions = options.map((opt) => ({
                ...opt,
                options: filterPipe.transform(opt['options'], filter, 'label', 'iataCode', 'icaoCode', ...additionalProps),
            }));

            return filteredCompleteOptions
                .filter((opt) => opt['options'].length)
                .map((opt) => ({
                    ...opt,
                    options: orderByClosestCodeToFilter(
                        opt['options'] as RepoItem<unknown>[],
                        filter
                    ),
                }));
        }

        const filtered = filterPipe.transform(options, filter, 'label', 'iataCode', 'icaoCode', 'code', ...additionalProps);

        return orderByClosestCodeToFilter(filtered, filter);
    }

}

@Pipe({
    name: 'simpleFilter',
    standalone: true,
})
export class SimpleFilterPipe implements PipeTransform {

    transform<T>(items: T[] = [], filter: string, ...checkProperties: string[]): T[] {

        const filterToLower = filter.toLowerCase();

        if (!checkProperties.length && typeof items[0] !== 'string') {
            // eslint-disable-next-line no-console
            console.warn('Provide one or more properties to filter against when providing objects to the filter pipe');

            return items;
        }

        return items.filter((item: T) => {

            if (typeof item === 'string') {
                return item.toLowerCase().includes(filterToLower) || item === '*';
            }

            return !!checkProperties.find((key) => {
                const val = (item as never)[key];

                const compareVal = String(val).toLowerCase();

                if([ 'iataCode', 'icaoCode' ].includes(key)) {
                    return compareVal === filter;
                }

                return String(val).toLowerCase().includes(filterToLower) || String(val) === '*';
            });
        });
    }

}
