import { map, of } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { httpErrorAction } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/error';
import { ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { ToastService } from '@rdc-apps/rdc-shared/src/lib/ui/toast';

import {
    createGrowthRouteAction,
    createGrowthRouteSuccessAction,
    getGrowthRouteAction,
    getGrowthRouteSuccessAction,
    updateGrowthRouteAction,
    updateGrowthRouteSuccessAction,
    deleteGrowthRouteAction,
    deleteGrowthRouteSuccessAction,
    refreshGrowthRouteAction,
    refreshGrowthRouteSuccessAction, createGrowthFailureAction
} from './growth.actions';
import { GrowthService } from './growth.service';

@Injectable()
export class GrowthEffects {


    get$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(getGrowthRouteAction),
                fetch({
                    run: () => this.growthService.get()
                        .pipe(
                            map((routes) => getGrowthRouteSuccessAction({ routes }))
                        ),
                    onError: (action, response) =>
                        of(httpErrorAction({ action, response })),
                })
            )
    );

    create$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(createGrowthRouteAction),
                fetch({
                    run: ({ route, routesSet, dispatchId }) => {

                        if(routesSet?.length) {
                            return this.growthService.create(...routesSet || [])
                                .pipe(
                                    map((routes) => createGrowthRouteSuccessAction({ routes, dispatchId }))
                                );
                        } else if(route) {

                            return this.growthService.createViaNetwork(route)
                                .pipe(
                                    map((routes) => createGrowthRouteSuccessAction({ routes, dispatchId }))
                                );
                        }

                        return of();
                    },
                    onError: (action, error: HttpErrorResponse) => {
                        this.toastService.simpleToast(
                            ToastType.ERROR,
                            `${error.message}`,
                            5000
                        );

                        return of(createGrowthFailureAction());
                    },
                })
            )
    );

    update$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(updateGrowthRouteAction),
                fetch({
                    run: ({ route }) => this.growthService.update(route)
                        .pipe(
                            map((routes) => updateGrowthRouteSuccessAction({ routes }))
                        ),
                    onError: (action, response) =>
                        of(httpErrorAction({ action, response })),
                })
            )
    );

    delete$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(deleteGrowthRouteAction),
                fetch({
                    run: ({ routeId }) => this.growthService.delete(routeId)
                        .pipe(
                            map((routes) => deleteGrowthRouteSuccessAction({ routes }))
                        ),
                    onError: (action, response) =>
                        of(httpErrorAction({ action, response })),
                })
            )
    );

    refresh$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(refreshGrowthRouteAction),
                fetch({
                    run: () => this.growthService.refresh()
                        .pipe(
                            map((routes) => refreshGrowthRouteSuccessAction({ routes }))
                        ),
                    onError: (action, response) =>
                        of(httpErrorAction({ action, response })),
                })
            )
    );

    constructor(
        private actions$: Actions,
        private growthService: GrowthService,
        private toastService: ToastService
    ) {}

}
