import { AppletCloud } from "@rdc-apps/rdc-apex/src/lib/shared/data-access/models";
import { RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

const bothClouds = [ AppletCloud.RISK, AppletCloud.GROWTH ];
export const rsiRouteMetrics: Partial<RepoItem<string>>[]  = [
    { label: 'Route Strength Indicator (RSI)', code: 'Rsi', clouds: [ AppletCloud.GROWTH ] },
    { label: 'Route Strength Indicator (RSI)', code: 'Rsi', clouds: [ AppletCloud.RISK ] },
    { label: 'Capacity', code: 'Capacity', clouds: bothClouds },
    { label: 'Departures', code: 'Departures', clouds: bothClouds },
    { label: 'Profit', code: 'Profit', clouds: bothClouds },
    { label: 'Profit margin', code: 'ProfitMargin', clouds: bothClouds },
    { label: 'Average fare (Cabin)', code: 'AvgFare', clouds: bothClouds },
]
