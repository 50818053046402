import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AutocompleteOption } from "./autocomplete.models";
import {
    AUTOCOMPLETE_FEATURE_KEY,
    AutocompleteState,
    autocompleteAdapter
} from './autocomplete.reducer';

// Lookup the 'Autocomplete' feature state managed by NgRx
export const getAutocompleteState = createFeatureSelector<AutocompleteState>(
    AUTOCOMPLETE_FEATURE_KEY
);

const { selectAll } = autocompleteAdapter.getSelectors();

export const getAutocompleteLoaded = createSelector(
    getAutocompleteState,
    (state: AutocompleteState) => state.loaded
);

export const getAutocompleteError = createSelector(
    getAutocompleteState,
    (state: AutocompleteState) => state.error
);

export const getAllAutocomplete = createSelector(
    getAutocompleteState,
    (state: AutocompleteState) => selectAll(state)
);

export const getAutocompleteByDataType = createSelector(
    getAutocompleteState,
    (state: AutocompleteState): { label: string; options: AutocompleteOption[] }[] => {
        const entities = selectAll(state);

        const entries = [ ...new Set(entities.map(({ dataType }) => dataType)) ];

        return entries.map((category) => ({
            label: category || 'Unknown',
            options: entities.filter(({ dataType }) => dataType === category),
        }));
    }
);
