import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CompactSavedQueriesMenuModule } from '@rdc-apps/rdc-apex/src/lib/shared/ui/compact-saved-queries-menu';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { DialogItemModule } from "@rdc-apps/rdc-shared/src/lib/directives/dialog-item";
import { MenuButtonDirectiveModule } from '@rdc-apps/rdc-shared/src/lib/directives/menu-button';
import { RdcCarrotModule } from '@rdc-apps/rdc-shared/src/lib/directives/rdc-carrot';
import { RdcMenuItemModule } from '@rdc-apps/rdc-shared/src/lib/directives/rdc-menu-item';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { MenuButtonComponent } from "@rdc-apps/rdc-shared/src/lib/ui/menu-button";
import { ValidatedControlModule } from '@rdc-apps/rdc-shared/src/lib/ui/validated-control';

import { QbTopBarComponent } from './qb-top-bar.component';

@NgModule({
    imports: [
        CommonModule,
        ButtonModule,
        IconModule,
        CdkMenuModule,
        RdcMenuItemModule,
        ValidatedControlModule,
        ReactiveFormsModule,
        RouterLink,
        CompactSavedQueriesMenuModule,
        RdcCarrotModule,
        MenuButtonDirectiveModule,
        MenuButtonComponent,
        DialogItemModule,
    ],
    declarations: [
        QbTopBarComponent,
    ],
    exports: [
        QbTopBarComponent,
    ],
})
export class QbTopBarModule {}
