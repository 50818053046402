<div class="rdc-lined-title"
     [ngClass]="{ error: additionalFiltersInvalid }"
     (click)="displayAdditionalFilters = !displayAdditionalFilters">

    <div class="rdc-lined-title-error">
        <rdc-apps-icon size="sm" *ngIf="additionalFiltersInvalid else expandCollapse">error</rdc-apps-icon>
        <ng-template #expandCollapse>
            <rdc-apps-icon size="sm">{{ displayAdditionalFilters ? 'expand_more' : 'chevron_right' }}</rdc-apps-icon>
        </ng-template>
        <span>Additional filters</span>
    </div>

</div>


<section class="rdc-query-builder-additional-filters"
         [ngClass]="{ hidden: !displayAdditionalFilters }">

    <label>
        <span>
            <span>Fare class</span>
            <rdc-apps-icon
                *ngIf="filtersFormGroup('cabinTypes','types').disabled"
                size="xxs"
                rdcAppsTooltip
                tooltipPosition="right"
                [tooltipOptions]="{ maxWidth: '250px' }"
                [tooltipText]="cabinToolTip">
                info
            </rdc-apps-icon>
        </span>

        <div class="icon-input">
            <button
                class="select-trigger"
                #faresMenuTrigger
                rdcAppsButton
                [cdkMenuTriggerFor]="faresMenu"
                [ngClass]="{ 'ng-invalid': this.filtersFormGroup('cabinTypes', 'types').invalid }"
                [disabled]="this.filtersFormGroup('cabinTypes', 'types').disabled">
                {{ cabinLabel || 'None' | titlecase }}
            </button>
            <rdc-apps-icon>expand_more</rdc-apps-icon>
        </div>
    </label>

    <label>
        <span>
            <span>Emissions scheme</span>
            <rdc-apps-icon
                *ngIf="hideEmissionsScheme || filtersFormGroup('emissionScheme','types').disabled"
                size="xxs"
                rdcAppsTooltip
                tooltipPosition="right"
                [tooltipOptions]="{ maxWidth: '250px' }"
                [tooltipText]="hideEmissionsScheme ? emissionsToolTipSeries : emissionsToolTip">
                info
            </rdc-apps-icon>
        </span>
        <div class="icon-input">
            <button
                class="select-trigger"
                #emissionsMenuTrigger
                rdcAppsButton
                [cdkMenuTriggerFor]="emissionsMenu"
                [ngClass]="{ 'ng-invalid': this.filtersFormGroup('emissionScheme', 'types').invalid }"
                [disabled]="hideEmissionsScheme || this.filtersFormGroup('emissionScheme', 'types').disabled">
                {{ hideEmissionsScheme ? 'Inherited from series' : emissionLabel || 'None' }}
            </button>
        </div>
    </label>

    <ng-container [formGroup]="filtersFormGroup('schedulesFilters')">

        <label class="rdc-query-builder-additional-filters-checkbox rdc-checkbox" [ngClass]="{ 'input-disabled': this.filtersFormGroup('schedulesFilters').disabled }">
            <input type="checkbox" formControlName="nonStopServices">
            <span>Non-stop only</span>
        </label>

        <label class="rdc-query-builder-additional-filters-checkbox rdc-checkbox" [ngClass]="{ 'input-disabled': this.filtersFormGroup('schedulesFilters').disabled }">
            <input type="checkbox" formControlName="franchisePartners">
            <span>Include franchise carrier operations</span>
        </label>

        <div class="rdc-query-builder-additional-filters-checkbox-disabled" *ngIf="filtersFormGroup('schedulesFilters').disabled">
            <rdc-apps-icon
                size="xxs"
                rdcAppsTooltip
                tooltipPosition="right"
                [tooltipOptions]="{ maxWidth: '250px' }"
                [tooltipText]="schedulesToolTip">
                info
            </rdc-apps-icon>
        </div>

    </ng-container>

</section>


<ng-template #faresMenu>
    <div cdkMenu
         class="rdc-select-menu"
         [style.width.px]="faresMenuTrigger.clientWidth">

        <ng-container *ngFor="let type of cabinTypes | displayOrder">
            <label class="rdc-checkbox">
                <input type="checkbox"
                       [checked]="isSelected('cabinTypes', type)"
                       (change)="toggleInArray('cabinTypes', type)">
                <span>{{ type.label }}</span>
            </label>
        </ng-container>

    </div>
</ng-template>

<ng-template #emissionsMenu>
    <div cdkMenu
         class="rdc-select-menu"
         [style.width.px]="emissionsMenuTrigger.clientWidth">

        <ng-container *ngFor="let type of emissionsSchemes | displayOrder">
            <label class="rdc-checkbox">
                <input type="checkbox"
                       [checked]="isSelected('emissionScheme', type)"
                       (change)="toggleInArray('emissionScheme', type)">
                <span>{{ type.label }}</span>
            </label>
        </ng-container>

    </div>
</ng-template>
