import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    getDefaultPreferencesUnitsMeasurements,
    UserPreferences
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/default-preferences';


@Injectable({ providedIn: 'root' })
export class QbUserDefaultsService {

    userUnitPreferences: UserPreferences = {};

    constructor(private store: Store) {

        this.store
            .select(getDefaultPreferencesUnitsMeasurements('defaultPreferencesState'))
            .subscribe((preferences) => {
                this.userUnitPreferences = preferences || this.userUnitPreferences;
            });
    }

    getPreferenceValue(code: string): string | undefined {
        return (this.userUnitPreferences as Record<string, string | undefined>)[code];
    }

    getDisplayCodeBasedOnPreferences(): string {

        const iataDefault = this.getPreferenceValue('iataCodes');
        const displayNamesDefault = this.getPreferenceValue('shouldDisplayNames');

        switch (true) {
            case !!iataDefault && !displayNamesDefault: {
                return 'codeOnly';
            }
            case !iataDefault && !!displayNamesDefault: {
                return 'nameOnly';
            }
            default: {
                return 'both';
            }
        }

    }
}
