import { Subject, takeUntil } from 'rxjs';

import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    AfterContentInit, AfterViewInit, ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    Input, OnDestroy,
    QueryList,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { easing } from '@rdc-apps/rdc-shared/src/lib/constants';
import { AccordionItemComponent } from '@rdc-apps/rdc-shared/src/lib/ui/accordion-item';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';

@Component({
    selector: 'rdc-apps-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: [ './accordion.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    animations: [
        trigger('expandCollapse', [
            state('open', style({
                height: '{{innerHeight}}',
                opacity: 1,
            }), { params: { innerHeight: '0px' } }),
            state('closed', style({
                height: '0px',
                opacity: 0,
            })),
            transition('open <=> closed', [
                animate(`250ms ${ easing.standard }`),
            ]),
        ]),
    ],
})
export class AccordionComponent extends RdcComponentUtils implements AfterContentInit, AfterViewInit, OnDestroy {

    @ContentChildren(AccordionItemComponent) accordionItems: QueryList<AccordionItemComponent> = new QueryList<AccordionItemComponent>();

    @ViewChildren('accItemContent') content: QueryList<ElementRef<HTMLDivElement>> = new QueryList<ElementRef<HTMLDivElement>>();

    @ViewChildren('contentContainers') contentContainers: QueryList<ElementRef<HTMLDivElement>> = new QueryList<ElementRef<HTMLDivElement>>();

    @Input() allowMultipleOpen = false;

    @Input() canCloseAll = true;

    @Input() openIcon = 'add';

    @Input() pointSize: 'sm' | 'md' = 'md';

    @Input() closeIcon = 'remove';

    @Input() borderless = false;

    accItemChangeNotifier: Subject<void> = new Subject<void>();

    observers: ResizeObserver[] = [];

    constructor(private cdr: ChangeDetectorRef) {
        super();
    }

    accItemHeights: string[] = [];

    override ngOnDestroy() {
        this.accItemChangeNotifier.next();
        this.accItemChangeNotifier.complete();

        this.observers.forEach((obs) => obs.disconnect());

        super.ngOnDestroy();
    }

    ngAfterContentInit(): void {
        this.accordionItems.changes
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(() => {
                this.accItemChangeNotifier.next();

                this.accordionItems.forEach((accItem, index) => {
                    accItem.toggled
                        .pipe(
                            takeUntil(this.componentDestroyed$),
                            takeUntil(this.accItemChangeNotifier)
                        )
                        .subscribe((open) => {
                            this.onToggle(index, open);
                        });
                });
            });
    }

    private observeSizeChanges(contentContainers: QueryList<ElementRef<HTMLDivElement>>): void {

        this.observers.forEach((obs) => obs.disconnect());
        this.observers = [];
        this.accItemHeights = [];

        contentContainers.forEach((container: ElementRef<HTMLDivElement>, index: number) => {

            this.accItemHeights[index] = `${ container.nativeElement.scrollHeight }px`;
            this.cdr.detectChanges();

            const observer = new ResizeObserver(() => {
                this.accItemHeights[index] = `${ container.nativeElement.scrollHeight }px`;

                this.cdr.detectChanges();
            });

            observer.observe(container.nativeElement);

            this.observers.push(observer);
        });
    }

    ngAfterViewInit(): void {
        // observe on first load
        this.observeSizeChanges(this.contentContainers);

        // then re-observe, if the acc
        this.contentContainers.changes
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((containers) => this.observeSizeChanges(containers));
    }

    cantClose(index: number): boolean {
        const oneOpen = this.accordionItems.filter(({ open }, i) => open && i === index).length === 1;

        return oneOpen && !this.canCloseAll;
    }

    onToggle(index: number, open?: boolean): void {

        const isOpen = this.accordionItems.get(index)?.open;

        if (isOpen && !this.canCloseAll && this.accordionItems.filter((acc) => acc.open).length === 1) {
            return;
        }

        const item = this.accordionItems.get(index);

        if (item && this.allowMultipleOpen) {
            if (typeof open === 'boolean') {
                item.open = open;
            } else {
                item.open = !item.open;
            }

            return;
        }

        this.accordionItems.forEach((accItem, i) => {
            if (typeof open === 'boolean') {
                accItem.open = (i === index) ? open : false;
            } else {
                accItem.open = (i === index) ? !accItem.open : false;
            }
        });
    }
}
