<div class="run-saved-menu">
    <div [hidden]="!filterable || !queryList.length" class="run-saved-menu-filters-cont">
        <div class="run-saved-menu-filters">
            <form class="run-saved-menu-filters-search">
                <label class="text-hidden icon-input">
                    <span>Filter</span>
                    <input ngModel type="search" name="filter" autocomplete="off" placeholder="Filter saved" #filter>
                    <rdc-apps-icon *ngIf="!filter.value">search</rdc-apps-icon>
                </label>
                <rdc-apps-icon *ngIf="filter.value" class="cancel" (click)="onClearInput()">cancel</rdc-apps-icon>
            </form>

            <label *ngIf="tagsFromQueries?.length" class="text-hidden run-saved-menu-filters-tag">
                <span>Type</span>
                <rdc-apps-tag-filter
                    [tags]="tagsFromQueries || []"
                    [filterGroup]="filtersForm"
                ></rdc-apps-tag-filter>
            </label>
        </div>
    </div>

    <h5>Saved queries</h5>

    <div class="run-saved-menu-queries"
         [ngClass]="{ 'height-unset': !limitHeight }"
         *ngIf="(queryList | tagsFilter : filterTags) | rdcFilter : filter.value : 'studyName' as filteredQueries">
            <ng-container *ngIf="filteredQueries.length else none">

                <ng-container *ngFor="let query of filteredQueries">

                    <div class="compact-saved-query-outer">

                        <a *ngIf="((selectedId$ | async) !== query.studyId || showActive)"
                           class="compact-saved-query"
                           rdcAppsMenuItem
                           rdcAppsDialogItem
                           [ngClass]="{
                                active: (selectedId$ | async) === query.studyId && router.isActive('query-builder/table', false),
                                notPinned: !query.pinned
                            }"
                           (click)="onRunQuery(query)"
                        >
                            <rdc-apps-icon
                                *ngIf="query.pinned"
                                size="xs"
                                [class.pinned]="query.pinned"
                                [fill]="query.pinned"
                            >
                                star
                            </rdc-apps-icon>
                            <div class="compact-saved-query-info">
                                <span [attr.title]="query.studyName" class="compact-saved-query-info-title">{{ query.studyName }}</span>
                            </div>
                            <div *ngIf="query.tags?.length" class="compact-saved-query-info-tags">
                                <ng-container *ngFor="let tag of query.tags; let i = index">
                                    <div *ngIf="(i + 1) <= 3"
                                         class="tag"
                                         [attr.title]="tag.label"
                                         [style.background-color]="tag.hexaDecimalColour"
                                    ></div>
                                </ng-container>
                                <span *ngIf="query.tags.length > 3"
                                      class="additional-count"
                                      rdcAppsTooltip
                                      tooltipPosition="right"
                                      [tooltipTemplate]="remainingTags"
                                      [tooltipTemplateContext]="{ query }"
                                >+ {{ query.tags.length - 3 }}</span>
                            </div>
                            <rdc-apps-icon class="chevron" size="xl">navigate_next</rdc-apps-icon>
                        </a>

                    </div>

                </ng-container>

            </ng-container>
        </div>


</div>

<ng-template #none>
    <div class="no-results-container">
        <h4>{{ queryList.length ? 'No matches found' : 'Nothing saved yet' }}</h4>
        <p *ngIf="!queryList.length">
            Queries you choose to save will appear here.
        </p>
        <button
            *ngIf="queryList.length"
            class="reset-button"
            rdcAppsButton
            display="compact"
            version="pill"
            (click)="onClear()"
        >
            Clear filters
        </button>
    </div>
</ng-template>

<ng-template #remainingTags let-qry="query">
    <div class="text-tooltip additional-tags-list">
        <div *ngFor="let tag of qry.tags.slice(3)">
            <div class="tag"
                 [attr.title]="tag.label"
                 [style.background-color]="tag.hexaDecimalColour"
            ></div>
            <span>{{ tag.label }}</span>
        </div>
    </div>
</ng-template>
