<rdc-apps-header header="Defaults & preferences" title="{{ editMode ? 'Edit group' : ' Add group' }}"></rdc-apps-header>

<ng-container [formGroup]="form">
    <ng-container [formGroupName]="givenFormGroupName">
        <div class="default-preferences-group" [formArrayName]="index">

            <rdc-apps-validated-control
                controlName="name"
                [control]="preferenceFormGroup.get('name')"
                [validationMessages]="validationMessages"
                [showValidation]="showValidation"
            >
                <div class="form-group">
                    <label class="form-group-label">Group name</label>
                    <input type="text" formControlName="name">
                </div>
            </rdc-apps-validated-control>

                <ng-container formArrayName="selections">
                    <rdc-apps-validated-control
                        controlName="selections"
                        [control]="preferenceFormGroup.get('selections')"
                        [validationMessages]="validationMessages"
                        [showToolTip]="false"
                        [showValidation]="showValidation"
                    >
                        <div class="form-group">
                            <label class="form-group-label">Select {{ groupType === GROUP_TYPE.Airport ? 'airports' : 'airlines' }}</label>

                            <rdc-apps-autocomplete
                                placeholder="Search {{ groupType === GROUP_TYPE.Airport ? 'airports' : 'airlines' }}"
                                [options]="autocompleteResults"
                                [showValidation]="false"
                                (autocomplete)="onAutoComplete($event)"
                                (valueSelected)="onSelect($event)">
                            </rdc-apps-autocomplete>

                        </div>
                    </rdc-apps-validated-control>
                </ng-container>

                <div class="default-preferences-group-wrap">
                    <ng-container *ngFor="let control of groupsArray.controls; let index = index;">
                        <div class="default-preferences-group-item">
                            <span [attr.title]="control.value.label">{{ control.value.label }}</span>
                            <button rdcAppsButton rdcAppsIconButton (click)="onDeleteGroupItem(index)">
                                <rdc-apps-icon size="sm">close</rdc-apps-icon>
                            </button>
                        </div>
                    </ng-container>

                    <label><input type="checkbox" formControlName="isDefaultGroup"> Default group</label>

                    <div class="default-preferences-group-actions">
                        <button rdcAppsButton class="cancel-btn" (click)="onCancel()">Cancel & return</button>
                        <button
                            rdcAppsButton
                            colour="primary"
                            (click)="onAddGroup()"
                        >
                            {{ (editMode ? 'Save group' : 'Add group') }}
                        </button>
                    </div>
                </div>
        </div>
    </ng-container>
</ng-container>

