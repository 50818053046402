import { Observable, takeUntil } from 'rxjs';

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    getAllSavedQueries,
    getQueriesSaving,
    QueryEntity,
    saveQuery,
    saveQuerySuccess
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/query';
import { CustomValidators } from '@rdc-apps/rdc-shared/src/lib/custom-validators';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';

@Component({
    selector: 'rdc-apps-qb-save-as',
    templateUrl: './qb-save-as.component.html',
    styleUrls: [ './qb-save-as.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class QbSaveAsComponent extends RdcComponentUtils implements OnInit {

    saveAsForm: FormGroup<{
        name: FormControl<string | null>;
        pinned: FormControl<boolean | null>;
    }>;

    saving$!: Observable<boolean>;

    existingNames: string[] = [];

    constructor(
        @Inject(DIALOG_DATA) private data: { query: QueryEntity },
        private store: Store,
        private actions: Actions,
        private dialogRef?: DialogRef
    ) {
        super(actions);

        const { query } = data;

        this.saveAsForm = new FormGroup({
            name: new FormControl(`${ query.studyName } copy`, Validators.required),
            pinned: new FormControl(query.pinned),
        });

        this.store.select(getAllSavedQueries())
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((queries) => {
                this.existingNames = queries.map((qry) => qry.studyName);

                this.saveAsForm.get('name')?.setValidators([
                    Validators.required,
                    CustomValidators.notInArray(this.existingNames),
                ]);
            });
    }

    ngOnInit(): void {
        this.saving$ = this.store.select(getQueriesSaving);
    }

    onSubmit() {
        if (this.saveAsForm.invalid) {
            return;
        }

        const existingQuery = this.data.query;
        const saveAsFormValue = this.saveAsForm.value;

        this.store.dispatch(saveQuery({
            query: {
                ...this.data.query,
                studyId: '00000000-0000-0000-0000-000000000000', // as new study
                studyName: saveAsFormValue.name || `${ existingQuery.studyName } clone`, // with new name
                pinned: !!saveAsFormValue.pinned, // with pinned status confirmed by user
            },
        }));

        this.onActions([ saveQuerySuccess ], () => {
            this.dialogRef?.close();
        });
    }
}
