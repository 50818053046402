import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    QueryBuilderTimePeriodOptionsComponent
} from '@rdc-apps/rdc-apex/src/lib/query-builder/ui/query-builder-time-period-options';
import { SelectAutocompleteModule } from "@rdc-apps/rdc-shared/src/lib/ui/select";

@Component({
    standalone: true,
    selector: 'rdc-apps-template-query-time-period',
    templateUrl: './template-query-time-period.component.html',
    styleUrls: [ './template-query-time-period.component.scss' ],
    imports: [ CommonModule, ReactiveFormsModule, SelectAutocompleteModule ],
})
export class TemplateQueryTimePeriodComponent extends QueryBuilderTimePeriodOptionsComponent {

}
