import { Subject } from 'rxjs';

import { Component, HostListener, Input, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'rdc-apps-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: [ './tooltip.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class TooltipComponent {

    @Input() text: string | null = null;

    @Input() template: TemplateRef<HTMLElement> | null = null;

    @Input() context: Record<string, any> | null = null;

    mouseOut: Subject<void> = new Subject<void>();

    hovering = false;

    @HostListener('mouseenter') onHover(): void {
        this.hovering = true;
    }

    @HostListener('mouseleave') onLeave(): void {
        this.hovering = false;
        this.mouseOut.next();
        this.mouseOut.complete();
    }
}
