import { KeyValue } from '@angular/common';
import { Directive, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TooltipsService } from '@rdc-apps/rdc-shared/src/lib/data-access/local-storage';

@Directive()
export abstract class DataPointBaseComponent implements OnInit {

    dataPointDescriptions: Map<string, string> = new Map<string, string>();

    @Input() form: FormGroup = new FormGroup({});
    @Input() groupName = 'Group';
    @Input() data!: { label: string; code: string; description?: string }[];
    @Input() givenFormGroupName = 'dataPoints';
    @Input() controlFormGroup!: KeyValue<string, FormGroup<Record<string, FormControl<boolean>>>>;
    @Input() controls!: KeyValue<string, FormControl<boolean>>[];

    constructor(public tooltips: TooltipsService) {
    }

    ngOnInit(): void {
        this.data?.forEach((dataPoint) => this.dataPointDescriptions.set(dataPoint.code, dataPoint.description || 'No description'));
    }

    getFullName(key: string): string {
        return this.data.find((value: { code: string }) => value.code === key)?.label || 'Unknown';
    }
}
