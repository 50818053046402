<div
    class="data-points-manual-selection"
    [ngClass]="{ 'active': !!this.dialog.getDialogById('data-points-dropdown'), 'ng-invalid' : form.invalid && form.touched && showValidation }"
    #manualSelectionContainer
>
    <div class="data-points-manual-selection-data-points-labels" #manualSelectionDataPointsLabels>
        <button
            *ngFor="let label of dataPointLabels"
            [attr.id]="label"
            (keydown.backspace)="onLabelFocus()"
        >
            <span>{{ label }}</span> <rdc-apps-icon size="xxs" (click)="onClear(label)">clear</rdc-apps-icon>
        </button>
        <input
            id="rdc-datapoints-search-input"
            class="data-points-manual-selection-input"
            type="text"
            autocomplete="off"
            [placeholder]="placeholderText"
            (input)="onInput($event, manualSelectionContainer)"
            (keydown.backspace)="onClearDataPointsLabels()"
            #searchInput
        >
    </div>

    <div class="data-points-manual-selection-actions">
        <rdc-apps-icon (click)="onClearAll()">clear</rdc-apps-icon>
        <rdc-apps-icon (click)="onToggleDropdown(manualSelectionContainer)" size="xl">expand_more</rdc-apps-icon>
    </div>
</div>

<ng-container *ngIf="studyType.value === QUERY_BUILDER_TYPE.Table && !basicBuilder">
    <div class="data-points-manual-selection-checkboxes">
        <ng-container [formGroup]="perXOptionsForm">
            <span>Also include: </span>
            <label
                *ngFor="let control of perXOptionsForm.controls | keyvalue"
                class="rdc-checkbox"
            >
                <input type="checkbox" [attr.id]="control.key" [formControlName]="control.key">
                <span>{{ perXOptionLabel(control.key) }}</span>

            </label>
        </ng-container>
    </div>
</ng-container>

<div class="error" *ngIf="form.invalid && form.touched && showValidation">* Select at least one data point to query by</div>

<ng-template #manualSelectionDropdown>
        <div
            *ngIf="dataPointsData"
            class="data-points-manual-selection-dropdown"
            [formGroup]="form"
        >
            <div id="automation-close" (click)="closeDialog()"></div>

            <ng-container *ngIf="(dataPointsData.datapoints | simpleFilter : searchInput.value : 'code' : 'label').length else noResults">
                <ng-container *ngFor="let group of controlFormGroups; let groupIndex = index">
                    <ng-container *ngIf="dataPointsMapped[groupIndex] && dataPointsMapped[groupIndex].dataPointsData | simpleFilter : searchInput.value : 'code' : 'label' as controls">
                        <div *ngIf="controls.length > 0" class="data-points-manual-selection-dropdown-wrapper">

                            <span class="data-points-manual-selection-dropdown-title">
                                {{ dataPointTitle(group.key) }} <a *ngIf="upgradeDataPoints(groupIndex)" (click)="salesforceLead.emit(dataPointTitle(group.key))">upgrade to unlock</a>
                            </span>

                            <div class="data-points-manual-selection-dropdown-group">
                                <ng-container *ngFor="let control of controls;">
                                    <label class="rdc-checkbox" [ngClass]="{ 'disabled': !control.isEnabled }">

                                        <ng-container *ngIf="!control.isEnabled else dataPointUnlocked">
                                            <ng-container *ngTemplateOutlet="lockIcon; context: { locked: 'data-point-locked' }"></ng-container>
                                            <span
                                                rdcAppsTooltip
                                                [tooltipText]="!control.visibleInBasicQueryBuilder && basicBuilder ? 'Available in Extended builder' : dataPointDescriptions.get(control.code) || 'No description'"
                                                [tooltipDelay]="250"
                                                [tooltipDisabled]="tooltips.disabled"
                                            >
                                                {{ dataPointLabel(control.code) }}
                                            </span>
                                        </ng-container>

                                        <ng-template #dataPointUnlocked>
                                            <ng-container [ngSwitch]="studyType.value">
                                                <ng-container *ngSwitchCase="QUERY_BUILDER_TYPE.Table">
                                                    <div [formGroupName]="group.key">
                                                        <input type="checkbox" [formControlName]="control.code">
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    <input
                                                        type="radio"
                                                        [attr.id]="control.code"
                                                        [checked]="isChecked(form.get('dataPointCodes')?.value, control.code)"
                                                        [value]="control.code"
                                                        (click)="onChange(group.key, control.code, true)"
                                                    >
                                                </ng-container>
                                            </ng-container>
                                            <span
                                                rdcAppsTooltip
                                                [tooltipText]="dataPointDescriptions.get(control.code) || 'No description'"
                                                [tooltipDelay]="250"
                                                [tooltipHideDelay]="0"
                                                [tooltipDisabled]="tooltips.disabled"
                                            >
                                                {{ dataPointLabel(control.code) }}
                                            </span>
                                        </ng-template>
                                    </label>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-template #noResults>
                <p class="data-points-manual-selection-dropdown-no-matches-found">No matches found</p>
            </ng-template>
        </div>
</ng-template>

<ng-template #lockIcon let-locked="locked">
    <img [attr.class]="locked" [attr.src]="LOCKED_ICON_SRC" alt="locked icon">
</ng-template>
