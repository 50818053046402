import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserDetailsEffects } from './+state/user-details.effects';
import * as fromUserDetails from './+state/user-details.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromUserDetails.USER_DETAILS_FEATURE_KEY,
            fromUserDetails.userDetailsReducer
        ),
        EffectsModule.forFeature([ UserDetailsEffects ]),
    ],
})
export class UserDetailsStoreModule {}
