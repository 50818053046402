import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressBarModule } from '@rdc-apps/rdc-shared/src/lib/ui/progress-bar';

import { TempUserSigninOidcCallbackComponent } from './temp-user-signin-oidc-callback.component';

@NgModule({
    imports: [ CommonModule, ProgressBarModule ],
    declarations: [ TempUserSigninOidcCallbackComponent ],
})
export class TempUserSigninOidcCallbackModule {}
