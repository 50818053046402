import { concatMap, delay, from, map, Observable, of, takeUntil } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    executeCloudRouteServedFailureAction,
    executeCloudRouteServedSuccessAction
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/actions';
import { RouteServed, ServedRoute } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService, RoutesServedService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { RdcResponseDto } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

@Injectable({ providedIn: 'root' })
export class CloudRouteServedService extends RdcBaseHttpService {

    servedRoutes: ServedRoute[] = [];
    cloudType!: string;

    constructor(
        private store$: Store,
        private routesServedService: RoutesServedService,
        @Inject(appEnvironment) private environment: AppEnvironment
    ) {
        super();
    }

    queueServedRoute(route: ServedRoute, cloudType: string): void {
        this.servedRoutes.push(route);
        this.cloudType = cloudType;
    }


    executeServedRoute(): void  {
        from(this.servedRoutes)
            .pipe(
                concatMap((routeItem) => of(routeItem).pipe(delay(500)))
            )
            .subscribe((route) =>
                this.sendServedRoute(route, this.cloudType)
                    .pipe(
                        takeUntil(this.routesServedService.cancelRouteServed)
                    ).subscribe(
                        undefined,
                        () => this.store$.dispatch(executeCloudRouteServedFailureAction({ served: route }))
                    )
            );
    }

    sendServedRoute(served: ServedRoute, cloudType: string): Observable<RouteServed> {
        this.clear();

        const routeUrl = cloudType === 'growth'
            ? '/GrowthCloud/routes/routeServed'
            : '/RiskCloud/routes/routeServed';

        // eslint-disable-next-line max-len
        return this.http.post<RdcResponseDto<RouteServed>>(`${this.environment.api.baseUrl}${routeUrl}`, served, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => {
                    this.store$.dispatch(executeCloudRouteServedSuccessAction({ routeServed: response.success.data }));

                    return response.success.data;
                })
            );
    }

    clear(): void {
        this.servedRoutes = [];
    }

}
