export interface qbRepoItem<T> {
    id: string;
    code: string;
    label: string;
    data?: T;
}

export type qbTimePeriod = 'relative' | 'specific' | null;
export type qbSpecificPeriod = 'months' | 'quarters' | 'seasons' | null;
export type qbOriginDestination =
    | 'all'
    | 'airport'
    | 'city'
    | 'country'
    | 'continent'
    | null;
export type qbAirline = 'all' | 'airline' | 'alliance' | 'airlineType' | null;
export type qbAircraft = 'all' | 'model' | 'family' | null;
