import { map, Observable } from "rxjs";

import { Inject, Injectable } from "@angular/core";
import { AppEnvironment, appEnvironment } from "@rdc-apps/rdc-apex/src/lib/shared/environment";
import { RdcBaseHttpService } from "@rdc-apps/rdc-apex/src/lib/shared/utilities";
import { RdcResponseDto, RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

@Injectable({ providedIn: "root" })
export class AirportOriginsService extends RdcBaseHttpService {

    constructor(@Inject(appEnvironment) private environment: AppEnvironment) {
        super();
    }

    get(airlineId: RepoItem<string>, timeRestricted = false): Observable<RepoItem<string >[]> {
        return this.http.get<RdcResponseDto<{ results: RepoItem<string>[] }>>(`${this.environment.api.baseUrl}/Autocomplete/${airlineId.id}/${timeRestricted ? 'originsCountries': 'origins'}${timeRestricted ? '/time' : ''}`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.results)
            );
    }
}
