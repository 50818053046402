import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SummaryGroupOrderModule } from '@rdc-apps/rdc-apex/src/lib/shared/pipes/summary-group-order';
import { SelectAutocompleteModule } from "@rdc-apps/rdc-shared/src/lib/ui/select";

import { QbFilterTimeframeComponent } from './qb-filter-timeframe.component';


@NgModule({
    imports: [ CommonModule, ReactiveFormsModule, SummaryGroupOrderModule, SelectAutocompleteModule ],
    declarations: [ QbFilterTimeframeComponent ],
    exports: [ QbFilterTimeframeComponent ],
})
export class QbFilterTimeframeModule {}
