<div class="default-preferences-item" *ngIf="controls" [ngClass]="{ 'delete': deleteMode }">

    <ng-container *ngIf="!deleteMode">
        <div class="default-preferences-item-wrapper">
        <span class="default-preferences-item-name">
            {{ controlName.value }}
            <small *ngIf="controlDefault.value">&nbsp;(default)</small>
        </span>
            <ng-container *ngFor="let control of controlsArray.controls; let index = index">
            <span class="default-preferences-item-content" [attr.title]="control.value.label">
                {{ iataIcaoCodesService.itemSummaryControl(control, controlsArray, index)}}
            </span>
            </ng-container>
        </div>

        <div class="default-preferences-item-actions">
            <button
                display="compact"
                rdcAppsButton
                rdcAppsIconButton
                type="button"
                (click)="editPreference.emit(index)"
            >
                <rdc-apps-icon>edit</rdc-apps-icon>
            </button>
            <button
                display="compact"
                rdcAppsButton
                rdcAppsIconButton
                type="button"
                (click)="deleteMode = true"
            >
                <rdc-apps-icon>delete</rdc-apps-icon>
            </button>
        </div>
    </ng-container>

    <ng-container *ngIf="deleteMode">
        <div class="default-preferences-item-delete">
            <p>Remove group</p>
            <div class="default-preferences-item-actions">
                <button
                    class="cancel no-border"
                    display="compact"
                    rdcAppsButton
                    type="button"
                    (click)="deleteMode = false"
                >
                    Cancel
                </button>
                <button
                    display="compact"
                    rdcAppsButton
                    type="button"
                    (click)="onDeletePreference(controls.value, index)"
                >
                    OK
                </button>
            </div>
        </div>
    </ng-container>


</div>
