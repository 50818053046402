import { of } from 'rxjs';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { Store } from '@ngrx/store';
import { initQueriesViaDataPoints } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points';
import { httpErrorAction } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/error';
import { initQueries } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/query';

@Injectable()
export class QueryViaDataPointEffects {
    initQueriesViaDataPoints = createEffect(() =>
        this.actions$.pipe(
            ofType(initQueriesViaDataPoints),
            fetch({
                run: () => this.store$.dispatch(initQueries()),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    constructor(private actions$: Actions, private store$: Store) {}
}
