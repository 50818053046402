import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { QueryStates } from '@rdc-apps/rdc-apex/src/lib/shared/constants';

@Component({
    selector: 'rdc-apps-qb-play-reset-buttons',
    templateUrl: './qb-play-buttons.component.html',
    styleUrls: [ './qb-play-buttons.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class QbPlayButtonsComponent {

    @Input() showForm = false;

    @Input() noTopBar = false;

    @Input() queryState!: number;

    @Input() invalid!: boolean;

    @Output() formSubmit: EventEmitter<void> = new EventEmitter<void>();

    queryStates = QueryStates;

    onFormSubmit() {
        if(this.invalid || this.queryState === QueryStates.QUERYING) {
            return;
        }

        this.formSubmit.emit();
    }
}
