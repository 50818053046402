import { createFeatureSelector, createSelector } from '@ngrx/store';

import { QUERY_FEATURE_KEY, QueryState, queryAdapter } from './query.reducer';

// Lookup the 'Query' feature state managed by NgRx
export const getQueryState =
    createFeatureSelector<QueryState>(QUERY_FEATURE_KEY);

const { selectAll, selectEntities } = queryAdapter.getSelectors();

export const getAllQueries = createSelector(getQueryState, (state: QueryState) =>
    selectAll(state)
);

export const getAllSavedQueries = (...types: string[]) => createSelector(getAllQueries, (state) => {
    if (types.length) {
        return state.filter((query) => types.includes(query.studyType));
    }

    return state;
});

export const getQueryEntities = createSelector(
    getQueryState,
    (state: QueryState) => selectEntities(state)
);

export const getSavedQueriesLoading = createSelector(
    getQueryState,
    (state: QueryState) => state.loading
);

export const getSavedQueriesLoaded = createSelector(
    getQueryState,
    (state: QueryState) => state.loadedSaved
);

export const getQueriesSaving = createSelector(
    getQueryState,
    (state: QueryState) => state.saving
);

export const getSelectedId = createSelector(
    getQueryState,
    (state: QueryState) => state.id
);

export const getSelectedQuery = createSelector(
    getQueryEntities,
    getSelectedId,
    (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
