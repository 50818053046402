import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as AutocompleteActions from './autocomplete.actions';
import { AutocompleteOption } from './autocomplete.models';

export const AUTOCOMPLETE_FEATURE_KEY = 'autocomplete';

export interface AutocompleteState extends EntityState<AutocompleteOption> {
    loaded: boolean; // has the Autocomplete list been loaded
    error?: string | null; // last known error (if any)
}

export interface AutocompletePartialState {
    readonly [AUTOCOMPLETE_FEATURE_KEY]: AutocompleteState;
}

export const autocompleteAdapter: EntityAdapter<AutocompleteOption> =
    createEntityAdapter<AutocompleteOption>();

export const initialAutocompleteState: AutocompleteState =
    autocompleteAdapter.getInitialState({
        // set initial required properties
        loaded: false,
    });

const reducer = createReducer(
    initialAutocompleteState,
    on(AutocompleteActions.autocomplete, (state, { query }) => {

        if (!query) {
            return autocompleteAdapter.setAll([], { ...state });
        }

        // fetching the results, so set loading
        return {
            ...state,
            loaded: false,
            error: null,
        };

    }),
    on(AutocompleteActions.loadAutocompleteSuccess, (state, { results }) =>
        autocompleteAdapter.setAll(results, { ...state, loaded: true })
    ),
    on(AutocompleteActions.loadAutocompleteFailure, (state, { error }) =>
        autocompleteAdapter.setAll([], { ...state, loaded: true, error })
    )
);

export function autocompleteReducer(
    state: AutocompleteState | undefined,
    action: Action
) {
    return reducer(state, action);
}
