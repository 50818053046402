import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'rdc-apps-no-results',
    templateUrl: './no-results.component.html',
    styleUrls: [ './no-results.component.scss' ],
})
export class NoResultsComponent {

    @Input() text = 'No matches found';

    @Output() resetFilter: EventEmitter<void> = new EventEmitter<void>();
}
