<section class="step-container">
    <div class="body">
        <ng-container *ngIf="selected" [ngTemplateOutlet]="selected.content"></ng-container>
    </div>

    <footer>
        <ol class="step-indicator">
            <ng-container *ngFor="let step of steps; let i = index;">
                <li class="indicator" [ngClass]="(selectedIndex === i) ? activeClass : ''">
                    <ng-container
                        *ngIf="step.stepLabel"
                        [ngTemplateOutlet]="step.stepLabel.template"
                    ></ng-container>
                </li>
            </ng-container>
        </ol>
    </footer>
</section>
