import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/header';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { SelectAutocompleteModule } from "@rdc-apps/rdc-shared/src/lib/ui/select";
import { ValidatedControlModule } from '@rdc-apps/rdc-shared/src/lib/ui/validated-control';

import { DefaultPreferencesGroupComponent } from './default-preferences-group.component';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        ValidatedControlModule,
        ButtonModule,
        HeaderModule,
        ReactiveFormsModule,
        SelectAutocompleteModule,
    ],
    declarations: [ DefaultPreferencesGroupComponent ],
    exports: [
        DefaultPreferencesGroupComponent,
    ],
})
export class DefaultPreferencesGroupModule {}
