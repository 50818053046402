<div class="confirm-dialog">

    <button
        rdcAppsButton
        rdcAppsDialogItem
        rdcAppsIconButton
        [dialogCloseIconBtn]="true"
        display="compact">
        <rdc-apps-icon>close</rdc-apps-icon>
    </button>

    <ng-container *ngIf="data.icon">
        <img class="confirm-icon" src="/assets/rdc-shared/images/{{ data.icon }}.svg" [alt]="data.icon">
    </ng-container>

    <div class="confirm-body">
        <h4>{{ data.title || 'Are you sure?' }}</h4>

        <p [innerHTML]="data.body || 'Confirm to continue'"></p>
    </div>


    <div class="confirm-buttons">
        <button
            class="close-dialog"
            rdcAppsButton
            rdcAppsDialogItem>
            {{ data.cancelText || 'Cancel' }}
        </button>

        <button
            rdcAppsButton
            rdcAppsDialogItem
            colour="primary"
            (click)="confirm.emit()">
            {{ data.confirmText || 'Confirm' }}
        </button>
    </div>

</div>
