import { FormControl } from '@angular/forms';
import { ExportingMimeTypeValue } from 'highcharts';

export interface ExportForm {
    export: FormControl<string>;
    exportType: FormControl<ExportingMimeTypeValue | string>;
    includeExtendedData: FormControl<boolean>;
    includeIATACodes: FormControl<boolean>;
    includeDisplayNames: FormControl<boolean>;
}

export interface ExportFormValue {
    export: string;
    exportType: ExportingMimeTypeValue | string;
    includeExtendedData: boolean;
    includeIATACodes: boolean;
    includeDisplayNames: boolean;
}
