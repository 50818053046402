import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as DataPointsActions from './data-points.actions';
import { DataPointsEntity } from './data-points.models';

export const DATA_POINTS_FEATURE_KEY = 'dataPoints';

export interface DataPointsState extends EntityState<DataPointsEntity> {
    selectedId?: string | number; // which DataPoints record has been selected
    loaded: boolean; // has the DataPoints list been loaded
    error?: string | null; // last known error (if any)
}

export interface DataPointsPartialState {
    readonly [DATA_POINTS_FEATURE_KEY]: DataPointsState;
}

export const dataPointsAdapter: EntityAdapter<DataPointsEntity> = createEntityAdapter<DataPointsEntity>({
    selectId: () => 'dataPointState',
});

export const initialDataPointsState: DataPointsState =
    dataPointsAdapter.getInitialState({
        // set initial required properties
        loaded: false,
    });

const reducer = createReducer(
    initialDataPointsState,
    on(DataPointsActions.initDataPoints, (state) => ({
        ...state,
        loaded: false,
        error: null,
    })),
    on(DataPointsActions.loadDataPointsSuccess, (state, { dataPoints }) =>
        dataPointsAdapter.setOne(dataPoints, { ...state, loaded: true })
    ),
    on(DataPointsActions.loadDataPointsFailure, (state, { error }) => ({
        ...state,
        error,
    }))
);

export function dataPointsReducer(
    state: DataPointsState | undefined,
    action: Action
) {
    return reducer(state, action);
}
