import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from '@rdc-apps/rdc-shared/src/lib/directives/tooltip';

import { MultiSelectCheckboxComponent } from './multi-select-checkbox.component';


@NgModule({
    imports: [ CommonModule, ReactiveFormsModule, TooltipModule ],
    declarations: [ MultiSelectCheckboxComponent ],
    exports: [
        MultiSelectCheckboxComponent,
    ],
})
export class MultiSelectCheckboxModule {}
