import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { overlayPositions } from '@rdc-apps/rdc-shared/src/lib/constants';

@Component({
    selector: 'rdc-apps-rdc-menu-carrot',
    templateUrl: './rdc-menu-carrot.component.html',
    styleUrls: [ './rdc-menu-carrot.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class RdcMenuCarrotComponent implements OnInit {

    @Input() position!: ConnectedPosition[];

    orientationClass = 'below';

    ngOnInit(): void {
        for (const [ key, value ] of overlayPositions.entries()) {

            if (JSON.stringify(this.position) === JSON.stringify(value)) {
                this.orientationClass = key;

                break;
            }

        }
    }
}
