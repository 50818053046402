import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from "@rdc-apps/rdc-shared/src/lib/directives/tooltip";
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';

import { QbPlayButtonsComponent } from './qb-play-buttons.component';

@NgModule({
    imports: [ CommonModule, IconModule, TooltipModule ],
    declarations: [ QbPlayButtonsComponent ],
    exports: [ QbPlayButtonsComponent ],
})
export class QbPlayResetButtonsModule {}
