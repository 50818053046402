import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipUiModule } from '@rdc-apps/rdc-shared/src/lib/ui/tooltip';

import { TooltipDirective } from './tooltip.directive';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        TooltipUiModule,
    ],
    declarations: [
        TooltipDirective,
    ],
    exports: [
        TooltipDirective,
    ],
})
export class TooltipModule {}
