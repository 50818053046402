import { Pipe, PipeTransform } from "@angular/core";
import { RdcDataTableColumn, RdcDataTableColumnSettings } from "table";

@Pipe({ name: 'ApplyColumnSettings', standalone: true })
export class ApplyColumnSettingsPipe implements PipeTransform {

    transform(columns: RdcDataTableColumn[] | undefined, settingsForColumns: Map<string, RdcDataTableColumnSettings>): RdcDataTableColumn[] {

        if(!settingsForColumns) {
            return columns || [];
        }

        return (columns || []).map((col) => {

            const settings = settingsForColumns.get(col.code) || {};

            return {
                ...col,
                ...settings,
            };
        });
    }

}
