export const hideAircraftGroupCodes = [
    "pricing",
    "revenueProfitPax",
    "costs",
    "taxesCharges"
];


export const checkboxCodes: string[] = [
    'governmentTaxes',
    'airportCharges',
    'iataCodes',
    'shouldDisplayNames',
    'includeOnlyCompleteData',
    'includeOnlyWhereResultsMatch',
];

export const filterDropDownCodes: string[] = [];
