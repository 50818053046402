export const ACTIVITY_PRODUCT_CODE = 'rdc.q.apex';

export const LOGIN = `${ACTIVITY_PRODUCT_CODE}.user.login`;
export const LOGOUT = `${ACTIVITY_PRODUCT_CODE}.user.logout`;
export const ENTER_FROM_APEX_CLASSIC = `${ACTIVITY_PRODUCT_CODE}.user.enter-from-classic`;
export const BACK_TO_APEX_CLASSIC = `${ACTIVITY_PRODUCT_CODE}.user.back-to-classic`;
export const RUN_TABLE_QUERY = `${ACTIVITY_PRODUCT_CODE}.query.table.run`;
export const NEW_TABLE_QUERY = `${ACTIVITY_PRODUCT_CODE}.query.table.new`;
export const NEW_CHART_QUERY = `${ACTIVITY_PRODUCT_CODE}.query.chart.new`;
export const NEW_SCATTER_QUERY = `${ACTIVITY_PRODUCT_CODE}.query.scatter.new`;
export const EXPORT_TABLE_QUERY = `${ACTIVITY_PRODUCT_CODE}.query.table.export`;
