<ng-scrollbar track="vertical" visibility="hover">
    <div
        class="tag-management"
        [formGroup]="form"
        #tagManagementContainer
    >
        <rdc-apps-header
            header="Tags"
            title="Tag management"
            [noMargin]="true"
        ></rdc-apps-header>

        <p>Tags are a great way to categorise your studies to help you find them later.</p>

        <rdc-apps-panel *ngIf="showMaxTagsLimit" [warning]="true">
            Maximum tags reached. Delete tags first or create new ones
        </rdc-apps-panel>

        <div class="form-group">
            <label class="tag-management-label">Tags (12 max)</label>
            <div class="tag-management-wrapper" formArrayName="tags">
                <ng-container *ngFor="let controls of tagsFormArray.controls; let index = index">
                    <rdc-apps-tag-management-item
                        [controls]="controls"
                        [index]="index"
                        [tags]="tagsArray"
                        [defaultColours]="defaultColours"
                        (deleteTag)="onDeleteTag($event, index)"
                        (tagLabel)="onTagLabelValue($event, index)"
                        (tagColour)="onTagColour($event, index)"
                    ></rdc-apps-tag-management-item>
                </ng-container>

                <button
                    rdcAppsButton
                    (click)="onAddManagementTag()"
                >
                    Add a tag
                </button>
            </div>
        </div>
    </div>
</ng-scrollbar>
