import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import * as appLoading from './app-loading.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            appLoading.APP_LOADING_FEATURE_KEY,
            appLoading.appLoadingReducer
        ),
    ],
})
export class AppLoadingStoreModule {}
