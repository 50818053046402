import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MenuButtonDirective } from './menu-button.directive';

@NgModule({
    declarations: [
        MenuButtonDirective,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        MenuButtonDirective,
    ],
})
export class MenuButtonDirectiveModule {}
