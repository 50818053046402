import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconComponent } from './icon.component';

@NgModule({
    imports: [ CommonModule ],
    declarations: [ IconComponent ],
    exports: [ IconComponent, IconComponent ],
})
export class IconModule {}
