<header class="rdc-apps-header">
    <h4>RDC Apps</h4>

    <button
        rdcAppsButton
        rdcAppsIconButton
        rdcAppsDialogItem
        display="compact"
        rdcAppsTooltip
        tooltipPosition="right"
        [tooltipText]="'Close'"
        [dialogCloseIconBtn]="true"
    >
        <rdc-apps-icon size="sm">close</rdc-apps-icon>
    </button>
</header>

<article class="rdc-app-item current">

    <div class="app-img apex">
        <img src="/assets/rdc-shared/images/apex-logo.svg" alt="apex logo">
    </div>

    <h5 class="app-title">Apex</h5>

    <p class="app-body">Intelligence for understanding airline performance</p>

</article>

<h5 class="app-switch-sub-header">Other apps</h5>

<article class="rdc-app-item">

    <div class="app-img apc">
        <img src="/assets/rdc-shared/images/apc-logo.svg" alt="apex logo">
    </div>

    <h5 class="app-title">AirportCharges</h5>

    <p class="app-body">The world's largest and most accurate source of airport charges, with 3,000+ landing fees, passenger charges, taxes and more <a target="_blank" href="https://airportcharges.com">airportcharges.com</a></p>

    <div class="app-login">
        <a target="_blank" [href]="env.rdcApps.apc">Login</a>
    </div>

</article>

<article class="rdc-app-item">

    <div class="app-img loop">
        <img src="/assets/rdc-shared/images/loop-logo.svg" alt="loop logo">
    </div>

    <h5 class="app-title">LOOP</h5>

    <p class="app-body">Centralised distribution for billing-ready fleet data made easy <a target="_blank" href="https://rdcaviation.com/products/loop">loopdata.app</a></p>

    <div class="app-login">
        <a target="_blank" [href]="env.rdcApps.loop">Login</a>
    </div>

</article>
