import { Observable } from "rxjs";

import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from "@ngrx/store";
import { QbFiltersDateRangeUtils } from '@rdc-apps/rdc-apex/src/lib/query-builder/utilities';
import { CompleteQuery } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/query';
import { runApexTrialMode } from "@rdc-apps/rdc-apex/src/lib/shared/data-access/store/user-details";
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

@Component({
    selector: 'rdc-apps-qb-filter-fixed-timeframe',
    templateUrl: './qb-filter-fixed-timeframe.component.html',
    styleUrls: [ './qb-filter-fixed-timeframe.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class QbFilterFixedTimeframeComponent implements OnInit {

    @Input() appendToFormGroup: FormGroup = new FormGroup({});

    @Input() dateRangeUtils!: QbFiltersDateRangeUtils;

    fixedYearOptions: Partial<RepoItem<number>>[] = [];
    monthsAsOptions: Partial<RepoItem<string>>[] = [];

    chartPropertiesFormGroup!: FormGroup;

    apexTrialMode$!: Observable<boolean>;

    constructor(
        private cdr: ChangeDetectorRef,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.apexTrialMode$ = this.store.select(runApexTrialMode('userDetailsState'));

        this.monthsAsOptions = this.dateRangeUtils.calendarMonthsRepoItems();

        this.appendToFormGroup.valueChanges
            .pipe()
            .subscribe((formValue: CompleteQuery) => {

                this.chartPropertiesFormGroup = this.appendToFormGroup.get('chartProperties') as FormGroup;

                if (!this.chartPropertiesFormGroup) {
                    return; // do nothing if it's table mode
                }

                const endMonthIsNextYear = this.dateRangeUtils.endMonthInNextYr(
                    formValue.filters.timePeriod?.specific?.startMonth,
                    formValue.filters.timePeriod?.specific?.endMonth
                );

                if (endMonthIsNextYear) {
                    this.fixedYearOptions = this.dateRangeUtils
                        .selectableYearsAsRepoItems()
                        .map((yr) => this.asMultiYearOption(yr));
                } else {
                    this.fixedYearOptions = this.dateRangeUtils.selectableYearsAsRepoItems();
                }

                this.cdr.detectChanges();
            });

    }

    private asMultiYearOption(opt: Partial<RepoItem<number>>): Partial<RepoItem<number>> {
        const year = opt.code || 0;

        return {
            label: `${ year }/${ String(year + 1).substring(2) } `,
            code: opt.code,
        };
    }
}
