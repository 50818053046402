import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';

import { AccordionComponent } from './accordion.component';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        ObserversModule,
    ],
    declarations: [
        AccordionComponent,
    ],
    exports: [
        AccordionComponent,
    ],
})
export class AccordionModule {}
