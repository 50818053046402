import { Observable, takeUntil } from 'rxjs';

import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { HeaderModule } from "@rdc-apps/rdc-apex/src/lib/settings/ui/header";
import { PresetColor } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import {
    getPresetColours, updatePresetColour
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/default-preferences';
import { ButtonModule } from "@rdc-apps/rdc-shared/src/lib/directives/button";
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';
import { ColorPickerModule, ColorPickerService } from "ngx-color-picker";

@Component({
    standalone: true,
    selector: 'rdc-apps-colours',
    templateUrl: './colours.component.html',
    styleUrls: [ './colours.component.scss' ],
    imports: [
        CommonModule,
        HeaderModule,
        ColorPickerModule,
        ReactiveFormsModule,
        ButtonModule
    ],
    providers: [ ColorPickerService ]
})
export class ColoursComponent extends RdcComponentUtils implements OnInit {

    @Input() form!: FormGroup;

    presetColours$!: Observable<PresetColor[] | undefined | null>;

    rdcDefaultColours: PresetColor[] = [
        { order: 0, colour: '#7BE0AA' },
        { order: 1, colour: '#9898E6' },
        { order: 2, colour: '#83D7D9' },
        { order: 3, colour: '#2F859E' },
        { order: 4, colour: '#A8D2FF' },
        { order: 5, colour: '#3A83D1' },
        { order: 6, colour: '#CF9FC2' },
        { order: 7, colour: '#074F71' },
    ];

    cpColours: string[] = [
        '#7BE0AA',
        '#9898E6',
        '#83D7D9',
        '#2F859E',
        '#A8D2FF',
        '#3A83D1',
        '#CF9FC2',
        '#074F71',
    ];

    colours: PresetColor[] = [];
    showResetButton = false;

    get coloursFormArray(): FormArray {
        return this.form.get('colours') as FormArray;
    }

    constructor(private store$: Store) {
        super();

        this.presetColours$ = this.store$.select(getPresetColours('defaultPreferencesState'));
    }

    ngOnInit(): void {
        this.presetColours$
            .pipe(
                takeUntil(this.componentDestroyed$)
            )
            .subscribe((colours) => {
                this.coloursFormArray.clear();
                this.colours = [];

                if (colours) {
                    colours.forEach((colour): void => {
                        const control = new FormControl<PresetColor>(colour);
                        this.coloursFormArray.push(control);
                        this.colours.push(colour);
                    });

                    this.showResetButton = this.colours.some((colour, index): boolean =>
                        colour.colour !== this.rdcDefaultColours[index].colour
                    );

                    this.colours = JSON.parse(JSON.stringify(this.colours));
                }
            });
    }

    onResetDefaultColours(): void {
        this.store$.dispatch(updatePresetColour({ colours: this.rdcDefaultColours }));

        this.showResetButton = false;

        this.colours.splice(0, this.colours.length, ...this.rdcDefaultColours);

        this.rdcDefaultColours.forEach((colour, index): void => {
            this.patchColourValue(this.coloursFormArray, colour.colour, index);
        });
    }

    patchColourValue(formArray: FormArray, colour: string, index: number): void {
        formArray.at(index).patchValue(colour);
    }

    setColour(index: number, $event: any): void {
        (this.form.get('defaultPreferences.presetColours') as FormArray).at(index).patchValue({ colour: $event });
    }
}
