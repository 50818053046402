import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectAutocompleteModule } from "@rdc-apps/rdc-shared/src/lib/ui/select";

import { QbFilterFixedTimeframeComponent } from './qb-filter-fixed-timeframe.component';

@NgModule({
    imports: [ CommonModule, SelectAutocompleteModule ],
    declarations: [ QbFilterFixedTimeframeComponent ],
    exports: [ QbFilterFixedTimeframeComponent ],
})
export class QbFilterChartFixedTimeframeModule {}
