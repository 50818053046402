import { FormControl } from '@angular/forms';

export interface TagsFormGroup {
    id: FormControl<string>;
    label: FormControl<string>;
    hexaDecimalColour: FormControl<string>;
    checked: FormControl<boolean>;
}

export type TagsManagementFormGroup = Omit<TagsFormGroup, 'checked'>;
