<div class="tags-creator">
    <rdc-apps-icon *ngIf="(tagsLoading$ | async)" size="xs" class="tag-loader">sync</rdc-apps-icon>

    <small>Add tags</small>

    <div class="tags-input">
        <div class="icon-input"
             rdcAppsTooltip
             tooltipText="You have added the maximum number of tags to this query"
             [tooltipDisabled]="!disableAddTag">
            <input
                #autocompleteInput
                autocomplete="off"
                placeholder="Search tags"
                type="search"
                [disabled]="disableAddTag"
                (focusin)="onFocusIn()"
                (focus)="onFocus()"
                (input)="onInput(autocompleteInput.value)"
                (keydown.enter)="$event.preventDefault()"
            >
            <rdc-apps-icon *ngIf="!autocompleteInput.value">search</rdc-apps-icon>
        </div>
        <rdc-apps-icon
            *ngIf="autocompleteInput.value"
            class="cancel"
            (click)="onClearInput()"
        >
            cancel
        </rdc-apps-icon>
    </div>

    <div class="tags-container" #tagsContainer>
            <div
                *ngFor="let tag of listTags; let index = index;"
                class="tag"
                [class.disabled]="(tagsLoading$ | async)"
                [style.background]="tag.hexaDecimalColour"
            >
                <button
                    type="button"
                    class="tag-color-picker"
                    [attr.id]="'tag-' + index + ''"
                    [(colorPicker)]="tag.hexaDecimalColour"
                    [cpDisabled]="tagDisabled"
                    [cpFallbackColor]="tagDefaultColor"
                    [cpPresetColors]="presetColors"
                    [cpEyeDropper]="true"
                    [cpAlphaChannel]="'disabled'"
                    [cpPosition]="colorPickerPosition"
                    [cpPositionOffset]="'60%'"
                    [cpPositionRelativeToArrow]="true"
                    [cpDisableInput]="true"
                    [style.border-color]="setColor(tag.hexaDecimalColour)"
                    (colorPickerClose)="onColorPicker($event, index)"
                    (colorPickerChange)="setColor($event)"
                    [style.color]="setColor(tag.hexaDecimalColour)"
                >{{ tag.label }}</button>

                <button
                    type="button"
                    class="delete-tag"
                    [style.color]="setColor(tag.hexaDecimalColour)"
                    (click)="onRemoveTag(tag)"
                >
                    <rdc-apps-icon size="xxs">close</rdc-apps-icon>
                </button>
            </div>
    </div>

    <button
        *ngIf="listTags.length > 1"
        rdcAppsButton
        version="pill-clear"
        class="clear-tags"
        (click)="onClearTags()"
    >
        Clear all
    </button>
</div>

<ng-template #menuTemplate>
    <div
        *ngIf="autocompleteInput.value"
        cdkMenu
        class="autocomplete"
        [ngStyle]="{ width: autocompleteInput.offsetWidth + 'px' }"
    >
        <div *ngIf="showDuplicateTagError" class="tag-error">Cannot add tag of the same name</div>
        <div *ngIf="showEmptyTagError" class="tag-error">Please add a tag</div>

        <ng-container *ngIf="autocompleteInput.value">
            <ng-container *ngFor="let result of tagsWithAssignedExcluded(autocompleteInput.value)">
                <button
                    rdcAppsMenuItem
                    cdkMenuItem
                    [disabled]="disableAddTag"
                    (cdkMenuItemTriggered)="onSelectTag(result)"
                >
                    <span>{{ result.label }}</span>
                </button>
            </ng-container>
        </ng-container>

        <ng-container>
            <button
                *ngIf="autocompleteInput.value.length && ((tagCount$ | async) || 0) < 12 && !hideAddTagButton"
                class="add-tag"
                [disabled]="disableAddTag"
                (click)="onAddTag(autocompleteInput.value)"
            >
                Add new tag
            </button>

            <div *ngIf="autocompleteInput.value.length && ((tagCount$ | async) || 0) === 12" class="tag-limit">
                <rdc-apps-icon size="xs">info</rdc-apps-icon>
                <span>You cannot create new tags as you have hit the tag limit.</span>
            </div>

        </ng-container>

    </div>
</ng-template>


