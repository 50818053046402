import { TitleCasePipe } from '@angular/common';
import { FormArray, FormGroup } from '@angular/forms';
import {
    scatterGroupByOptions,
    studyBuilderRelativeOpts,
    xAxisOptionsForType,
    xAxisSubOptions,
    xAxisTypeOptions
} from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import { ApexStudy } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { DataPoint } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points';
import { StudyTemplateFilterLimits } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/study-templates';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

export class TemplateHelper {

    static getFiltersFromString(filtersString: string): string[] {
        return filtersString
            .replaceAll(/\[.+?\]/g, '')
            .split('.')
            .slice(1)
            .map((fil) => fil.trim());
    }

    static getValuesForTemplateItems(
        matches: string[],
        chartRequest: FormGroup<ApexStudy>,
        filterMap: Map<string, number>,
        dataPoints: DataPoint[] = [],
        disabledFilters: string[] = [],
        filterLimits: StudyTemplateFilterLimits[],
        onTrial: boolean,
    ): {
        templateValueMap: Map<string, string | undefined>;
        filterOverrides: Map<string, string | undefined>;
        seriesOverrides: Map<string, string | undefined>;
        filterMap: Map<string, number>;
    } {

        let templateValueMap = new Map<string, string | undefined>();
        let filterOverrideMap = new Map<string, string | undefined>();
        let seriesOverrideMap = new Map<string, string | undefined>();

        let filterMapA = new Map(filterMap);

        matches.forEach((code) => {

            const splitString = code.split('|');

            const trimmed = splitString[0].trim();

            const tcp = new TitleCasePipe();

            if (trimmed.includes('datapoint')) {

                templateValueMap.set(trimmed, this.getDataPointLabel(chartRequest, dataPoints));

                return;
            }

            if (trimmed.includes('seriesRange')) {

                const series = chartRequest.get('queryRequest.chartProperties.series') as FormArray;

                if (series?.length > 0) {

                    const first = series.at(0).value.timePeriodSelection?.selected;
                    const last = series.at(series.length - 1).value.timePeriodSelection?.selected;

                    const yr = new Date().getFullYear();

                    templateValueMap.set(trimmed, `${ first || yr } to ${ last || yr }`);
                }

                return;
            }

            if (trimmed.includes('timeperiod')) {

                templateValueMap.set(trimmed, this.getTimePeriodLabel(chartRequest, tcp));

                return;
            }

            if (trimmed.includes('xaxis')) {

                templateValueMap.set(trimmed, this.getAxisLabel(chartRequest, tcp));

                return;
            }

            if (trimmed.includes('comparison')) {

                const computed = this.setSeriesComparisonLabels(code, chartRequest, templateValueMap, seriesOverrideMap);

                templateValueMap = computed.templateValueMap;
                seriesOverrideMap = computed.seriesOverrideMap;

                return;
            }

            if (trimmed.includes('filters')) {

                const computed = this.setFilterValuesAndLabelOverride(trimmed, chartRequest, templateValueMap, filterMap, disabledFilters, filterOverrideMap, filterLimits);

                templateValueMap = computed.templateValues;
                filterOverrideMap = computed.filterOverrides;
                filterMapA = computed.filterCounts;

                return;
            }

            if (trimmed.includes('groupby')) {

                const groupByVal = chartRequest.get('queryRequest.chartProperties.scatter')?.value;

                const label = scatterGroupByOptions.find((gbv) => gbv.code === groupByVal?.groupBy);

                templateValueMap.set(trimmed, label?.label);

                return;
            }

        });

        return { templateValueMap, filterMap: filterMapA, filterOverrides: filterOverrideMap, seriesOverrides: seriesOverrideMap };
    }

    static getInvalidTemplateSections(
        matches: string[],
        templateValueMap: Map<string, string | undefined>,
        chartRequest: FormGroup<ApexStudy>
    ): string[] {

        const matchDisableMap: string[] = [];

        const arr: string[] = [];

        matches.forEach((str) => {

            const split = str.split('|');

            const current = split[0].trim();

            if (current.includes('comparison')) {

                const keysForFil = Array.from(templateValueMap.keys()).filter((key) => key.includes(current));

                arr.push(...keysForFil);

                return;
            }
            if (current.includes('filters')) {

                const filtersToShow = TemplateHelper.getFiltersFromString(current);

                const toRestrict = filtersToShow.filter((fil) => {
                    const type = chartRequest.get(`queryRequest.filters.${fil}.type`)?.value;

                    return type !== 'all';
                });

                if (!toRestrict.length) {
                    arr.push(`${ current }[0]`);
                }

                toRestrict.forEach((ff) => {
                    const keysForFil = Array.from(templateValueMap.keys()).filter((key) => key.includes(ff));

                    if (!keysForFil.length) {
                        arr.push(`${ current }[0]`);
                    } else {
                        arr.push(...keysForFil);
                    }

                });

                return;
            }

            arr.push(current);
        });


        let disableAfterIndex = arr.length;

        arr.forEach((code, index) => {

            const prev = arr[index - 1];

            if (!prev) {
                return;
            }

            if (index >= disableAfterIndex) {
                matchDisableMap.push(code);

                return;
            }
            if (!templateValueMap.get(prev)) {
                disableAfterIndex = index;

                matchDisableMap.push(code);
            }
        });

        return matchDisableMap;
    }

    private static getDataPointLabel(
        chartRequest: FormGroup<ApexStudy>,
        dataPoints: DataPoint[] = []
    ): string | undefined {
        const selectedDataPoint = chartRequest.get('queryRequest.dataPoints.dataPointCodes')?.value || [];

        const dp = dataPoints.find((c) => c.code === selectedDataPoint[0]);

        if (dp) {
            chartRequest.get('queryRequest.dataPoints')?.markAsTouched();
        }

        return dp?.label;
    }


    private static getTimePeriodLabel(chartRequest: FormGroup<ApexStudy>, tcp: TitleCasePipe): string | undefined {

        const timePeriodValues = chartRequest.get('queryRequest.filters.timePeriod')?.value;

        const specificValues = timePeriodValues?.specific;

        switch (true) {
            case !!timePeriodValues?.relative?.relativeMonths : {

                const found = studyBuilderRelativeOpts.find((ab) => ab.code === timePeriodValues?.relative?.relativeMonths);

                return `for the ${found?.label?.toLowerCase() }`;
            }
            case timePeriodValues?.specific?.type === 'months' : {

                const startMonth = tcp.transform(specificValues?.startMonth)?.substring(0,3);
                const endMonth = tcp.transform(specificValues?.endMonth)?.substring(0,3);

                if (specificValues?.startYear === specificValues?.endYear) {

                    return `${ startMonth } and ${ endMonth } ${ specificValues?.startYear }`;
                }

                return `${ startMonth } ${ specificValues?.startYear } and ${ endMonth } ${ specificValues?.endYear }`;
            }
            case timePeriodValues?.specific?.type === 'seasons' :
            case timePeriodValues?.specific?.type === 'quarters' : {

                const key = timePeriodValues?.specific?.type as 'seasons' | 'quarters';

                if (!specificValues?.[key]) {
                    return undefined;
                }

                const valuesForType = specificValues[key] as Record<string, boolean>;

                const keys = Object.keys(valuesForType)
                    .filter((k) => valuesForType[k])
                    .map((k) => tcp.transform(k));

                const joinStr = (key === 'quarters') ? ', ' : ' and ';

                if (specificValues?.startYear === specificValues?.endYear) {

                    return `${ keys.length ? keys.join(joinStr) : key } ${ specificValues?.startYear }`;
                }

                return `${ keys.length ? keys.join(joinStr) : key }${ (key === 'quarters') ? '' : ',' } from ${ specificValues?.startYear } to ${ specificValues?.endYear }`;
            }
            case !!timePeriodValues?.specific?.year : {

                const year = timePeriodValues?.specific?.year ? String(timePeriodValues?.specific?.year) : undefined;

                return year;
            }
            case !!timePeriodValues?.specific?.yearStartsMonth : {

                return timePeriodValues?.specific?.yearStartsMonth;
            }
            default: {
                return undefined;
            }
        }

    }

    private static getAxisLabel(chartRequest: FormGroup<ApexStudy>, tcp: TitleCasePipe): string | undefined {

        const { type, partition } = chartRequest.get('queryRequest.chartProperties.xAxis')?.value || {};

        if (!type) {
            return undefined;
        }

        if (type !== 'timePeriod') {

            const typeLabel = xAxisTypeOptions.find((ab) => ab.code === type)?.label;

            const partitions = xAxisOptionsForType.get(type as never) || [];

            const pLab = partitions.find((ab) => ab.code === partition)?.label || '';

            if (pLab.includes(typeLabel as never)) {
                return `${ pLab }`;
            }

            return `${ typeLabel } ${ pLab.toLowerCase() }`;

        } else if (type === 'timePeriod') {

            const specificValues = chartRequest.get('queryRequest.filters.timePeriod')?.value;

            switch (true) {
                case partition === 'calendarMonth' : {

                    if (specificValues?.relative?.relativeMonths) {

                        const found = studyBuilderRelativeOpts.find((ab) => ab.code === specificValues.relative?.relativeMonths);

                        return `Timeframe (${ found?.label })`;
                    }

                    return 'Month';
                }
                case partition === 'year' : {

                    const placeholder = new Date().getFullYear();

                    return `Years (${ specificValues?.specific?.startYear || placeholder } - ${ specificValues?.specific?.endYear || placeholder })`;
                }
                case partition === 'quarter' : {

                    return 'Quarter';
                }
                default: {
                    return partition ? tcp.transform(partition) : undefined;
                }
            }

        }

        return undefined;
    }

    private static setSeriesComparisonLabels(
        code: string,
        chartRequest: FormGroup<ApexStudy>,
        templateValueMap: Map<string, string | undefined>,
        seriesOverrideMap: Map<string, string | undefined>
    ): { templateValueMap: Map<string, string | undefined>; seriesOverrideMap: Map<string, string | undefined> } {

        const indexes = code.match(/\d/g) || [ '0' ];

        const comparison = Number(indexes[0]);

        const seriesValues = chartRequest.get('queryRequest.chartProperties.series')?.value || [];
        const seriesDefs = chartRequest.get('queryRequest.chartProperties.seriesDefinitions')?.value || [];

        const letters = [ 'A', 'B', 'C', 'D', 'E', 'F' ];

        seriesValues.forEach((series, index) => {

            const selectedTp = series.timePeriodSelection?.selected;

            const timePeriodLabel = xAxisSubOptions.get('relativeYears')?.find((emmScheme) =>
                emmScheme.code === series.timePeriodSelection?.selected
            )?.label || selectedTp;

            const emissionsLabel = xAxisSubOptions.get('emissionsScheme')?.find((emmScheme) =>
                emmScheme.code === series.emissionsSchemeSelection
            )?.label;

            const attributeLabel = (series.attributeSelections || [])[comparison]?.selected.label;

            if ([ 'timePeriod', 'emissionsScheme' ].includes(seriesDefs[comparison]?.type)) {

                templateValueMap.set(`comparison[${ comparison }]series[${ index }]`, timePeriodLabel || emissionsLabel);

                return;
            }

            templateValueMap.set(`comparison[${ comparison }]series[${ index }]`, attributeLabel);

            seriesOverrideMap.set(
                `comparison[${ comparison }]series[${ index }]`,
                `${ new TitleCasePipe().transform(seriesDefs[comparison]?.type) } ${ letters[index] }`
            );
        });

        return { templateValueMap, seriesOverrideMap };
    }

    private static setFilterValuesAndLabelOverride(
        trimmed: string,
        chartRequest: FormGroup<ApexStudy>,
        templateValueMap: Map<string, string | undefined>,
        filterMap: Map<string, number>,
        disabledFilters: string[],
        filterOverrideMap: Map<string, string | undefined>,
        filterLimits: StudyTemplateFilterLimits[]
    ): {
        filterCounts: Map<string, number>;
        templateValues: Map<string, string | undefined>;
        filterOverrides: Map<string, string | undefined>;
    } {

        const filtersToShow = this.getFiltersFromString(trimmed);

        const toUse = filtersToShow.find((f) => (chartRequest.get<string>(`queryRequest.filters.${ f }.selected`) as FormArray)?.length);

        const limit = filterLimits.find((lim) => lim.filter === trimmed);

        if (!toUse) {
            // in the event there are none, just make one to get the process going
            const showAll = filtersToShow
                .filter((fil) => !disabledFilters.includes(fil))
                .some((fil) => chartRequest.get(`queryRequest.filters.${ fil }.type`)?.value === 'all');

            let ts = filtersToShow
                .filter((fil) => !disabledFilters.includes(fil))
                .map((fil) => `${ new TitleCasePipe().transform(fil) }`);

            templateValueMap.set(`${ trimmed }[0]`, undefined);

            if (showAll) {
                filterOverrideMap.set(`${ trimmed }[0]`, `${ ts.join(', ') }`);

                ts = ts.map((str) => `${str}s`);

                templateValueMap.set(`${ trimmed }[0]`, `All ${ ts.join(', ') }`);
            } else if (ts.length === 1 && limit?.limit === 1) {
                filterOverrideMap.set(`${ trimmed }[0]`, `${ ts[0] === 'Destination' ? 'A' : 'An' } ${ ts[0] }`);
            } else {
                filterOverrideMap.set(`${ trimmed }[0]`, `${ ts.join('/') }`);
            }

            filterMap.set(trimmed, 1);

        } else {
            const valsForFilter: RepoItem<string>[] = chartRequest.get(`queryRequest.filters.${ toUse }.selected`)?.value || [];

            filterMap.set(trimmed, valsForFilter.length || 1);

            for (let i = 0; i < valsForFilter.length; i++) {

                if (limit?.limit === 1) {
                    filterOverrideMap.set(`${ trimmed }[${ i }]`, `${ toUse === 'destination' ? 'A' : 'An' } ${ new TitleCasePipe().transform(toUse) }`);
                } else {
                    filterOverrideMap.set(`${ trimmed }[${ i }]`, `${ new TitleCasePipe().transform(toUse) }`);
                }

                templateValueMap.set(`${ trimmed }[${ i }]`, valsForFilter[i]?.label);
            }
        }

        return {
            filterCounts: filterMap,
            templateValues: templateValueMap,
            filterOverrides: filterOverrideMap,
        };
    }

}
