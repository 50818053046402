import { map, Observable } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { PresetColor } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { RdcResponseDto } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

import {
    CloudDefaultsSelection,
    DefaultPreferencesGroup, MapColourPreset,
    PreferencesGroup,
    UserPreferences
} from './default-preferences.models';

@Injectable({ providedIn: 'root' })
export class DefaultPreferencesService extends RdcBaseHttpService {

    constructor(
        @Inject(appEnvironment) private environment: AppEnvironment,
    ) {
        super();
    }

    getAirportGroup(): Observable<PreferencesGroup[]> {
        // eslint-disable-next-line max-len
        return this.http.get<RdcResponseDto<DefaultPreferencesGroup>>(`${ this.environment.api.baseUrl }/Account/preferences/airportGroup`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.groups)
            );
    }

    createAirportGroup(group: PreferencesGroup): Observable<PreferencesGroup[]> {
        // eslint-disable-next-line max-len
        return this.http.put<RdcResponseDto<DefaultPreferencesGroup>>(`${ this.environment.api.baseUrl }/Account/preferences/airportGroup`, group, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.groups)
            );
    }

    deleteAirportGroup(groupId: string): Observable<PreferencesGroup[]> {
        // eslint-disable-next-line max-len
        return this.http.delete<RdcResponseDto<DefaultPreferencesGroup>>(`${ this.environment.api.baseUrl }/Account/preferences/airportGroup/${groupId}`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.groups)
            );
    }

    getAirlineGroup(): Observable<PreferencesGroup[]> {
        // eslint-disable-next-line max-len
        return this.http.get<RdcResponseDto<DefaultPreferencesGroup>>(`${ this.environment.api.baseUrl }/Account/preferences/airlineGroup`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.groups)
            );
    }

    getCloudDefaults(): Observable<CloudDefaultsSelection> {
        // eslint-disable-next-line max-len
        return this.http.get<RdcResponseDto<CloudDefaultsSelection>>(`${ this.environment.api.baseUrl }/Account/preferences/cloud-defaults`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data)
            );
    }

    createCloudDefaults(originAirportId?: number, airlineId?: number): Observable<CloudDefaultsSelection> {
        // eslint-disable-next-line max-len
        return this.http.put<RdcResponseDto<CloudDefaultsSelection>>(`${ this.environment.api.baseUrl }/Account/preferences/cloud-defaults/upsert`, { originAirportId, airlineId },{ headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data)
            );
    }

    createAirlineGroup(group: PreferencesGroup): Observable<PreferencesGroup[]> {
        // eslint-disable-next-line max-len
        return this.http.put<RdcResponseDto<DefaultPreferencesGroup>>(`${ this.environment.api.baseUrl }/Account/preferences/airlineGroup`, group, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.groups)
            );
    }

    deleteAirlineGroup(groupId: string): Observable<PreferencesGroup[]> {
        // eslint-disable-next-line max-len
        return this.http.delete<RdcResponseDto<DefaultPreferencesGroup>>(`${ this.environment.api.baseUrl }/Account/preferences/airlineGroup/${groupId}`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.groups)
            );
    }

    getUserPreferences(): Observable<UserPreferences> {
        // eslint-disable-next-line max-len
        return this.http.get<RdcResponseDto<UserPreferences>>(`${ this.environment.api.baseUrl }/Account/preferences/userPreferences`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data)
            );
    }

    updateUserPreferences(unitsMeasurements: UserPreferences): Observable<UserPreferences> {
        // eslint-disable-next-line max-len
        return this.http.put<RdcResponseDto<UserPreferences>>(`${ this.environment.api.baseUrl }/Account/preferences/userPreferences`, unitsMeasurements, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data)
            );
    }

    updatePresetColours(colours?: PresetColor[]): Observable<UserPreferences> {
        // eslint-disable-next-line max-len
        return this.http.put<RdcResponseDto<UserPreferences>>(`${ this.environment.api.baseUrl }/Account/preferences/userColourPreset`,
            { presetColours: colours },
            { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data)
            );
    }

    updateMapColours(colours: MapColourPreset): Observable<UserPreferences> {
        // eslint-disable-next-line max-len
        return this.http.put<RdcResponseDto<UserPreferences>>(`${ this.environment.api.baseUrl }/Account/preferences/userMapColourPreset`,
            { mapColours: colours },
            { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data)
            );
    }
}
