import { Component } from "@angular/core";

import { SelectComponent } from "./select.component";

@Component({
    selector: 'rdc-apps-autocomplete',
    templateUrl: './select.component.html',
    styleUrls: [ './select.component.scss' ],
})
export class SelectAutoCompleteComponent extends SelectComponent {
    override filterable = true;

    override readonly filterMode: 'default' | 'autocomplete' = 'autocomplete';
}
