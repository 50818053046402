import { DatePipe, TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ɵFormGroupValue } from '@angular/forms';
import {
    studyBuilderRelativeOpts,
    xAxisOptionsForType,
    xAxisTypeOptions
} from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import {
    QueryRequestChartProps,
    QueryRequestChartSeries,
    QueryRequestChartXAxis
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { DataPointsEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points';

import { QbFiltersDateRangeUtils } from './QbFiltersDateRangeUtils';

@Injectable({ providedIn: 'root' })
export class QuerySummariser {

    static exclude: string[] = [
        'flow',
        'colour',
    ];


    static summariseDataPoints(dataPoints: string[]): string {
        return `${dataPoints.join(', ')}, `;
    }

    static summariseSeriesSelection(
        chartProperties: ɵFormGroupValue<QueryRequestChartProps> = {},
        studyType = 'table'
    ): string {

        if (chartProperties.type === 'single') {

            return `Single ${ studyType } series`;
        }

        const strings: string[] = [];

        chartProperties.series?.forEach((series) => {
            strings.push(this.createLabelForSeries(series));
        });

        return `Comparison series: ${  strings.join(', ')}`;
    }


    // eslint-disable-next-line complexity
    static createLabelForSeries(series: ɵFormGroupValue<QueryRequestChartSeries>, options: Record<string, any> = {}): string {
        let label = 'Select series filter';

        let timePeriodIfSelected = series.timePeriodSelection?.selected ? series.timePeriodSelection?.selected : null;
        const emissionsIfSelected = series.emissionsSchemeSelection ?  String(series.emissionsSchemeSelection).toUpperCase() : null;

        if (timePeriodIfSelected && options['spansMultipleYrs']) {
            const plusOne = Number(timePeriodIfSelected) + 1;

            timePeriodIfSelected = `${ timePeriodIfSelected }/${ String(plusOne).substring(2) }`;
        } else {

            switch (timePeriodIfSelected) {
                case 'q1': {
                    timePeriodIfSelected = 'Q1 (Jan - Mar)';
                    break;
                }
                case 'q2': {
                    timePeriodIfSelected = 'Q2 (Apr - Jun)';
                    break;
                }
                case 'q3': {
                    timePeriodIfSelected = 'Q3 (Jul - Sep)';
                    break;
                }
                case 'q4': {
                    timePeriodIfSelected = 'Q4 (Oct - Dec)';
                    break;
                }
                default: {
                    timePeriodIfSelected = String(timePeriodIfSelected).toUpperCase();
                }
            }

        }

        const splitA = (series.attributeSelections || [])[0]?.selected?.label?.split('] ') || [];
        const splitB = (series.attributeSelections || [])[1]?.selected?.label?.split('] ') || [];

        const compareA = splitA[1] || splitA[0];
        const compareB = splitB[1] || splitB[0];

        if (compareA && compareB) {
            label = `${compareA}, ${compareB}`;
        } else if ((compareA || compareB) && (series.timePeriodSelection?.selected || series.emissionsSchemeSelection)) {
            label = `${ compareA || compareB } (${ emissionsIfSelected || timePeriodIfSelected })`;
        } else if (!!series.timePeriodSelection?.selected && !!series.emissionsSchemeSelection) {
            label = `${ emissionsIfSelected } (${ timePeriodIfSelected })`;
        }  else if (compareA || compareB || !!series.timePeriodSelection?.selected || !!series.emissionsSchemeSelection) {
            label = compareA || compareB || emissionsIfSelected || timePeriodIfSelected || label;
        }

        return label;
    }

    static summariseFilterBy(filters: Record<string, any> = {}, datapointsData?: DataPointsEntity | null | undefined, basicQb = false): string {

        const dru = new QbFiltersDateRangeUtils();

        let summaryStrings: string[] = [];

        // eslint-disable-next-line complexity
        Object.keys(filters).forEach((filter) => {

            if (filter === 'timePeriod') {

                switch (filters[filter]?.type) {
                    case 'specific': {

                        if (filters[filter].specific) {

                            const pipe = new TitleCasePipe();

                            const { startYear, startMonth, endMonth, seasons, quarters, yearStartsMonth } = filters[filter].specific;

                            let { endYear } = filters[filter].specific;

                            const trimmedStartMonth = pipe.transform(startMonth)?.slice(0,3) || '';
                            const trimmedEndMonth = pipe.transform(endMonth)?.slice(0,3) || '';
                            const trimmedYearStartsMo = pipe.transform(yearStartsMonth)?.slice(0,3) || '';

                            let year = filters[filter].specific['year'];
                            let spansTwoYears = false;

                            if (dru.rangeSpansTwoYears(filters[filter].specific) && year) {
                                spansTwoYears = true;

                                year = `${ trimmedStartMonth } ${ String(year).substring(2) } - ${ trimmedEndMonth } ${ String(Number(year) + 1).substring(2) }`;
                            }

                            if (seasons) {
                                summaryStrings = this.summariseCheckboxes(summaryStrings, startYear, endYear, year, seasons);

                                return;
                            }
                            if (quarters) {
                                summaryStrings = this.summariseCheckboxes(summaryStrings, startYear, endYear, year, quarters);

                                return;
                            }

                            if ((!startYear && !endYear) && (!startMonth && !endMonth) && !year) {
                                return;
                            }

                            if (year) {

                                if(spansTwoYears) {
                                    summaryStrings.unshift(year);

                                    return;
                                }

                                if(trimmedStartMonth && trimmedStartMonth === trimmedEndMonth) {
                                    summaryStrings.unshift(`${trimmedStartMonth} ${year}`);

                                    return;
                                }

                                if(trimmedStartMonth && trimmedEndMonth) {
                                    summaryStrings.unshift(`${trimmedStartMonth} - ${trimmedEndMonth} ${year}`);

                                    return;
                                }

                                summaryStrings.unshift(`${year}`);

                                return;
                            }

                            // two nulls would match for start and end month so set to something else
                            if (startYear === endYear && (startMonth || '*') === (endMonth || '')) {

                                let startEndMoString = `${ pipe.transform(trimmedStartMonth) } - ${ pipe.transform(trimmedEndMonth) } `;

                                if(trimmedStartMonth === trimmedEndMonth) {
                                    startEndMoString = `${ pipe.transform(trimmedEndMonth) } `;
                                }

                                summaryStrings.unshift(`${ yearStartsMonth ? '' : startEndMoString }${ startYear || year || '' }`);

                                return;
                            }

                            const monthList = [ 'Jan','Feb','Mar','Apr', 'May','Jun','Jul','Aug','Sep','Oct','Nov','Dec' ];
                            let yearStartsEndMonth = '';

                            if(trimmedYearStartsMo) {
                                const i = monthList.findIndex((m) => m === trimmedYearStartsMo);

                                yearStartsEndMonth = monthList[i - 1] || 'Dec';

                                if(i !== 0) {
                                    endYear++;
                                }
                            }

                            summaryStrings.unshift(
                                `${ pipe.transform(trimmedStartMonth || trimmedYearStartsMo ) || '' } ${ startYear || year || '' } - ${ pipe.transform(trimmedEndMonth || yearStartsEndMonth) || '' } ${ endYear || year || '' }`
                            );
                        }

                        break;
                    }
                    case 'relative': {

                        const key = filters[filter].relative?.relativeMonths || '';

                        const relativeOption = [ ...studyBuilderRelativeOpts ].find(({ code }) => code === key);

                        if (!datapointsData) {
                            summaryStrings.push('Relative time period');

                            break;
                        }

                        const label = relativeOption?.label || '';

                        const datePipe = new DatePipe('en-GB');

                        const date = new Date();
                        const format = 'MMM YY';

                        const current = datePipe.transform(new Date().getTime(), format);

                        const start = new Date().setMonth(date.getMonth() + 1);
                        const end = new Date().setMonth(date.getMonth() - 1);

                        switch (true) {
                            case key === 'last1Month': {
                                const timeframe = date.setMonth(date.getMonth() - 1);

                                summaryStrings.push(`${ relativeOption?.label || '' } (${ datePipe.transform(timeframe, format) })`);
                                break;
                            }
                            case key.includes('last') && label.includes('3'): {
                                const timeframe = date.setMonth(date.getMonth() - 3);

                                summaryStrings.push(`${ relativeOption?.label || '' } (${ datePipe.transform(timeframe, format) } - ${ datePipe.transform(end, format) })`);
                                break;
                            }
                            case key.includes('last') && label.includes('6'): {
                                const timeframe = date.setMonth(date.getMonth() - 6);

                                summaryStrings.push(`${ relativeOption?.label || '' } (${ datePipe.transform(timeframe, format) } - ${ datePipe.transform(end, format) })`);
                                break;
                            }
                            case key.includes('last') && label.includes('12'): {
                                const timeframe = date.setMonth(date.getMonth() - 12);

                                summaryStrings.push(`${ relativeOption?.label || '' } (${ datePipe.transform(timeframe, format) } - ${ datePipe.transform(end, format) })`);
                                break;
                            }
                            case key === 'currentMonth': {
                                summaryStrings.push(`${ relativeOption?.label || '' } (${ current })`);
                                break;
                            }
                            case key === 'next1Month': {
                                const timeframe = date.setMonth(date.getMonth() + 1);

                                summaryStrings.push(`${ relativeOption?.label || '' } (${ datePipe.transform(timeframe, format) })`);
                                break;
                            }
                            case key.includes('next') && label.includes('3'): {
                                const timeframe = date.setMonth(date.getMonth() + 3);

                                summaryStrings.push(`${ relativeOption?.label || '' } (${ datePipe.transform(start, format) } - ${ datePipe.transform(timeframe, format) })`);
                                break;
                            }
                            case key.includes('next') && label.includes('6'): {
                                const timeframe = date.setMonth(date.getMonth() + 6);

                                summaryStrings.push(`${ relativeOption?.label || '' } (${ datePipe.transform(start, format) } - ${ datePipe.transform(timeframe, format) })`);
                                break;
                            }
                            case key.includes('next') && label.includes('12'): {
                                const timeframe = date.setMonth(date.getMonth() + 12);

                                summaryStrings.push(`${ relativeOption?.label || '' } (${ datePipe.transform(start, format) } - ${ datePipe.transform(timeframe, format) })`);
                                break;
                            }
                            default: {
                                summaryStrings.push(relativeOption?.label || '');
                            }
                        }

                        break;
                    }
                }

                return;
            }

            if (!basicQb) {
                if (filters[filter]?.type && filters[filter]?.selected) {

                    const { type, selected } = filters[filter];

                    switch (true) {
                        case filter === 'origin': {
                            summaryStrings = this.multiSelectString(type, 'From ', selected, summaryStrings);
                            break;
                        }
                        case filter === 'destination': {
                            const hasOrg = filters['origin']?.selected?.length;

                            summaryStrings = this.multiSelectString(type,  hasOrg ? 'to ': '', selected, summaryStrings);
                            break;
                        }
                        default: {
                            summaryStrings = this.multiSelectString(type, '', selected, summaryStrings);
                            break;
                        }
                    }
                }
            }

        });

        return summaryStrings.join(', ').replace(', to', ' to');
    }

    static basicBuilderSummariseMarket(filters: Record<string, any> = {}): string {
        let summaryStrings: string[] = [];

        Object.keys(filters).forEach((filter) => {
            if (filters[filter]?.type && filters[filter]?.selected) {

                const { type, selected } = filters[filter];

                switch (true) {
                    case filter === 'origin': {
                        summaryStrings = this.multiSelectString(type, 'From ', selected, summaryStrings);
                        break;
                    }
                    case filter === 'destination': {
                        const hasOrg = filters['origin']?.selected?.length;

                        summaryStrings = this.multiSelectString(type, hasOrg ? 'to ' : '', selected, summaryStrings);
                        break;
                    }
                    default: {
                        summaryStrings = this.multiSelectString(type, '', selected, summaryStrings);
                        break;
                    }
                }
            }
        });

        return summaryStrings.join(', ').replace(', to', ' to');
    }

    private static summariseCheckboxes(
        summaryStrings: string[],
        startYear: number,
        endYear: number,
        year: number,
        values: Record<string, boolean>
    ): string[] {

        const overallString: string[] = [];
        const concatinateVals: string[] = [];

        Object.keys(values).forEach((key) => {
            if (values[key]) {
                switch (key) {
                    case 'q1': {
                        concatinateVals.push(`${new TitleCasePipe().transform(key)} Jan - Mar`);
                        break;
                    }
                    case 'q2': {
                        concatinateVals.push(`${new TitleCasePipe().transform(key)} Apr - Jun`);
                        break;
                    }
                    case 'q3': {
                        concatinateVals.push(`${new TitleCasePipe().transform(key)} Jul - Sep`);
                        break;
                    }
                    case 'q4': {
                        concatinateVals.push(`${new TitleCasePipe().transform(key)} Oct - Dec`);
                        break;
                    }
                    default: {
                        concatinateVals.push(new TitleCasePipe().transform(key));
                    }
                }
            }
        });

        if (concatinateVals.length) {
            overallString.push(concatinateVals.join(' / '));
        }

        overallString.push(startYear === endYear ? `${ startYear || year }` : `${ startYear }-${ endYear }`);

        summaryStrings.unshift(overallString.join(' '));

        return summaryStrings;
    }

    private static multiSelectString(type: string, pretext = '', selected: { iataCode: string, label: string }[], strs: string[]): string[] {
        if (type === 'all') {
            return strs;
        }

        const split = selected[0]?.label.split(']')[1] || selected[0]?.label.split(']')[0];

        if (selected.length > 1) {

            if([ 'subRegion', 'region' ].includes(type)) {
                strs.push(`${pretext}${ split || 'None' } +${ selected.length - 1 } More`);
            } else {
                strs.push(`${pretext}${ selected[0]?.iataCode ? selected[0]?.iataCode : selected[0]?.label || 'None' } +${ selected.length - 1 } More`);
            }

            return strs;
        }

        if([ 'subRegion', 'region' ].includes(type)) {
            strs.push(`${pretext}${ split || 'None' }`);
        } else {
            strs.push(`${pretext}${ selected[0]?.iataCode ? selected[0]?.iataCode : selected[0]?.label || 'None' }`);
        }

        return strs;
    }


    static summariseGroupBy(summaries: Record<string, any> = {}, datapointsData: DataPointsEntity | null | undefined): string {

        if (!datapointsData) {
            return '';
        }

        const values = Object.values(summaries);
        const dataPointLabelMap = new Map<string, string>();
        const summary: string[] = [];
        const tcp = new TitleCasePipe();

        if (values.filter((val) => val === 'all').length === values.length) {
            return 'None';
        }

        datapointsData.dropdowns.dropdownOptionGroups.forEach(({ dropdownOptions }) =>
            dropdownOptions.forEach(({ label, code }) => dataPointLabelMap.set(code, label))
        );

        Object.keys(summaries).forEach((key) => {
            const value = summaries[key];

            const label = dataPointLabelMap.get(value);

            if (value === 'all' || !label) {
                return;
            }

            if ([ 'origin','destination' ].includes(key)) {
                summary.push(`${ tcp.transform(key) }: ${ label }`);

                return;
            }

            const item = datapointsData.dropdowns.dropdownOptionGroups.find(({ code }) => code === key);

            summary.push(`${ item?.label }: ${ label }`);
        });

        return summary.join(', ');
    }

    static transform(value: unknown): string {

        if (typeof value === 'string') {
            const titleCasePipe = new TitleCasePipe();

            return titleCasePipe.transform(value.split(/(?=[A-Z])|(?<=[a-z])(?=\d)/g).join(' '));
        }

        return String(value);
    }

    static summariseXAxis(xAxis: ɵFormGroupValue<QueryRequestChartXAxis> = {}): string {
        const strings: string[] = [];

        const type = xAxisTypeOptions.find(({ code }) => xAxis.type === code);

        const typeOpts = xAxisOptionsForType.get(xAxis.type || '');

        const partition = typeOpts?.find(({ code }) => code === xAxis.partition);

        strings.push(type?.label || 'Unspecified type');

        if (partition) {
            strings.push(partition.label || '');
        }

        return strings.join(', ');
    }
}
