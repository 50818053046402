import { map } from "rxjs";

import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { fetch } from "@ngrx/router-store/data-persistence";
import {
    getAirportOriginsAction,
    getAirportOriginsFailureAction,
    getAirportOriginsSuccessAction
} from "@rdc-apps/rdc-apex/src/lib/shared/data-access/actions";
import { AirportOriginsService } from "@rdc-apps/rdc-apex/src/lib/shared/data-access/services";

@Injectable()
export class AirportOriginEffects {
    private actions$ = inject(Actions);
    private airportOriginsService = inject(AirportOriginsService);

    get$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(getAirportOriginsAction),
                fetch({
                    run: ({ airline, timeRestricted }) => this.airportOriginsService.get(airline, timeRestricted)
                        .pipe(
                            map((response) => getAirportOriginsSuccessAction({ airportOrigins: response }))
                        ),
                    onError: () => getAirportOriginsFailureAction({ error: 'Error' }),
                })
            )
    );
}
