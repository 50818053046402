import { CommonModule } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { DialogItemModule } from '@rdc-apps/rdc-shared/src/lib/directives/dialog-item';
import { TooltipModule } from "@rdc-apps/rdc-shared/src/lib/directives/tooltip";
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';

@Component({
    standalone: true,
    selector: 'rdc-apps-app-drawer',
    templateUrl: './app-drawer.component.html',
    styleUrls: [ './app-drawer.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [ CommonModule, ButtonModule, IconModule, DialogItemModule, TooltipModule ],
})
export class AppDrawerComponent {

    constructor(
        @Inject(appEnvironment) public env: AppEnvironment
    ) {}
}
