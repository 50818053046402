export interface MapLineData {
    name: string;
    lineWidth: number;
    color: string;
    zIndex: number;
    geometry: {
        type: any;
        coordinates: number[][];
    };
}

export interface MapPointData {
    id: string;
    lat: number;
    lon: number;
    color: string;
    name: string;
    x: number;
    y: number;
}

export interface ConvertedMapLine {
    type: any;
    name: string;
    data: MapLineData[];
}

export interface ConvertedMapPoint {
    type: any;
    name: string;
    data: MapPointData[];
}
