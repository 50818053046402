import { createAction, props } from '@ngrx/store';

import { AutocompleteDispatch, AutocompleteOption, MultiAutocompleteDispatch } from './autocomplete.models';


export const autocomplete = createAction(
    '[Autocomplete] Init',
    props<AutocompleteDispatch>()
);

export const multiAutocomplete = createAction(
    '[Autocomplete] Multi',
    props<MultiAutocompleteDispatch>()
);

export const loadAutocompleteSuccess = createAction(
    '[Autocomplete/API] Load Autocomplete Success',
    props<{ results: AutocompleteOption[] }>()
);

export const loadAutocompleteFailure = createAction(
    '[Autocomplete/API] Load Autocomplete Failure',
    props<{ error: string }>()
);
