import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DataPointsEffects } from './+state/data-points.effects';
import * as fromDataPoints from './+state/data-points.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromDataPoints.DATA_POINTS_FEATURE_KEY,
            fromDataPoints.dataPointsReducer
        ),
        EffectsModule.forFeature([ DataPointsEffects ]),
    ],
})
export class DataPointsStoreModule {}
