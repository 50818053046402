import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TimePeriodRenderService {

    rendered = new Subject<void>();

    render(): void {
        this.rendered.next();
    }
}
