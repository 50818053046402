import { createAction, props } from "@ngrx/store";
import { RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

export const getAirportOriginsAction = createAction(
    '[Airport Origins] Get Airport Origins',
    props<{ airline: RepoItem<string>, timeRestricted: boolean; }>()
)

export const getAirportOriginsSuccessAction = createAction(
    '[Airport Origins] Get Airport Origins Success',
    props<{ airportOrigins: RepoItem<string>[] }>()
)

export const getAirportOriginsFailureAction = createAction(
    '[Airport Origins] Get Airport Origins Failure',
    props<{ error: any }>()
)
