import { FormArray, FormControl, UntypedFormGroup } from '@angular/forms';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

import { QueryBuilderType } from './query-builder-type';

export interface ApexStudyTable {
    studyId: FormControl<string | null>;
    cloudId: FormControl<string | null>;
    tags: FormArray<FormControl<RepoItem<string> | null>>;
    pinned: FormControl<boolean | null>;
    studyName: FormControl<string | null>;
    studyType: FormControl<QueryBuilderType | null>;
    queryRequest: UntypedFormGroup;
}
