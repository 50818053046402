import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

export const growthRouteSortOptions: Partial<RepoItem<string>>[] = [
    { label: 'RSI best', code: 'rsi', displayOrder: 1 },
    { label: 'RSI change most', code: 'rsi-most', displayOrder: 2 },
    { label: 'RSI change least', code: 'rsi-least', displayOrder: 3 },
    { label: 'Origin', code: 'origin', displayOrder: 4 },
    { label: 'Destination', code: 'destination', displayOrder: 5 },
];

export const riskRouteSortOptions: Partial<RepoItem<string>>[] = [
    { label: 'RSI weakest', code: 'rsi-weakest', displayOrder: 1 },
    { label: 'RSI change most', code: 'rsi-most', displayOrder: 2 },
    { label: 'RSI change least', code: 'rsi-least', displayOrder: 3 },
    { label: 'Origin', code: 'origin', displayOrder: 4 },
    { label: 'Destination', code: 'destination', displayOrder: 5 },
];

export const routeTypeOptions: Partial<RepoItem<string>>[] = [
    { label: 'New', code: 'new', displayOrder: 1 },
    { label: 'Backfill', code: 'backfill', displayOrder: 2 },
    { label: 'Frequency / Capacity', code: 'freqCapacity', displayOrder: 3 },
];

export const routeServedTypeOptions: Partial<RepoItem<string>>[] = [
    { label: 'Frequency / Capacity', code: 'freqCapacity', displayOrder: 1 },
    { label: 'Backfill', code: 'backfill', displayOrder: 2 },
];

export const routeUnservedTypeOptions: Partial<RepoItem<string>>[] = [
    { label: 'New', code: 'new', displayOrder: 1 },
];
