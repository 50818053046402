<div *ngIf="query?.studyType === 'scatter'" class="scatter-highlight">
    <rdc-apps-select
        placeholder="Highlight data point"
        display="compact"
        [options]="highlightableDatapoints"
        [filterable]="true"
        [showValidation]="false"
        [maskSelected]="highlight"
        (valueSelected)="onHighlightOptChange($event)"
        (clearSelected)="highlight = undefined">
    </rdc-apps-select>
</div>
<figure #flow id="chart" [ngClass]="{ 'with-highlight' : query?.studyType === 'scatter' }"></figure>
<img class="apx-logo"
     src="/assets/rdc-apex/images/apex-logo-full.svg"
     alt="apex logo">
