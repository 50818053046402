<ng-content></ng-content>
<button
        #trigger
        rdcAppsButton
        position="right"
        [colour]="button?.colour || 'default'"
        [class.menu-active]="!!dialogRef"
        [display]="button?.display"
        [disabled]="btnDisabled"
        (click)="onOpenMenu(trigger, true, false)"
        (keydown.arrowDown)="onOpenMenu(trigger)">
  <rdc-apps-icon size="lg">expand_more</rdc-apps-icon>
</button>

<ng-template #noMenu>
  <div class="empty-menu" cdkMenu>
    <span>Menu content...</span>
  </div>
</ng-template>
