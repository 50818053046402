import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInAnimationSpeed = 500;

export const fadeInAnimation = trigger('fadeIn', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate(`${fadeInAnimationSpeed}ms ease-in-out`, style({ opacity: 1 } )),
    ]),
]);
