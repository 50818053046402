import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl } from '@angular/forms';

interface ValidationMessages {
    [key: string]: string;
}

@Component({
    selector: 'rdc-apps-validated-control',
    templateUrl: './validated-control.component.html',
    styleUrls: [ './validated-control.component.scss' ],
    encapsulation: ViewEncapsulation.None,
})
export class ValidatedControlComponent implements OnInit {
    @Input() control!: AbstractControl | null;
    @Input() controlName!: string;
    @Input() showValidation = false;
    @Input() validationMessages: ValidationMessages = {};
    @Input() validateBeforeInteraction = true;
    @Input() formSubmitted = false;
    @Input() showToolTip = true;

    ngOnInit(): void {
        this.control?.valueChanges.subscribe(() => {
            if (this.control?.errors !== null) {
                this.showValidation = true;
            }
        });
    }

    get id(): string | undefined {
        return this.controlName
            ? `${this.controlName}ValidationMessage`
            : undefined;
    }

    get firstErrorMessage(): string {
        return this.showErrorMessage
            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
            this.validationMessages[Object.keys(this.control?.errors)[0]]
            : '';
    }

    get showErrorMessage(): boolean {
        if (this.validateBeforeInteraction) {
            return (
                this.showValidation &&
                this.validationMessages &&
                Boolean(this.control?.errors)
            );
        }

        return (
            this.showValidation &&
            this.validationMessages &&
            Boolean(this.control?.errors) &&
            (this.control?.dirty || this.control?.touched || this.formSubmitted)
        );
    }

    get showInvalid(): boolean {
        if (this.validateBeforeInteraction) {
            return this.showValidation && Boolean(this.control?.errors);
        }

        return (
            this.showValidation &&
            Boolean(this.control?.errors) &&
            (this.control?.dirty || this.control?.touched || this.formSubmitted)
        );
    }
}
