import { createAction, props } from '@ngrx/store';
import { CloudRefresh } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';

export const cloudRefresh = createAction(
    '[Cloud Refresh] Cloud refresh',
    props<{ cloud: string }>()
);

export const cloudRefreshSuccess = createAction(
    '[Cloud Refresh] Cloud refresh Success',
    props<{ refresh: CloudRefresh }>()
);

export const cloudRefreshFailure = createAction(
    '[Cloud Refresh] Cloud refresh Failure'
);
