<ng-container *ngIf="dateRangeUtils" [formGroup]="formGroup">

    <section class="query-filters-autocomplete">

        <rdc-apps-query-builder-filter-autocomplete
            [chartRequest]="form"
            [dropDowns]="dropDowns"
            [autoCompleteResults$]="autocompleteResults$">
        </rdc-apps-query-builder-filter-autocomplete>

    </section>

    <section class="query-filters-pricing-timeframe">

        <!-- responsible for itself in whether it displays or not -->
        <rdc-apps-qb-filter-fixed-timeframe
            *ngIf="dateRangeUtils"
            [appendToFormGroup]="appendToFormGroup"
            [dateRangeUtils]="dateRangeUtils"
        ></rdc-apps-qb-filter-fixed-timeframe>

        <!-- responsible for itself in whether it displays or not -->
        <rdc-apps-qb-filter-timeframe
            *ngIf="dateRangeUtils"
            [studyForm]="form"
            [relativeMonthOpts]="relativeMonthOpts"
            [dateRangeUtils]="dateRangeUtils"
            (initialised)="initialised.emit()"
            (selectedTimePeriod)="selectedTimePeriod.emit($event)"
        >
        </rdc-apps-qb-filter-timeframe>

        <rdc-apps-qb-summaries
            *ngIf="chartQueryTypes.includes(form.get('studyType')?.value)"
            [form]="appendToFormGroup"
            [queryType]="form.get('studyType')?.value"
            [dataPointsData]="dataPointsData">
        </rdc-apps-qb-summaries>


        <div class="rdc-lined-title" [ngClass]="{ error: additionalFiltersInvalid }" (click)="additionalFiltersOpen = !additionalFiltersOpen">
            <rdc-apps-icon size="sm" *ngIf="additionalFiltersInvalid else expandCollapse">error</rdc-apps-icon>
            <ng-template #expandCollapse>
                <rdc-apps-icon size="sm">{{ additionalFiltersOpen ? 'expand_more' : 'chevron_right' }}</rdc-apps-icon>
            </ng-template>
            <span>Additional filters</span>
        </div>

        <div class="additional-filters" [ngClass]="{ collapsed: !additionalFiltersOpen }">

            <div [hidden]="!additionalFiltersOpen">

                <div class="query-filters-dynamic-filters">

                    <label class="query-filters-pricing-cabin-type">
                    <span class="query-filters-pricing-cabin-type-label">
                        <span>Fare class</span>
                        <rdc-apps-icon *ngIf="formGroup.get('cabinTypes.types')?.disabled"
                                       size="xxs"
                                       rdcAppsTooltip
                                       tooltipPosition="right"
                                       [tooltipOptions]="{ maxWidth: '250px' }"
                                       [tooltipText]="cabinToolTip">
                            info
                        </rdc-apps-icon>
                    </span>

                        <div class="icon-input">
                            <button class="select-trigger"
                                    #cabinTrigger
                                    type="button"
                                    rdcAppsButton
                                    [ngClass]="{ 'ng-invalid': formGroup.get('cabinTypes.types')?.invalid }"
                                    [disabled]="formGroup.get('cabinTypes.types')?.disabled || disabledCabinTypeInput"
                                    [cdkMenuTriggerFor]="cabinTypeSelect">
                                {{ cabinTypeLabel }}
                            </button>
                            <rdc-apps-icon>expand_more</rdc-apps-icon>
                        </div>
                    </label>


                    <label class="query-filters-pricing-cabin-type">
                <span class="query-filters-pricing-cabin-type-label">
                    <span>Emissions scheme</span>
                    <rdc-apps-icon *ngIf="formGroup.get('emissionScheme.types')?.disabled"
                                   size="xxs"
                                   rdcAppsTooltip
                                   tooltipPosition="right"
                                   [tooltipOptions]="{ maxWidth: '250px' }"
                                   [tooltipText]="emissionsToolTip">
                        info
                    </rdc-apps-icon>
                </span>

                        <div class="icon-input">
                            <button class="select-trigger"
                                    #emissionsTrigger
                                    type="button"
                                    rdcAppsButton
                                    [ngClass]="{ 'ng-invalid': formGroup.get('emissionScheme.types')?.invalid }"
                                    [disabled]="formGroup.get('emissionScheme.types')?.disabled"
                                    [cdkMenuTriggerFor]="emissionSchemeSelect">
                                {{ emissionLabel }}
                            </button>
                            <rdc-apps-icon>expand_more</rdc-apps-icon>
                        </div>
                    </label>

                    <label formGroupName="schedulesFilters" class="rdc-checkbox" [class.input-disabled]="formGroup.get('schedulesFilters')?.disabled">
                        <input type="checkbox" formControlName="nonStopServices">
                        <span>Non-stop only</span>
                    </label>

                    <label formGroupName="schedulesFilters" class="rdc-checkbox" [class.input-disabled]="formGroup.get('schedulesFilters')?.disabled">
                        <input type="checkbox" formControlName="franchisePartners">
                        <span class="franchise-partner-label">Include franchise carrier operations</span>
                    </label>

                    <rdc-apps-icon *ngIf="formGroup.get('schedulesFilters')?.disabled"
                                   size="xxs"
                                   class="schedules-info"
                                   rdcAppsTooltip
                                   tooltipPosition="right"
                                   [tooltipOptions]="{ maxWidth: '250px' }"
                                   [tooltipText]="schedulesToolTip">
                        info
                    </rdc-apps-icon>

                </div>

            </div>
        </div>

    </section>

    <ng-template #emissionSchemeSelect>
        <div cdkMenu class="rdc-select-menu" [ngStyle]="{ width: emissionsTrigger.clientWidth + 'px' }">
            <label rdcAppsMenuItem class="rdc-checkbox" *ngFor="let emissionSchemeOpt of dropDownsForGroup('emissionsSchemeFilter') | displayOrder">
                <input type="checkbox"
                       [checked]="inFilterTypeFormArray(emissionSchemeOpt.code, 'emissionScheme')"
                       (change)="onFilterTypeToggle(emissionSchemeOpt, 'emissionScheme')">
                <span>{{ emissionSchemeOpt.label }}</span>
            </label>
        </div>
    </ng-template>

    <ng-template #cabinTypeSelect>
        <div cdkMenu class="rdc-select-menu" [ngStyle]="{ width: cabinTrigger.clientWidth + 'px' }">
            <label rdcAppsMenuItem class="rdc-checkbox" *ngFor="let cabinType of dropDownsForGroup('cabin') | displayOrder">
                <input type="checkbox"
                       [checked]="inFilterTypeFormArray(cabinType.code, 'cabinTypes')"
                       (change)="onFilterTypeToggle(cabinType, 'cabinTypes')">
                <span>{{ cabinType.label }}</span>
            </label>
        </div>
    </ng-template>

</ng-container>
