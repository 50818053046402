import { Directive, HostBinding } from '@angular/core';

@Directive({
    selector: '[rdcAppsMenuItem]',
})
export class RdcMenuItemDirective {

    @HostBinding('attr.type') type = 'button';

    @HostBinding('class.rdc-menu-item') get rdcMenuItem(): boolean {
        return true;
    }
}
