import { HttpErrorResponse } from '@angular/common/http';
import { Action, createAction, props } from '@ngrx/store';

export const httpErrorAction = createAction(
    '[Error] Http Error',
    props<{ action: Action; response: HttpErrorResponse }>()
);

export const clearHttpErrorAction = createAction(
    '[Error] Clear Http Error'
);
