import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { DialogItemModule } from '@rdc-apps/rdc-shared/src/lib/directives/dialog-item';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { LoadingOverlayComponent } from '@rdc-apps/rdc-shared/src/lib/ui/loading-overlay';
import { SelectAutocompleteModule } from "@rdc-apps/rdc-shared/src/lib/ui/select";
import { DisplayCodesComponent } from "display-codes";

import { DatatableExportComponent } from './datatable-export.component';

@NgModule({
    imports: [
        CommonModule,
        ButtonModule,
        DialogItemModule,
        ReactiveFormsModule,
        IconModule,
        SelectAutocompleteModule,
        LoadingOverlayComponent,
        DisplayCodesComponent
    ],
    declarations: [ DatatableExportComponent ],
    exports: [ DatatableExportComponent ],
})
export class DatatableExportModule {}
