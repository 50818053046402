import { combineLatest, filter, of, switchMap, take } from 'rxjs';

import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getUserDetailsData } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/user-details';
import { getUserSettings, getUserSettingsData } from "@rdc-apps/rdc-apex/src/lib/shared/data-access/store/user-settings";

export const userCanActivateCloud = (cloudCode: 'risk' | 'network' | 'growth' | 'sustainability' | 'performance'): CanActivateFn => () => {
    const store = inject(Store);
    const router = inject(Router);

    return combineLatest([
        store.select(getUserDetailsData('userDetailsState')),
        store.select(getUserSettingsData('userSettingsState'))
    ]).pipe(
            filter(([ details, userSettings ]) => !!details && !!userSettings),
            take(1),
            switchMap(([ details, userSettings ]) => {

                if (!details || !userSettings) {
                    return of(false);
                }

                if (details.organisation.activeCloudCodes.includes(cloudCode)) {

                    const get = localStorage.getItem(`${cloudCode}SkipRelocate`);

                    localStorage.removeItem(`${cloudCode}SkipRelocate`);

                    const settingValue = userSettings[`${cloudCode}OnboardingCompleted`] ?? undefined;

                    if(settingValue !== undefined && !settingValue && !get) {

                        localStorage.setItem(`${cloudCode}SkipRelocate`, 'true');

                        return of(router.parseUrl(`/${cloudCode}`));
                    }

                    return of(true);
                }

                return of(router.parseUrl(`${cloudCode}/upgrade`));
            })
        );
};

export const userCanNotActivateCloud = (cloudCode: string): CanActivateFn => () => {
    const store = inject(Store);
    const router = inject(Router);

    return store.select(getUserDetailsData('userDetailsState'))
        .pipe(
            filter((details) => !!details),
            take(1),
            switchMap((details) => {

                if (!details) {
                    return of(true);
                }

                if (!details.organisation.activeCloudCodes.includes(cloudCode)) {
                    return of(true);
                }

                return of(router.parseUrl(cloudCode));
            })
        );
};
