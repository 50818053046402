import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { easing } from '@rdc-apps/rdc-shared/src/lib/constants';

export const showHide = trigger('showHide', [
    state('open', style({
        height: '*',
    })),
    state('closed', style({
        height: '0px',
    })),
    transition('open <=> closed', [
        group([
            animate(`500ms ${ easing.standard }`),
            // animates all children within this component div
            query('@*', [
                animateChild(),
            ], { optional: true }),
        ]),
    ]),
]);

export const dropIn = trigger('dropIn', [
    state('*', style({
        opacity: 1,
    })),
    state('void', style({
        opacity: 0,
    })),
    transition('void => *', [
        animate(`1000ms ${ easing.standard }`),
    ]),
]);
