import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TabItemComponent } from './tab-item.component';

@NgModule({
    imports: [ CommonModule ],
    declarations: [ TabItemComponent ],
    exports: [
        TabItemComponent,
    ],
})
export class TabItemModule {}
