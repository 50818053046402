import { BehaviorSubject, catchError, of, Subscription, tap } from "rxjs";

import { inject, Injectable } from "@angular/core";
import { appEnvironment } from "@rdc-apps/rdc-apex/src/lib/shared/environment";
import { RdcBaseHttpService } from "@rdc-apps/rdc-apex/src/lib/shared/utilities";
import { RdcResponseDto, RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

// eslint-disable-next-line
enum AppletData {
    GEO_FOR_BENCHMARK ='geoLocationsForBenchmarks',
    AIRLINES_FOR_BENCHMARK_DESTINATION ='airlinesForBenchmarkDestination',
    AIRLINES_FOR_ORIGIN ='airlinesForOrigin',
    SHARED_ORIGINS_AND_AIRLINES_FOR_ORIGIN ='sharedOriginsAndAirlinesForOrigin',
    DESTINATION_AIRPORTS_FOR_ORIGIN ='destinationAirportsForOrigin',
}

@Injectable({ providedIn: "root" })
export class CloudIataDataService extends RdcBaseHttpService {

    private environment = inject(appEnvironment);

    private requests = new Map<string, Subscription | null>();

    public geolocations = new BehaviorSubject<Partial<RepoItem<string>>[]>([]);

    public airlines = new BehaviorSubject<Partial<RepoItem<string>>[]>([]);

    public origins = new BehaviorSubject<Partial<RepoItem<string>>[]>([]);

    public airports = new BehaviorSubject<Partial<RepoItem<string>>[]>([]);

    public loading = new Map<string, boolean>();

    constructor() {
        super();

        this.geolocations.next([]);
    }

    get isLoading(): boolean {
        return Array.from(this.loading.values()).some((v) => v);
    }

    geoLocationsForBenchmarksOrOrigin(airportIds: number[]): void {

        this.loading.set(AppletData.GEO_FOR_BENCHMARK, true);

        this.requests.get(AppletData.GEO_FOR_BENCHMARK)?.unsubscribe();

        const request = this.http.post<RdcResponseDto<{ allowedLocations: Partial<RepoItem<unknown>>[] }>>(`${ this.environment.api.baseUrl }/GrowthCloud/applet/geolocations`,
            { benchmarkAirportIds: airportIds },
            { headers: this.getTokenHeaders() }
        ).pipe(
            catchError(() => of(null)),
            tap((response) => {
                this.loading.set(AppletData.GEO_FOR_BENCHMARK, false);

                if(response) {

                    const locations = response.success.data.allowedLocations;

                    const types = [ ...new Set(locations.map(({ type }) => type || 'unknown')) ];

                    const mapped = types.map((locationType) => ({
                        label: locationType,
                        options: locations.filter(({ type }) => type === locationType),
                    }));

                    this.geolocations.next(mapped);
                }
            })
        ).subscribe();

        this.requests.set(AppletData.GEO_FOR_BENCHMARK, request);
    }

    getAirlinesServingOriginsAndDestination(originAirportIds: number[], destinationId: number, destinationTerm: string): void {

        this.loading.set(AppletData.AIRLINES_FOR_BENCHMARK_DESTINATION, true);

        this.requests.get(AppletData.AIRLINES_FOR_BENCHMARK_DESTINATION)?.unsubscribe();

        const request = this.http.post<RdcResponseDto<{ allowedAirlines: Partial<RepoItem<string>>[] }>>(`${ this.environment.api.baseUrl }/GrowthCloud/applet/airlines`,
            {
                originAirportIds,
                destinationId,
                destinationTerm,
            },
            { headers: this.getTokenHeaders() }
        ).pipe(
            catchError(() => of(null)),
            tap((response) => {

                this.loading.set(AppletData.AIRLINES_FOR_BENCHMARK_DESTINATION, false);

                if(response) {
                    this.airlines.next(response.success.data.allowedAirlines);
                }
            })
        ).subscribe();

        this.requests.set(AppletData.AIRLINES_FOR_BENCHMARK_DESTINATION, request);
    }

    // RISK 1 FOR AIRLINES
    getAirlinesForOriginAirport(originAirportId: number): void {

        this.loading.set(AppletData.AIRLINES_FOR_ORIGIN, true);

        this.requests.get(AppletData.AIRLINES_FOR_ORIGIN)?.unsubscribe();

        const request = this.http.get<RdcResponseDto<{ airlines: Partial<RepoItem<string>>[] }>>(`${ this.environment.api.baseUrl }/RiskCloud/applet/airlinesForOrigin/${ originAirportId }`,
            { headers: this.getTokenHeaders() }
        ).pipe(
            catchError(() => of(null)),
            tap((response) => {

                this.loading.set(AppletData.AIRLINES_FOR_ORIGIN, false);

                if(response) {
                    this.airlines.next(response.success.data.airlines);
                }
            })
        ).subscribe();

        this.requests.set(AppletData.AIRLINES_FOR_ORIGIN, request);
    }

    // RISK MANUAL ADD
    getDestinationAirportsForOrigin(originAirportId: number): void {

        this.loading.set(AppletData.DESTINATION_AIRPORTS_FOR_ORIGIN, true);

        this.requests.get(AppletData.DESTINATION_AIRPORTS_FOR_ORIGIN)?.unsubscribe();

        const request = this.http.get<RdcResponseDto<{ destinations: Partial<RepoItem<string>>[] }>>(`${ this.environment.api.baseUrl }/RiskCloud/applet/destinationsForOrigin/${ originAirportId }`,
            { headers: this.getTokenHeaders() }
        ).pipe(
            catchError(() => of(null)),
            tap((response) => {

                this.loading.set(AppletData.DESTINATION_AIRPORTS_FOR_ORIGIN, false);

                if (response?.success) {
                    this.airports.next(response.success.data.destinations);
                }
            })
        ).subscribe();

        this.requests.set(AppletData.DESTINATION_AIRPORTS_FOR_ORIGIN, request);
    }

    getSharedOriginsAndAirlinesForOrigin(originAirportId: number): void {

        this.loading.set(AppletData.SHARED_ORIGINS_AND_AIRLINES_FOR_ORIGIN, true);

        this.requests.get(AppletData.SHARED_ORIGINS_AND_AIRLINES_FOR_ORIGIN)?.unsubscribe();

        const request = this.http.get<RdcResponseDto<{
            airlines: Partial<RepoItem<string>>[];
            origins: Partial<RepoItem<string>>[];
        }>>(`${ this.environment.api.baseUrl }/RiskCloud/applet/airlinesOriginsSharedDestinations/${ originAirportId }`,
            { headers: this.getTokenHeaders() }
        ).pipe(
            catchError(() => of(null)),
            tap((response) => {

                this.loading.set(AppletData.SHARED_ORIGINS_AND_AIRLINES_FOR_ORIGIN, false);

                if (response) {
                    this.airlines.next(response.success.data.airlines);
                    this.origins.next(response.success.data.origins);
                }
            })
        ).subscribe();

        this.requests.set(AppletData.SHARED_ORIGINS_AND_AIRLINES_FOR_ORIGIN, request);
    }



}
