<div class="settings">
    <div class="settings-topbar">
        <h4>Settings</h4>
        <button
            rdcAppsButton
            display="compact"
            (click)="onDone()"
        >
            Done
        </button>
    </div>

    <rdc-apps-tabs [activeTabIndex]="dialogData?.activeTabIndex || 0">
        <rdc-apps-tab-item heading="Defaults & preferences">
            <div class="settings-tab-content">
                <rdc-apps-default-preferences
                    [form]="form"
                    [formGroup]="defaultPreferencesFormGroup"
                    [dataPointsDropDownData]="dataPointsDropDownData$ | async"
                    [autocompleteResults]="(autocompleteResults$ | async) || []"
                    (autocomplete)="onAutoComplete($event)"
                ></rdc-apps-default-preferences>
            </div>
        </rdc-apps-tab-item>
        <rdc-apps-tab-item heading="Colours" [disableFlex]="true">
            <div class="settings-tab-content">
                <rdc-apps-colours [form]="form"></rdc-apps-colours>
            </div>

            <div class="settings-tab-content">
                <rdc-apps-map-colours [mapColourFormGroup]="form.get('defaultPreferences.mapColours')"></rdc-apps-map-colours>
            </div>
        </rdc-apps-tab-item>
        <rdc-apps-tab-item heading="Tags">
            <div class="settings-tab-content">
                <rdc-apps-tag-management [form]="form"></rdc-apps-tag-management>
            </div>
        </rdc-apps-tab-item>
        <rdc-apps-tab-item heading="Clouds">
            <div class="settings-tab-content">
                <rdc-apps-cloud-management
                    [form]="form"
                    [formGroup]="defaultPreferencesFormGroup"
                    [autocompleteResults]="(autocompleteResults$ | async) || []"
                    (autocomplete)="onAutoComplete($event)">
                </rdc-apps-cloud-management>
            </div>
        </rdc-apps-tab-item>
        <rdc-apps-tab-item heading="Account">
            <div class="settings-tab-content">
                <rdc-apps-account
                    [form]="accountFormGroup"
                    [userDetails]="userDetails$ | async"
                ></rdc-apps-account>
            </div>
        </rdc-apps-tab-item>
    </rdc-apps-tabs>
</div>
