<ng-container *ngIf="showCompleteTimePeriodSelection else minorSelection">

    <div class="rdc-template-timeframe"
         [ngClass]="{ 'years-only': specificToTypeValue !== 'months' }"
         [formGroup]="timePeriodFormGroup()">

        <label
            *ngIf="timePeriodFormGroup('relative')">
            <span>Relative period</span>
            <rdc-apps-select
                controlName="relativeMonths"
                [controlGroup]="timePeriodFormGroup('relative')"
                [options]="studyBuilderRelativeOpts">
            </rdc-apps-select>
        </label>


        <label class="text-hidden" *ngIf="timePeriodFormGroup('specific')">
            <span>Specific to</span>
            <rdc-apps-select
                controlName="type"
                [controlGroup]="timePeriodFormGroup('specific')"
                [options]="specificTimeFrameOpts">
            </rdc-apps-select>
        </label>

        <label
            *ngIf="timePeriodFormGroup('specific', 'startYear')">
            <span>Start year</span>
            <rdc-apps-select
                controlName="startYear"
                [controlGroup]="timePeriodFormGroup('specific')"
                [options]="startYears">
            </rdc-apps-select>
        </label>

        <label
            *ngIf="timePeriodFormGroup('specific', 'endYear')">
            <span>End year</span>
            <rdc-apps-select
                controlName="endYear"
                placeholder="Select a year"
                [controlGroup]="timePeriodFormGroup('specific')"
                [options]="endYears">
            </rdc-apps-select>
        </label>

        <span></span>

        <label
            *ngIf="timePeriodFormGroup('specific', 'startMonth')">
            <span>Start month</span>
            <rdc-apps-select
                controlName="startMonth"
                [showValidation]="false"
                [controlGroup]="timePeriodFormGroup('specific')"
                [options]="startMonths">
            </rdc-apps-select>
        </label>

        <label
            *ngIf="timePeriodFormGroup('specific', 'endMonth')">
            <span>End month</span>
            <rdc-apps-select
                controlName="endMonth"
                [showValidation]="false"
                [controlGroup]="timePeriodFormGroup('specific')"
                [options]="endMonths">
            </rdc-apps-select>
        </label>

    </div>

    <ng-container
        *ngIf="timePeriodFormGroup('specific')"
        [formGroup]="timePeriodFormGroup()">

        <ng-container
            *ngFor="let control of ['seasons', 'quarters']"
            formGroupName="specific">

            <div class="rdc-template-timeframe-additional" *ngIf="timePeriodFormGroup('specific', control) as checkboxGroup" [formGroupName]="control">

                <span class="rdc-lined-title">What to show</span>

                <div class="rdc-template-timeframe-additional-inner">
                    <label class="rdc-checkbox" *ngFor="let checkbox of checkboxGroup.controls | keyvalue" >
                        <input type="checkbox" [formControlName]="checkbox.key">
                        <ng-container [ngSwitch]="checkbox.key">
                            <span *ngSwitchCase="'q1'">Q1 (Jan - Mar)</span>
                            <span *ngSwitchCase="'q2'">Q2 (Apr - Jun)</span>
                            <span *ngSwitchCase="'q3'">Q3 (Jul - Sep)</span>
                            <span *ngSwitchCase="'q4'">Q4 (Oct - Dec)</span>
                            <span *ngSwitchDefault>{{ checkbox.key | titlecase }}</span>
                        </ng-container>
                    </label>
                </div>

            </div>

        </ng-container>

    </ng-container>


</ng-container>

<ng-template #minorSelection>

    <ng-container *ngIf="timePeriodFormGroup('specific', 'yearStartsMonth') || timePeriodFormGroup('specific', 'year')">

        <div class="rdc-template-timeframe minor-timeframe">

            <label class="text-hidden" *ngIf="timePeriodFormGroup('specific', 'yearStartsMonth')">
                <span>Year start month</span>
                <rdc-apps-select
                    [controlGroup]="timePeriodFormGroup('specific')"
                    [disabled]="apexTrialMode$ | async"
                    controlName="yearStartsMonth"
                    [options]="startMonths">
                </rdc-apps-select>
            </label>

            <label class="text-hidden" *ngIf="timePeriodFormGroup('specific','year')">
                <span>Year</span>
                <rdc-apps-select
                    [controlGroup]="timePeriodFormGroup('specific')"
                    controlName="year"
                    [options]="endYears">
                </rdc-apps-select>
            </label>
        </div>

    </ng-container>

</ng-template>

