import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'rdc-apps-header',
    templateUrl: './header.component.html',
    styleUrls: [ './header.component.scss' ],
})
export class HeaderComponent {

    @Input() header!: string;
    @Input() title!: string;

    @HostBinding('class.no-margin') @Input() noMargin = false;
    @HostBinding('class.margin-top') @Input() marginTop = false;

}
