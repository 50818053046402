import { map, Observable } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { RdcResponseDto, ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { ToastService } from '@rdc-apps/rdc-shared/src/lib/ui/toast';

import { Risk, RiskCreateRoute, RiskEntity } from './risk.models';

@Injectable({ providedIn: 'root' })
export class RiskService extends RdcBaseHttpService {

    constructor(
        private toastService: ToastService,
        @Inject(appEnvironment) private environment: AppEnvironment
    ) {
        super();
    }

    get(): Observable<RiskEntity[]> {
        // eslint-disable-next-line max-len
        return this.http.get<RdcResponseDto<Risk>>(`${this.environment.api.baseUrl}/RiskCloud/routes`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.routes)
            );
    }

    create(routesSet: RiskCreateRoute[]): Observable<RiskEntity[]> {
        // eslint-disable-next-line max-len
        return this.http.post<RdcResponseDto<Risk>>(`${this.environment.api.baseUrl}/RiskCloud/routes/create`, { routes: routesSet }, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => {

                    if (Number(response.success.data.created.slice(0,1)) > 0) {
                        const msg = response.success.data.singleRouteMessage || response.success.data.created;

                        this.toastService.simpleToast(ToastType.SUCCESS, msg, 5000);
                    }

                    if (Number(response.success.data.skipped.slice(0,1)) > 0) {
                        const msg = response.success.data.singleRouteMessage || response.success.data.skipped;

                        this.toastService.simpleToast(ToastType.WARN, msg, 5000);
                    }

                    return response.success.data.routes;
                })
            );
    }

    delete(routeId: string): Observable<RiskEntity[]> {
        // eslint-disable-next-line max-len
        return this.http.delete<RdcResponseDto<Risk>>(`${this.environment.api.baseUrl}/RiskCloud/routes/delete/${routeId}`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.routes)
            );
    }

    refresh(): Observable<RiskEntity[]> {
        // eslint-disable-next-line max-len
        return this.http.get<RdcResponseDto<Risk>>(`${this.environment.api.baseUrl}/RiskCloud/routes/refresh`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.routes)
            );
    }

}
