import { RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";
import { StringUtils } from "turbocommons-ts";

export const orderByClosestCodeToFilter = <T extends Partial<RepoItem<unknown>>>(arr: T[], filter: string): T[] => arr
    // eslint-disable-next-line complexity
    .sort((a,b) => {
        // first then by rdc criteria

        const iataCodeA = (a.iataCode || '').toUpperCase();
        const iataCodeB = (b.iataCode || '').toUpperCase();

        const icaoCodeA = (a.icaoCode || '').toUpperCase();
        const icaoCodeB = (b.icaoCode || '').toUpperCase();

        const codeA = (a.icaoCode || '').toUpperCase();
        const codeB = (b.icaoCode || '').toUpperCase();

        const filterToUpper = filter.toUpperCase();

        const comparedIataA = StringUtils.compareByLevenshtein(iataCodeA, filterToUpper);
        const comparedIataB = StringUtils.compareByLevenshtein(iataCodeB, filterToUpper);

        const comparedIcaoA = StringUtils.compareByLevenshtein(icaoCodeA, filterToUpper);
        const comparedIcaoB = StringUtils.compareByLevenshtein(icaoCodeB, filterToUpper);

        const comparedCodeA = StringUtils.compareByLevenshtein(codeA, filterToUpper);
        const comparedCodeB = StringUtils.compareByLevenshtein(codeB, filterToUpper);


        const splitLabelAToUpper = (a.label || '')
            .toUpperCase()
            .split('] '); // remove any code from the label if present

        const splitLabelBToUpper = (b.label || '')
            .toUpperCase()
            .split('] ');

        const aLabel = splitLabelAToUpper[1] || splitLabelAToUpper[0];
        const bLabel = splitLabelBToUpper[1] || splitLabelBToUpper[0];

        // in order of priority
        const iataMatch= propMatches(filterToUpper, aLabel, bLabel, comparedIataA, comparedIataB);

        if(iataMatch > -2) {
            return iataMatch;
        }

        const icaoMatch= propMatches(filterToUpper, aLabel, bLabel, comparedIcaoA, comparedIcaoB);

        if(icaoMatch > -2) {
            return icaoMatch;
        }

        const genericCodeMatch= propMatches(filterToUpper, aLabel, bLabel, comparedCodeA, comparedCodeB);

        if(genericCodeMatch > -2) {
            return genericCodeMatch;
        }

        const aStartsWith = aLabel.startsWith(filterToUpper);
        const bStartsWith = bLabel.startsWith(filterToUpper);

        // 1. label starts with filter alphabetically
        if(filterToUpper && aStartsWith && !bStartsWith) {
            return -1;
        }

        if(filterToUpper && !aStartsWith && bStartsWith) {
            return 1;
        }

        if(filterToUpper && aStartsWith && bStartsWith) {
            if(aLabel < bLabel) {
                return -1;
            }

            if(aLabel > bLabel) {
                return 1;
            }
        }

        // all else fails, order alphabetically.
        if(aLabel < bLabel) {
            return -1;
        }

        if(aLabel > bLabel) {
            return 1;
        }

        return 0;
    });

const propMatches = (filter: string, nameA: string, nameB: string, propA: number, propB: number): number => {
    // if A matches directly move up
    if(filter && (propA === 0 && propB !== 0)) {
        return -1;
    }
    // if B matches directly move up
    if(filter && (propA !== 0 && propB === 0)) {
        return 1;
    }
    // if they're both the same, order alphabetically
    if(filter && (propA === 0 && propB === 0)) {
        if(nameA < nameB) {
            return -1;
        }

        return 1;
    }

    return -2;
}
