import { FormArray, FormControl, FormGroup } from '@angular/forms';

import {
    qbAircraft,
    qbAirline,
    qbOriginDestination, qbRepoItem, qbSpecificPeriod, qbTimePeriod
} from './query-builder-form.model';

export interface QueryBuilderFilters {
    origin?: FormGroup<QueryBuilderFilterAutoComplete<qbOriginDestination>>;
    destination?: FormGroup<QueryBuilderFilterAutoComplete<qbOriginDestination>>;
    aircraft?: FormGroup<QueryBuilderFilterAutoComplete<qbAircraft>>;
    airline?: FormGroup<QueryBuilderFilterAutoComplete<qbAirline>>;
    timePeriod: FormGroup<QbTimePeriodFilters>;
    cabinTypes?: FormGroup<{
        types: FormArray<FormControl<string | null>>;
    }>;
    emissionScheme?: FormGroup<{
        types: FormArray<FormControl<string | null>>;
    }>;
    schedulesFilters?: FormGroup<{
        nonStopServices: FormControl<boolean | null>;
        franchisePartners: FormControl<boolean | null>;
    }>;
}

export interface QueryBuilderFilterAutoComplete<T> {
    type: FormControl<T>;
    selected: FormArray<QbFilterSelectedItem>;
}

export interface QbTimePeriodFilters {
    type: FormControl<qbTimePeriod>;
    specific?: FormGroup<QbSpecificTimeFilters>;
    relative?: FormGroup<{
        relativeMonths: FormControl<string | null>;
    }>;
}

export interface QbSpecificTimeFilters {
    type: FormControl<qbSpecificPeriod>;
    startYear: FormControl<number | null | undefined>;
    endYear: FormControl<number | null | undefined>;
    startMonth?: FormControl<string | null | undefined>;
    endMonth?: FormControl<string | null | undefined>;
    months?: FormGroup<QbSpecificMonths>;
    seasons?: FormGroup<QbSpecificSeasons>;
    quarters?: FormGroup<QbSpecificQuarters>;
}

export interface QbSpecificTimeFiltersPartial {
    startYear?: number | null;
    endYear?: number | null;
    startMonth?: string | null;
    endMonth?: string | null;
}

export type QbSpecificMonths = Record<string, FormControl<boolean | null>>;

export interface QbSpecificQuarters {
    q1: FormControl<boolean | null>;
    q2: FormControl<boolean | null>;
    q3: FormControl<boolean | null>;
    q4: FormControl<boolean | null>;
}

export interface QbSpecificSeasons {
    summer: FormControl<boolean | null>;
    winter: FormControl<boolean | null>;
}

export interface QueryBuilderSummariesFilters {
    [key: string]: FormControl<string | boolean | null>;
}

export type QbFilterSelectedItem = FormControl<qbRepoItem<never>>;


export interface QbSummaryBuilderItem {
    path: string;
    appendComma?: boolean;
    override?: string;
}
