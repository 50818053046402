import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TemplateEventService {

    toggleDataPoints = new Subject<void>();

    progressTemplate = new Subject<void>();

    toggleDataPointDrawer(): void {
        this.toggleDataPoints.next();
    }

    progress(): void {
        this.progressTemplate.next();
    }
}
