import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DpFilterModule } from '@rdc-apps/rdc-apex/src/lib/shared/pipes/dp-filter';
import { TooltipModule } from '@rdc-apps/rdc-shared/src/lib/directives/tooltip';
import { SimpleFilterPipe } from "@rdc-apps/rdc-shared/src/lib/pipes/filter";
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { MultiSelectCheckboxModule } from '@rdc-apps/rdc-shared/src/lib/ui/multi-select-checkbox';
import { NoResultsModule } from '@rdc-apps/rdc-shared/src/lib/ui/no-results';

import { DataPointsManualSelectionComponent } from './data-points-manual-selection.component';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        DpFilterModule,
        NoResultsModule,
        MultiSelectCheckboxModule,
        ReactiveFormsModule,
        TooltipModule,
        CdkMenuModule,
        SimpleFilterPipe,
    ],
    declarations: [ DataPointsManualSelectionComponent ],
    exports: [
        DataPointsManualSelectionComponent,
    ],
})
export class DataPointsManualSelectionModule {}
