<div class="progress-bar-container" *ngIf="showTopLoader">
    <rdc-apps-progress-bar></rdc-apps-progress-bar>
</div>

<ng-container *ngIf="(loading$ | async) === false">
    <rdc-apps-sidebar
        #sidebar
        previewVersion="v4.0"
        appLogo="/assets/rdc-apex/images/apex-logo-full.svg"
        [class.sidebar-open]="this.openMenus.length"
        (toggle)="onSidebarToggle()"
    >

        <div navigation>

            <rdc-apps-sidebar-item
                icon="n-icon-build"
                activeIcon="n-icon-build-fill"
                type="button"
                label="Query builder"
                [attr.data-sidebar-item]="'table-builder'"
                [expanded]="sidebar.open"
                [isActive]="router.isActive('query-builder/table', false)"
                [menuActive]="openMenus.includes('quick-launch-table')"
                (sideMenuTrigger)="onOpenQuickRunMenu($event, false,'table', 'table')">
            </rdc-apps-sidebar-item>

            <rdc-apps-expandable-sidebar-item
                icon="n-icon-chart"
                [label]="(rdcFeature.NETWORK_MAP | featureEnabled)() ? 'Chart &amp; Map builder' : 'Chart builder'"
                [attr.data-sidebar-item]="'chart-builder'"
                [menuOpen]="sidebarMenuTriggered"
                [isActive]="isChartBuilderActive"
                [expanded]="sidebar.open"
                (toggled)="onToggleMenu($event)"
                (click)="onExpandIfNotExpanded()"
            >

                <rdc-apps-sidebar-item
                    type="button"
                    label="Column, Line & Area"
                    itemId="chart-column"
                    [attr.data-sidebar-item]="'bar-line-area'"
                    [isActive]="router.isActive('query-builder/chart', false)"
                    [menuActive]="openMenus.includes('quick-launch-chart')"
                    [expanded]="sidebar.open"
                    [childItem]="true"
                    (sideMenuTrigger)="onOpenQuickRunMenu($event, true, 'chart', 'bar', 'line', 'area')">
                </rdc-apps-sidebar-item>

                <rdc-apps-sidebar-item
                    type="button"
                    label="Scatter plots"
                    itemId="chart-scatter"
                    [attr.data-sidebar-item]="'scatter-plots'"
                    [isActive]="router.isActive('query-builder/scatter', false)"
                    [menuActive]="openMenus.includes('quick-launch-scatter')"
                    [expanded]="sidebar.open"
                    [childItem]="true"
                    (sideMenuTrigger)="onOpenQuickRunMenu($event, true,'scatter', 'scatter')">
                </rdc-apps-sidebar-item>

                <rdc-apps-sidebar-item
                    *ngIf="(rdcFeature.NETWORK_MAP | featureEnabled)()"
                    type="link"
                    label="Network map"
                    [icon]="'cloud'"
                    [attr.data-sidebar-item]="'network-map'"
                    [isActive]="router.isActive('network/network-map', false)"
                    [menuActive]="router.isActive('network/network-map', false)"
                    [expanded]="sidebar.open"
                    [childItem]="true"
                    (click)="onNetworkMap()"
                ></rdc-apps-sidebar-item>

            </rdc-apps-expandable-sidebar-item>

            <h5>Clouds</h5>

            <ng-container *ngFor="let cloud of clouds">

                <!-- TEMP FOR VIZZLY -->
                <ng-container *ngIf="env.testing">
                    <ng-container [ngSwitch]="cloud.label">
                        <rdc-apps-cloud-item
                            *ngSwitchCase="'Sustainability'"
                            [label]="cloud.label"
                            [icon]="cloud.icon"
                            [activeIcon]="cloud.activeIcon"
                            [cloudActive]="cloud.active"
                            [cloudOnboarded]="cloud.onboarded"
                            [cloudContent]="cloud.content"
                            [cdkMenuTriggerFor]="sustainabilityMenu"
                            [cdkMenuPosition]="overlayPositions.get('right')"
                            [isActive]="router.isActive(cloud.route[1], false)"
                            [expanded]="sidebar.open"
                        ></rdc-apps-cloud-item>

                        <rdc-apps-cloud-item
                            *ngSwitchDefault
                            [label]="cloud.label"
                            [route]="cloud.route"
                            [icon]="cloud.icon"
                            [activeIcon]="cloud.activeIcon"
                            [cloudActive]="cloud.active"
                            [cloudOnboarded]="cloud.onboarded"
                            [cloudContent]="cloud.content"
                            [expanded]="sidebar.open"
                            [sidebarMenuOpen]="sidebarMenuOpen"
                            [sidebarMenuTriggered]="sidebarMenuTriggered"
                            [isActive]="router.isActive(cloud.route[1], false)"
                        ></rdc-apps-cloud-item>
                    </ng-container>
                </ng-container>

                <!-- TEMP FOR VIZZLY -->
                <ng-container *ngIf="env.development || env.production">
                    <rdc-apps-cloud-item
                        [label]="cloud.label"
                        [route]="cloud.route"
                        [icon]="cloud.icon"
                        [activeIcon]="cloud.activeIcon"
                        [cloudActive]="cloud.active"
                        [cloudOnboarded]="cloud.onboarded"
                        [cloudContent]="cloud.content"
                        [expanded]="sidebar.open"
                        [sidebarMenuOpen]="sidebarMenuOpen"
                        [sidebarMenuTriggered]="sidebarMenuTriggered"
                        [isActive]="router.isActive(cloud.route[1], false)"
                    ></rdc-apps-cloud-item>
                </ng-container>

            </ng-container>


        </div>

        <div footer>

            <hr>

            <a href="mailto:support@rdcaviation.com">
                <rdc-apps-sidebar-item
                    role="support"
                    icon="n-icon-support"
                    type="button"
                    label="Contact support"
                    [expanded]="sidebar.open">
                </rdc-apps-sidebar-item>
            </a>

            <rdc-apps-sidebar-item
                role="support"
                type="button"
                icon="n-icon-apps"
                label="RDC apps"
                [expanded]="sidebar.open"
                [menuActive]="openMenus.includes('app-drawer')"
                (sideMenuTrigger)="onOpenAppDrawer($event)">
            </rdc-apps-sidebar-item>

            <ng-container *ngIf="showRoutePerformanceLink">
                <hr>
                <ng-container *ngTemplateOutlet="legacyApexLink; context: { experienceType: 'full' }"></ng-container>
            </ng-container>

            <ng-container *ngIf="showBackToApexLink">
                <ng-container *ngTemplateOutlet="legacyApexLink; context: { experienceType: 'limited' }"></ng-container>
            </ng-container>

            <ng-template #legacyApexLink let-experienceType="experienceType">
                <a [href]="experienceType === 'full' ? env.rdcApps.apexRoutePerformance : env.rdcApps.apexLegacy" target="_blank">
                    <rdc-apps-sidebar-item
                        type="button"
                        role="support"
                        icon="n-icon-classic"
                        [label]="experienceType === 'full' ? 'Route Performance' : 'Back to Apex classic'"
                        [expanded]="sidebar.open"
                        (click)="onLegacyApex()"
                    ></rdc-apps-sidebar-item>
                </a>
            </ng-template>

        </div>

        <div footer-settings>

            <rdc-apps-sidebar-item
                #accountMenuTrigger="cdkMenuTriggerFor"
                type="button"
                icon="n-icon-gear"
                role="account"
                label="Settings"
                [attr.title]="userDetails?.email"
                [accountEmail]="userDetails?.email || ''"
                [cdkMenuTriggerFor]="accountMenu"
                [menuActive]="accountMenuTrigger.isOpen()"
                [cdkMenuPosition]="overlayPositions.get('right')"
                [expanded]="sidebar.open"
                (click)="onSettingsMenu()"
            ></rdc-apps-sidebar-item>

        </div>

    </rdc-apps-sidebar>

    <!-- main app content -->
    <main #main [class.no-scroll]="showOverlayLoader">
        <rdc-apps-loading-overlay
            *ngIf="showOverlayLoader"
            position="absolute">
        </rdc-apps-loading-overlay>

        <ng-scrollbar
            track="all"
            visibility="hover"
            autoHeightDisabled="false"
            [class.on-error-page]="onErrorPage"
        >
            <rdc-apps-breadcrumb
                *ngIf="showBreadcrumb"
                id="breadcrumb"
                [breadcrumbIconBgColour]="breadcrumbIconBgColour"
            ></rdc-apps-breadcrumb>

            <router-outlet></router-outlet>
        </ng-scrollbar>

    </main>
</ng-container>

<!-- Menu for account -->
<ng-template #accountMenu>
    <div cdkMenu class="cdk-from-center-left with-margin account-menu">
        <div class="tips-container">
            <div class="tips">
                <label class="rdc-checkbox">
                    <span>Show tooltips</span>
                    <div class="rdc-switch small">
                        <input
                            type="checkbox"
                            [checked]="tooltips.enabled"
                            (change)="tooltips.enabled = !tooltips.enabled"
                        >
                        <span class="rdc-slider round"></span>
                    </div>
                </label>
            </div>
        </div>
        <a rdcAppsMenuItem
           cdkMenuItem
           (click)="onOpenSettings()"
        >
            <span>Settings</span>
        </a>
<!--        <a-->
<!--            rdcAppsMenuItem-->
<!--            cdkMenuItem-->
<!--            (click)="onLogOut()"-->
<!--        >-->
<!--          <rdc-apps-icon size="xs">power_rounded</rdc-apps-icon>-->
<!--          <span>Log off</span>-->
<!--        </a>-->
    </div>
</ng-template>

<ng-template #newVersionDialog>
    <div class="update-apex-dialog">
        <p>We've made some changes. Update Apex now to see them.</p>
        <button class="dismiss" rdcAppsButton display="compact" (click)="onDismissUpdate()">Not now</button>
        <button rdcAppsButton display="compact" colour="primary" (click)="onUpdateApex()">Update Apex</button>
    </div>
</ng-template>

<ng-template #sustainabilityMenu>
    <div cdkMenu class="cdk-from-center-left with-margin">
        <a rdcAppsMenuItem cdkMenuItem [routerLink]="['/', 'sustainability' ]">
            <span>Home</span>
        </a>
        <a rdcAppsMenuItem cdkMenuItem [routerLink]="['/', 'sustainability', 'airline' ]">
            <span>Airline</span>
        </a>
        <a rdcAppsMenuItem cdkMenuItem [routerLink]="['/', 'sustainability', 'airframe' ]">
            <span>Airframe</span>
        </a>
        <a rdcAppsMenuItem cdkMenuItem [routerLink]="['/', 'sustainability', 'benchmark' ]">
            <span>Benchmark</span>
        </a>
    </div>
</ng-template>
