import { map, Observable } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { RdcResponseDto } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

import { QueryEntity } from './query.models';

@Injectable({ providedIn: 'root' })
export class QueriesService extends RdcBaseHttpService {

    constructor(
        @Inject(appEnvironment) private env: AppEnvironment
    ) {
        super();
    }

    save(query: QueryEntity): Observable<RdcResponseDto<{ studyId: string }>> {

        // eslint-disable-next-line max-len
        return this.http.put<RdcResponseDto<{ studyId: string }>>(`${this.env.api.baseUrl}/Study/save`, query, { headers: this.getTokenHeaders() })
            .pipe(
                map((res) => res)
            );
    }

    delete(studyId: string): Observable<RdcResponseDto<void>> {

        // eslint-disable-next-line max-len
        return this.http.delete<RdcResponseDto<void>>(`${this.env.api.baseUrl}/Study/${studyId}`, { headers: this.getTokenHeaders() })
            .pipe(
                map((res) => res)
            );
    }

    get(): Observable<QueryEntity[]> {
        // eslint-disable-next-line max-len
        return this.http.get<RdcResponseDto<{ studies: QueryEntity[] }>>(`${this.env.api.baseUrl}/Study/load`, { headers: this.getTokenHeaders() })
            .pipe(
                map((resp) => resp.success.data.studies)
            );
    }
}
