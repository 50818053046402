import { catchError, of, take } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { inject, Pipe, PipeTransform, Signal, signal } from "@angular/core";
import { appEnvironment } from "@rdc-apps/rdc-apex/src/lib/shared/environment";
import { RdcFeature } from "@rdc-apps/rdc-shared/src/lib/constants";

@Pipe({ name: 'featureEnabled', standalone: true })
export class FeatureFlagEnabledPipe implements PipeTransform {

    private readonly env = inject(appEnvironment);
    private readonly httpClient = inject(HttpClient);

    private enabled = signal(false);

    transform(feature: RdcFeature): Signal<boolean> {

        this.httpClient.get<{ features: { name: string; enabled: boolean }[] }>(`${ this.env.api.featureUrl }/ui/feature-flags/apex-ng`)
            .pipe(
                take(1),
                catchError(() => of({
                    features: [
                        { name: feature, enabled: false },
                    ]
                })),
            )
            .subscribe(({ features }) => {

                const featureFlag = features.find(({ name }) => name === feature);

                this.enabled.set(!!featureFlag?.enabled);
            });

        return this.enabled;
    }

}


