import { of } from "rxjs";

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ENTER_FROM_APEX_CLASSIC } from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import { sendActivity } from '@rdc-apps/rdc-shared/src/lib/data-access/actions';

export const LegacyActivityGuard: CanActivateFn = () => {
    const store = inject(Store);
    const router = inject(Router);

    const legacyRedirectUrl = sessionStorage.getItem('origin');

    if (legacyRedirectUrl?.includes('origin')) {
        router.navigateByUrl(`${legacyRedirectUrl}`);

        store.dispatch(sendActivity({
            activity: {
                activityCode: ENTER_FROM_APEX_CLASSIC,
                detail: {
                    launchSource: 'Classic.New',
                },
            },
        }));

        sessionStorage.removeItem('origin');

        return of(false);
    }

    return of(true);
}
