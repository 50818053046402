import { Overlay } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RdcDialogHelper {

    constructor(private overlay: Overlay) {
    }

    dialogPosition(): any {
        const sidebar = document.getElementById('rdc-sidebar');

        let top = '12.5%';

        if (window.innerHeight <= 760) {
            top = '2.5%';
        }

        return this.overlay
            .position()
            .global()
            .centerHorizontally(`${sidebar?.clientWidth || 0}px`)
            .top(top);
    }

}
