export const getValForTruncatedNumber = (unmodifiedValue: string, rawNumber: number): number => {
    switch (true) {
        case unmodifiedValue.includes('M'): {
            return rawNumber * 1000000;
        }
        case unmodifiedValue.includes('B'): {
            return rawNumber * 1000000000;
        }
        case unmodifiedValue.includes('T'): {
            return rawNumber * 1000000000000;
        }
        case unmodifiedValue.includes('Q'): {
            return rawNumber * 1000000000000000;
        }
        default: {
            return rawNumber;
        }
    }
}
