import { map, Observable } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { RdcResponseDto } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

import { TagEntity, TagsResults } from './tags.models';

@Injectable({ providedIn: 'root' })
export class TagsService extends RdcBaseHttpService {

    constructor(
        @Inject(appEnvironment) private environment: AppEnvironment,
    ) {
        super();
    }

    get(): Observable<TagEntity[]> {
        // eslint-disable-next-line max-len
        return this.http.get<RdcResponseDto<TagsResults>>(`${ this.environment.api.baseUrl }/configurations/user/tag/list`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.results)
            );
    }

    create(tags: TagEntity[]): Observable<TagEntity[]> {
        return this.http.put<RdcResponseDto<TagsResults>>(`${this.environment.api.baseUrl}/configurations/user/tag/upsert`,
            { requests: tags }, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.results)
            );
    }

    delete(ids: string[]): Observable<string> {
        return this.http.delete<RdcResponseDto<TagsResults>>(`${this.environment.api.baseUrl}/configurations/user/tag/delete`, {
            body: {
                tagIds: ids,
            },
            headers: this.getTokenHeaders(),
        }).pipe(
            map((response) => response.success.message)
        );
    }

    search(label: string): Observable<TagEntity[]> {
        return this.http.get<RdcResponseDto<TagsResults>>(`${this.environment.api.baseUrl}/configurations/user/tag/autocomplete`, {
            params: {
                label: label.toLowerCase(),
                limit: 10,
            },
            headers: this.getTokenHeaders(),
        }).pipe(
            map((response) => response.success.data.results)
        );
    }

    updateStudyTags(studyId: string, tags: TagEntity[]): Observable<void> {
        const body = {
            requests: tags.map((tag) => ({
                tagId: tag.id,
                studyId,
            })),
        };

        return this.http.post<RdcResponseDto<void>>(`${this.environment.api.baseUrl}/study/tag`, body, {
            headers: this.getTokenHeaders(),
        }).pipe(
            map((response) => response.success.data)
        );
    }

    createCloudRouteTags(routeId: string, tags: TagEntity[], cloudType: string): Observable<void> {
        const body = {
            requests: tags.map((tag) => ({
                tagId: tag.id,
                routeId,
            })),
        };

        const routeUrl = cloudType === 'growth'
            ? `/GrowthCloud/routes/${routeId}/tag/${tags[0].id}`
            : `/RiskCloud/routes/${routeId}/tag/${tags[0].id}`;

        return this.http.post<RdcResponseDto<void>>(`${this.environment.api.baseUrl}${routeUrl}`, body, {
            headers: this.getTokenHeaders(),
        }).pipe(
            map((response) => response.success.data)
        );
    }

    untag(studyId: string, ids: string[]): Observable<string> {
        const body = {
            requests: ids.map((id) => ({
                tagId: id,
                studyId,
            })),
        };

        return this.http.delete<RdcResponseDto<TagsResults>>(`${this.environment.api.baseUrl}/study/untag`, {
            body,
            headers: this.getTokenHeaders(),
        }).pipe(
            map((response) => response.success.message)
        );
    }

    removeCloudRouteTags(routeId: string, ids: string[], cloudType: string): Observable<string> {
        const body = {
            requests: ids.map((id) => ({
                tagId: id,
                routeId,
            })),
        };

        const routeUrl = cloudType === 'growth'
            ? '/GrowthCloud/routes/untag'
            : '/RiskCloud/routes/untag';

        return this.http.post<RdcResponseDto<TagsResults>>(`${this.environment.api.baseUrl}${routeUrl}`, body,{
            headers: this.getTokenHeaders(),
        }).pipe(
            map((response) => response.success.message)
        );
    }
}
