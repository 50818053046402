import { QueryContent } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';

export const queryContentMap: Record<string, QueryContent[]> = {
    ['Query']: [
        {
            title: 'ONE',
            subtitle: 'Start the universal query builder with fares values that emulate original Apex.',
            image: '/assets/rdc-apex/images/magic.svg',
        },
        {
            title: 'TWO',
            subtitle: 'Start the universal query builder with schedules values that emulate original Apex.',
            image: '/assets/rdc-apex/images/magic.svg',
        },
        {
            title: 'THREE',
            subtitle: 'Unleash the power of our new query builder with this multipoint query.',
            image: '/assets/rdc-apex/images/magic.svg',
        },
        {
            title: 'FOUR',
            subtitle: 'Unleash the power of our new query builder with this multipoint query.',
            image: '/assets/rdc-apex/images/magic.svg',
        },
        {
            title: 'FIVE',
            subtitle: 'Our versatile new chart engine can create a much wider range of useful outputs than original apex.',
            image: '/assets/rdc-apex/images/magic.svg',
        },
        {
            title: 'SIX',
            subtitle: 'Our versatile new chart engine can create a much wider range of useful outputs than original apex.',
            image: '/assets/rdc-apex/images/magic.svg',
        },
    ],
    ['Default']: [
        {
            title: 'Default title',
            subtitle: 'Default subtitle.',
            image: '/assets/rdc-apex/images/magic.svg',
        },
    ],
};
