<ng-container *ngIf="appendToFormGroup?.get('filters.timePeriod.specific') as tpSpecificFormGroup">

    <ng-container *ngIf="tpSpecificFormGroup.get('year') || tpSpecificFormGroup.get('yearStartsMonth')">

        <h6 class="rdc-lined-title">
            <span>Time period options</span>
        </h6>

        <div class="qb-filter-fixed-timeframe">

            <label *ngIf="tpSpecificFormGroup.get('year')">
                <span>Year</span>
                <rdc-apps-select
                    [controlGroup]="tpSpecificFormGroup"
                    controlName="year"
                    [options]="fixedYearOptions">
                </rdc-apps-select>
            </label>

            <label *ngIf="tpSpecificFormGroup.get('yearStartsMonth')">
                <span>Year start month</span>
                <rdc-apps-select
                    [controlGroup]="tpSpecificFormGroup"
                    [disabled]="apexTrialMode$ | async"
                    controlName="yearStartsMonth"
                    [options]="monthsAsOptions">
                </rdc-apps-select>
            </label>

        </div>

    </ng-container>

</ng-container>
