<div class="toggle-pill-container">
    <button
        rdcAppsTooltip
        tooltipText="Expand for options"
        tooltipPosition="below"
        [tooltipDisabled]="isOpen"
        [disabled]="disabled"
        (click)="toggled.emit()"
    >
        <span class="toggle-pill" [ngClass]="{ 'disabled': disabled }">
            <span>{{ isOpen ? 'Close' : 'Options' }}</span>
            <rdc-apps-icon>{{ isOpen ? 'expand_less' : 'expand_more' }}</rdc-apps-icon>
        </span>
    </button>

</div>
