import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FiltersDateRangeEffects } from './+state/filters-date-range.effects';
import * as fromFiltersDateRange from './+state/filters-date-range.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromFiltersDateRange.FILTERS_DATE_RANGE_FEATURE_KEY,
            fromFiltersDateRange.filtersDateRangeReducer
        ),
        EffectsModule.forFeature([ FiltersDateRangeEffects ]),
    ],
})
export class FiltersDateRangeStoreModule {}
