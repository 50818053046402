import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RoutesServedService {

    cancelRouteServed: Subject<void> = new Subject();

    cancelRouteServedRequest(): void {
        this.cancelRouteServed.next();
    }

    storedSelectedRoutes(route: { routeId: string }, cloudType: string) {
        const storedSelectedRoute = localStorage.getItem(`${cloudType}-selectedRoute`);
        if (storedSelectedRoute) {
            if (route.routeId === JSON.parse(storedSelectedRoute).routeId) {
                localStorage.removeItem(`${cloudType}-selectedRoute`);
            }
        }
    }

}
