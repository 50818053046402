import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { ValidatedControlModule } from '@rdc-apps/rdc-shared/src/lib/ui/validated-control';
import { ColorPickerModule } from 'ngx-color-picker';

import { TagManagementItemComponent } from './tag-management-item.component';

@NgModule({
    imports: [ CommonModule, ButtonModule, IconModule, ReactiveFormsModule, ValidatedControlModule, ColorPickerModule ],
    declarations: [ TagManagementItemComponent ],
    exports: [
        TagManagementItemComponent,
    ],
})
export class TagManagementItemModule {}
