export interface Activity {
    activityCode: string;
    detail?: ActivityDetail;
}

export interface ActivityDetail {
    area?: string;
    type?: string;
    launchSource?: string;
    actionSource?: string;
    mode?: string;
    study?: string;
    creationType?: string;
    queryObject?: object;
    cloud?: string;
}
