import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { SidebarItemModule } from '@rdc-apps/rdc-shared/src/lib/ui/sidebar-item';

import { expandCollapse } from './expandable-sidebar-animations';

@Component({
    standalone: true,
    selector: 'rdc-apps-expandable-sidebar-item',
    templateUrl: './expandable-sidebar.component.html',
    styleUrls: [ './expandable-sidebar.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    animations: [
        expandCollapse,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        SidebarItemModule,
    ],
})
export class ExpandableSidebarComponent {

    @Input() label = 'label';
    @Input() expanded = false;
    @Input() menuOpen = false;
    @Input() icon!: string;
    @Input() isActive!: boolean;

    @Output() toggled = new EventEmitter<{ element: HTMLButtonElement; open: boolean }>();

    constructor(public router: Router) {}

    onExpand($event: HTMLButtonElement): void {
        if (!this.expanded && this.menuOpen) {

            this.toggled.emit({
                element: $event,
                open: this.menuOpen,
            });

            return;
        }

        this.menuOpen = !this.menuOpen;

        this.toggled.emit({
            element: $event,
            open: this.menuOpen,
        });
    }
}
