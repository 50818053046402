import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DefaultPreferencesGroupModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/default-preferences-group';
import { DefaultPreferencesItemModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/default-preferences-item';
import { HeaderModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/header';
import { SummaryGroupOrderModule } from '@rdc-apps/rdc-apex/src/lib/shared/pipes/summary-group-order';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { TooltipModule } from "@rdc-apps/rdc-shared/src/lib/directives/tooltip";
import { SelectAutocompleteModule } from "@rdc-apps/rdc-shared/src/lib/ui/select";
import { DisplayCodesComponent } from "display-codes";
import { NgScrollbarModule } from 'ngx-scrollbar';

import { DefaultPreferencesComponent } from './default-preferences.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HeaderModule,
        DefaultPreferencesItemModule, DefaultPreferencesGroupModule,
        ButtonModule,
        SummaryGroupOrderModule,
        NgScrollbarModule, SelectAutocompleteModule,
        NgScrollbarModule, DisplayCodesComponent, TooltipModule,
    ],
    declarations: [ DefaultPreferencesComponent ],
    exports: [
        DefaultPreferencesComponent,
    ],
})
export class DefaultPreferencesModule {}
