import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { dropIn } from '@rdc-apps/rdc-apex/src/lib/shared/animations';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { DialogItemModule } from '@rdc-apps/rdc-shared/src/lib/directives/dialog-item';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';

@Component({
    selector: 'rdc-apps-cloud-popup',
    standalone: true,
    imports: [ CommonModule, ButtonModule, DialogItemModule, IconModule ],
    templateUrl: './cloud-popup.component.html',
    styleUrls: [ './cloud-popup.component.scss' ],
    animations: [ dropIn ],
})
export class CloudPopupComponent {

    @Input() content!: string;

    @Output() closePopup = new EventEmitter();

}
