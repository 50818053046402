<div [ngClass]="{ 'is-expanded': menuOpen && expanded }">

    <rdc-apps-sidebar-item
        type="button"
        [expands]="true"
        [icon]="icon"
        [label]="label"
        [expanded]="expanded"
        [isActive]="isActive"
        [menuActive]="menuOpen && expanded"
        (sideMenuTrigger)="onExpand($event)">
    </rdc-apps-sidebar-item>


    <div class="rdc-sidebar-expand-container" [@expandCollapse]="menuOpen && expanded ? 'expanded' : 'collapsed'">
        <div class="rdc-sidebar-expand-menu">
            <ng-content></ng-content>
        </div>
    </div>

</div>
