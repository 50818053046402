<div class="save-as-dialog">
    <h4>Save query as</h4>

    <button rdcAppsButton
            rdcAppsDialogItem
            display="compact"
            rdcAppsIconButton
            [disabled]="(saving$ | async)"
            [dialogCloseIconBtn]="true"
            type="button">
        <rdc-apps-icon size="sm">close</rdc-apps-icon>
    </button>

    <p>Save your query to return to it later.</p>

    <form (ngSubmit)="onSubmit()" [formGroup]="saveAsForm">

        <rdc-apps-validated-control
            [control]="saveAsForm.get('name')"
            controlName="name"
            [showValidation]="true"
            [validationMessages]="{
                required: 'Name is required',
                inArray: 'Name already in use'
            }">
            <label>
                <span>Query name</span>
                <div class="icon-input">
                    <input type="text" autocomplete="off" formControlName="name">
                    <rdc-apps-icon>edit</rdc-apps-icon>
                </div>
            </label>
        </rdc-apps-validated-control>

        <label class="rdc-checkbox">
            <input type="checkbox" formControlName="pinned">
            <span>Starred</span>
        </label>

        <div class="save-as-controls">
            <button rdcAppsButton
                    rdcAppsDialogItem>
                Cancel
            </button>

            <button rdcAppsButton
                    colour="primary"
                    [disabled]="saveAsForm.invalid || (saving$ | async)"
                    type="submit">
                Save query
            </button>
        </div>
    </form>
</div>
