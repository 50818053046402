import { of } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import {
    executeCloudRouteServedAction,
    queueCloudRouteServedAction
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/actions';
import { CloudRouteServedService } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/services';
import { httpErrorAction } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/error';

@Injectable()
export class CloudRouteServedEffects {

    private actions$ = inject(Actions);
    private cloudRouteServedService = inject(CloudRouteServedService);

    queueServed$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(queueCloudRouteServedAction),
                fetch({
                    run: ({ served, cloudType }) => this.cloudRouteServedService.queueServedRoute(served, cloudType),
                    onError: (action, response) =>
                        of(httpErrorAction({ action, response })),
                })
            )
    );

    executeServed$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(executeCloudRouteServedAction),
                fetch({
                    run: () => this.cloudRouteServedService.executeServedRoute(),
                    onError: (action, response) =>
                        of(httpErrorAction({ action, response })),
                })
            )
    );

}
