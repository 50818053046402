import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { RdcCarrotModule } from '@rdc-apps/rdc-shared/src/lib/directives/rdc-carrot';
import { RdcMenuItemModule } from '@rdc-apps/rdc-shared/src/lib/directives/rdc-menu-item';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { SelectAutocompleteModule } from "@rdc-apps/rdc-shared/src/lib/ui/select";

import { ChartComponent } from './chart.component';

@NgModule({
    imports: [ CommonModule, ButtonModule, CdkMenuModule, RdcMenuItemModule, IconModule, RdcCarrotModule, SelectAutocompleteModule ],
    declarations: [ ChartComponent ],
    exports: [ ChartComponent ],
})
export class ChartModule {}
