import { debounce, firstValueFrom, interval, Observable, Subject, Subscription, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { QbFiltersDateRangeUtils, QueryBuilderUtils } from '@rdc-apps/rdc-apex/src/lib/query-builder/utilities';
import {
    APP_STUDY_BUILDER,
    APP_TEMPLATE_BUILDER,
    hideAircraftGroupCodes,
    rdcTrialYearStart,
    studyBuilderRelativeOpts,
    xAxisOptionsForType,
    xAxisTypeOptions
} from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import { ApexStudy, QueryRequestChartXAxis } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { DataPointsEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points';
import { runApexTrialMode } from "@rdc-apps/rdc-apex/src/lib/shared/data-access/store/user-details";
import { RepoItem, ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { getIsAppLoading } from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';
import { TooltipModule } from '@rdc-apps/rdc-shared/src/lib/directives/tooltip';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { SelectAutocompleteModule } from "@rdc-apps/rdc-shared/src/lib/ui/select";
import { ToastService } from '@rdc-apps/rdc-shared/src/lib/ui/toast';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';

@Component({
    standalone: true,
    selector: 'rdc-apps-chart-builder-x-axis',
    templateUrl: './chart-builder-x-axis.component.html',
    styleUrls: [ './chart-builder-x-axis.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [ CommonModule, ReactiveFormsModule, IconModule, TooltipModule, SelectAutocompleteModule ],
})
export class ChartBuilderXAxisComponent extends RdcComponentUtils implements OnInit {

    @Input() chartRequest!: FormGroup<ApexStudy>;

    @Input() dateRangeUtils!: QbFiltersDateRangeUtils;

    @Input() dataPointsData!: DataPointsEntity | undefined;

    @Output() init = new EventEmitter<void>();

    fullxAxisTypeOptions = xAxisTypeOptions.filter((c) => ![ 'sectorLength' ].includes(c.code as never));

    xAxisTypeOptions = [ ...this.fullxAxisTypeOptions ];

    fullxAxisOptionsForType = new Map(xAxisOptionsForType);

    xAxisOptionsForType: Partial<RepoItem<string>>[] = [];

    relativeOpts = studyBuilderRelativeOpts;

    startYearOpts: Partial<RepoItem<number>>[] = [];
    endYearOpts: Partial<RepoItem<number>>[] = [];

    startMonthOpts: Partial<RepoItem<string>>[] = [];
    endMonthOpts: Partial<RepoItem<string>>[] = [];

    startYearSubscription: Subscription | undefined;

    lastAxisWasTimePeriod = false;

    timePeriodNoLongerValid = new Subject<void>();

    timePeriodToolTipText = 'This determines what months show up on the chart x-axis and constrains the data to your selection';

    apexTrialMode$!: Observable<boolean>;

    constructor(
        private store: Store,
        private fb: FormBuilder,
        protected cdr: ChangeDetectorRef,
        protected toastService: ToastService
    ) {
        super();
    }

    ngOnInit(): void {
        this.apexTrialMode$ = this.store.select(runApexTrialMode('userDetailsState'));

        this.startYearOpts = this.dateRangeUtils.selectableYearsAsRepoItems();

        this.endYearOpts = this.dateRangeUtils.selectableYearsAsRepoItems();

        const dataPointSub = this.getAsFormGroup('dataPoints').valueChanges;

        // on init, remove emissions
        this.xAxisTypeOptions = this.fullxAxisTypeOptions.filter((c) => c.code !== 'emissionsScheme');

        dataPointSub
            .subscribe(async(dataPoints) => {

                const dataPoint = dataPoints.dataPointCodes[0];
                const isSustainabilityDp = QueryBuilderUtils.isInDataPointGroups([ 'sustainability' ], dataPoint, this.dataPointsData);
                const isHideAircraftDp = QueryBuilderUtils.isInDataPointGroups(hideAircraftGroupCodes, dataPoint, this.dataPointsData);

                const xAxis = this.chartRequest.get<string>('queryRequest.chartProperties.xAxis') as FormGroup<QueryRequestChartXAxis>;

                let options = this.fullxAxisTypeOptions.slice();

                if (!dataPoint || !isSustainabilityDp) {
                    options = await this.removeOptionAndNotify('emissionsScheme', options, xAxis);
                }

                if(isHideAircraftDp) {
                    options = await this.removeOptionAndNotify('aircraft', options, xAxis);
                }

                this.xAxisTypeOptions = options;

                if (xAxis?.touched) { // restore state of validity
                    this.cdr.detectChanges();

                    xAxis?.markAllAsTouched();
                }
            });

        this.getAsFormGroup('chartProperties.xAxis.type').valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(async (type: string) => {

                this.timePeriodNoLongerValid.next();

                // add other exceptions to this array
                if ([ 'emissionsScheme' ].includes(type)) {
                    this.xAxisOptionsForType = [];

                    this.getAsFormGroup('chartProperties.xAxis').removeControl('partition');
                } else {
                    this.xAxisOptionsForType = this.fullxAxisOptionsForType.get(type) || [];
                }

                if (!this.xAxisOptionsForType?.length) {
                    return;
                }

                const partitionControl = this.fb.nonNullable.control(this.xAxisOptionsForType[0].code || type, Validators.required);

                if (this.getAsFormGroup('chartProperties.xAxis.partition')) {
                    this.getAsFormGroup('chartProperties.xAxis.partition').patchValue(this.xAxisOptionsForType[0].code || type as any);
                } else {
                    this.getAsFormGroup('chartProperties.xAxis').addControl('partition', partitionControl, { emitEvent: false });
                }

                if (type === 'timePeriod') {
                    this.lastAxisWasTimePeriod = true;

                    const onTrial = await firstValueFrom(this.apexTrialMode$);

                    if(onTrial) {
                        this.handlePartitionSelection('calendarMonth', onTrial);
                    } else {
                        this.addRelativeTimePeriod(); // default action
                    }

                    this.getAsFormGroup('chartProperties.xAxis.partition').valueChanges
                        .pipe(takeUntil(this.timePeriodNoLongerValid))
                        .subscribe(async (partition: string) => {
                            // eslint-disable-next-line no-shadow
                            const trialAccount = await firstValueFrom(this.apexTrialMode$);

                            this.handlePartitionSelection(partition, trialAccount);
                        });

                    this.getAsFormGroup('filters.timePeriod.type').valueChanges
                        .pipe(takeUntil(this.timePeriodNoLongerValid))
                        .subscribe(async () => {
                            // eslint-disable-next-line no-shadow
                            const isOnTrial = await firstValueFrom(this.apexTrialMode$);

                            this.handlePartitionSelection(this.getAsFormGroup('chartProperties.xAxis.partition').value, isOnTrial);
                        });
                } else if (this.lastAxisWasTimePeriod) {
                    this.getAsFormGroup('filters.timePeriod').patchValue({ type: 'specific' });
                }
            });

        this.init.emit();
    }

    private async removeOptionAndNotify(optionCode: string, options: Partial<RepoItem<string>>[], xAxis: FormGroup): Promise<Partial<RepoItem<string>>[]> {

        const isLoading = await firstValueFrom(this.store.select(getIsAppLoading(APP_STUDY_BUILDER, APP_TEMPLATE_BUILDER)));

        if (xAxis?.value.type === optionCode && !isLoading) {

            xAxis.get('type')?.reset();

            this.toastService.simpleToast(ToastType.WARN, 'xAxis and/or series selection has been affected by this change', 5000);
        }

        return options.filter((c) => c.code !== optionCode);
    }

    private addRelativeTimePeriod(): void {
        this.getAsFormGroup('filters.timePeriod').patchValue({ type: 'relative' }, { emitEvent: false });

        this.getAsFormGroup('filters.timePeriod').setControl('relative', this.fb.nonNullable.group({
            relativeMonths: this.fb.nonNullable.control(studyBuilderRelativeOpts[0].code, Validators.required),
        }), { emitEvent: false });
    }

    private handlePartitionSelection(partition: string, onTrial: boolean): void {

        this.startYearSubscription?.unsubscribe();

        if (partition === 'calendarMonth' && this.getAsFormGroup('filters.timePeriod.type')?.value === 'relative') {

            this.getAsFormGroup('filters.timePeriod').setControl('relative', this.fb.nonNullable.group({
                relativeMonths: this.fb.nonNullable.control(studyBuilderRelativeOpts[0].code, Validators.required),
            }));

            return;
        }

        if (partition === 'calendarMonth' && this.getAsFormGroup('filters.timePeriod.type')?.value === 'specific') {

            this.getAsFormGroup('filters.timePeriod').removeControl('relative');

            const defaultYr = onTrial ? rdcTrialYearStart : new Date().getFullYear() - 1;

            const yearValue = this.getAsFormGroup('filters.timePeriod.specific.year')?.value || defaultYr;

            this.startMonthOpts = this.dateRangeUtils.calendarMonthsRepoItems('january');
            this.endMonthOpts = this.dateRangeUtils.calendarMonthsRepoItems('january');

            let newControls: any = {
                startMonth: this.fb.nonNullable.control('january', Validators.required),
                endMonth: this.fb.nonNullable.control('december', Validators.required),
            };

            if (yearValue) {
                newControls = {
                    ...newControls,
                    year: this.fb.nonNullable.control(yearValue, Validators.required),
                };
            }

            this.getAsFormGroup('filters.timePeriod').setControl('specific', this.fb.nonNullable.group(newControls));

            this.cdr.detectChanges();

            this.getAsFormGroup('filters.timePeriod.specific')?.valueChanges
                .pipe(debounce(() => interval(0)))
                .subscribe((specificVals) => {
                    this.startMonthOpts = this.dateRangeUtils.calendarMonthsRepoItems('january', false, specificVals.year, true);

                    const includeNextYr = this.dateRangeUtils.endYear !== specificVals.year;

                    this.endMonthOpts = this.dateRangeUtils.calendarMonthsRepoItems(specificVals.startMonth, includeNextYr, specificVals.year, true);

                    this.cdr.detectChanges();
                });

            return;
        }

        if (partition === 'year') {

            const yearStartValue = this.getAsFormGroup('filters.timePeriod.specific.yearStartsMonth')?.value;

            const defaultYr = onTrial ? rdcTrialYearStart : (new Date().getFullYear() - 1);

            let newControls: any = {
                startYear: this.fb.nonNullable.control(defaultYr, Validators.required),
                endYear: this.fb.nonNullable.control(defaultYr, Validators.required),
            };

            if (yearStartValue) {
                newControls = {
                    ...newControls,
                    yearStartsMonth: this.fb.nonNullable.control(yearStartValue, Validators.required),
                    type: this.fb.nonNullable.control('yearStartsMonth', Validators.required),
                };
            }

            this.getAsFormGroup('filters.timePeriod').setControl('specific', this.fb.nonNullable.group(newControls));

            this.getAsFormGroup('filters.timePeriod').patchValue({ type: 'specific' }, { emitEvent: false });

            this.startYearSubscription = this.getAsFormGroup('filters.timePeriod.specific.startYear')?.valueChanges.subscribe((startYear) => {
                this.endYearOpts = this.dateRangeUtils.selectableYearsAsRepoItems(startYear);
                this.cdr.detectChanges();
            });

            return;
        }

        this.getAsFormGroup('filters.timePeriod').patchValue({ type: 'specific' }, { emitEvent: false });
    }

    getAsFormGroup(path: string): FormGroup {
        return this.chartRequest.get(`queryRequest.${ path }`) as FormGroup;
    }

    get xAxisPartitionValue(): string {
        return this.getAsFormGroup('chartProperties.xAxis.partition')?.value || '';
    }

    getFormGroupValue(path: string): string | null {
        return this.getAsFormGroup(path)?.value;
    }

}
