import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { Actions } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import {
    QueryBuilderFilterAutocompleteComponent
} from '@rdc-apps/rdc-apex/src/lib/query-builder/ui/query-builder-filter-autocomplete';
import {
    StudyTemplateFilterLimits
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/study-templates';
import { TemplateEventService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { SelectAutocompleteModule } from "@rdc-apps/rdc-shared/src/lib/ui/select";
import { ToastService } from '@rdc-apps/rdc-shared/src/lib/ui/toast';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
    standalone: true,
    selector: 'rdc-apps-template-query-filters',
    templateUrl: './template-query-filters.component.html',
    styleUrls: [ './template-query-filters.component.scss' ],
    imports: [ CommonModule, IconModule, ButtonModule, NgScrollbar, SelectAutocompleteModule ],
})
export class TemplateQueryFiltersComponent extends QueryBuilderFilterAutocompleteComponent implements OnChanges {

    // show all by default
    @Input() editableAreas = 'filters.origin.destination.airline.aircraft';

    // disabled filters are hidden from the user
    @Input() hideDisabled = true;

    @Input() filterLimits: StudyTemplateFilterLimits[] | undefined = [];

    displayFilterIndex = 0;

    showFilterControls: string[] = [];

    locked = false;

    constructor(
        store: Store,
        toastService: ToastService,
        actions: Actions,
        public templateEventService: TemplateEventService
    ) {
        super(store, toastService, actions);
    }

    ngOnChanges(): void {

        this.showFilterControls = this.editableAreas
            .replaceAll(/\[.+?\]/g, '')
            .split('.')
            .slice(1);

        const limit = this.filterLimits?.find(
            ({ filter }) => this.showFilterControls.some((ctrl) => filter.includes(ctrl))
        );

        this.locked = !!limit?.isLocked;

        const indexes = this.editableAreas.match(/\d/g) || [ '0' ];

        this.displayFilterIndex = Number(indexes[0]);
    }

    onUserSelect(filter: string, selected: Partial<RepoItem<unknown>>): void {
        this.templateEventService.progress();

        this.onSelect(filter, selected);
    }

    override onSelect(filter: string, selected: Partial<RepoItem<unknown>>) {

        const selectedArray = this.filtersFormGroup<FormArray>(filter, 'selected');

        if (selectedArray?.at(this.displayFilterIndex)) {

            selectedArray?.at(this.displayFilterIndex)?.patchValue(selected);

            return;
        }

        selectedArray?.push(new FormControl(selected, Validators.required));
    }

    onAddFilter(key: string): void {

        const showFilterControls = key
            .replaceAll(/\[.+?\]/g, '')
            .split('.')
            .slice(1);

        showFilterControls.forEach((control) => {

            const typeValAll = this.filtersFormGroup<FormArray>(control, 'type').value === 'all';

            const selectedArray = this.filtersFormGroup<FormArray>(control, 'selected');

            const indexes = key.match(/\d/g) || [ '0' ];

            if (!this.disabledControls.includes(control) && !typeValAll) {

                if (selectedArray?.length === 0) {

                    selectedArray?.push(new FormControl(null, Validators.required));
                    selectedArray?.push(new FormControl(null, Validators.required));

                    return;
                }

                if(Number(indexes[0])) {
                    selectedArray?.insert(Number(indexes[0]) + 1, new FormControl(null, Validators.required));

                    return;
                }
                selectedArray?.push(new FormControl(null, Validators.required));
            }
        });
    }

    onRemoveFilter(key: string, removeLast = false): void {

        const showFilterControls = key
            .replaceAll(/\[.+?\]/g, '')
            .split('.')
            .slice(1);

        const indexes = key.match(/\d/g) || [ '0' ];

        let i = -1;

        try {
            i = Number(indexes[0]);
        } catch {
            //
        }

        showFilterControls.forEach((control) => {
            const array = this.filtersFormGroup<FormArray>(control, 'selected');

            if(array) {
                array.removeAt(removeLast ? (array.length - 1) : i);
            }
        });
    }

    override onRemoveSelected(filter: string, index: number) {
        this.showFilterControls.forEach((control) => {
            this.filtersFormGroup<FormArray>(control, 'selected')?.at(index)?.reset(null);
        });
    }

}
