import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { StudyTemplatesEffects } from './+state/study-templates.effects';
import * as studyTemplates from './+state/study-templates.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            studyTemplates.STUDY_TEMPLATE_FEATURE_KEY,
            studyTemplates.studyTemplatesReducer
        ),
        EffectsModule.forFeature([ StudyTemplatesEffects ]),
    ],
})
export class StudyTemplateStoreModule {}
