import { FormArray, FormControl } from "@angular/forms";
import { RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

// eslint-disable-next-line
export enum AppletType {
    BENCHMARK = 'benchmark',
    AIRLINE = 'airline',
    WEAK_ROUTES = 'weak-routes',
    WEAK_BENCHMARK = 'weak-benchmark',
    MARKET = 'market',
    TIME = 'time',
    DESTINATION = 'destination',
}

// eslint-disable-next-line
export enum AppletCloud {
    GROWTH = 'GrowthCloud',
    RISK = 'RiskCloud',
    NETWORK = 'NetworkCloud',
    NETWORK_MAP = 'NetworkMap'
}

export interface AppletTypeCloud {
    Type: FormControl<AppletType>;
    Cloud: FormControl<AppletCloud>;
}

export interface GrowthRiskAppletForm extends AppletTypeCloud {
    PlannedOriginAirport: FormControl<null | RepoItem<string>>;
    BenchmarkAirports: FormArray<FormControl<RepoItem<string>>>;
    FilterContinents: FormArray<FormControl<RepoItem<string>>>;
    FilterCountries: FormArray<FormControl<RepoItem<string>>>;
    TargetAirlines: FormArray<FormControl<RepoItem<string>>>;
    Metric: FormControl<string>;
}

