

<section #sidebar class="rdc-sidebar" id="rdc-sidebar">

    <span class="toggle-container">
        <span class="rdc-sidebar-menu-toggle">
            <rdc-apps-icon
                size="sm"
                rdcAppsTooltip
                tooltipPosition="right"
                [class.slight-right]="!open"
                [tooltipText]="open ? 'Collapse' : 'Expand'"
                (click)="onToggle()"
            >
                {{ open ? 'chevron_left' : 'chevron_right' }}
            </rdc-apps-icon>
        </span>
    </span>

        <div class="rdc-sidebar-inner">

            <div>

                <div class="rdc-sidebar-header-container">

                    <header class="menu-title" [@sideBarLogo]="open ? 'open' : 'closed'" [ngClass]="{ 'closed': !open }">
                        <a [routerLink]="['/','home']">
                            <img class="logo" alt="Apex app" [src]="appLogo">
                        </a>
                    </header>
                </div>

                <div *ngIf="previewVersion" class="preview">
                    <div class="preview-pill">
                        <span *ngIf="open">Preview</span>
                        <span>{{ previewVersion }}</span>
                    </div>
                </div>

            </div>

            <hr>

            <ng-scrollbar
                track="vertical"
                visibility="hover"
                appearance="standard"
                viewClass="rdc-ng-scroll-aside"
                [autoHeightDisabled]="false">

                <nav>
                    <ng-content select="[navigation]"></ng-content>
                </nav>

            </ng-scrollbar>

            <footer>
                <ng-content select="[footer]"></ng-content>
                <hr>
                <ng-content select="[footer-settings]"></ng-content>
            </footer>

        </div>

</section>
