import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RdcButtonDirective } from './button.directive';
import { RdcIconButtonDirective } from './icon-button.directive';

@NgModule({
    declarations: [
        RdcButtonDirective,
        RdcIconButtonDirective,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        RdcButtonDirective,
        RdcIconButtonDirective,
    ],
})
export class ButtonModule {}
