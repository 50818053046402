import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DpFilterPipe } from './dp-filter.pipe';

@NgModule({
    declarations: [ DpFilterPipe ],
    imports: [ CommonModule ],
    exports: [ DpFilterPipe ],
})
export class DpFilterModule {}
