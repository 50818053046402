<ng-container *ngIf="!hideFullTimeFrame && filtersFormGroup.get('timePeriod')" [formGroup]="filtersFormGroup">

    <h6 *ngIf="!basicBuilder" class="rdc-lined-title" style="margin-top: -8px;">
        <span>Time period options</span>
    </h6>

    <div class="query-filters-timeframe" [ngClass]="{ 'simple-builder ': basicBuilder }" formGroupName="timePeriod">

        <section class="timeframe-options">

            <div class="timeframe">
                <h6>Timeframe</h6>
                <div>
                    <label class="rdc-checkbox" [class.disabled]="apexTrialMode$ | async">
                        <input *ngIf="(apexTrialMode$ | async) === false else placeholder" type="radio" formControlName="type" value="relative" (click)="onTimePeriodType('relative')">
                        <ng-template #placeholder>
                            <input type="radio">
                        </ng-template>
                        <span>Relative</span>
                    </label>
                    <label class="rdc-checkbox">
                        <input formControlName="type" name="type" type="radio" value="specific" (click)="onTimePeriodType('specific')">
                        <span>Fixed</span>
                    </label>
                </div>
            </div>

            <div *ngIf="filtersFormGroup.get('timePeriod.relative')" formGroupName="relative" class="specifics relative">
                <label class="specific-to">
                    <span>Relative to:</span>
                    <rdc-apps-select
                        [controlGroup]="getAsFormGroup('timePeriod.relative')"
                        controlName="relativeMonths"
                        [options]="relativeMonthOpts | displayOrder">
                    </rdc-apps-select>
                </label>
            </div>

            <ng-container *ngIf="!basicBuilder">
                <ng-template *ngTemplateOutlet="timePeriodSpecifics; context: { filtersFormGroup: filtersFormGroup, controlName: 'type' }"></ng-template>
            </ng-container>

            <div *ngIf="filtersFormGroup.get('timePeriod.specific')" formGroupName="specific" class="specifics">

                <div class="specific-years-months">

                    <label>
                        <span>{{ basicBuilder ? 'From:' : 'Start year:' }}</span>
                        <rdc-apps-select
                            [controlGroup]="getAsFormGroup('timePeriod.specific')"
                            controlName="startYear"
                            [options]="selectableStartYearOptions">
                        </rdc-apps-select>
                    </label>

                    <label *ngIf="filtersFormGroup.get('timePeriod.specific.startMonth')">
                        <span>{{ basicBuilder ? '' : 'Start month:' }}</span>
                        <rdc-apps-select
                            [controlGroup]="getAsFormGroup('timePeriod.specific')"
                            controlName="startMonth"
                            [options]="selectableSpecificStartMonths">
                        </rdc-apps-select>
                    </label>

                    <label>
                        <span>{{ basicBuilder ? 'To:': 'End year:' }}</span>
                        <rdc-apps-select
                            [controlGroup]="getAsFormGroup('timePeriod.specific')"
                            controlName="endYear"
                            [options]="selectableEndYearOptions">
                        </rdc-apps-select>
                    </label>

                    <label *ngIf="filtersFormGroup.get('timePeriod.specific.endMonth')">
                        <span>{{ basicBuilder ? '' : 'End month:' }}</span>
                        <rdc-apps-select
                            [controlGroup]="getAsFormGroup('timePeriod.specific')"
                            controlName="endMonth"
                            [options]="selectableSpecificEndMonths">
                        </rdc-apps-select>
                    </label>

                </div>

            </div>

            <ng-container *ngIf="basicBuilder">
                <ng-template *ngTemplateOutlet="timePeriodSpecifics; context: { filtersFormGroup: filtersFormGroup, controlName: 'timePeriod' }"></ng-template>
            </ng-container>

        </section>


        <section formGroupName="specific" *ngIf="filtersFormGroup.get('timePeriod.specific')">

            <ng-container *ngFor="let option of specificTimeFrameOptions">
                <div *ngIf="filtersFormGroup.get('timePeriod.specific.' + option.code)">
                    <h6 class="rdc-lined-title">
                        <span>What to show</span>
                    </h6>

                    <div [formGroupName]="option.code" class="specific-select-to-show">
                        <label *ngFor="let ctrl of (getSpecificOptionControls(option.code) | keyvalue : keepOrder)" class="rdc-checkbox">
                            <input [formControlName]="ctrl.key" type="checkbox">
                            <ng-container [ngSwitch]="ctrl.key">
                                <span *ngSwitchCase="'q1'">Q1 (Jan - Mar)</span>
                                <span *ngSwitchCase="'q2'">Q2 (Apr - Jun)</span>
                                <span *ngSwitchCase="'q3'">Q3 (Jul - Sep)</span>
                                <span *ngSwitchCase="'q4'">Q4 (Oct - Dec)</span>
                                <span *ngSwitchDefault>{{ ctrl.key | titlecase }}</span>
                            </ng-container>
                        </label>

                    </div>
                </div>

            </ng-container>

        </section>

    </div>
</ng-container>

<ng-template #timePeriodSpecifics let-filtersFormGroup="filtersFormGroup" let-controlName='controlName'>
    <ng-container [formGroup]="filtersFormGroup">
        <div class="specifics-select" [class.basic]="basicBuilder">
            <label class="specific-to" [ngClass]="{ basicBuilder: 'show-by' }">
                <span *ngIf="filtersFormGroup.value.timePeriod.type !== 'relative' || basicBuilder">{{ basicBuilder ? 'Show by' : 'Specific to' }}:</span>
                <ng-container *ngIf="getAsFormGroup('timePeriod.specific') || basicBuilder">
                    <rdc-apps-select
                        *ngIf="appendToFormGroup.get('summaries') as summariesGroup"
                        [controlGroup]="basicBuilder ? summariesGroup : getAsFormGroup('timePeriod.specific')"
                        [controlName]="controlName"
                        [options]="basicBuilder ? showByOpts : specificTimeFrameOptions"
                        (valueSelected)="onSelectedTimePeriod($event)"
                    ></rdc-apps-select>
                </ng-container>
            </label>
        </div>
    </ng-container>
</ng-template>
