import { Observable, of } from 'rxjs';

import { Dialog, DIALOG_DATA } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Router, RouterLinkWithHref } from '@angular/router';
import { Store } from '@ngrx/store';
import { TemplatedChartBuilderComponent } from '@rdc-apps/rdc-apex/src/lib/home/ui/templated-chart-builder';
import { TabItemModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/tab-item';
import { TabsModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/tabs';
import { APP_STUDY_TEMPLATES } from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import {
    fetchStudyTemplates,
    getStudyTemplates, StudyTemplate
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/study-templates';
import { CompactSavedQueriesMenuModule } from '@rdc-apps/rdc-apex/src/lib/shared/ui/compact-saved-queries-menu';
import { QueryBuilderQuickLauncherComponent } from '@rdc-apps/rdc-apex/src/lib/shell/ui/query-builder-quick-launcher';
import { getIsAppLoading } from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { DialogItemModule } from '@rdc-apps/rdc-shared/src/lib/directives/dialog-item';
import { TooltipModule } from "@rdc-apps/rdc-shared/src/lib/directives/tooltip";
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { LoadingOverlayComponent } from '@rdc-apps/rdc-shared/src/lib/ui/loading-overlay';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
    standalone: true,
    selector: 'rdc-apps-chart-builder-quick-launcher',
    templateUrl: './chart-builder-quick-launcher.component.html',
    styleUrls: [ './chart-builder-quick-launcher.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [
        CommonModule,
        IconModule,
        ButtonModule,
        TabsModule,
        TabItemModule,
        CompactSavedQueriesMenuModule,
        RouterLinkWithHref,
        DialogItemModule,
        NgScrollbar,
        NgOptimizedImage,
        LoadingOverlayComponent,
        TooltipModule,
    ],
})
export class ChartBuilderQuickLauncherComponent extends QueryBuilderQuickLauncherComponent {

    constructor(
        private dialog: Dialog,
        private overlay: Overlay,
        router: Router,
        store$: Store,
        @Inject(DIALOG_DATA) data: {
            title: string;
            queryCategory: string;
            queryTypes: string[];
        }
    ) {
        super(router, store$, data);

        store$.dispatch(fetchStudyTemplates());

        this.loadingTemplates$ = store$.select(getIsAppLoading(APP_STUDY_TEMPLATES));

        this.templates$ = store$.select(getStudyTemplates(...data.queryTypes));
    }

    templates$: Observable<StudyTemplate[]> = of([]);

    loadingTemplates$: Observable<boolean> = of(true);

    loadTemplate(template: StudyTemplate): void {
        this.dialog.open(TemplatedChartBuilderComponent, {
            width: '750px',
            panelClass: [ 'rdc-dialog' ],
            positionStrategy: this.overlay
                .position()
                .global()
                .centerHorizontally()
                .top('150px'),
            data: {
                template,
                source: 'sidenav'
            },
        });
    }
}
