import { createAction, props } from '@ngrx/store';

import { TagEntity } from './tags.models';

export const getTags = createAction('[Tags Page] Init');

export const getTagsSuccess = createAction(
    '[Tags/API] Get Tags Success',
    props<{ tags: TagEntity[] }>()
);

export const getTagsFailure = createAction(
    '[Tags/API] Get Tags Failure',
    props<{ error: string }>()
);

export const createTags = createAction(
    '[Tags/API] Create Tags',
    props<{ tags: TagEntity[] }>()
);

export const createTagsSuccess = createAction(
    '[Tags/API] Create Tags Success',
    props<{ tags: TagEntity[] }>()
);

export const createTagsFailure = createAction(
    '[Tags/API] Create Tags Failure',
    props<{ error: string }>()
);

export const createCloudRouteTag = createAction(
    '[Tags/API] Create Cloud Route Tags',
    props<{ tags: TagEntity[]; routeId: string, cloudType: string }>()
);

export const createCloudRouteTagSuccess = createAction(
    '[Tags/API] Create Cloud Route Tags Success',
    props<{ tags: TagEntity[]; routeId: string }>()
);

export const deleteTags = createAction(
    '[Tags/API] Delete Tags',
    props<{ tagIds: string[] }>()
);

export const removeTagsFromStudy = createAction(
    '[Tags/API] Remove Tags',
    props<{ tagIds: string[]; studyId: string }>()
);

export const removeTagsSuccess = createAction(
    '[Tags/API] Remove tags success',
    props<{ tagIds: string[]; studyId: string }>()
);

export const removeTagsFromCloudRoute = createAction(
    '[Tags/API] Remove Tags From Cloud Route',
    props<{ tagIds: string[]; routeId: string; cloudType: string }>()
);

export const removeTagsFromCloudRouteSuccess = createAction(
    '[Tags/API] Remove Tags From Cloud Route Success',
    props<{ tagIds: string[]; routeId: string }>()
);

export const tagsFailure = createAction(
    '[Tags/API] Tags failure'
);

export const deleteTagsSuccess = createAction(
    '[Tags/API] Delete Tags Success',
    props<{ tagIds: string[] }>()
);

export const deleteTagsFailure = createAction(
    '[Tags/API] Delete Tags Failure',
    props<{ error: string }>()
);

export const setStudyTags = createAction(
    '[Tags/API] Set study tags',
    props<{ tags: TagEntity[]; studyId: string }>()
);

export const setStudyTagsSuccess = createAction(
    '[Tags/API] Set study tags success',
    props<{ tags: TagEntity[]; studyId: string }>()
);

export const searchTags = createAction(
    '[Tags/API] Search Tags',
    props<{ value: string }>()
);

export const searchTagsSuccess = createAction(
    '[Tags/API] Search Tags Success',
    props<{ tags: TagEntity[] }>()
);

export const searchTagsFailure = createAction(
    '[Tags/API] Search Tags Failure',
    props<{ error: string }>()
);
