import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class CloudPopupService {

    removeRemainingPopups(): void {
        const remainingOverlayRefs = document.getElementsByClassName('rdc-cloud-popup-overlay');
        const htmlCollectionArray = Array.from(remainingOverlayRefs)

        htmlCollectionArray.forEach((htmlElement) => htmlElement.remove());
    }

}
