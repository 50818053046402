import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    APP_LOADING_FEATURE_KEY, AppLoadingState,
    appLoadingAdapter
} from './app-loading.reducer';

// Lookup the 'appLoading' feature state managed by NgRx
export const getAppLoadingState = createFeatureSelector<AppLoadingState>(
    APP_LOADING_FEATURE_KEY
);

const { selectAll } = appLoadingAdapter.getSelectors();

export const getGloballyLoading = createSelector(
    getAppLoadingState,
    (state: AppLoadingState) => selectAll(state).some((entity) => entity.options?.global)
);

export const getGloballyOverlayLoading = createSelector(
    getAppLoadingState,
    (state: AppLoadingState) => selectAll(state).some((entity) => entity.options?.overlay)
);

export const getIsAppLoading = (...keys: string[]) => createSelector(
    getAppLoadingState,
    (state: AppLoadingState) => selectAll(state).some((entity) => keys.includes(entity.key)),
);

export const getLoadingMessage = createSelector(
    getAppLoadingState,
    (state: AppLoadingState) => selectAll(state).pop()?.message || 'Loading...'
);

export const getLoadingToasts = createSelector(
    getAppLoadingState,
    (state: AppLoadingState) => selectAll(state).filter((entity) => entity.options?.toast)
);

export const getLoadingToastMessages = createSelector(
    getAppLoadingState,
    getLoadingToasts,
    (state: AppLoadingState, toasts) => toasts.pop()?.message || false
);

