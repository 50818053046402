import { Observable } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { SalesforceLead } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';

@Injectable({ providedIn: 'root' })
export class SalesforceLeadService extends RdcBaseHttpService {

    constructor(
        @Inject(appEnvironment) private environment: AppEnvironment
    ) {
        super();
    }

    createSalesforceLead(lead: SalesforceLead): Observable<void> {
        return this.http.post<void>(`${this.environment.salesforceLeadUrl}`, lead);
    }
}
