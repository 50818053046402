import { createAction, props } from '@ngrx/store';

import { UpdateUserDetails, UserDetails } from './user-details.models';

export const getUserDetails = createAction('[UserDetails/API] Get');

export const getUserDetailsSuccess = createAction(
    '[UserDetails/API] Get UserDetails Success',
    props<{ userDetails: UserDetails }>()
);

export const getUserDetailsFailure = createAction(
    '[UserDetails/API] Get UserDetails Failure',
    props<{ error: string }>()
);

export const updateUserDetails = createAction(
    '[UserDetails/API] Update',
    props<{ updateUserDetails: UpdateUserDetails }>()
);

export const updateUserDetailsSuccess = createAction(
    '[UserDetails/API] Update UserDetails Success',
    props<{ userDetails: UserDetails }>()
);

export const updateUserDetailsFailure = createAction(
    '[UserDetails/API] Update UserDetails Failure',
    props<{ error: string }>()
);
