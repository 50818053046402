import { map } from 'rxjs';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import {
    clearQueuedActivities,
    executeQueuedActivities,
    queueActivity,
    sendActivity,
    sendActivityFailure,
    sendActivitySuccess,
} from '@rdc-apps/rdc-shared/src/lib/data-access/actions';
import { ActivityService } from '@rdc-apps/rdc-shared/src/lib/data-access/services';

@Injectable()
export class ActivityEffects {
    send$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sendActivity),
            fetch({
                run: ({ activity }) =>
                    this.activityService
                        .send(activity)
                        .pipe(map(() => sendActivitySuccess())),
                onError: () => sendActivityFailure(),
            })
        )
    );

    queue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(queueActivity),
            fetch({
                run: ({ activity }) => this.activityService.queue(activity),
            })
        )
    );

    execute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(executeQueuedActivities),
            fetch({
                run: () => this.activityService.executeQueue(),
            })
        )
    );

    clear$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clearQueuedActivities),
            fetch({
                run: () => this.activityService.clear(),
            })
        )
    );

    constructor(
        private actions$: Actions,
        private activityService: ActivityService
    ) {}
}
