import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from '@rdc-apps/rdc-shared/src/lib/directives/tooltip';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';

import { ValidatedControlComponent } from './validated-control.component';

@NgModule({
    imports: [ CommonModule, TooltipModule, IconModule ],
    declarations: [ ValidatedControlComponent ],
    exports: [ ValidatedControlComponent ],
})
export class ValidatedControlModule {}
