<div class="chart-x-axis-definition"
     [formGroup]="getAsFormGroup('chartProperties')">

    <label>
        <span>Type</span>
        <rdc-apps-select
            controlName="type"
            [options]="xAxisTypeOptions"
            [controlGroup]="getAsFormGroup('chartProperties.xAxis')">
        </rdc-apps-select>
    </label>

    <label *ngIf="getAsFormGroup('chartProperties.xAxis.partition')">
        <span>Show on X-axis</span>
        <rdc-apps-select
            controlName="partition"
            [options]="xAxisOptionsForType"
            [controlGroup]="getAsFormGroup('chartProperties.xAxis')">
        </rdc-apps-select>
    </label>

</div>

<div class="chart-x-axis-definition"
     *ngIf="['calendarMonth', 'year'].includes(xAxisPartitionValue)">

    <div class="chart-x-axis-timeframe"
         *ngIf="xAxisPartitionValue === 'calendarMonth'"
         [formGroup]="getAsFormGroup('filters.timePeriod')">

        <div class="chart-x-axis-timeframe-text">
            <span>Fixed or relative X-axis</span>
            <rdc-apps-icon size="xxs"
                rdcAppsTooltip
                tooltipPosition="right"
                [tooltipText]="timePeriodToolTipText">
                info
            </rdc-apps-icon>
        </div>

        <div class="chart-x-axis-timeframe-options">
            <label class="rdc-checkbox" [class.disabled]="apexTrialMode$ | async">
                <input *ngIf="(apexTrialMode$ | async) === false else placeholder" type="radio" formControlName="type" value="relative">
                <ng-template #placeholder>
                    <input type="radio">
                </ng-template>
                <span>Relative</span>
            </label>

            <label class="rdc-checkbox">
                <input type="radio" formControlName="type" value="specific">
                <span>Fixed</span>
            </label>
        </div>

    </div>

    <ng-container *ngIf="xAxisPartitionValue === 'calendarMonth' && getFormGroupValue('filters.timePeriod.type') === 'relative'">

        <label>
            <span>Relative period</span>
            <rdc-apps-select
                controlName="relativeMonths"
                [options]="relativeOpts"
                [controlGroup]="getAsFormGroup('filters.timePeriod.relative')">
            </rdc-apps-select>
        </label>

    </ng-container>

    <!--  START YEAR END YEAR OPTIONS  -->
    <ng-container *ngIf="xAxisPartitionValue === 'year'">

        <label>
            <span>Start year</span>
            <rdc-apps-select
                controlName="startYear"
                [options]="startYearOpts"
                [controlGroup]="getAsFormGroup('filters.timePeriod.specific')">
            </rdc-apps-select>
        </label>

        <label>
            <span>End year</span>
            <rdc-apps-select
                controlName="endYear"
                [options]="endYearOpts"
                [controlGroup]="getAsFormGroup('filters.timePeriod.specific')">
            </rdc-apps-select>
        </label>

    </ng-container>

    <!--  START MONTH END MONTH OPTIONS  -->
    <ng-container *ngIf="xAxisPartitionValue === 'calendarMonth' && getFormGroupValue('filters.timePeriod.type') === 'specific'">

        <label>
            <span>Start month</span>
            <rdc-apps-select
                controlName="startMonth"
                [options]="startMonthOpts"
                [disabled]="apexTrialMode$ | async"
                [controlGroup]="getAsFormGroup('filters.timePeriod.specific')">
            </rdc-apps-select>
        </label>

        <label>
            <span>End month</span>
            <rdc-apps-select
                controlName="endMonth"
                [options]="endMonthOpts"
                [disabled]="apexTrialMode$ | async"
                [controlGroup]="getAsFormGroup('filters.timePeriod.specific')">
            </rdc-apps-select>
        </label>

    </ng-container>


</div>

