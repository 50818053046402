<header class="quick-launcher-header">
    <h4>{{ data.title }}</h4>

    <button
        rdcAppsButton
        rdcAppsIconButton
        rdcAppsDialogItem
        display="compact"
        rdcAppsTooltip
        tooltipPosition="right"
        [tooltipText]="'Close'"
        [dialogCloseIconBtn]="true"
    >
        <rdc-apps-icon size="sm">close</rdc-apps-icon>
    </button>
</header>

<rdc-apps-tabs display="horizontal">

    <rdc-apps-tab-item heading="New">

        <div class="quick-launcher-query-templates">

            <section class="quick-launcher-section">
                <a
                    rdcAppsButton
                    rdcAppsDialogItem
                    display="compact"
                    colour="primary"
                    class="quick-launcher-new-query"
                    (click)="onNewQuery()"
                >
                    <span>{{ newQueryButtonText }}</span>
                </a>
            </section>

            <div class="quick-launcher-section">
                <h5>From Template</h5>
            </div>

            <div class="quick-launcher-section-template-list">

                <rdc-apps-loading-overlay
                    *ngIf="loadingTemplates$ | async"
                    placeContent="start center">
                </rdc-apps-loading-overlay>

                <ng-scrollbar [autoHeightDisabled]="false" visibility="hover">

                    <div class="quick-launcher-section-template-list-inner">

                        <button *ngFor="let template of (templates$ | async); let index = index"
                                (click)="loadTemplate(template)"
                                rdcAppsDialogItem
                                class="quick-launcher-section-template">
                            <h6>{{ template.label }}</h6>
                            <div class="type">
                                <p>{{ template.templateStudyModel.studyType === 'bar' ? 'Column' : template.templateStudyModel.studyType | titlecase }}</p>
                            </div>
                            <div class="quick-launcher-section-template-icon">
                                <img ngSrc="/assets/rdc-apex/images/{{ template.templateStudyModel.studyType }}-chart-ico.svg" fill>
                            </div>
                        </button>

                    </div>

                </ng-scrollbar>

            </div>

        </div>

    </rdc-apps-tab-item>

    <rdc-apps-tab-item heading="Saved">

        <div class="quick-launcher-queries">

            <rdc-apps-compact-saved-queries-menu
                [queries]="savedQueries$ | async"
                [showActive]="true"
                [limitHeight]="false">
            </rdc-apps-compact-saved-queries-menu>
        </div>

    </rdc-apps-tab-item>

</rdc-apps-tabs>
