export interface SalesforceLead {
    callToAction: string;
    campaignId: string;
    company: string;
    description: string;
    email: string;
    firstName: string;
    lastName: string;
    leadSource: string;
    leadSourceDetail: string;
    productOfInterest: string;
    recordType: string;
}
