import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DropdownOptionsForSummaryPipe } from './dropdown-options-for-summary.pipe';

@NgModule({
    imports: [ CommonModule ],
    declarations: [ DropdownOptionsForSummaryPipe ],
    exports: [ DropdownOptionsForSummaryPipe ],
})
export class DropdownOptionsForSummaryModule {}
