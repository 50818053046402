import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import * as TagActions from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/tags';

import * as RiskActions from './risk.actions';
import { RiskEntity } from './risk.models';


export const RISK_FEATURE_KEY = 'risk';

export interface RiskState extends EntityState<RiskEntity> {
    selectedId?: string | number; // which Risk record has been selected
    loaded: boolean; // has the Risk list been loaded
    error?: string | null; // last known error (if any)
}

export interface RiskPartialState {
    readonly [RISK_FEATURE_KEY]: RiskState;
}

export const riskAdapter: EntityAdapter<RiskEntity> =
    createEntityAdapter<RiskEntity>({
        selectId: (model) => model.routeId,
    });

export const initialRiskState: RiskState = riskAdapter.getInitialState({
    // set initial required properties
    loaded: false,
});

const reducer = createReducer(
    initialRiskState,
    on(
        RiskActions.getRiskRouteAction,
        RiskActions.refreshRiskRouteAction,
        (state) => ({
            ...state,
            loaded: false,
            error: null,
        })),
    on(
        RiskActions.getRiskRouteSuccessAction,
        RiskActions.refreshRiskRouteSuccessAction,
        (state, { routes }) =>
            riskAdapter.setAll(routes, { ...state, loaded: true })
    ),
    on(RiskActions.getRiskRouteFailureAction, (state, { error }) => ({ ...state, error })),
    on(RiskActions.createRiskRouteAction, (state) => ({
        ...state,
        loaded: false,
        error: null,
    })),
    on(RiskActions.createRiskRouteSuccessAction, (state) => ({
        ...state,
        loaded: true,
        error: null,
    })),
    on(RiskActions.deleteRiskRouteAction, (state) => ({
        ...state,
        loaded: false,
        error: null,
    })),
    on(RiskActions.deleteRiskRouteSuccessAction, (state) => ({
        ...state,
        loaded: true,
        error: null,
    })),
    on(TagActions.createCloudRouteTagSuccess, (state, { tags, routeId }) => {
        const route = state.entities[routeId] ;

        if (!route) {
            return state;
        }

        const copiedTags = route.tags.slice();

        copiedTags.push(...tags);

        return riskAdapter.upsertOne({
            ...route,
            tags: copiedTags,
        },{ ...state });
    }),
    on(TagActions.removeTagsFromCloudRouteSuccess, (state, { tagIds, routeId }) => {
        const route = state.entities[routeId];

        if (!route) {
            return state;
        }

        const remaining = route.tags.filter((tag) => !tagIds.includes(tag.id));

        return riskAdapter.upsertOne({
            ...route,
            tags: remaining,
        },{ ...state });
    })
);

export function riskReducer(state: RiskState | undefined, action: Action) {
    return reducer(state, action);
}
