import { Injectable } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { RouteOption } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';

@Injectable({ providedIn: 'root' })
export class IataIcaoCodesService {

    itemSummary(value: RouteOption, array: RouteOption[], index: number): string {
        return array.length > 1 && array.length - 1 !== index
            ? `${value.iataCode},`
            : value.iataCode;
    }

    itemSummaryControl(control: AbstractControl, controlsArray: FormArray, index: number): string {
        return controlsArray.length > 1 && controlsArray.length - 1 !== index
            ? `${control.value.iataCode},`
            : control.value.iataCode;
    }

}
