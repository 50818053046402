import { Component, EventEmitter, HostBinding, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
    selector: 'rdc-apps-tab-item',
    templateUrl: './tab-item.component.html',
    styleUrls: [ './tab-item.component.scss' ],
})
export class TabItemComponent {

    @HostBinding('class.active') activeTab = false;

    @Input() heading!: string;
    @Input() disableFlex!: boolean;
    @Input() disabled = false;
    @Input() additionalHTML!: string;
    @Input() set active(isActive: boolean) {
        this.isActive = isActive;
        this.activeTab = isActive;

        if (this.isActive) {
            this.activated.emit();
        }
    }

    get active(): boolean {
        return this.isActive;
    }

    @Output() activated = new EventEmitter();

    @ViewChild('contentWrapper') contentWrapper!: ViewContainerRef;

    private isActive!: boolean;

}
