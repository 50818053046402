import { map, Observable } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { RdcResponseDto } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

import { RdcTemplateResponse, StudyTemplate } from './study-templates.models';

@Injectable({ providedIn: 'root' })
export class StudyTemplatesService extends RdcBaseHttpService {

    constructor(
        @Inject(appEnvironment) private environment: AppEnvironment
    ) {
        super();
    }

    get(): Observable<StudyTemplate[]> {
        return this.http.get<RdcResponseDto<RdcTemplateResponse>>(`${ this.environment.api.baseUrl }/Template`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.templates
            ));
    }

}
