import { createAction, props } from '@ngrx/store';

import { UserSettings } from './user-settings.models';

export const getUserSettings = createAction(
    '[UserSettings] Get User Settings'
);

export const getUserSettingsSuccess = createAction(
    '[UserSettings] Get User Settings Success',
    props<{ userSettings: UserSettings }>()
);

export const getUserSettingsFailure = createAction(
    '[UserSettings] Get User Settings Failure',
    props<{ error: string }>()
);

export const setUserSettings = createAction(
    '[UserSettings] Set User Settings',
    props<{ userSettings: UserSettings }>()
);

export const setUserUserSettingsSuccess = createAction(
    '[UserSettings] Set User Settings Success',
    props<{ userSettings: UserSettings }>()
);
