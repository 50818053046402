import { map } from 'rxjs';

import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { getTags } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/tags';
import { getUserDetails } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/user-details';
import { getUserSettings } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/user-settings';
import { OidcSecurityService } from 'angular-auth-oidc-client';

export const AuthorizationGuard: CanActivateFn = (
    _,
    state: RouterStateSnapshot
) => {
    const store = inject(Store);
    const router: Router = inject(Router);
    const oidcSecurityService = inject(OidcSecurityService);

    return oidcSecurityService.isAuthenticated$
        .pipe(
            map(({ isAuthenticated }) => {

                if (!isAuthenticated) {
                    // set url session
                    sessionStorage.setItem('origin', `${state.url}`);

                    router.navigate([ 'authorize' ]);

                    return false;
                }

                store.dispatch(getTags());
                store.dispatch(getUserDetails());
                store.dispatch(getUserSettings());

                return true;
        })
    );
};
