<ng-container *ngIf="dataPointsData" [formGroup]="form">

    <section class="qb-summaries">

        <ng-container *ngIf="queryType !== 'scatter'">

            <ng-container *ngFor="let group of dataPointsData?.summaryGroups | displayOrder" formGroupName="summaries">

                <ng-container *ngIf="groupHasControls('summaries', group.summaries) && (group.summaries | displayOrder) as orderedSummaries">
                    <ng-template
                        [ngTemplateOutlet]="summaryOutputIterator"
                        [ngTemplateOutletContext]="{
                        orderedOptions: orderedSummaries,
                        groupIndex: -1,
                        iteratorGroup: form.get('summaries')
                    }">
                    </ng-template>
                </ng-container>

            </ng-container>

        </ng-container>

        <div class="rdc-lined-title" (click)="additionalOptsOpen = !additionalOptsOpen">
            <div class="rdc-lined-title-text">
                <rdc-apps-icon size="sm">{{ additionalOptsOpen ? 'expand_more' : 'chevron_right' }}</rdc-apps-icon>
                <span>Additional options</span>
            </div>
        </div>

        <div [hidden]="!additionalOptsOpen">

            <p class="additional-options-preferences">These options can be applied as defaults in your <strong (click)="onOpenSettings()">Defaults & preferences</strong></p>

            <div style="display: flex; flex-direction: column;">
                <ng-container formGroupName="adjustments">

                    <ng-container *ngFor="let group of outputGroups | displayOrder; let groupIndex = index;">

                        <ng-container *ngIf="groupHasControls('adjustments', group.outputs) && (group.outputs | displayOrder) as orderedOutputs;">
                            <ng-template
                                [ngTemplateOutlet]="summaryOutputIterator"
                                [ngTemplateOutletContext]="{
                                    orderedOptions: orderedOutputs,
                                    groupIndex,
                                    iteratorGroup: form.get('adjustments')
                            }">
                            </ng-template>
                        </ng-container>

                    </ng-container>

                </ng-container>
            </div>

        </div>

    </section>
</ng-container>


<ng-template #summaryOutputIterator let-orderedOptions="orderedOptions" let-groupIndex="groupIndex" let-formGroup="iteratorGroup">

    <div class="qb-summaries-group">

        <ng-container *ngFor="let outputSummary of orderedOptions; let index = index;" [formGroup]="formGroup">

            <div *ngIf="groupIndex === 2 && index === 0" class="qb-summaries-select" style="margin-top: 12px;">
                <rdc-apps-display-codes
                    [form]="form"
                    controlName="adjustments"
                ></rdc-apps-display-codes>
            </div>

            <div class="qb-summaries-select"
                 *ngIf="formGroup.get(outputSummary.code) && !hideOutputs.includes(outputSummary.code)"
                 [class.checkbox]="checkboxCodes.includes(outputSummary.code)"
                 [ngSwitch]="outputSummary.code">

                <ng-container *ngFor="let itemCode of checkboxCodes">
                    <label *ngSwitchCase="itemCode" class="rdc-checkbox">
                        <input type="checkbox" [formControlName]="outputSummary.code">
                        <span>{{ outputSummary.label }}</span>
                    </label>
                </ng-container>

                <label *ngSwitchCase="'currency'">
                    <span>{{ outputSummary.label }}</span>
                    <rdc-apps-select
                        [controlGroup]="formGroup"
                        [filterable]="true"
                        controlName="currency"
                        [options]="dataPointsData?.dropdowns?.currencies || []">
                    </rdc-apps-select>
                </label>

                <ng-container *ngFor="let itemCode of filterDropDowns">
                    <label *ngSwitchCase="itemCode">
                        <span>{{ outputSummary.label }}</span>
                        <rdc-apps-select
                            [controlGroup]="formGroup"
                            [filterable]="false"
                            [controlName]="itemCode"
                            [options]="outputSummary | dropdownsForSummary : dataPointsData?.dropdowns?.dropdownOptionGroups | displayOrder">
                        </rdc-apps-select>
                    </label>
                </ng-container>

                <label *ngSwitchDefault>
                    <span>{{ outputSummary.label }}</span>
                    <div class="icon-input">
                        <rdc-apps-select
                            [controlGroup]="formGroup"
                            [controlName]="outputSummary.code"
                            [options]="outputSummary | dropdownsForSummary : dataPointsData?.dropdowns?.dropdownOptionGroups | displayOrder">
                        </rdc-apps-select>
                    </div>
                </label>

            </div>
        </ng-container>
    </div>

</ng-template>
