import { DialogModule } from '@angular/cdk/dialog';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CloudItemComponent } from '@rdc-apps/rdc-apex/src/lib/shell/ui/cloud-item';
import { QueryBuilderQuickLauncherModule } from '@rdc-apps/rdc-apex/src/lib/shell/ui/query-builder-quick-launcher';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { RdcMenuItemModule } from '@rdc-apps/rdc-shared/src/lib/directives/rdc-menu-item';
import { TooltipModule } from '@rdc-apps/rdc-shared/src/lib/directives/tooltip';
import { ExpandableSidebarComponent } from '@rdc-apps/rdc-shared/src/lib/ui/expandable-sidebar-item';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { LoadingOverlayComponent } from '@rdc-apps/rdc-shared/src/lib/ui/loading-overlay';
import { ProgressBarModule } from '@rdc-apps/rdc-shared/src/lib/ui/progress-bar';
import { SidebarModule } from '@rdc-apps/rdc-shared/src/lib/ui/sidebar';
import { SidebarItemModule } from '@rdc-apps/rdc-shared/src/lib/ui/sidebar-item';
import { ToastModule } from '@rdc-apps/rdc-shared/src/lib/ui/toast';
import { BreadcrumbComponent } from "breadcrumb";
import { FeatureFlagEnabledPipe } from "feature-flag";
import { NgScrollbarModule } from 'ngx-scrollbar';

import { ApexLayoutComponent } from './apex-layout.component';
import { HasCloudAccessPipe } from "./has-cloud-access.pipe";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SidebarModule,
        RdcMenuItemModule,
        CdkMenuModule,
        IconModule,
        ToastModule,
        TooltipModule,
        DialogModule,
        QueryBuilderQuickLauncherModule,
        ButtonModule,
        ReactiveFormsModule,
        SidebarItemModule,
        ProgressBarModule,
        ExpandableSidebarComponent,
        LoadingOverlayComponent,
        NgScrollbarModule,
        CloudItemComponent,
        FeatureFlagEnabledPipe,
        HasCloudAccessPipe,
        BreadcrumbComponent,
    ],
    providers: [
        FeatureFlagEnabledPipe,
        HasCloudAccessPipe
    ],
    declarations: [
        ApexLayoutComponent,
    ],
    exports: [
        ApexLayoutComponent,
    ],
})
export class ApexLayoutModule {}
