import { appLoading } from './app-loading.actions';

export class AppLoadStatus {
    static loading(key: string, message?: string, options?: LoadingEntityOptions): { type: string; loading: LoadingEntity } {
        return appLoading({
            loading: { key, message, options },
        });
    }
}

export interface LoadingEntity {
    key: string;
    message?: string;
    options?: LoadingEntityOptions;
}

export interface LoadingEntityOptions {
    global?: boolean;
    overlay?: boolean;
    toast?: boolean;
}
