<ng-container *ngIf="showCompleteTimePeriodSelection else minorSelection">

    <span class="rdc-lined-title">Time period options</span>

    <div class="rdc-query-full-time-period-selection"
         [ngClass]="{ 'years-only': specificToTypeValue && specificToTypeValue !== 'months' }"
         [formGroup]="timePeriodFormGroup()">

        <div class="rdc-query-full-time-period-selection-timeframe">
            <span>Timeframe</span>

            <div class="rdc-query-full-time-period-selection-timeframe-options">
                <label class="rdc-checkbox" [class.disabled]="apexTrialMode$ | async">
                    <input *ngIf="(apexTrialMode$ | async) === false else placeholder" type="radio" formControlName="type" value="relative">
                    <ng-template #placeholder>
                        <input type="radio">
                    </ng-template>
                    <span>Relative</span>
                </label>

                <label class="rdc-checkbox">
                    <input type="radio" value="specific" formControlName="type">
                    <span>Fixed</span>
                </label>
            </div>
        </div>


        <label
            class="rdc-query-full-time-period-selection-specific-to"
            *ngIf="timePeriodFormGroup('relative')">
            <span>Relative period</span>
            <rdc-apps-select
                [controlGroup]="timePeriodFormGroup('relative')"
                controlName="relativeMonths"
                [options]="studyBuilderRelativeOpts">
            </rdc-apps-select>
        </label>


        <label
            class="rdc-query-full-time-period-selection-specific-to"
            *ngIf="timePeriodFormGroup('specific')">
            <span>Specific to</span>
            <rdc-apps-select
                [controlGroup]="timePeriodFormGroup('specific')"
                controlName="type"
                [options]="specificTimeFrameOpts">
            </rdc-apps-select>
        </label>

        <label
            class="rdc-query-full-time-period-selection-start-year"
            *ngIf="timePeriodFormGroup('specific', 'startYear')">
            <span>Start year</span>
            <rdc-apps-select
                controlName="startYear"
                [controlGroup]="timePeriodFormGroup('specific')"
                [options]="startYears">
            </rdc-apps-select>
        </label>

        <label
            class="rdc-query-full-time-period-selection-end-year"
            *ngIf="timePeriodFormGroup('specific', 'endYear')">
            <span>End year</span>
            <rdc-apps-select
                controlName="endYear"
                [controlGroup]="timePeriodFormGroup('specific')"
                [options]="endYears">
            </rdc-apps-select>
        </label>


        <label
            class="rdc-query-full-time-period-selection-start-month"
            *ngIf="timePeriodFormGroup('specific', 'startMonth')">
            <span>Start month</span>
            <rdc-apps-select
                controlName="startMonth"
                [controlGroup]="timePeriodFormGroup('specific')"
                [options]="startMonths">
            </rdc-apps-select>
        </label>

        <label
            class="rdc-query-full-time-period-selection-end-month"
            *ngIf="timePeriodFormGroup('specific', 'endMonth')">
            <span>End month</span>
            <rdc-apps-select
                controlName="endMonth"
                [controlGroup]="timePeriodFormGroup('specific')"
                [options]="endMonths">
            </rdc-apps-select>
        </label>

    </div>

    <ng-container
        *ngIf="timePeriodFormGroup('specific')"
        [formGroup]="timePeriodFormGroup()">

        <ng-container
            *ngFor="let control of ['seasons', 'quarters']"
            formGroupName="specific">

            <div *ngIf="timePeriodFormGroup('specific', control) as checkboxGroup" [formGroupName]="control">

                <span class="rdc-lined-title">What to show</span>

                <div class="rdc-query-full-time-period-selection-specific-options">
                    <label class="rdc-checkbox" *ngFor="let checkbox of checkboxGroup.controls | keyvalue" >
                        <input type="checkbox" [formControlName]="checkbox.key">
                        <ng-container [ngSwitch]="checkbox.key">
                            <span *ngSwitchCase="'q1'">Q1 (Jan - Mar)</span>
                            <span *ngSwitchCase="'q2'">Q2 (Apr - Jun)</span>
                            <span *ngSwitchCase="'q3'">Q3 (Jul - Sep)</span>
                            <span *ngSwitchCase="'q4'">Q4 (Oct - Dec)</span>
                            <span *ngSwitchDefault>{{ checkbox.key | titlecase }}</span>
                        </ng-container>
                    </label>
                </div>

            </div>

        </ng-container>

    </ng-container>


</ng-container>

<ng-template #minorSelection>

    <ng-container *ngIf="timePeriodFormGroup('specific', 'yearStartsMonth') || timePeriodFormGroup('specific', 'year')">

        <span class="rdc-lined-title">Time period options</span>

        <div class="rdc-query-partial-time-period-selection">

            <label *ngIf="timePeriodFormGroup('specific', 'yearStartsMonth')">
                <span>Year start month</span>
                <rdc-apps-select
                    controlName="yearStartsMonth"
                    [controlGroup]="timePeriodFormGroup('specific')"
                    [disabled]="apexTrialMode$ | async"
                    [options]="startMonths">
                </rdc-apps-select>
            </label>

            <label *ngIf="timePeriodFormGroup('specific','year')">
                <span>Year</span>
                <rdc-apps-select
                    [controlGroup]="timePeriodFormGroup('specific')"
                    controlName="year"
                    [options]="endYears">
                </rdc-apps-select>
            </label>
        </div>

    </ng-container>

</ng-template>

