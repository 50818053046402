import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AutocompleteEffects } from './+state/autocomplete.effects';
import * as fromAutocomplete from './+state/autocomplete.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromAutocomplete.AUTOCOMPLETE_FEATURE_KEY,
            fromAutocomplete.autocompleteReducer
        ),
        EffectsModule.forFeature([ AutocompleteEffects ]),
    ],
})
export class AutocompleteStoreModule {}
