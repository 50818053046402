import { of, map } from 'rxjs';

import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { httpErrorAction } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/error';

import {
    createRiskRouteAction,
    createRiskRouteSuccessAction, deleteRiskRouteAction, deleteRiskRouteSuccessAction,
    getRiskRouteAction,
    getRiskRouteSuccessAction, refreshRiskRouteAction, refreshRiskRouteSuccessAction
} from './risk.actions';
import { RiskService } from './risk.service';

@Injectable()
export class RiskEffects {

    private actions$ = inject(Actions);
    private riskService = inject(RiskService);

    get$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(getRiskRouteAction),
                fetch({
                    run: () => this.riskService.get()
                        .pipe(
                            map((routes) => getRiskRouteSuccessAction({ routes }))
                        ),
                    onError: (action, response) =>
                        of(httpErrorAction({ action, response })),
                })
            )
    );

    create$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(createRiskRouteAction),
                fetch({
                    run: ({ routesSet, dispatchId }) => this.riskService.create(routesSet)
                        .pipe(
                            map((routes) => createRiskRouteSuccessAction({ routes, dispatchId }))
                        ),
                    onError: (action, response) =>
                        of(httpErrorAction({ action, response })),
                })
            )
    );

    delete$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(deleteRiskRouteAction),
                fetch({
                    run: ({ routeId }) => this.riskService.delete(routeId)
                        .pipe(
                            map((routes) => deleteRiskRouteSuccessAction({ routes }))
                        ),
                    onError: (action, response) =>
                        of(httpErrorAction({ action, response })),
                })
            )
    );

    refresh$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(refreshRiskRouteAction),
                fetch({
                    run: () => this.riskService.refresh()
                        .pipe(
                            map((routes) => refreshRiskRouteSuccessAction({ routes }))
                        ),
                    onError: (action, response) =>
                        of(httpErrorAction({ action, response })),
                })
            )
    );
}
