import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, EmbeddedViewRef, Injectable, TemplateRef, Type } from '@angular/core';
import { ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

import { ToastComponent } from './toast.component';

@Injectable({ providedIn: 'root' })
export class ToastService {

    overlayRef: OverlayRef;

    toastComponentRef: ComponentRef<ToastComponent>;

    constructor(
        private overlay: Overlay
    ) {
        const configs = new OverlayConfig({
            hasBackdrop: false,
            panelClass: [ 'rdc-toast-container' ],
            backdropClass: 'rdc-toast-overlay',
        });

        this.overlayRef = this.overlay.create(configs);

        this.toastComponentRef = this.overlayRef.attach(new ComponentPortal(ToastComponent));
    }

    simpleToast(type: ToastType, body: string, timeout: number | false): EmbeddedViewRef<unknown> {
        return this.toastComponentRef.instance.simpleToast(type, body, timeout);
    }

    toastComponent<T>(type: ToastType, component: Type<T>, timeout: number | false): ComponentRef<T> {
        return this.toastComponentRef.instance.toastComponent(type, component, timeout);
    }

    toastTemplate<T>(type: ToastType, template: TemplateRef<T>, timeout: number | false): EmbeddedViewRef<T> {
        return this.toastComponentRef.instance.toastTemplate(type, template, timeout);
    }
}
