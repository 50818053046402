<rdc-apps-loading-overlay *ngIf="loading$ | async"></rdc-apps-loading-overlay>

<button rdcAppsButton
        rdcAppsIconButton
        rdcAppsDialogItem
        display="compact"
        [dialogCloseIconBtn]="true">
    <rdc-apps-icon size="sm">close</rdc-apps-icon>
</button>

<div class="rdc-export-dialog">

    <h4 class="rdc-dialog-title">Export query</h4>

    <form (ngSubmit)="onSubmit()" [formGroup]="exportForm">

        <div class="select-export" *ngIf="exportFormats.length > 1">
            <label *ngIf="exportFormats.includes('chart')" class="rdc-checkbox">
                <input type="radio" formControlName="export" value="chart">
                <span>Chart</span>
            </label>

            <label *ngIf="exportFormats.includes('tabular')" class="rdc-checkbox">
                <input type="radio" formControlName="export" value="tabular">
                <span>Tabular</span>
            </label>
        </div>

        <label>
            <span>Export as:</span>
            <rdc-apps-select
                controlName="exportType"
                [controlGroup]="exportForm"
                [options]="exportTypes">
            </rdc-apps-select>
        </label>

        <div class="rdc-export-extra-opts"
             *ngIf="exportForm.get('export')?.value === 'tabular' && data.context !== 'applet'">

            <rdc-apps-select
                controlName="selection"
                [controlGroup]="displayOptions"
                [options]="displayOptionsSelections">
            </rdc-apps-select>

            <label class="rdc-checkbox">
                <input type="checkbox" formControlName="includeExtendedData">
                <span>Include macro label columns</span>
            </label>
        </div>

        <div class="export-controls">
            <button
                    class="rdc-export-cancel"
                    rdcAppsButton
                    type="button"
                    rdcAppsDialogItem>
                Cancel
            </button>

            <button rdcAppsButton
                    [disabled]="loading$ | async"
                    colour="primary">
                <span *ngIf="(loading$ | async) === false">Export</span>
                <span *ngIf="loading$ | async">Exporting...</span>
            </button>
        </div>
    </form>

</div>
