import { Pipe, PipeTransform } from '@angular/core';
import { ɵFormGroupValue } from "@angular/forms";
import { TagsFormGroup } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

@Pipe({ name: 'tagsFilter' })
export class TagsFilterPipe implements PipeTransform {

    transform<T extends { tags: { id: string }[] }>(
        items: T[],
        tags: ɵFormGroupValue<TagsFormGroup>[]
    ): T[] {

        if (!items) {
            return [];
        }

        if (tags.every((tag) => !tag.checked)) {
            return items;
        }

        const tagIdArray = tags
            .filter(({ checked }) => checked)
            .map(({ id }) => id);


        return items.filter((item) => item.tags.find(({ id }: any) => tagIdArray.includes(id)));
    }

}
