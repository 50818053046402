<div class="rdc-template-series-year-range">

    <label>
        <span>Start year</span>
        <rdc-apps-select
            controlName="startYear"
            placeholder="Select a year"
            [controlGroup]="yearRangeForm"
            [options]="startYearOpts">
        </rdc-apps-select>
    </label>

    <label>
        <span>End year</span>
        <rdc-apps-select
            controlName="endYear"
            placeholder="Select a year"
            [controlGroup]="yearRangeForm"
            [options]="endYearOpts"
            (valueSelected)="onProgressIfValid($event)">
        </rdc-apps-select>
    </label>

</div>
