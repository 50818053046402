<section class="rdc-accordion" [ngClass]="{ borderless }">
  <!-- foreach accordion-item -->
  <ng-container *ngFor="let item of accordionItems; let i = index">

    <div class="accordion-item" [style.padding-top.px]="item.spacer" [class]="{ hidden: item.hide }" [attr.disabled]="item.disabled">

      <h4 class="accordion-toggle" [ngClass]="{ borderless, invalid: item.invalid, 'cant-close': cantClose(i) }" (click)="onToggle(i)">

        <div class="accordion-icon" [ngClass]="{ 'icon-open': item.open, invalid: item.invalid, 'inverse': item.inverse }" [class]="pointSize">
          <span *ngIf="item.disabled else showIcon">
            <rdc-apps-icon size="xxs">remove</rdc-apps-icon>
          </span>
          <ng-template #showIcon>
              <span *ngIf="!item.open">
                <rdc-apps-icon [rdcIcon]="!item.inverse ? openIcon : ''" [size]="item.inverse ? 'xl' : pointSize">{{ item.invalid ? 'exclamation' : (item.inverse ? 'add_circle' : openIcon) }}</rdc-apps-icon>
              </span>
              <span *ngIf="item.open">
                <rdc-apps-icon [rdcIcon]="!item.inverse ? closeIcon : ''" [size]="item.inverse ? 'xl' : pointSize">{{ item.invalid ? 'exclamation' : (item.inverse ? 'do_not_disturb_on' : closeIcon) }}</rdc-apps-icon>
              </span>
          </ng-template>
        </div>

        <div class="accordion-heading" [ngClass]="{ 'active': item.open, invalid: item.invalid }">
          <span class="accordion-title">{{ item.titleText }}</span>
          <span
              *ngIf="item.subText && !item.open"
              [attr.title]="item.subText"
              class="accordion-subtext"
          >
              {{ item.subText }}
          </span>
        </div>
      </h4>

      <!--  render the accordion content -->
      <div #accItemContent
           [@expandCollapse]="{ value: item.open ? 'open' : 'closed', params: { innerHeight: accItemHeights[i] || '0px' } }"
           class="accordion-content">
          <div #contentContainers>
              <ng-template [ngTemplateOutlet]="item.template"></ng-template>
          </div>
      </div>

    </div>

  </ng-container>
</section>
