import { catchError, filter, map, of, Subject, Subscription, tap } from 'rxjs';

import { DatePipe } from "@angular/common";
import { Inject, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { APP_EXPORTING_STUDY, APP_RUNNING_STUDY } from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import { AppletCloud, AppletType, ExportFormValue } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import {
    MarketChartResultSet,
    MarketStudyEntity,
    NetworkAppletResultsSets,
    QueryEntity,
    QueryResultSet,
    RsiDiscoverStudyEntity,
    RsiRiskRouteStudyEntity,
    RsiStudyEntity,
    saveQuerySuccess,
    TimeResultsSet,
    TimeStudyEntity,
    updateQueryLastRun
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/query';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { sendActivity } from "@rdc-apps/rdc-shared/src/lib/data-access/actions";
import { RdcResponseDto, ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { appLoaded, AppLoadStatus } from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';
import { ToastService } from '@rdc-apps/rdc-shared/src/lib/ui/toast';
import { saveAs } from "file-saver";
import { RdcDataTableSort } from "table";

// eslint-disable-next-line no-shadow
export enum QueryStates {
    INITIAL = 0,
    QUERYING = 1,
    HAS_RESULTS = 2,
    EXPORT_OK = 3,
    ERROR = 4,
}

// eslint-disable-next-line no-shadow
export enum QueryActions {
    INITIAL = 0,
    EXECUTE = 1,
    EXPORT= 2,
}

export interface QueryServiceStatus {
    action: QueryActions;
    state: QueryStates;
}

// Service for making "throw away" calls to the query engine, as results do not need to be stored.
@Injectable({ providedIn: 'root' })
export class QueryService extends RdcBaseHttpService {

    constructor(
        protected toast: ToastService,
        protected router: Router,
        protected store: Store,
        protected actions: Actions,
        @Inject(appEnvironment) protected env: AppEnvironment
    ) {
        super();

        this.actions.pipe(ofType(saveQuerySuccess))
            .subscribe(({ query }) => this.lastRunQuery ? this.lastRunQuery = { ...this.lastRunQuery, studyName: query.studyName } : null);

        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe(() => {
                this.request?.unsubscribe();
                this.store.dispatch(appLoaded({ key: APP_RUNNING_STUDY }));
            });
    }

    // for exporting, as a user can modify a query between running and choosing to export.
    // will need changing to ɵFormGroupValue<ApexStudy>
    lastRunQuery: any | null = null;

    lastRunRsiStudy: RsiStudyEntity | RsiDiscoverStudyEntity | RsiRiskRouteStudyEntity | null = null;

    querying = false;

    request!: Subscription;

    results!: any | null;

    sorting: RdcDataTableSort | null = null;

    status: Subject<QueryServiceStatus> = new Subject<QueryServiceStatus>();

    reset(): void {
        this.results = null;
        this.lastRunRsiStudy = null;
        this.lastRunQuery = null;
        this.status.next({ action: QueryActions.INITIAL, state: QueryStates.INITIAL });
    }

    killRequest(): void {
        this.request?.unsubscribe();
        this.status.next({ action: QueryActions.EXECUTE, state: this.results ? QueryStates.HAS_RESULTS : QueryStates.INITIAL });
        this.querying = false;
    }

    // will need updating when we have converted the table side too.
    chart(query: QueryEntity): void {

        this.querying = true;

        this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.QUERYING });

        this.request?.unsubscribe();

        this.request = this.http.post<RdcResponseDto<QueryResultSet>>(`${ this.env.api.baseUrl }/Study/run`, query, {
            headers: this.getTokenHeaders(),
        })
            .pipe(
                tap(() => {
                    this.querying = false;
                    this.updateLastRunIfSaved(query);
                }),
                map((resp) => resp.success.data),
                catchError(() => {
                    this.toast.simpleToast(ToastType.ERROR, 'Something went wrong when running the query!', 5000);

                    this.querying = false;
                    this.results = null;

                    this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.ERROR });

                    this.store.dispatch(appLoaded({ key: APP_RUNNING_STUDY }));

                    return of(null); // return no results
                })
            ).subscribe((results: QueryResultSet | null) => {
                if (results) {
                    this.results = results;

                    this.results.studyModel = query;

                    this.lastRunQuery = query;

                    this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.HAS_RESULTS });

                    this.store.dispatch(appLoaded({ key: APP_RUNNING_STUDY }));
                }
            });
    }

    query(query: QueryEntity, paginationOptions: { take: number; skip: number } = { take: 100, skip: 0 }): void {

        let theQuery = { ...query };

        this.request?.unsubscribe();

        this.querying = true;

        this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.QUERYING });

        if(query.queryRequest.resultSorting) {
            const { dataPointAttributeCode, sortDirection } = query.queryRequest.resultSorting;

            this.sorting = {
                columnCode: dataPointAttributeCode,
                sortDirection,
            };

            // apply the sorting
            theQuery = {
                ...query,
                queryRequest: {
                    ...query.queryRequest,
                    resultSorting: {
                        dataPointAttributeCode,
                        sortDirection
                    },
                },
            };

        } else {
            this.sorting = null;
        }

        // use subscribe to keep existing results on screen whilst a new query is being run
        // need an interceptor for failed requests in the app, will be its own issue.
        this.request = this.http.post<RdcResponseDto<QueryResultSet>>(`${ this.env.api.baseUrl }/Study/run`, theQuery, {
            headers: this.getTokenHeaders(),
            params: {
                take: paginationOptions.take,
                skip: paginationOptions.skip,
            },
        })
            .pipe(
                tap(() => {
                    this.querying = false;
                    this.updateLastRunIfSaved(query);
                }),
                map((resp) => resp.success.data),
                catchError(() => {
                    this.toast.simpleToast(ToastType.ERROR, 'Something went wrong when running the query!', 5000);

                    this.querying = false;
                    this.results = null;

                    this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.ERROR });

                    this.store.dispatch(appLoaded({ key: APP_RUNNING_STUDY }));

                    return of(null); // return no results
                })
            ).subscribe((results: QueryResultSet | null) => {

                if (results) {
                    if (paginationOptions.skip > 0) { // if paging, add the results to the existing
                        this.results = {
                            ...results,
                            tableRows: [
                                ...(this.results?.tableRows || []),
                                ...(results.tableRows || []),
                            ],
                        } ;
                    } else {
                        this.results = results;
                        this.results.studyModel = query;
                    }

                    this.lastRunQuery = query;

                    this.store.dispatch(appLoaded({ key: APP_RUNNING_STUDY }));

                    this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.HAS_RESULTS });
                }
            });
    }

    private updateLastRunIfSaved(query: QueryEntity | undefined): void {
        if (!query) {
            return;
        }

        if (query.studyId !== '00000000-0000-0000-0000-000000000000') {
            this.store.dispatch(updateQueryLastRun({ studyId: query.studyId }));
        }
    }

    // Exports the last run query if available
    export(options: ExportFormValue, studyType: 'table' | 'multi' | 'scatter', resultsToExport?: QueryResultSet, templateId = 'blank', basicTableQb?: boolean ): void {

        this.store.dispatch(AppLoadStatus.loading(APP_EXPORTING_STUDY, 'Exporting study...'));

        this.request?.unsubscribe();

        this.querying = true;

        this.status.next({ action: QueryActions.EXPORT, state: QueryStates.QUERYING });

        const datapoints = resultsToExport?.studyModel.queryRequest.dataPoints?.dataPointCodes || [];

        if(datapoints.length > 8 && ((resultsToExport?.totalCount || 0) > 10000)) {
            this.toast.simpleToast(
                ToastType.WARN,
                `Your export has been limited to 10000 records. Fair usage and contractual commitments require us to limit the number of records that can be downloaded in one file. Please contact RDC Support if you require further assistance in relation to this query.`,
            5000);

        } else if((resultsToExport?.totalCount || 0) > 50000) {
            this.toast.simpleToast(
                ToastType.WARN,
                `Your export has been limited to 50000 records. Fair usage and contractual commitments require us to limit the number of records that can be downloaded in one file. Please contact RDC Support if you require further assistance in relation to this query.`,
                5000);
        }

        this.request = this.http.post<string>(`${ this.env.api.baseUrl }/Study/exportTabularStudy`, {
            ...options,
            study: resultsToExport?.studyModel,
        }, {
            observe: 'response',
            headers: this.getTokenHeaders(),
            responseType: 'arraybuffer',
        } as never)
            .pipe(
                catchError(() => {
                    this.querying = false;

                    this.toast.simpleToast(ToastType.ERROR, 'Something went wrong when exporting the query!', 5000);

                    this.status.next({ action: QueryActions.EXPORT, state: QueryStates.ERROR });

                    this.store.dispatch(appLoaded({ key: APP_EXPORTING_STUDY }));

                    return of(null);
                })
            ).subscribe((response: any) => {

                this.updateLastRunIfSaved(resultsToExport?.studyModel);

                this.querying = false;

                if (!response) {
                    this.status.next({ action: QueryActions.EXPORT, state: QueryStates.ERROR });

                    return;
                }

                const nameWithoutSpaces = resultsToExport?.studyModel?.studyName.split(' ').join('_');
                const dateTime = new DatePipe('en-GB').transform(new Date().getTime(),'yyyy-MMM-dd_HHmm');

                const filename = `${ nameWithoutSpaces }_${ dateTime }.${options.exportType}`;

                const blob = new Blob([ response.body ], { type: `application/${ options.exportType }` });

                saveAs(blob, filename);

                this.store.dispatch(appLoaded({ key: APP_EXPORTING_STUDY }));

                this.store.dispatch(sendActivity({
                    activity: {
                        activityCode: `rdc.q.apex.query.${ studyType }.export`,
                        detail: {
                            launchSource: 'Query.export',
                            creationType: studyType === 'table'
                                ? basicTableQb
                                    ? 'basic'
                                    : 'extended'
                                : templateId || 'Blank',
                            area: 'Data & Visualisation',
                            queryObject: options,
                        },
                    },
                }));

                this.status.next({ action: QueryActions.EXPORT, state: QueryStates.EXPORT_OK });
            });
    }



    rsiStudy(
        rsiStudy: RsiStudyEntity | RsiDiscoverStudyEntity | RsiRiskRouteStudyEntity,
        cloud: AppletCloud,
        applet: AppletType,
        take = 20,
        skip = 0,
    ): void {

        this.querying = true;

        this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.QUERYING });

        this.request?.unsubscribe();

        let url = `${ this.env.api.baseUrl }/${ cloud }/routes/study/${ applet }`

        if(cloud === AppletCloud.RISK) {
            url = `${ this.env.api.baseUrl }/RiskCloud/routes/study`;
        }

        this.request = this.http.post<RdcResponseDto<QueryResultSet>>(url, rsiStudy, {
            headers: this.getTokenHeaders(),
            params: { take, skip },
        }).pipe(
            catchError(() => {
                this.toast.simpleToast(ToastType.ERROR, 'Error running study', 5000);

                this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.INITIAL });

                return of(null);
            })
        ).subscribe((resp) => {
            this.querying = false;

            if(!resp) {
                return;
            }

            this.lastRunRsiStudy = rsiStudy;

            if(skip > 0 && this.results?.tableRows.length) {
                this.results.tableRows = [
                    ...this.results?.tableRows || [],
                    ...resp.success.data.tableRows
                ];
            } else {
                this.results = resp.success.data;
            }

            this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.HAS_RESULTS });
        });
    }

    marketStudy(marketStudy: MarketStudyEntity): void {
        this.querying = true;

        this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.QUERYING });

        this.request?.unsubscribe();

        const url = `${ this.env.api.baseUrl }/Network/market`;

        this.request = this.http.post<RdcResponseDto<NetworkAppletResultsSets<MarketChartResultSet[]>>>(url, marketStudy, {
            headers: this.getTokenHeaders(),
        }).pipe(
            catchError(() => {
                this.toast.simpleToast(ToastType.ERROR, 'Error running study', 5000);

                this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.INITIAL });

                return of(null);
            })
        ).subscribe((resp): void => {
            this.querying = false;

            if (!resp) {
                return;
            }

            this.lastRunQuery = marketStudy;

            (this.results as unknown as NetworkAppletResultsSets<MarketChartResultSet[]>) = resp.success.data;

            this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.HAS_RESULTS });
        });
    }

    networkExport(exportStudyName: string | null, study: any, options: { exportType: string; applet: string; includeDisplayNames?: boolean; includeExtendedData?: boolean; includeIATACodes?: boolean; [key: string]: any }): void {

        const studyName  = `${ exportStudyName || 'Exported_study' }.${options.exportType}`;

        this.store.dispatch(AppLoadStatus.loading(APP_EXPORTING_STUDY, 'Exporting study...'));

        this.request?.unsubscribe();

        this.querying = true;

        this.status.next({ action: QueryActions.EXPORT, state: QueryStates.QUERYING });

        let url;
        let body;

        switch (options.applet) {
            case 'time': {
                url = `${ this.env.api.baseUrl }/Network/time/export`;
                body = study;
                break;
            }
            case 'destination': {
                url = `${ this.env.api.baseUrl }/Network/destination/export`;
                body = { ...options, destinationRequest: study };
                break;
            }
            default:{
                url = `${ this.env.api.baseUrl }/Network/export`;
                body = { ...options, study };
            }
        }

        this.request = this.http.post<string>(url, body, {
            observe: 'response',
            headers: this.getTokenHeaders(),
            responseType: 'arraybuffer',
        } as never)
            .pipe(
                catchError(() => {
                    this.querying = false;

                    this.toast.simpleToast(ToastType.ERROR, 'Something went wrong when exporting the query!', 5000);

                    this.status.next({ action: QueryActions.EXPORT, state: QueryStates.ERROR });

                    this.store.dispatch(appLoaded({ key: APP_EXPORTING_STUDY }));

                    return of(null);
                })
            ).subscribe((response: any) => {

                this.querying = false;

                if (!response) {
                    this.status.next({ action: QueryActions.EXPORT, state: QueryStates.ERROR });

                    return;
                }

                const blob = new Blob([ response.body ], { type: `application/${ options.exportType }` });

                saveAs(blob, studyName);

                this.store.dispatch(appLoaded({ key: APP_EXPORTING_STUDY }));

                const activityTypeMap = new Map()
                    .set('airline', 'network')
                    .set('airport', 'airport');

                const mode = activityTypeMap.get(study.type || '');

                let detail = {
                    queryObject: study,
                };

                if(mode) {
                    detail = {
                        ...detail,
                        mode
                    } as any;
                }

                this.store.dispatch(sendActivity({
                    activity: {
                        activityCode: `rdc.q.apex.network.${ options.applet }.table.export`,
                        detail,
                    },
                }));

                this.status.next({ action: QueryActions.EXPORT, state: QueryStates.EXPORT_OK });
            });
    }

    timeStudy(timeStudy: TimeStudyEntity): void {
        this.querying = true;

        this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.QUERYING });

        this.request?.unsubscribe();

        let url

        if(timeStudy.networkTimeAnalysisType === 'airport') {
            url = `${ this.env.api.baseUrl }/Network/time/airport`;
        } else {
            url = `${ this.env.api.baseUrl }/Network/time/airline`;
        }

        this.request = this.http.post<RdcResponseDto<TimeResultsSet>>(url, timeStudy, {
            headers: this.getTokenHeaders(),
        }).pipe(
            catchError(() => {
                this.toast.simpleToast(ToastType.ERROR, 'Error running study', 5000);

                this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.INITIAL });

                return of(null);
            })
        ).subscribe((resp): void => {
            this.querying = false;

            if (!resp) {
                return;
            }

            this.lastRunQuery = timeStudy;

            (this.results as unknown as TimeResultsSet) = resp.success.data;

            this.status.next({ action: QueryActions.EXECUTE, state: QueryStates.HAS_RESULTS });
        });
    }


    appletToBuilderStorage(appletToBuilder: any): void {
        localStorage.setItem('appletToBuilder', JSON.stringify(appletToBuilder));
    }

    removeLocalStorage(key: string): void {
        localStorage.removeItem(key);
    }
}
