import { filter, of, switchMap, take } from 'rxjs';

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getUserDetailsData } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/user-details';

export const userCanActivateCloud = (cloudCode: string): CanActivateFn => () => {
    const store = inject(Store);
    const router = inject(Router);

    return store.select(getUserDetailsData('userDetailsState'))
        .pipe(
            filter((details) => !!details),
            take(1),
            switchMap((details) => {

                if (!details) {
                    return of(false);
                }

                if (details.organisation.activeCloudCodes.includes(cloudCode)) {
                    return of(true);
                }

                return of(router.parseUrl(`${cloudCode}/upgrade`));
            })
        );
};

export const userCanNotActivateCloud = (cloudCode: string): CanActivateFn => () => {
    const store = inject(Store);
    const router = inject(Router);

    return store.select(getUserDetailsData('userDetailsState'))
        .pipe(
            filter((details) => !!details),
            take(1),
            switchMap((details) => {

                if (!details) {
                    return of(true);
                }

                if (!details.organisation.activeCloudCodes.includes(cloudCode)) {
                    return of(true);
                }

                return of(router.parseUrl(cloudCode));
            })
        );
};
