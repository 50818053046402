import { createAction, props } from '@ngrx/store';

import { DataPointsEntity } from './data-points.models';

export const initDataPoints = createAction('[DataPoints Page] Init');

export const loadDataPointsSuccess = createAction(
    '[DataPoints/API] Load DataPoints Success',
    props<{ dataPoints: DataPointsEntity }>()
);

export const loadDataPointsFailure = createAction(
    '[DataPoints/API] Load DataPoints Failure',
    props<{ error: string }>()
);

export const dataPointSelectedAction = createAction('[DataPoints] Selected');

export const initQueriesViaDataPoints = createAction('[DataPoints Page] Init Queries via DataPoints');
