import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';
import { IataIcaoCodesService } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/services';
import {
    DeletePreferenceGroup,
    PreferencesGroup
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/default-preferences';

@Component({
    selector: 'rdc-apps-default-preferences-item',
    templateUrl: './default-preferences-item.component.html',
    styleUrls: [ './default-preferences-item.component.scss' ],
})
export class DefaultPreferencesItemComponent {

    @Input() controls!: AbstractControl;
    @Input() index!: number;

    @Output() editPreference = new EventEmitter<number>();
    @Output() deletePreference = new EventEmitter<DeletePreferenceGroup>();

    deleteMode = false;

    get controlsArray(): FormArray {
        return this.controls.get('selections') as FormArray;
    }

    get controlName(): FormControl {
        return this.controls.get('name') as FormControl;
    }

    get controlDefault(): FormControl {
        return this.controls.get('isDefaultGroup') as FormControl;
    }

    constructor(public iataIcaoCodesService: IataIcaoCodesService) {}

    onDeletePreference(group: PreferencesGroup, index: number): void {
        this.deletePreference.emit({ group, index });
    }

}
