import { distinctUntilChanged, takeUntil } from "rxjs";

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { displayOptionsSelections } from "@rdc-apps/rdc-apex/src/lib/shared/constants";
import { QueryBuilderType } from "@rdc-apps/rdc-apex/src/lib/shared/data-access/models";
import { SelectAutocompleteModule } from "@rdc-apps/rdc-shared/src/lib/ui/select";
import { RdcComponentUtils } from "@rdc-apps/rdc-shared/src/lib/utilities";

@Component({
    selector: 'rdc-apps-display-codes',
    standalone: true,
    imports: [ CommonModule, SelectAutocompleteModule ],
    templateUrl: './display-codes.component.html',
    styleUrl: './display-codes.component.scss',
})
export class DisplayCodesComponent extends RdcComponentUtils implements OnInit {

    @Input() form!: FormGroup;
    @Input() controlName!: string;
    @Input() queryType: QueryBuilderType | undefined = QueryBuilderType.Table;
    @Input() alternateCodes = false;
    @Input() emitEvent = false;
    @Input() showLabel = true;

    displayOptionsForm!: FormGroup;
    displayOptionsSelections = displayOptionsSelections;

    constructor(private fb: FormBuilder) {
        super();

        this.displayOptionsForm = this.fb.group({
            selection: this.fb.control('codeOnly', [ Validators.required ])
        });
    }

    ngOnInit(): void {

        // patch codes to displayOptionsForm
        if (this.controlName) {
            this.form.valueChanges
                .pipe(
                    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
                )
                .subscribe(() => {
                    const codes = this.form.getRawValue()[`${this.controlName}`];

                    if (codes) {
                        if (codes.iataCodes && codes.shouldDisplayNames) {
                            this.displayOptionsForm.patchValue({ selection: 'both' })
                        }

                        if (!codes.iataCodes && codes.shouldDisplayNames) {
                            this.displayOptionsForm.patchValue({ selection: 'nameOnly' })
                        }

                        if (codes.iataCodes && !codes.shouldDisplayNames) {
                            this.displayOptionsForm.patchValue({ selection: 'codeOnly' })
                        }
                    }
                })
        }

        this.displayOptionsForm.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$)
            )
            .subscribe(({ selection }) => {
                if (this.queryType !== 'table') {
                    return;
                }

                let patch = this.alternateCodes
                    ? { includeIATACodes: false, includeDisplayNames: true }
                    : { iataCodes: true, shouldDisplayNames: true };

                switch (selection) {
                    case 'nameOnly': {
                        patch = this.alternateCodes
                            ? { includeIATACodes: false, includeDisplayNames: true }
                            : { iataCodes: false, shouldDisplayNames: true };
                        break;
                    }
                    case 'codeOnly': {
                        patch = this.alternateCodes
                            ? { includeIATACodes: true, includeDisplayNames: false }
                            : { iataCodes: true, shouldDisplayNames: false };
                        break;
                    }
                    case 'both': {
                        patch = this.alternateCodes
                            ? { includeIATACodes: true, includeDisplayNames: true }
                            : { iataCodes: true, shouldDisplayNames: true };
                        break;
                    }
                }

                if (this.controlName) {
                    this.form.get(`${this.controlName}`)?.patchValue(patch, { emitEvent: this.emitEvent });
                } else {
                    this.form?.patchValue(patch, { emitEvent: false });
                }
            })
    }
}
