import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ERROR_FEATURE_KEY, ErrorState } from './error.reducer';

// Lookup the 'Error' feature state managed by NgRx
export const getErrorState =
    createFeatureSelector<ErrorState>(ERROR_FEATURE_KEY);

export const getErrorError = createSelector(
    getErrorState,
    (state: ErrorState) => state.error
);
