import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TagsFilterPipe } from './tags-filter.pipe';

@NgModule({
    imports: [ CommonModule ],
    declarations: [
        TagsFilterPipe,
    ],
    exports: [
        TagsFilterPipe,
    ],
})
export class TagsFilterPipeModule {}
