<div class="cloud-popup" @dropIn>
    <button
        rdcAppsButton
        rdcAppsIconButton
        rdcAppsDialogItem
        (click)="closePopup.emit()"
    >
        <rdc-apps-icon>close</rdc-apps-icon>
    </button>
    <p>{{ content }}</p>
</div>
