import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    QbFilterChartFixedTimeframeModule
} from '@rdc-apps/rdc-apex/src/lib/query-builder/ui/qb-filter-chart-fixed-timeframe';
import { QbFilterTimeframeModule } from '@rdc-apps/rdc-apex/src/lib/query-builder/ui/qb-filter-timeframe';
import { QbSummariesModule } from '@rdc-apps/rdc-apex/src/lib/query-builder/ui/qb-summaries';
import {
    QueryBuilderFilterAutocompleteComponent
} from '@rdc-apps/rdc-apex/src/lib/query-builder/ui/query-builder-filter-autocomplete';
import { SummaryGroupOrderModule } from '@rdc-apps/rdc-apex/src/lib/shared/pipes/summary-group-order';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { RdcMenuItemModule } from '@rdc-apps/rdc-shared/src/lib/directives/rdc-menu-item';
import { TooltipModule } from '@rdc-apps/rdc-shared/src/lib/directives/tooltip';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';

import { QbFormFiltersComponent } from './qb-form-filters.component';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        ReactiveFormsModule,
        SummaryGroupOrderModule,
        TooltipModule,
        CdkMenuModule,
        RdcMenuItemModule,
        ButtonModule,
        QbFilterChartFixedTimeframeModule,
        QbFilterTimeframeModule,
        QbSummariesModule,
        QueryBuilderFilterAutocompleteComponent,
    ],
    declarations: [ QbFormFiltersComponent ],
    exports: [ QbFormFiltersComponent ],
})
export class QbFormFiltersModule {}
