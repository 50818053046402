import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {

    static requireOneOrMore(...controlPaths: string[]): ValidatorFn {

        return (group) => {

            const valid = controlPaths.filter((path) => (group.get(path) as FormArray).length && group.get(path)?.valid);

            // only having one or none of these controls is valid
            if(valid.length) {
                return null;
            }

            return { requireOneOrMore: true };
        };

    }

    static notEmpty(): ValidatorFn {

        return (control) => {

            if (!control.value.length) {
                return { isEmpty: true };
            }

            return null;
        };

    }

    static noNulls(): ValidatorFn {

        return (control) => {

            if (control.value.type === 'all') {
                return null;
            }

            if (!control.value.selected.length) {
                return { isEmpty: true };
            }

            if (control.value.selected.some((val: any) => !val)) {
                return { hasNull: true };
            }

            return null;
        };

    }

    static notInArray(options: string[]): ValidatorFn {

        return (control) => {
            if (options.includes(control.value)) {
                return { inArray: true };
            }

            return null;
        };

    }

    static includesAGroupByControl(): ValidatorFn {

        return (control) => {

            if (Object.keys((control as FormGroup).controls).find((key) => [ 'origin', 'destination', 'airline' ].includes(key))) {
                return null;
            }

            return { noGroupByInQuery: true };
        };

    }

    static inArray(options: (string | number | null)[]): ValidatorFn {

        return (control) => {
            if (options.includes(control.value)) {
                return null;
            }

            return { notInArray: true };
        };

    }

    static atLeastOneChecked(): ValidatorFn {
        return (control) => {

            if (JSON.stringify(control.value).includes('true')) {
                return null;
            }

            return { notOneChecked: true };
        };
    }

    static matchValidator(controlOne: AbstractControl, controlTwo: AbstractControl): ValidatorFn {
        return () => {
            if (controlOne.value === controlTwo.value) {
                return null;
            }

            return { misMatch: true };
        };

    }

    static isSame(controlNameOne: string, controlNameTwo: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const controlOne = control.get(controlNameOne);
            const controlTwo = control.get(controlNameTwo);

            if (controlOne && controlTwo && controlOne.value?.label !== controlTwo.value?.label) {
                return null;
            }

            return { isSame: true };
        };

    }

}
