import { map, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { Store } from '@ngrx/store';
import { httpErrorAction } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/error';
import { appLoaded, AppLoadStatus } from "@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading";

import * as DataPointsActions from './data-points.actions';
import { APP_DATA_POINTS_DATA } from "./data-points.loading-key";
import { DataPointsService } from './data-points.service';

@Injectable()
export class DataPointsEffects {

    loading$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DataPointsActions.initDataPoints),
            fetch({
                run: () => this.store$.dispatch(AppLoadStatus.loading(
                    APP_DATA_POINTS_DATA,
                    'loading data points',
                    { global: true, overlay: true }
                ))
            })
        )
    );

    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DataPointsActions.initDataPoints),
            fetch({
                run: () =>
                    this.dataPointsService.get().pipe(
                        map((dataPoints) =>
                            DataPointsActions.loadDataPointsSuccess({
                                dataPoints,
                            })
                        )
                    ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    loadSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DataPointsActions.loadDataPointsSuccess),
            fetch({
                run: () => {
                    this.store$.dispatch(DataPointsActions.initQueriesViaDataPoints());

                    this.store$.dispatch(appLoaded({ key: APP_DATA_POINTS_DATA }));
                },
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    constructor(
        private readonly actions$: Actions,
        private dataPointsService: DataPointsService,
        private store$: Store
    ) {}
}
