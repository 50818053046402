import { createAction, props } from '@ngrx/store';

import { StudyTemplate } from './study-templates.models';

export const fetchStudyTemplates = createAction(
    '[Study templates] fetch'
);

export const fetchStudyTemplatesSuccess = createAction(
    '[Study templates/API] Load study templates Success',
    props<{ results: StudyTemplate[] }>()
);

export const fetchStudyTemplatesFailure = createAction(
    '[Study templates/API] Load study templates Failure',
    props<{ error: string }>()
);
