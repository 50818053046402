import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RiskEffects } from './+state/risk.effects';
import * as fromRisk from './+state/risk.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(fromRisk.RISK_FEATURE_KEY, fromRisk.riskReducer),
        EffectsModule.forFeature([ RiskEffects ]),
    ],
})
export class RiskStoreModule {}
