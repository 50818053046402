import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    STUDY_TEMPLATE_FEATURE_KEY,
    StudyTemplateState,
    studyTemplateAdapter
} from './study-templates.reducer';

// Lookup the 'Autocomplete' feature state managed by NgRx
export const getStudyTemplateState = createFeatureSelector<StudyTemplateState>(
    STUDY_TEMPLATE_FEATURE_KEY
);

const { selectAll } = studyTemplateAdapter.getSelectors();

export const getStudyTemplates = (...studyTypes: string[]) => createSelector(
    getStudyTemplateState,
    (state: StudyTemplateState) => selectAll(state).filter((entity) => studyTypes.includes(entity.templateStudyModel.studyType || ''))
);

export const getStudyTemplateStatus = createSelector(
    getStudyTemplateState,
    (state: StudyTemplateState) => state.status
);
export const isStudyTemplate = (id: string) => createSelector(
    getStudyTemplateState,
    (state: StudyTemplateState) => selectAll(state).some(({ templateId }) => templateId === id)
);
