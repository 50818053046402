import { animate, style, transition, trigger } from '@angular/animations';

export const slideInAnimationSpeed = 500;
export const slideInAnimation = trigger('slideIn', [
    transition(':enter',[
        style({ height: 0, opacity: 0, margin: 0 }),
        animate(`${slideInAnimationSpeed}ms ease-in`, style( {
            height: '*',
            opacity: 1,
            margin: '*',
        })),
    ]),
]);
