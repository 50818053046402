import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";

@Component({
    templateUrl: './temp-user-signin-oidc-callback.component.html',
})
export class TempUserSigninOidcCallbackComponent implements OnInit {
    constructor(
        private router: Router,
        private oidcSecurityService: OidcSecurityService
    ) {}

    ngOnInit() {
        if(this.oidcSecurityService.isAuthenticated()) {
            this.router.navigate([ 'home' ]);

            return;
        }
    }
}
