import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { ChangeDetectorRef, inject, Pipe, PipeTransform } from "@angular/core";
import { RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";
import { RdcFilterPipe } from "@rdc-apps/rdc-shared/src/lib/pipes/filter";


@Pipe({ name: 'rdcSelectFilter', standalone: true })
export class SelectFilterPipe implements PipeTransform {
    transform<T extends Partial<RepoItem<unknown>>>(options: T[], filter: string, allowFilter: boolean, filterMode: 'default' | 'autocomplete' = 'default', ...additionalProps: string[]): T[] {

        if(!allowFilter || filterMode === 'autocomplete') {
            return options;
        }

        return new RdcFilterPipe().transform(options, filter, ...additionalProps);
    }
}

@Pipe({ name: 'removeEmptyCategories', standalone: true })
export class RemoveEmptyCatPipe implements PipeTransform {
    transform(options: Partial<RepoItem<unknown>>[]): Partial<RepoItem<unknown>>[] {
        return options.filter((option) => !option['options'] || option['options'].length);
    }
}

@Pipe({ name: 'vsHeight', standalone: true })
export class VirtualScrollHeightPipe implements PipeTransform {

    cdr = inject(ChangeDetectorRef);

    transform(options: Partial<RepoItem<unknown>>[], maxHeight: number, filterHelpHeight = 0, vp: CdkVirtualScrollViewport): number {

        let height = filterHelpHeight;

        for(const option of options) {

            if(!option['options']) {
                height+= 32; // if no options, it's a single option, with a height of 32.
            } else {
                height+= 20; // add the height of the category span

                height+= (option['options'].length * 32); // add the height of all the options
            }

            if(height >= maxHeight) {

                height = maxHeight;

                break;
            }
        }


        setTimeout(() => {
            vp.checkViewportSize();
        },10);

        return height + 2;
    }

}

