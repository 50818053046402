<a
   class="cloud-item"
   [routerLink]="route"
   [queryParams]="queryParams"
   [attr.disabled]="disabled"
   [ngClass]="{
        'support-item': role === 'support',
        'account-item': role === 'account',
        'menu-active': menuActive,
        'is-active': isActive,
        'expand-menu-child': childItem,
        'cloud-onboarding': cloudOnboarded !== undefined && !cloudOnboarded && cloudActive,
        'cloud-active': cloudActive,
        'cloud-locked': !cloudActive && role !== 'home' ,
        expanded,
   }"
   rdcAppsTooltip
   rdcAppsCloudPopup
   [cloudParentComponentInstance]="this"
   [sidebarMenuTriggered]="sidebarMenuTriggered"
   [sidebarMenuOpen]="sidebarMenuOpen"
   [sideMenuExpanded]="expanded"
   [@shrinkSidebarItem]="expanded ? 'max' : 'min'"
   routerLinkActive="active"
   [routerLinkActiveOptions]="{ exact: requireExactRoute }"
   [tooltipText]="label"
   tooltipPosition="right"
   [tooltipDisabled]="expanded || cloudOnboarded !== undefined && !cloudOnboarded"
   (click)="cloudTrigger.emit()"
>
    <div *ngIf="childItem" class="child-border"></div>

    <div class="icon-container">
        <rdc-apps-icon
            *ngIf="icon"
            size="xxs"
            [rdcIcon]="isActive && activeIcon ? activeIcon || '' : icon || ''"
            [ngClass]="{ 'child-icon': childItem }"
            [sideBarIcon]="!childItem"
            [sideBarExpanded]="expanded"
        >
            {{ icon }}
        </rdc-apps-icon>
    </div>

    <span *ngIf="role !== 'account'">{{ label }}</span>

    <div class="account-info" *ngIf="role === 'account'">
        <span>{{ label }}</span>
        <span class="account-email">{{ accountEmail }}</span>
    </div>
</a>
