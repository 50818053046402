import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { GrowthEffects } from './+state/growth.effects';
import * as fromGrowth from './+state/growth.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromGrowth.GROWTH_FEATURE_KEY,
            fromGrowth.growthReducer
        ),
        EffectsModule.forFeature([ GrowthEffects ]),
    ],
})
export class GrowthStoreModule {}
