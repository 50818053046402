import { debounce, distinctUntilChanged, interval, Subscription, takeUntil } from 'rxjs';

import { Dialog } from '@angular/cdk/dialog';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { Actions } from "@ngrx/effects";
import {
    QbUserDefaultsService,
    QueryBuilderUtils,
    TableDataPoints
} from '@rdc-apps/rdc-apex/src/lib/query-builder/utilities';
import { SettingsComponent } from '@rdc-apps/rdc-apex/src/lib/settings/feature/settings';
import { checkboxCodes, filterDropDownCodes } from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import { QueryBuilderType } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import {
    dataPointSelectedAction,
    DataPointsEntity,
    QbOutputSummaryGroup,
    SummaryOutputRepoItem
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points';
import { DropdownOptionsForSummaryPipe } from '@rdc-apps/rdc-apex/src/lib/shared/pipes/dropdown-options-for-summary';
import { DisplayOrderPipe } from '@rdc-apps/rdc-apex/src/lib/shared/pipes/summary-group-order';
import { CustomValidators } from '@rdc-apps/rdc-shared/src/lib/custom-validators';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { RdcComponentUtils, RdcDialogHelper } from '@rdc-apps/rdc-shared/src/lib/utilities';

@Component({
    selector: 'rdc-apps-qb-summaries',
    templateUrl: './qb-summaries.component.html',
    styleUrls: [ './qb-summaries.component.scss' ],
})
export class QbSummariesComponent extends RdcComponentUtils implements OnInit, OnDestroy, OnChanges {

    @Input() form!: FormGroup;
    @Input() dataPointsData!: DataPointsEntity | null | undefined;
    @Input() queryType: QueryBuilderType | undefined = QueryBuilderType.Table;
    @Input() setSummarization!: boolean | null;

    @Output() init = new EventEmitter<void>();

    checkboxCodes = checkboxCodes;
    filterDropDowns = filterDropDownCodes;
    hideOutputs: string[] = [];
    additionalOptsOpen = false;
    outputGroups: QbOutputSummaryGroup[] = [];
    nameAndCodeSub: Subscription | undefined;

    constructor(
        private fb: FormBuilder,
        private userDefaultsService: QbUserDefaultsService,
        private dialog: Dialog,
        private rdcDialogHelper: RdcDialogHelper,
        actions: Actions
    ) {
        super(actions);
    }

    ngOnChanges(): void {
        this.setControls();
    }

    ngOnInit(): void {

        this.outputGroups = [ ...JSON.parse(JSON.stringify(this.dataPointsData?.outputGroups || [])) ];

        if (this.queryType === QueryBuilderType.Table) {

            const groupToAddTo = this.outputGroups.find(({ id }) => id === '508a53fb-8cbb-43e0-95f5-fd9a4618c184');

            groupToAddTo?.outputs?.push({
                datapointCodes: [],
                id: 'non-db',
                code: 'includeOnlyCompleteData',
                label: 'Include only complete data',
                displayOrder: 0,
            });

        }

        this.form.get('chartProperties.type')?.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                debounce(() => interval(0))
            )
            .subscribe(() => this.setControls());

        this.form.get('dataPoints')?.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                debounce(() => interval(0))
            )
            .subscribe((dataPoints) => {
                this.hideAircraftOutput(dataPoints);
                this.hideEmissionsOutput(dataPoints);
                this.setControls();
            });

        // code amended as there was an unexpected memory leak
        // (couldnt find the route causes but I believe due to the number of form updates setControls constantly gets fired on each change)
        // as well as when switching between basic and extended qb it loses the subscription to the dataPoints form
        // causing the functions to not fire
        // switching to actions fixes the issue
        this.onActions([ dataPointSelectedAction ], () => {
            const dataPoints = this.form.get('dataPoints')?.getRawValue();
            this.hideAircraftOutput(dataPoints);
            this.hideEmissionsOutput(dataPoints);
            this.setControls();
        });

        this.form.get('filters.schedulesFilters.franchisePartners')?.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                distinctUntilChanged()
            )
            .subscribe(() => {
                this.setControls()
            });

        this.hideAircraftOutput(this.form.get('dataPoints')?.value);

        this.setControls();

        this.init.emit();
    }

    override ngOnDestroy() {
        super.ngOnDestroy();

        this.nameAndCodeSub?.unsubscribe();
    }

    hideAircraftOutput(dataPoints: TableDataPoints): void {
        if (QueryBuilderUtils.tableBuilderShouldHideAircraft({ dataPoints })) {
            this.form.get('summaries.aircraft')?.patchValue('all');

            this.hideOutputs = [ 'aircraft' ];

            return;
        } else {
            this.hideOutputs = [];
        }
    }

    hideEmissionsOutput(dataPoints: TableDataPoints): void {
        if (this.queryType === QueryBuilderType.Table) {
            const checkedValues = Object.values(dataPoints['sustainability']);

            if (checkedValues && checkedValues.every((checked) => !checked)) {
                this.form.get('summaries.emissionsScheme')?.patchValue('all');
                this.hideOutputs = [ ...this.hideOutputs, 'emissionsScheme' ];
            } else {
                const index = this.hideOutputs.indexOf('emissionsScheme');
                this.hideOutputs = this.hideOutputs.splice(index);
            }
        }
    }

    setControls(): void {
        this.nameAndCodeSub?.unsubscribe();

        this.form.get('adjustments.iataCodes')?.enable({ emitEvent: false });
        this.form.get('adjustments.shouldDisplayNames')?.enable({ emitEvent: false });

        const previousSummaries = this.form.get('summaries')?.value;
        const previousAdjustments = this.form.get('adjustments')?.value;

        if (this.queryType === 'table') {

            const summaries = this.setControlsForGroup(this.dataPointsData?.summaryGroups, 'summaries');

            this.form.setControl('summaries', summaries);

            this.form.get('summaries')?.patchValue(previousSummaries);
        }

        const adjustments = this.setControlsForGroup(this.outputGroups, 'outputs');

        const iataDefault = this.userDefaultsService.getPreferenceValue('iataCodes');
        const displayNamesDefault = this.userDefaultsService.getPreferenceValue('shouldDisplayNames');

        const dod = [ 'null', 'undefined' ].includes(String(displayNamesDefault)) ? false : displayNamesDefault;
        const iod = [ 'null', 'undefined' ].includes(String(iataDefault)) ? true : iataDefault;

        adjustments.setControl('shouldDisplayNames', this.fb.control(previousAdjustments?.shouldDisplayNames || dod));

        adjustments.setControl('iataCodes', this.fb.control(previousAdjustments?.iataCodes || iod));

        adjustments.setControl(
            'includeOnlyWhereResultsMatch',
            this.fb.nonNullable.control(false)
        );

        adjustments.setControl(
            'includeOnlyCompleteData',
            this.fb.nonNullable.control(this.queryType !== QueryBuilderType.Table)
        );

        this.form.setControl('adjustments', adjustments);

        this.form.get('adjustments')?.patchValue(previousAdjustments);
    }

    private setControlsForGroup(groupArr: QbOutputSummaryGroup[] = [], key: 'summaries' | 'outputs'): FormGroup {

        const controlsFormGroup = new FormGroup({});

        groupArr.forEach((group) => {

            group[key]?.forEach((item: SummaryOutputRepoItem) => {

                if (item.code === 'operatingCarrier' && !this.form.get('filters.schedulesFilters.franchisePartners')?.value) {
                    (this.form.get('summaries') as FormGroup)?.removeControl(item.code as never);
                    (this.form.get('adjustments') as FormGroup)?.removeControl(item.code as never);
                }

                // if it requires one or more data points to be checked
                if (item.datapointCodes.length) {
                    // for each required data point
                    item.datapointCodes.every((dpc, index) => {

                        // loop through the data point groups
                        for (const dpg of this.dataPointsData?.datapointsGroups || []) {
                            // check if the data point is present and checked
                            if (this.form.get(`dataPoints.${ dpg.code }.${ dpc }`)?.value || key === 'outputs') {

                                const { validator, options } = this.validatorAndOptionsForControl(item);

                                // then we can add the option
                                controlsFormGroup.addControl(item.code, this.fb.control(
                                    this.userDefaultsService.getPreferenceValue(item.code) || options[0] || false,
                                    validator
                                ));

                                return false;
                            }
                        }

                        // if we got to the end and the required option is not checked, remove it if it.
                        if (index + 1 === item.datapointCodes.length) {
                            controlsFormGroup.removeControl(item.code as never);
                        }

                        return true;
                    });

                    // bespoke behavior
                    if (item.code === 'operatingCarrier' && !this.form.get('filters.schedulesFilters.franchisePartners')?.value) {
                        controlsFormGroup.removeControl(item.code as never);
                    }
                } else { // it's always present
                    const { validator, options } = this.validatorAndOptionsForControl(item);

                    if (!controlsFormGroup.get(item.code)) {

                        controlsFormGroup.addControl(item.code, this.fb.control(
                            this.userDefaultsService.getPreferenceValue(item.code) || options[0] || false,
                            validator
                        ));

                    }

                }
            });
        });

        return controlsFormGroup;
    }


    groupHasControls(groupName: string, entries: QbOutputSummaryGroup[] = []): boolean {
        return !!entries.find((item) => !!this.form.get(`${groupName}.${item.code}`));
    }

    private validatorAndOptionsForControl(item: QbOutputSummaryGroup): { validator: ValidatorFn | []; options: string[] } {

        let options: RepoItem<string>[];
        let mappedOrderedOptions: string[];

        if (item.code === 'currency') {
            options = this.dataPointsData?.dropdowns.currencies || [];

            mappedOrderedOptions = new DisplayOrderPipe()
                .transform(options)
                .map((opt) => opt.code);

            return {
                validator: mappedOrderedOptions.length ? CustomValidators.inArray(mappedOrderedOptions) : [],
                options: [ 'GBP' ], // default currency, will be set by user eventually but still need a default.
            };
        }

        options = new DropdownOptionsForSummaryPipe()
            .transform(item, this.dataPointsData?.dropdowns.dropdownOptionGroups);

        mappedOrderedOptions = new DisplayOrderPipe()
            .transform(options)
            .map((opt) => opt.code);

        // some are checkboxes, so have no options, so can be falsey
        return {
            validator: [],
            options: mappedOrderedOptions,
        };
    }

    onOpenSettings(): void {
        const dialogRef = this.dialog.open(SettingsComponent, {
            positionStrategy: this.rdcDialogHelper.dialogPosition(),
            disableClose: true,
            minHeight: '576px',
            maxHeight: '100vh',
            width: '720px',
            panelClass: [ 'rdc-dialog-no-border' ],
        });

        dialogRef.closed.subscribe();
    }
}


