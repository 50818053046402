import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ErrorEffects } from './+state/error.effects';
import * as fromError from './+state/error.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromError.ERROR_FEATURE_KEY,
            fromError.errorReducer
        ),
        EffectsModule.forFeature([ ErrorEffects ]),
    ],
})
export class ErrorStoreModule {}
