import { map, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { APP_USER_PREFERENCES } from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import { httpErrorAction } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/error';
import { appLoaded, AppLoadStatus } from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';

import * as DefaultPreferencesActions from './default-preferences.actions';
import { DefaultPreferencesService } from './default-preferences.service';

@Injectable()
export class DefaultPreferencesEffects {

    getAirportGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultPreferencesActions.getAirportGroups),
            fetch({
                run: () => this.defaultPreferencesService.getAirportGroup()
                    .pipe(
                        map((response) => DefaultPreferencesActions.getAirportGroupsSuccess({ groups: response }))
                    ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    createAirportGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultPreferencesActions.createAirportGroup),
            fetch({
                run: ({ group }) => this.defaultPreferencesService.createAirportGroup(group)
                    .pipe(
                        map((response) => DefaultPreferencesActions.createAirportGroupSuccess({ groups: response }))
                    ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    deleteAirportGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultPreferencesActions.deleteAirportGroup),
            fetch({
                run: ({ groupId }) => this.defaultPreferencesService.deleteAirportGroup(groupId)
                    .pipe(
                        map((response) => DefaultPreferencesActions.deleteAirportGroupSuccess({ groups: response }))
                    ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    getAirlineGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultPreferencesActions.getAirlineGroups),
            fetch({
                run: () => this.defaultPreferencesService.getAirlineGroup()
                    .pipe(
                        map((response) => DefaultPreferencesActions.getAirlineGroupsSuccess({ groups: response }))
                    ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    createAirlineGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultPreferencesActions.createAirlineGroup),
            fetch({
                run: ({ group }) => this.defaultPreferencesService.createAirlineGroup(group)
                    .pipe(
                        map((response) => DefaultPreferencesActions.createAirlineGroupSuccess({ groups: response }))
                    ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    deleteAirlineGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultPreferencesActions.deleteAirlineGroup),
            fetch({
                run: ({ groupId }) => this.defaultPreferencesService.deleteAirlineGroup(groupId)
                    .pipe(
                        map((response) => DefaultPreferencesActions.deleteAirlineGroupSuccess({ groups: response }))
                    ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    getUnitsMeasurements$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultPreferencesActions.getDefaultPreferences),
            fetch({
                run: () => {

                    this.store$.dispatch(AppLoadStatus.loading(
                        APP_USER_PREFERENCES,
                        'Loading preferences...'
                    ));

                    return this.defaultPreferencesService.getUserPreferences()
                        .pipe(
                            map((response) => DefaultPreferencesActions.getDefaultPreferencesSuccess({ unitsMeasurements: response }))
                        )
                },
                onError: (action, response) => of(httpErrorAction({ action, response })),
            })
        )
    );

    updateUnitsMeasurements$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultPreferencesActions.updateDefaultPreferences),
            fetch({
                run: ({ unitsMeasurements }) => this.defaultPreferencesService.updateUserPreferences(unitsMeasurements)
                    .pipe(
                        map((response) => DefaultPreferencesActions.updateUnitsMeasurementsSuccess({ unitsMeasurements: response }))
                    ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    updatePresetColours$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultPreferencesActions.updatePresetColour),
            fetch({
                run: ({ colours }) => this.defaultPreferencesService.updatePresetColours(colours)
                    .pipe(
                        map((response) => DefaultPreferencesActions.updatePresetColourSuccess({ unitsMeasurements: response }))
                    ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    updateMapColours$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultPreferencesActions.updateMapColours),
            fetch({
                run: ({ mapColours }) => this.defaultPreferencesService.updateMapColours(mapColours)
                    .pipe(
                        map(() => DefaultPreferencesActions.updateMapColourSuccess({ mapColours }))
                    ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    getCloudDefaults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultPreferencesActions.getCloudDefaults),
            fetch({
                run: () => this.defaultPreferencesService.getCloudDefaults()
                    .pipe(
                        map(({ originAirport, airlineId }) => DefaultPreferencesActions.getCloudDefaultsSuccess({ originAirport, airlineId }))
                    ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    createCloudDefaults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultPreferencesActions.createCloudDefaults),
            fetch({
                run: ({ originAirportId, airlineId }) => this.defaultPreferencesService.createCloudDefaults(originAirportId, airlineId)
                    .pipe(
                        map((response) => DefaultPreferencesActions.createCloudDefaultsSuccess({ originAirport: response.originAirport, airlineId: response.airlineId }))
                    ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    loaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultPreferencesActions.getDefaultPreferencesSuccess),
            fetch({
                run: () => this.store$.dispatch(appLoaded({ key: APP_USER_PREFERENCES })),
            })
        )
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store,
        private defaultPreferencesService: DefaultPreferencesService
    ) {}
}
