import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { QueryEffects } from './+state/query.effects';
import * as fromQuery from './+state/query.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromQuery.QUERY_FEATURE_KEY,
            fromQuery.queryReducer
        ),
        EffectsModule.forFeature([ QueryEffects ]),
    ],
})
export class QueryStoreModule {}
