import { InjectionToken, ValueProvider } from '@angular/core';

// define environment expectations
export interface AppEnvironment {
    enableAnalytics: boolean;
    production: boolean;
    testing?: boolean;
    development?: boolean;
    api: {
        baseUrl: string;
        featureUrl: string;
    };
    rdcApps: {
        loop: string;
        apc: string;
        apexLegacy: string;
        apexRoutePerformance: string;
        apexLegacyBase: string;
    };
    oidcConfig: {
        IdentityEndpoint: string;
        BaseUrl: string;
        ClientId: string;
        LogoutRedirectUrl: string;
    };
    authRedirect: string;
    salesforceLeadUrl: string;
}

export const appEnvironment = new InjectionToken<AppEnvironment>('rdc-apex.env');

// assign environment from env provided in app.module.ts
export const appEnvironmentProvider = (value: AppEnvironment): ValueProvider => ({
    provide: appEnvironment,
    useValue: value,
});

