import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { ButtonModule } from "@rdc-apps/rdc-shared/src/lib/directives/button";
import { IconModule } from "@rdc-apps/rdc-shared/src/lib/ui/icon";

@Component({
    selector: 'rdc-apps-map-dialog',
    standalone: true,
    imports: [ CommonModule, ButtonModule, IconModule ],
    templateUrl: './map-dialog.component.html',
    styleUrl: './map-dialog.component.scss',
})
export class MapDialogComponent {

    @Output() cloudLink = new EventEmitter();

}
