import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    FILTERS_DATE_RANGE_FEATURE_KEY,
    FiltersDateRangeState
} from './filters-date-range.reducer';

// Lookup the 'FiltersDateRange' feature state managed by NgRx
export const getFiltersDateRangeState =
    createFeatureSelector<FiltersDateRangeState>(
        FILTERS_DATE_RANGE_FEATURE_KEY
    );

export const getLoadedDateRange = createSelector(
    getFiltersDateRangeState,
    (state: FiltersDateRangeState) => state.range
);

export const getFiltersDateRangeLoaded = createSelector(
    getFiltersDateRangeState,
    (state: FiltersDateRangeState) => state.loaded
);

export const getFiltersDateRangeError = createSelector(
    getFiltersDateRangeState,
    (state: FiltersDateRangeState) => state.error
);
