import { Observable, of } from 'rxjs';

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from "@angular/common";
import { AfterViewInit, Component, inject, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AccountModule } from "@rdc-apps/rdc-apex/src/lib/settings/feature/account";
import { ColoursComponent } from "@rdc-apps/rdc-apex/src/lib/settings/feature/colours";
import { DefaultPreferencesModule } from "@rdc-apps/rdc-apex/src/lib/settings/feature/default-preferences";
import { TagManagementModule } from "@rdc-apps/rdc-apex/src/lib/settings/feature/tag-management";
import { TabItemModule } from "@rdc-apps/rdc-apex/src/lib/settings/ui/tab-item";
import { TabsModule } from "@rdc-apps/rdc-apex/src/lib/settings/ui/tabs";
import { PresetColor } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import {
    autocomplete,
    AutocompleteDispatch,
    AutocompleteOption,
    getAllAutocomplete
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/autocomplete';
import {
    DataPointsDropdownData,
    getDataPointsDropDownData
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points';
import {
    createCloudDefaults,
    updateDefaultPreferences, updateMapColours
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/default-preferences';
import { TagEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/tags';
import {
    getUserDetailsData,
    updateUserDetails,
    UserDetails
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/user-details';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { ToastType } from "@rdc-apps/rdc-shared/src/lib/data-access/models";
import { ButtonModule } from "@rdc-apps/rdc-shared/src/lib/directives/button";
import { ToastService } from "@rdc-apps/rdc-shared/src/lib/ui/toast";
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';
import { UserPreferences } from "@schematics/angular/third_party/github.com/Microsoft/TypeScript/lib/typescript";
import { MapColoursComponent } from "map-colours";

@Component({
    standalone: true,
    selector: 'rdc-apps-settings',
    templateUrl: './settings.component.html',
    styleUrls: [ './settings.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [
        TabsModule,
        TabItemModule,
        DefaultPreferencesModule,
        MapColoursComponent,
        TagManagementModule,
        AccountModule,
        ButtonModule,
        AsyncPipe,
        ColoursComponent
    ]
})
export class SettingsComponent extends RdcComponentUtils implements AfterViewInit {

    autocompleteResults$: Observable<AutocompleteOption[]> = of([]);
    dataPointsDropDownData$!: Observable<DataPointsDropdownData>;
    userDetails$!: Observable<UserDetails | undefined>;

    form!: FormGroup;
    defaultPreferencesValues!: Partial<UserPreferences>;

    dialogData: { activeTabIndex?: number } | null = inject(DIALOG_DATA);

    constructor(
        private store$: Store,
        private fb: FormBuilder,
        private toast: ToastService,
        @Inject(appEnvironment) public env: AppEnvironment,
        private dialogRef?: DialogRef
    ) {
        super();

        this.autocompleteResults$ = this.store$.select(getAllAutocomplete);
        this.dataPointsDropDownData$ = this.store$.select(getDataPointsDropDownData('dataPointState'));
        this.userDetails$ = this.store$.select(getUserDetailsData('userDetailsState'));

        this.form = this.fb.group({
            account: new FormGroup(this.fb.group({
                email: this.fb.control({ value: '', disabled: true }, Validators.required),
                organisation: this.fb.control({ value: '', disabled: true }),
                firstName: this.fb.control('', Validators.required),
                lastName: this.fb.control('', Validators.required),
                jobTitle: this.fb.control(''),
                passwords: this.fb.group({
                    password: this.fb.control(''),
                    confirmPassword: this.fb.control(''),
                }),
            }).controls, { updateOn: 'blur' }),
            defaultPreferences: this.fb.group({
                defaultAirports: this.fb.array([]),
                defaultAirlines: this.fb.array([]),
                distanceUnits: this.fb.control('', Validators.required),
                tableOutput: this.fb.control('', Validators.required),
                currency: this.fb.control('', Validators.required),
                directionality: this.fb.control('bothWays', Validators.required),
                governmentTaxes: this.fb.control(true),
                includeOnlyCompleteData: this.fb.control(true),
                airportCharges: this.fb.control(true),
                iataCodes: this.fb.control(true),
                shouldDisplayNames: this.fb.control(false),
                reportingLevel: this.fb.control('', Validators.required),
                cloudDefaultAirport: this.fb.control(null),
                cloudDefaultAirline: this.fb.control(null),
                presetColours:  this.fb.array([]),
                mapColours: this.fb.group({
                    landColour: this.fb.control('#DEF2DE'),
                    seaColour: this.fb.control('#E2F7FD'),
                    borderColour: this.fb.control('#C7DECF'),
                    unservedColour: this.fb.control('#DA5555'),
                    servedColour: this.fb.control('#4D66F2'),
                }),
            }),
            colours: this.fb.array<PresetColor>([]),
            tags: this.fb.array<TagEntity>([]),
        });
    }

    get accountFormGroup(): FormGroup {
        return this.form.get('account') as FormGroup;
    }

    get defaultPreferencesFormGroup(): FormGroup {
        return this.form.get('defaultPreferences') as FormGroup;
    }

    onAutoComplete(autoComplete: AutocompleteDispatch): void {
        this.store$.dispatch(autocomplete({ query: autoComplete.query, filter: autoComplete.filter }));
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.defaultPreferencesValues = this.defaultPreferencesFormGroup.getRawValue()
        },250);
    }

    onDone(): void {

        if (this.accountFormGroup.invalid) {
            return;
        }

        const { firstName, lastName, jobTitle } = this.accountFormGroup.value;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { defaultAirports, defaultAirlines, ...restOfPreferences } = this.defaultPreferencesFormGroup.getRawValue();

        if (this.accountFormGroup.touched) {
            this.store$.dispatch(updateUserDetails({ updateUserDetails: { firstName, lastName, jobTitle } }));
        }

        // check to see if preferences have changed, if they have then allow the following (stops the preferences on QB being overwritten)
        const samePreferencesValues = JSON.stringify(this.defaultPreferencesValues) === JSON.stringify(this.defaultPreferencesFormGroup.getRawValue());

        if (!samePreferencesValues) {

            if (this.defaultPreferencesFormGroup.invalid) {
                this.toast.simpleToast(ToastType.ERROR, 'Please ensure all required fields are populated', 5000);

                return;
            }

            const { cloudDefaultAirport, cloudDefaultAirline } = this.defaultPreferencesFormGroup.getRawValue();

            this.store$.dispatch(createCloudDefaults({
                originAirportId: cloudDefaultAirport ? cloudDefaultAirport?.id : null,
                airlineId: cloudDefaultAirline ? cloudDefaultAirline?.id : null
            }));

            this.store$.dispatch(updateDefaultPreferences({ unitsMeasurements: restOfPreferences }));

            this.store$.dispatch(updateMapColours({ mapColours: restOfPreferences.mapColours }));
        }

        this.dialogRef?.close();
    }
}
