import { KeyValue } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataPointBaseComponent } from '@rdc-apps/rdc-apex/src/lib/query-builder/utilities';

@Component({
    selector: 'rdc-apps-multi-select-checkbox',
    templateUrl: './multi-select-checkbox.component.html',
    styleUrls: [ './multi-select-checkbox.component.scss' ],
})
export class MultiSelectCheckboxComponent extends DataPointBaseComponent {

    selectUnselectAll(
        formGroupName: string,
        controls: KeyValue<string, FormControl<boolean>>[]
    ) {
        const setTo = !this.allChecked(formGroupName, controls);

        const patch: Record<string, Record<string, boolean>> = { [formGroupName]: {} };

        this.controls.forEach((control) => {
            patch[formGroupName][control.key] = setTo;
        });

        this.form.get(`${this.givenFormGroupName}`)?.patchValue(patch); // update all in one go
    }

    allChecked(formGroupName: string, dataPointControls: KeyValue<string, FormControl<boolean>>[]): boolean {
        return dataPointControls
            .map((keyVal) => keyVal.value.value)
            .filter((checked: boolean) => checked).length === this.controls.length;
    }
}
