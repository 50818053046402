import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';

import { NoResultsComponent } from './no-results.component';

@NgModule({
    imports: [ CommonModule, RouterModule, ButtonModule ],
    declarations: [ NoResultsComponent ],
    exports: [ NoResultsComponent ],
})
export class NoResultsModule {}
