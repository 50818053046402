import { take } from "rxjs";

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ConnectedPosition, Overlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { QueryBuilderUtils } from "@rdc-apps/rdc-apex/src/lib/query-builder/utilities";
import { QueryBuilderType } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { QueryService } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/services';
import { QueryEntity } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/query';
import {
    connectedAbove,
    connectedBelow,
    connectedLeft,
    connectedRight,
    easing,
    overlayPositions
} from '@rdc-apps/rdc-shared/src/lib/constants';
import { sendActivity } from '@rdc-apps/rdc-shared/src/lib/data-access/actions';
import { RdcComponentUtils, RdcDialogHelper } from '@rdc-apps/rdc-shared/src/lib/utilities';
import { TableBuilderDialogComponent } from "table-builder-dialog";

@Component({
    selector: 'rdc-apps-qb-top-bar',
    templateUrl: './qb-top-bar.component.html',
    styleUrls: [ './qb-top-bar.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    animations: [
        trigger('rotate', [
            state('open', style({
                transform: 'rotate(180deg)',
            })),
            state('closed', style({
                transform: 'rotate(0deg)',
            })),
            transition('open <=> closed', [
                animate(`500ms ${ easing.standard }`),
            ]),
        ]),
    ],
})
export class QbTopBarComponent extends RdcComponentUtils {

    @ViewChild('savedQueryMenu', { static: true }) savedQueryMenu!: TemplateRef<unknown>;

    @Input() nameControlFormGroup: FormGroup = new FormGroup({
        studyName: new FormControl(''),
    });

    @Input() isSaving: boolean | null = false;
    @Input() editingQuery: boolean | null = false;
    @Input() studyType: 'table' | 'chart' | 'scatter' = QueryBuilderType.Table;
    @Input() savedQueries: QueryEntity[] = [];
    @Input() basicBuilder!: boolean;
    @Input() activeStudyId: string | number | undefined = '';
    @Input() disableExport: boolean | null | undefined;

    @Output() saveAction: EventEmitter<void> = new EventEmitter<void>();
    @Output() exportAction: EventEmitter<void> = new EventEmitter<void>();
    @Output() saveAsAction: EventEmitter<void> = new EventEmitter<void>();

    overlayPositions: Map<string, ConnectedPosition[]> = overlayPositions;
    nameValidationMessages: Record<string, string> = {
        required: 'Name is required',
    };
    date = Date.now();

    dialogRef: DialogRef | undefined;
    constructor(
        private dialog: Dialog,
        private overlay: Overlay,
        private store$: Store,
        private router: Router,
        private route: ActivatedRoute,
        private rdcDialogHelper: RdcDialogHelper,
        public queryService: QueryService
    ) {
        super();
    }

    get disabledSavedQueries(): boolean {
        // check if there are saved queries or the current viewed saved query is equivalent to route params id
        return (
            this.savedQueries.length === 0 ||
            this.savedQueries.length === 1 && this.savedQueries[0].studyId === this.route.snapshot.params['id']
        );
    }


    onOpenLoadMenu(selectTrigger: HTMLButtonElement | HTMLInputElement, focus = true, restoreFocus = true) {
        event?.preventDefault();

        this.dialogRef?.close();

        const defaultMenuPosition = overlayPositions.get('below-center-right') || [ connectedBelow ];

        this.dialogRef = this.dialog.open(this.savedQueryMenu, {
            id: 'load-menu',
            backdropClass: 'none',
            panelClass: [ 'rdc-select-component-dialog' ],
            width: '240px',
            height: '0',
            autoFocus: focus ? 'first-tabbable' : 'non_existing_element',
            restoreFocus,
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(selectTrigger)
                .withPositions([
                    defaultMenuPosition[0],
                    connectedAbove,
                    connectedRight,
                    connectedLeft
                ]),
        });

        this.dialogRef.closed
            .pipe(take(1))
            .subscribe(() => {
                this.dialogRef = undefined;
            });
    }

    onSaveKeyDown(event: Event): void {
        event.preventDefault();
        this.saveAction.emit();
    }

    onNewQuery(): void {

        let activityCode = 'table';

        if (this.studyType === 'table') {
            const dialog = this.dialog.open(TableBuilderDialogComponent, {
                width: '100%',
                panelClass: [ 'rdc-dialog' ],
                positionStrategy: this.rdcDialogHelper.dialogPosition(),
                maxWidth: 400,
            });

            dialog.componentInstance?.basic
                .pipe(take(1))
                .subscribe((bqb) => {
                    this.router.navigate([ '/', 'query-builder', this.studyType ], { queryParams: { ts: this.date, bqb } })

                    this.store$.dispatch(sendActivity({
                        activity: {
                            activityCode: `rdc.q.apex.query.${ activityCode }.new`, // chart or scatter
                            detail: {
                                area: 'Data & Visualisation',
                                launchSource: 'Query.New',
                                creationType: this.studyType === 'table'
                                    ? bqb
                                        ? 'basic'
                                        : 'extended'
                                    : 'blank',
                            },
                        },
                    }));
                });
        }

        if (this.studyType !== 'table') {
            activityCode = this.studyType === 'chart' ? 'multi' : 'scatter';

            this.router.navigate([ '/', 'query-builder', this.studyType ], { queryParams: { ts: this.date } });

            this.store$.dispatch(sendActivity({
                activity: {
                    activityCode: `rdc.q.apex.query.${ activityCode }.new`, // chart or scatter
                    detail: {
                        area: 'Data & Visualisation',
                        launchSource: 'Query.New',
                        creationType: 'blank',
                    },
                },
            }));
        }
    }

    onSaveAs(): void {

        let activityCode = 'table';

        if (this.studyType !== 'table') {
            activityCode = this.studyType === 'chart' ? 'multi' : 'scatter';
        }

        this.store$.dispatch(sendActivity({
            activity: {
                activityCode: `rdc.q.apex.query.${ activityCode }.save-as`,
                detail: {
                    area: 'Data & Visualisation',
                    launchSource: 'Query.SaveAs',
                },
            },
        }));
    }

    onExport(): void {
        this.exportAction.emit();
    }

    onLoadSaved(query: QueryEntity): void {
        const queryType = QueryBuilderUtils.getTypeOfQuery(query as never);

        this.router.navigate(
        [ '/', 'query-builder', queryType.type, query.studyId ],
        {
            queryParams: {
                ts: Date.now(),
            },
        });
    }
}
