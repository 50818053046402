// add / remove items here for them to disappear from the
// filters section of the query builder form.

import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

export const timePeriodOptions: [ 'relative', 'specific' ] = [ 'relative', 'specific' ];

export const specificTimeFrameOptions: [ 'months', 'quarters', 'seasons' ] = [ 'months', 'quarters', 'seasons'  ];

export const specificQuarterOptions: [ 'q1', 'q2', 'q3', 'q4' ] = [ 'q1', 'q2', 'q3', 'q4' ];

export const autocompleteTypeFilters: [ 'origin', 'destination', 'airline', 'aircraft' ] = [
    'origin',
    'destination',
    'airline',
    'aircraft',
];

export const displayOptionsSelections = [
    { label: 'Name only', code: 'nameOnly' },
    { label: 'Code only', code: 'codeOnly' },
    { label: 'Name and code', code: 'both' },
];

export const specificTimeFrameOpts: Partial<RepoItem<string>>[] = [
    { label: 'Months', code: 'months' },
    { label: 'Quarters', code: 'quarters' },
    { label: 'Seasons', code: 'seasons' },
];

export const showByOpts: Partial<RepoItem<string>>[] = [
    { label: 'Months', code: 'monthandYear' },
    { label: 'Quarters', code: 'calendarQuarter' },
    { label: 'Seasons', code: 'season' },
    { label: 'Year', code: 'year' },
    { label: 'All', code: 'all' },
];
