import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as TagsActions from './tags.actions';
import { TagEntity } from './tags.models';

export const TAGS_FEATURE_KEY = 'tags';

export interface TagsState extends EntityState<TagEntity> {
    selectedId?: string | number; // which Tags record has been selected
    loading: boolean;
    tagCount: number;
    loaded: boolean; // has the Tags list been loaded
    error?: string | null; // last known error (if any)
}

export interface TagsPartialState {
    readonly [TAGS_FEATURE_KEY]: TagsState;
}

export const tagsAdapter: EntityAdapter<TagEntity> =
    createEntityAdapter<TagEntity>();

export const initialTagsState: TagsState = tagsAdapter.getInitialState({
    // set initial required properties
    loading: false,
    loaded: false,
    tagCount: 0,
});

const reducer = createReducer(
    initialTagsState,
    on(TagsActions.getTags, (state) => ({
        ...state,
        loading: true,
        loaded: false,
        error: null,
    })),
    on(TagsActions.getTagsSuccess, (state, { tags }) =>
        tagsAdapter.setAll(tags, { ...state, tagCount: tags.length, loading: false, loaded: true })
    ),
    on(TagsActions.searchTagsSuccess, (state, { tags }) =>
        tagsAdapter.setAll(tags, { ...state, loading: false, loaded: true })
    ),
    on(TagsActions.getTagsFailure, (state, { error }) => ({ ...state, error })),

    on(TagsActions.setStudyTags, (state) => ({ ...state, loading: true, loaded: false })),

    on(TagsActions.removeTagsFromStudy, (state) => ({ ...state, loading: true, loaded: false })),

    on(TagsActions.setStudyTagsSuccess, (state) => ({ ...state, loading: false, loaded: true })),

    on(TagsActions.removeTagsSuccess, (state) => ({ ...state, loading: false, loaded: true })),

    on(TagsActions.tagsFailure, (state) => ({ ...state, loading: false, loaded: false })),

    on(TagsActions.createTags, (state) => ({
        ...state,
        loading: true,
        loaded: false,
        error: null,
    })),
    on(TagsActions.createTagsSuccess, (state, { tags }) =>
        tagsAdapter.setMany(tags, { ...state, loading: false, loaded: true })
    ),
    on(TagsActions.createTagsFailure, (state, { error }) => ({ ...state, error })),

    on(TagsActions.deleteTags, (state) => ({ ...state, loading: true, loaded: false })),

    on(TagsActions.deleteTagsSuccess, (state, { tagIds }) =>
        tagsAdapter.removeMany(tagIds, { ...state, loading: false, loaded: true, tagCount: state.tagCount - 1 })),

    on(TagsActions.deleteTagsFailure, (state, { error }) => ({ ...state, error }))
);

export function tagsReducer(state: TagsState | undefined, action: Action) {
    return reducer(state, action);
}
