import { Icon } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

export interface EmptyCloudItem {
    icon: string;
    iconSize: Icon;
    type: string;
    launch: string;
    cloud: string;
    title: string;
    copy: string;
}
