import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TabItemModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/tab-item';
import { TabsModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/tabs';
import { CompactSavedQueriesMenuModule } from '@rdc-apps/rdc-apex/src/lib/shared/ui/compact-saved-queries-menu';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { DialogItemModule } from '@rdc-apps/rdc-shared/src/lib/directives/dialog-item';
import { TooltipModule } from "@rdc-apps/rdc-shared/src/lib/directives/tooltip";
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';

import { QueryBuilderQuickLauncherComponent } from './query-builder-quick-launcher.component';

@NgModule({
    imports: [
        CommonModule,
        CompactSavedQueriesMenuModule,
        ButtonModule,
        RouterLink,
        CdkMenuModule,
        IconModule,
        DialogItemModule,
        TabItemModule,
        TabsModule,
        TooltipModule,
    ],
    declarations: [ QueryBuilderQuickLauncherComponent ],
    exports: [ QueryBuilderQuickLauncherComponent ],
})
export class QueryBuilderQuickLauncherModule {}
