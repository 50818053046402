import { Pipe, PipeTransform } from "@angular/core";
import { UserDetails } from "@rdc-apps/rdc-apex/src/lib/shared/data-access/store/user-details";

@Pipe({ name: 'hasCloudAccess', standalone: true })
export class HasCloudAccessPipe implements PipeTransform {

    transform(userDetails: UserDetails | undefined | null, ...clouds: string[]): boolean {

        if(!userDetails) {
            return false;
        }

        return clouds.every((cloud) => userDetails.organisation.activeCloudCodes.includes(cloud));
    }
}
