<div class="icon-input" [ngClass]="{ compact: display }">
    <input
        #autocompleteInput
        autocomplete="off"
        placeholder="Search {{ filterableName }}"
        type="search"
        [disabled]="disabled"
        [ngClass]="{ 'ng-invalid': invalid }"
        (input)="suggestFor(autocompleteInput.value)"
        (focus)="onFocus()"
        (blur)="inputBlur.emit()"
        (keydown.enter)="$event.preventDefault()"
    >
        <rdc-apps-icon *ngIf="!autocompleteInput.value">search</rdc-apps-icon>

        <rdc-apps-icon
            *ngIf="autocompleteInput.value"
            class="cancel"
            (click)="onClear()"
        >
            cancel
        </rdc-apps-icon>
</div>

<!-- Autocomplete menu -->
<ng-template #menuTemplate>
    <div
        *ngIf="autocompleteInput.value"
        #menuContainer
        cdkMenu
        class="autocomplete"
        [ngStyle]="{ width: autocompleteInput.offsetWidth + 'px' }"
    >

        <span *ngIf="!autocompleteInput.value || !autocompleteResults?.length" class="type-to-search">
            No matches found
        </span>

        <ng-container *ngFor="let result of autocompleteResults">
            <button
                rdcAppsMenuItem
                cdkMenuItem
                (cdkMenuItemTriggered)="onSelect(result)"
            >
                <span>{{ result.label }}</span>
            </button>
        </ng-container>

    </div>
</ng-template>


