import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataPointsGroupPillsModule } from '@rdc-apps/rdc-apex/src/lib/query-builder/ui/data-points-group-pills';
import {
    DataPointsManualSelectionModule
} from '@rdc-apps/rdc-apex/src/lib/query-builder/ui/data-points-manual-selection';
import { DpFilterModule } from '@rdc-apps/rdc-apex/src/lib/shared/pipes/dp-filter';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { MultiSelectCheckboxModule } from '@rdc-apps/rdc-shared/src/lib/ui/multi-select-checkbox';
import { NoResultsModule } from '@rdc-apps/rdc-shared/src/lib/ui/no-results';

import { QbDataPointsComponent } from './qb-data-points.component';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        ReactiveFormsModule,
        DpFilterModule,
        DataPointsGroupPillsModule,
        DataPointsManualSelectionModule,
        FormsModule,
        MultiSelectCheckboxModule,
        NoResultsModule,
    ],
    declarations: [ QbDataPointsComponent ],
    exports: [
        QbDataPointsComponent,
    ],
})
export class QbDataPointsModule {}
