import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { DialogItemModule } from '@rdc-apps/rdc-shared/src/lib/directives/dialog-item';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { TagCreatorModule } from '@rdc-apps/rdc-shared/src/lib/ui/tag-creator';
import { ValidatedControlModule } from '@rdc-apps/rdc-shared/src/lib/ui/validated-control';

import { QbSaveAsComponent } from './qb-save-as.component';

@NgModule({
    imports: [ CommonModule, ButtonModule, DialogItemModule, ReactiveFormsModule, FormsModule, ValidatedControlModule, IconModule, TagCreatorModule ],
    declarations: [ QbSaveAsComponent ],
    exports: [ QbSaveAsComponent ],
})
export class QbSaveAsModule {}
