<a *ngIf="type === 'link' else button"
   class="rdc-sidebar-item"
   [routerLink]="route"
   [queryParams]="queryParams"
   [attr.disabled]="disabled"
   [ngClass]="{
        'support-item': role === 'support',
        'account-item': role === 'account',
        'menu-active': menuActive,
        'is-active': isActive,
        'expand-menu-child': childItem,
        expanded,
   }"
   rdcAppsTooltip
   routerLinkActive="active"
   [routerLinkActiveOptions]="{ exact: requireExactRoute }"
   [@shrinkSidebarItem]="expanded ? 'max' : 'min'"
   [tooltipText]="label"
   tooltipPosition="right"
   [tooltipDisabled]="expanded"
   (click)="onSideMenuLinkClick()"
>
        <div *ngIf="childItem" class="child-border"></div>

        <div class="icon-container">
            <rdc-apps-icon
                *ngIf="icon"
                size="xxs"
                [rdcIcon]="isActive && activeIcon ? activeIcon || '' : icon || ''"
                [ngClass]="{ 'child-icon': childItem }"
                [sideBarIcon]="!childItem"
                [sideBarExpanded]="(role === 'account') ? true : expanded">
                {{ icon }}
            </rdc-apps-icon>

        </div>

        <span *ngIf="role !== 'account'">{{ label }}</span>

        <div class="account-info" *ngIf="role === 'account'">
            <span>{{ label }}</span>
            <span class="account-email">{{ accountEmail }}</span>
        </div>
</a>


<ng-template #button>
    <button
        #triggerButton
        class="rdc-sidebar-item"
        [attr.id]="itemId"
        [ngClass]="{
            'support-item': role === 'support',
            'account-item': role === 'account',
            'menu-active': menuActive,
            'active': isActive,
            'expand-menu-child': childItem,
            'expandable': expands,
            expanded,
        }"
        rdcAppsTooltip
        [@shrinkSidebarItem]="expanded ? 'max' : 'min'"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: requireExactRoute }"
        [disabled]="disabled"
        [tooltipText]="label"
        tooltipPosition="right"
        [tooltipDisabled]="expanded"
        (click)="onSideMenuButtonClick(triggerButton)"
    >
            <div *ngIf="childItem" class="child-border"></div>

            <div class="icon-container">
                <rdc-apps-icon
                    *ngIf="icon"
                    [rdcIcon]="isActive && activeIcon ? activeIcon || '' : icon || ''"
                    [ngClass]="{ 'child-icon': childItem }"
                    size="xxs"
                    [sideBarIcon]="!childItem"
                    [sideBarExpanded]="(role === 'account') ? true : expanded">
                    {{ icon }}
                </rdc-apps-icon>

                <rdc-apps-icon
                    *ngIf="expands && !expanded"
                    class="expand-arrow"
                    size="xs"
                >
                    expand_more
                </rdc-apps-icon>
            </div>

            <span *ngIf="role !== 'account'">{{ label }}</span>

            <div class="account-info" *ngIf="role === 'account'">
                <span>{{ label }}</span>
                <span class="account-email">{{ accountEmail }}</span>
            </div>
    </button>
</ng-template>
