import { take } from 'rxjs';

import { CdkMenuTrigger } from '@angular/cdk/menu';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output, QueryList, TemplateRef,
    ViewChild, ViewChildren, ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { Actions } from '@ngrx/effects';
import { AutocompleteOption } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/autocomplete';
import { overlayPositions } from '@rdc-apps/rdc-shared/src/lib/constants';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';

@Component({
    selector: 'rdc-apps-old-autocomplete',
    templateUrl: './old-autocomplete.component.html',
    styleUrls: [ './old-autocomplete.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class OldAutocompleteComponent<T extends AutocompleteOption> extends RdcComponentUtils {

    @ViewChild('autocompleteInput', { static: true }) autocompleteInput!: ElementRef<HTMLInputElement>;

    @ViewChild('autoCompleteMenu', { static: true }) autoCompleteMenu!: CdkMenuTrigger;

    @ViewChild('menuTemplate', { static: true }) menuTemplateRef!: TemplateRef<HTMLTemplateElement>;

    @ViewChildren('menuContainer') menuContainer!: QueryList<ElementRef<HTMLButtonElement>>;

    @Input() display: null | 'compact' = null;

    @Input() disabled = false;

    @Input() invalid: boolean | null | undefined = false;

    @Input() filterableName = 'items';

    @Input() autocompleteResults: T[] | null = [];

    @Input() hasBackDrop = true;

    @Output() selectedOption: EventEmitter<T> = new EventEmitter<T>();

    @Output() inputBlur: EventEmitter<void> = new EventEmitter<void>();

    @Output() autocomplete: EventEmitter<string> = new EventEmitter<string>();

    overlayRef!: OverlayRef;
    inFocus = false;

    constructor(
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private viewContainerRef: ViewContainerRef,
        private actions: Actions
    ) {
        super(actions);
    }


    suggestFor(queryString: string): void {
        if (queryString.trim().length >= 2 || !queryString.length) {
            this.autocomplete.emit(queryString);
        }
    }

    onSelect(option: T): void {
        this.selectedOption.emit(option);

        this.overlayRef?.dispose();

        this.onReset(false);

        setTimeout(() => {
            this.autocompleteInput.nativeElement.focus();
        });
    }

    onClear(): void {
        this.autocompleteInput.nativeElement.value = '';
    }

    onFocus(): void {
        if (this.overlayRef?.hasAttached()) {
            return;
        }

        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.autocompleteInput)
            .withPositions(overlayPositions.get('below') || []);

        this.overlayRef = this.overlay.create({ positionStrategy, hasBackdrop: this.hasBackDrop, backdropClass: 'autocomplete-backdrop' });

        const autocompleteMenu = new TemplatePortal(this.menuTemplateRef, this.viewContainerRef);

        this.overlayRef.attach(autocompleteMenu);

        this.overlayRef.keydownEvents()
            .subscribe((key) => {
                if (key.code === 'ArrowDown' && !this.inFocus) {
                    this.menuContainer.first.nativeElement.focus();
                    this.inFocus = true;
                }
                if ([ 'Escape', 'Tab' ].includes(key.code)) {
                    this.onReset();
                    this.overlayRef?.dispose();
                }
            });

        this.overlayRef.backdropClick().pipe(take(1)).subscribe(() => {
            this.onReset();
            this.overlayRef?.dispose();
        });

    }

    onReset(blur = true): void {
        this.autocomplete.emit('');
        this.autocompleteInput.nativeElement.value = '';
        this.inFocus = false;

        if (blur) {
            this.autocompleteInput.nativeElement.blur();
        }
    }
}
