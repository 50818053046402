import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TagsEffects } from './+state/tags.effects';
import * as fromTags from './+state/tags.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(fromTags.TAGS_FEATURE_KEY, fromTags.tagsReducer),
        EffectsModule.forFeature([ TagsEffects ]),
    ],
})
export class TagsStoreModule {}
