import { createAction, props } from '@ngrx/store';
import { RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

import { Availability, AvailableYears, AvailableYearsResponse, NetworkEntity, NetworkSaveStudy } from './network.models';


export const loadNetworkQueriesAction = createAction(
    '[Network/API] Load Network Queries',
);

export const loadNetworkQueriesSuccessAction = createAction(
    '[Network/API] Load Network Queries Success',
    props<{ networkQueries: NetworkEntity[] }>()
);

export const loadNetworkQueriesFailureAction = createAction(
    '[Network/API] Load Network Queries Failure',
    props<{ error: any }>()
);

export const setNetworkActiveQueryAction = createAction(
    '[Network] Set Network Active Query',
    props<{ selectedId: string }>()
);

export const saveNetworkQueryAction = createAction(
    '[Network/API] Save Network Query',
    props<NetworkSaveStudy>()
);

export const saveAsNetworkQueryAction = createAction(
    '[Network/API] Save As Network Query',
    props<{ networkQuery: NetworkEntity, studyId: string }>()
);

export const saveAsDestNetworkQueryAction = createAction(
    '[Network/API] Save As Network Query',
    props<{ networkQuery: NetworkEntity, studyId: string }>()
);

export const saveNetworkQuerySuccessAction = createAction(
    '[Network/API] Save Network Query Success',
    props<{ networkQuery: NetworkEntity, studyId: string }>()
);

export const saveNetworkQueriesFailureAction = createAction(
    '[Network/API] Save Network Query Failure',
    props<{ error: any }>()
);

export const availabilityNetworkAction = createAction(
    '[Network/API] Availability Network',
    props<{ airlineId: number, originAirportId: number, timeframe: string }>()
);

export const availabilityNetworkSuccessAction = createAction(
    '[Network/API] Availability Network Success',
    props<{ availability: Availability }>()
);

export const availabilityNetworkFailureAction = createAction(
    '[Network/API] Availability Network Failure',
    props<{ error: unknown }>()
);

export const getAirlinesNetworkAction = createAction(
    '[Network/API] Get Airlines Network',
);

export const getAirlinesNetworkSuccessAction = createAction(
    '[Network/API] Get Airlines Network Success',
    props<{ results: RepoItem<string>[] }>()
);

export const getAirlinesNetworkFailureAction = createAction(
    '[Network/API] Get Airlines Network Failure',
    props<{ error: unknown }>()
);

export const getDestinationsNetworkAction = createAction(
    '[Network/API] Get Destinations Network',
    props<{ originAirportId: number, airlineId: number, applet: 'destination' | 'time' }>()
);

export const getDestinationsNetworkSuccessAction = createAction(
    '[Network/API] Get Destinations Network Success',
    props<{ results: RepoItem<string>[] }>()
);

export const getDestinationsNetworkFailureAction = createAction(
    '[Network/API] Get Destinations Network Failure',
    props<{ error: any }>()
);

export const getAvailableYearsNetworkAction = createAction(
    '[Network/API] Get Available Years Network',
    props<{ availableYears: AvailableYears, applet?: 'destination' | 'time' }>()
);

export const getAvailableYearsNetworkSuccessAction = createAction(
    '[Network/API] Get Available Years Network Success',
    props<{ availableYears: AvailableYearsResponse }>()
);

export const getDestAvailableYearsNetworkSuccessAction = createAction(
    '[Network/API] Get Available Years Network Success',
    props<{ availableYears: number[] }>()
);

export const getAvailableYearsNetworkNetworkFailureAction = createAction(
    '[Network/API] Get Available Years Network Failure',
    props<{ error: any }>()
);
