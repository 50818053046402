import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressBarModule } from '@rdc-apps/rdc-shared/src/lib/ui/progress-bar';

import { TempUserAuthorizeComponent } from './temp-user-authorize.component';

@NgModule({
    imports: [ CommonModule, ProgressBarModule ],
    declarations: [ TempUserAuthorizeComponent ],
})
export class TempUserAuthorizeModule {}
