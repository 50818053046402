import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RdcCarrotDirective } from './rdc-carrot.directive';

@NgModule({
    declarations: [ RdcCarrotDirective ],
    exports: [ RdcCarrotDirective ],
    imports: [ CommonModule ],
})
export class RdcCarrotModule {}
