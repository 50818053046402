import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';

import { EmptyStateComponent } from './empty-state.component';

@NgModule({
    imports: [ CommonModule, NgOptimizedImage ],
    declarations: [ EmptyStateComponent ],
    exports: [
        EmptyStateComponent,
    ],
})
export class EmptyStateModule {}
