import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as UserDetailsActions from './user-details.actions';
import { UserDetailsEntity } from './user-details.models';

export const USER_DETAILS_FEATURE_KEY = 'userDetails';

export interface UserDetailsState extends EntityState<UserDetailsEntity> {
    selectedId?: string | number; // which UserDetails record has been selected
    loading: boolean; // has the UserDetails list been loaded
    loaded: boolean; // has the UserDetails list been loaded
    error?: string | null; // last known error (if any)
}

export interface UserDetailsPartialState {
    readonly [USER_DETAILS_FEATURE_KEY]: UserDetailsState;
}

export const userDetailsAdapter: EntityAdapter<UserDetailsEntity> =
    createEntityAdapter<UserDetailsEntity>({
        selectId: () => 'userDetailsState',
    });

export const initialUserDetailsState: UserDetailsState =
    userDetailsAdapter.getInitialState({
        // set initial required properties
        loading: false,
        loaded: false,
    });

const reducer = createReducer(
    initialUserDetailsState,
    on(UserDetailsActions.getUserDetails, (state) => ({
        ...state,
        loading: true,
        loaded: false,
        error: null,
    })),
    on(UserDetailsActions.getUserDetailsSuccess, (state, { userDetails }) =>
        userDetailsAdapter.setOne({ userDetails }, { ...state, loading: false, loaded: true })
    ),
    on(UserDetailsActions.getUserDetailsFailure, (state, { error }) => ({
        ...state,
        error,
    })),
    on(UserDetailsActions.updateUserDetails, (state) => ({
        ...state,
        loaded: false,
        error: null,
    })),
    on(UserDetailsActions.updateUserDetailsSuccess, (state, { userDetails }) =>
        userDetailsAdapter.setOne({ userDetails }, { ...state, loaded: true })
    ),
    on(UserDetailsActions.updateUserDetailsFailure, (state, { error }) => ({
        ...state,
        error,
    }))
);

export function userDetailsReducer(
    state: UserDetailsState | undefined,
    action: Action
) {
    return reducer(state, action);
}
