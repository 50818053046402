import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

import { QueryBuilderType } from './query-builder-type';

export interface ApexStudy {
    studyId: FormControl<string>;
    studyName: FormControl<string>;
    studyType: FormControl<QueryBuilderType>;
    queryRequest: FormGroup<ChartStudyQueryRequest>;
}

export interface ChartStudyQueryRequest {
    dataPoints: FormGroup<QueryRequestDataPoints>;
    chartProperties: FormGroup<QueryRequestChartProps>;
    filters: FormGroup<QueryRequestFilters>;
    adjustments: FormGroup<Record<string, FormControl>>;
    summaries: FormGroup<Record<string, FormControl>>;
}

export interface QueryRequestFilters {
    origin: FormGroup<QueryRequestFilterList>;
    destination: FormGroup<QueryRequestFilterList>;
    airline: FormGroup<QueryRequestFilterList>;
    aircraft: FormGroup<QueryRequestFilterList>;
    emissionScheme: FormGroup<{
        types: FormArray<FormControl<string>>;
    }>;
    cabinTypes: FormGroup<{
        types: FormArray<FormControl<string>>;
    }>;
    schedulesFilters: FormGroup<{
        franchisePartners: FormControl<boolean>;
        nonStopServices: FormControl<boolean>;
    }>;
    timePeriod: FormGroup<QueryRequestTimePeriod>;
}

export interface QueryRequestDataPoints {
    dataPointCodes: FormArray<FormControl<string>>;
}


export interface QueryRequestTimePeriod {
    type: FormControl<'specific' | 'relative'>;
    specific?: FormGroup<QueryRequestTimePeriodSpecific>;
    relative?: FormGroup<{
        relativeMonths: FormControl<string>;
    }>;
}
export interface QueryRequestTimePeriodSpecific {
    type?: FormControl<'months' | 'quarters' | 'seasons' | 'yearStartsMonth'>;
    startYear?: FormControl<number>;
    startMonth?: FormControl<string>;
    endYear?: FormControl<number>;
    endMonth?: FormControl<string>;
    year?: FormControl<number>;
    yearStartsMonth?: FormControl<string>;
    quarters?: FormGroup<{
        q1: FormControl<boolean>;
        q2: FormControl<boolean>;
        q3: FormControl<boolean>;
        q4: FormControl<boolean>;
    }>;
    seasons?: FormGroup<{
        summer: FormControl<boolean>;
        winter: FormControl<boolean>;
    }>;
}
export interface QueryRequestChartProps {
    type: FormControl<string>;
    singleSeriesColour?: FormControl<string>;
    series?: FormArray<FormGroup<QueryRequestChartSeries>>;
    seriesDefinitions?: FormArray<FormGroup<QueryRequestChartSeriesDefs>>;
    scatter?: FormGroup<{
        groupBy: FormControl<string>;
    }>;
    xAxis: FormGroup<QueryRequestChartXAxis>;
}

export interface QueryRequestChartXAxis {
    type: FormControl<string>;
    partition?: FormControl<string>;
}

export interface QueryRequestFilterList {
    type: FormControl<string>;
    selected: FormArray<FormControl>;
}

export interface QueryRequestChartSeries {
    label: FormControl<string>;
    colour: FormControl<string>;
    chartType: FormControl<string>;
    attributeSelections: FormArray<FormControl<QueryRequestChartSeriesAttributeSelection>>;
    timePeriodSelection?: FormGroup<QueryRequestChartSeriesDropDownSelection>;
    emissionsSchemeSelection?: FormControl<string>;
}

export interface QueryRequestChartSeriesDropDownSelection {
    partition: FormControl<string>;
    selected: FormControl<string>;
}

export interface QueryRequestChartSeriesAttributeSelection {
    type: FormControl<string>;
    partition: FormControl<string>;
    selected: Partial<RepoItem<string | number>>;
}

export interface QueryRequestChartSeriesDefs {
    type: FormControl<string>;
    partition: FormControl<string>;
}
