import { EMPTY, switchMap } from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as ErrorActions from './error.actions';
import { ErrorService } from './error.service';

@Injectable()
export class ErrorEffects {
    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ErrorActions.httpErrorAction),
            switchMap(() => {
                const returnUrl = this.router.routerState.snapshot.url;

                this.errorService.setErrorPagePath(this.errorService.errorPath || 'error');

                this.errorService.handleErrorRedirect(returnUrl);

                return EMPTY;
            })
        ), { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private router: Router,
        private errorService: ErrorService
    ) {}
}
