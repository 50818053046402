import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { NetworkEffects } from './+state/network.effects';
import * as fromNetwork from './+state/network.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromNetwork.NETWORK_FEATURE_KEY,
            fromNetwork.networkReducer
        ),
        EffectsModule.forFeature([ NetworkEffects ]),
    ],
})
export class NetworkStoreModule {}
