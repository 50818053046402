interface SortType {
    pinned: boolean,
    studyName: string,
    createdUtc: string,
    lastRunUtc: string,
}

export const querySortOptions: { code: string; label: string; sort: (a: any , b: any) => number }[] = [
    {
        code: 'alphabetically',
        label: 'Name (A-Z)',
        sort: (a: SortType, b: SortType) => {
            if (a.pinned !== b.pinned) {
                return a.pinned ? -1 : 1;
            } else {
                return a.studyName.localeCompare(b.studyName);
            }
        },
    },
    {
        code: 'alphabetically-desc',
        label: 'Name (Z-A)',
        sort: (a: SortType, b: SortType) => {
            if (a.pinned !== b.pinned) {
                return a.pinned ? -1 : 1;
            } else {
                return b.studyName.localeCompare(a.studyName);
            }
        },
    },
    {
        code: 'created',
        label: 'Created date (newest)',
        sort: (a: SortType, b: SortType) => {
            if (a.pinned !== b.pinned) {
                return a.pinned ? -1 : 1;
            } else {
                const aDate = new Date(a.createdUtc).getTime();
                const bDate = new Date(b.createdUtc).getTime();

                return aDate > bDate ? -1 : 1;
            }
        },
    },
    {
        code: 'created-desc',
        label: 'Created date (oldest)',
        sort: (a: SortType, b: SortType) => {
            if (a.pinned !== b.pinned) {
                return a.pinned ? -1 : 1;
            } else {
                const aDate = new Date(a.createdUtc).getTime();
                const bDate = new Date(b.createdUtc).getTime();

                return aDate < bDate ? -1 : 1;
            }
        },
    },
    {
        code: 'last-run',
        label: 'Last run (most recent)',
        sort: (a: SortType, b: SortType) => {
            if (a.pinned !== b.pinned) {
                return a.pinned ? -1 : 1;
            } else {
                const aDate = new Date(a.lastRunUtc).getTime();
                const bDate = new Date(b.lastRunUtc).getTime();

                return aDate > bDate ? -1 : 1;
            }
        },
    },
    {
        code: 'last-run-desc',
        label: 'Last run (least recent)',
        sort: (a: SortType, b: SortType) => {
            if (a.pinned !== b.pinned) {
                return a.pinned ? -1 : 1;
            } else {
                const aDate = new Date(a.lastRunUtc).getTime();
                const bDate = new Date(b.lastRunUtc).getTime();

                return aDate < bDate ? -1 : 1;
            }
        },
    },
    // add cloud later
];
