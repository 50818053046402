<section
    class="rdc-tabs"
    [ngClass]="{
        horizontal: display === 'horizontal',
        vertical: display === 'vertical'
    }">

    <div class="tabs">

    <span
        *ngIf="heading"
        class="tabs-heading"
    >
        {{ heading }}
    </span>

        <div class="tabs-row" *ngIf="allowUserSwitching">
            <button
                #tabButton
                *ngFor="let tab of tabs; let index = index"
                class="tabs-tab"
                [class.is-active]="tab.active"
                [class.init]="!initialised"
                [class.is-disabled]="tab.disabled"
                (click)="select(tab, index)"
            >
                {{ tab.heading }}
                <span
                    *ngIf="tab.additionalHTML"
                    class="tabs-additional"
                    [innerHTML]="tab.additionalHTML"
                ></span>
            </button>

            <span
                class="active-bg"
                [class.animate]="initialised"
                [style.left.px]="activeXPosition"
                [style.width.px]="activeWidth">
            </span>
        </div>

    </div>

    <div
        *ngIf="!hideContent"
        class="tabs-content"
    >
        <ng-content></ng-content>
    </div>


</section>
