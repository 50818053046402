import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TooltipModule } from "@rdc-apps/rdc-shared/src/lib/directives/tooltip";
import { IconModule } from "@rdc-apps/rdc-shared/src/lib/ui/icon";

@Component({
    standalone: true,
    selector: 'rdc-apps-drawer-toggle',
    templateUrl: './toggle-pill.component.html',
    styleUrl: './toggle-pill.component.scss',
    imports: [ CommonModule, IconModule, TooltipModule ],
})
export class DrawerToggleComponent {

    @Input() isOpen = true;
    @Input() disabled = false;

    @Output() toggled = new EventEmitter<void>();
}
