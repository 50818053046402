import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserSettingsEffects } from './+state/user-settings.effects';
import * as fromUserSettings from './+state/user-settings.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromUserSettings.USER_SETTINGS_FEATURE_KEY,
            fromUserSettings.userSettingsReducer
        ),
        EffectsModule.forFeature([ UserSettingsEffects ]),
    ],
})
export class UserSettingsStoreModule {}
