<section class="rdc-template-series" *ngIf="!locked">

    <ng-container *ngIf="queryFormGroup('chartProperties','seriesDefinitions')"
                  [formGroup]="queryFormGroup('chartProperties','seriesDefinitions')">

        <ng-container *ngFor="let control of getFormArrayControls('seriesDefinitions') let index = index">

            <ng-container *ngIf="index === displayComparisonIndex">

                    <label class="text-hidden">
                        <span>Type</span>
                        <rdc-apps-select
                            controlName="type"
                            placeholder="Select metric category"
                            [controlGroup]="getControlControl(control)"
                            [disabled]="displaySeriesIndex > 0 || (locked && control.value.type)"
                            [options]="seriesDefTypeOptions[index]">
                        </rdc-apps-select>
                    </label>

                    <label class="rdc-template-series-axis text-hidden" *ngIf="control.value.type !== 'emissionsScheme'">
                        <ng-container>
                            <span>Show on X-axis</span>
                            <rdc-apps-select
                                controlName="partition"
                                placeholder="Select metric"
                                [controlGroup]="getControlControl(control)"
                                [disabled]="!control.value.type || displaySeriesIndex > 0 || (locked && control.get('partition')?.value)"
                                [options]="xAxisOptionsOverrides[index] || xAxisOptionsForType.get(control.value.type) || []">
                            </rdc-apps-select>
                        </ng-container>
                    </label>

            </ng-container>

        </ng-container>

    </ng-container>

</section>

<div class="rdc-template-series-value"
     *ngIf="queryFormGroup('chartProperties','series')"
     [formGroup]="queryFormGroup('chartProperties','series')">

    <ng-container *ngFor="let control of getFormArrayControls('series'); let seriesIndex = index">

        <div *ngIf="seriesIndex === displaySeriesIndex" [formArrayName]="seriesIndex">

            <ng-container *ngFor="let attributeControl of getFormArrayControls('seriesDefinitions'); let comparisonIndex = index">

                <div *ngIf="comparisonIndex === displayComparisonIndex" >

                    <ng-container [ngSwitch]="true">

                        <ng-container *ngSwitchCase="!!selectOptionsForComparison[comparisonIndex]?.length && getFormArrayControls('seriesDefinitions').at(comparisonIndex)?.value?.type === 'timePeriod'">

                            <rdc-apps-select
                                controlName="selected"
                                *ngIf="control.get('timePeriodSelection')"
                                [controlGroup]="getControlControl(control, 'timePeriodSelection')"
                                [options]="selectOptionsForComparison[comparisonIndex]"
                                (valueSelected)="templateEventService.progress()">
                            </rdc-apps-select>

                        </ng-container>

                        <ng-container *ngSwitchCase="!!selectOptionsForComparison[comparisonIndex]?.length && getFormArrayControls('seriesDefinitions').at(comparisonIndex)?.value?.type === 'emissionsScheme'">

                            <rdc-apps-select
                                *ngIf="control.get('emissionsSchemeSelection')"
                                controlName="emissionsSchemeSelection"
                                [controlGroup]="getControlControl(control)"
                                [options]="selectOptionsForComparison[comparisonIndex]"
                                (valueSelected)="templateEventService.progress()">
                            </rdc-apps-select>

                        </ng-container>

                        <ng-container *ngSwitchDefault>

                            <div>
                                <ng-container [ngTemplateOutlet]="dropDownFilters.get(attributeControl.value.partition) ? selectable : autoCompletable"></ng-container>
                                <ng-template #selectable>
                                    <rdc-apps-select
                                        overrideSelectedLabel="Add series filter"
                                        [options]="dropDownFilters.get(attributeControl.value.partition) || []"
                                        (valueSelected)="onSeriesAutocompleteSelect(control, comparisonIndex, $event)">
                                    </rdc-apps-select>
                                </ng-template>
                                <ng-template #autoCompletable>
                                    <rdc-apps-autocomplete
                                        placeholder="Search {{ attributeControl.value.type }}"
                                        [showValidation]="false"
                                        [disabled]="!attributeControl.value.partition"
                                        [options]="autoCompleteResults$ | async"
                                        [maskSelected]="getAttributeValue(control, comparisonIndex)"
                                        (valueSelected)="onSeriesAutocompleteSelect(control, comparisonIndex, $event)"
                                        (autocomplete)="onAutocomplete(attributeControl.value.partition, $event)"
                                        (clearSelected)="onRemoveAttributeSelection(control, comparisonIndex)">
                                    </rdc-apps-autocomplete>
                                </ng-template>
                            </div>

                        </ng-container>

                    </ng-container>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>

