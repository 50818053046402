import { takeUntil } from 'rxjs';

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { QueryBuilderType } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import {
    DataPointsData,
    DataPointsEntity,
    DataPointsSelection
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points';
import { QueryDataPointsConversionService } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/query';
import { DisplayOrderPipe } from '@rdc-apps/rdc-apex/src/lib/shared/pipes/summary-group-order';
import { CustomValidators } from '@rdc-apps/rdc-shared/src/lib/custom-validators';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';

@Component({
    selector: 'rdc-apps-qb-data-points',
    templateUrl: './qb-data-points.component.html',
    styleUrls: [ './qb-data-points.component.scss' ],
})
export class QbDataPointsComponent extends RdcComponentUtils implements OnInit {

    @Input() form: FormGroup = new FormGroup({});
    @Input() dataPointsData!: DataPointsEntity | null | undefined;
    @Input() showGroupPills = true;
    @Input() showValidation = true;
    @Input() basicBuilder!: boolean;

    @Output() initialisedTableDataPoints: EventEmitter<void> = new EventEmitter<void>();
    @Output() dataPointsSummaryText = new EventEmitter<string[]>();
    @Output() salesforceLead = new EventEmitter<string>();

    @ViewChild('dataPointFilter') dataPointFilter!: ElementRef<HTMLInputElement>;
    @ViewChild('dataPointsContainer') dataPointsContainer!: ElementRef<HTMLElement>;

    dataPointsMapped!: DataPointsData[];
    selectedDataPointsArray: DataPointsSelection[] = [];

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private queryDataPointsConversionService: QueryDataPointsConversionService
    ) {
        super();
    }

    get dataPoints(): FormGroup<{ controls: FormGroup<Record<string, FormControl<boolean>>> }> {
        return this.form.get('queryRequest.dataPoints') as FormGroup<{ controls: FormGroup<Record<string, FormControl<boolean>>> }>;
    }

    get queryRequest(): FormGroup {
        return this.form.get('queryRequest') as FormGroup;
    }

    get dataPointsFormGroup(): FormGroup {
        return this.form.get('queryRequest.dataPoints') as FormGroup;
    }

    get perXOptionsFormGroup(): FormGroup {
        return this.form.get('queryRequest.queryPerXOptions') as FormGroup;
    }

    get studyType(): FormControl {
        return this.form.get('studyType') as FormControl;
    }

    get studyTypeTable(): boolean {
        return this.form.get('studyType')?.value === QueryBuilderType.Table;
    }

    ngOnInit(): void {
        this.dataPointsMapped = this.queryDataPointsConversionService.createQuery(this.dataPointsData);

        if (this.basicBuilder) {
            this.dataPointsMapped = this.basicMappedDataPoints();
        }

        if (this.form.get('studyType')?.value === QueryBuilderType.Column) {
            this.dataPointsMapped = this.reorderMappedDataPoints();
        }

        this.form.get('studyType')?.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$)
            )
            .subscribe((studyType) => {
                if (studyType === QueryBuilderType.Table) {
                    this.selectedDataPointsArray = [];
                    this.initDataPointsMapping();
                } else {
                    this.dataPointsMapped = this.reorderMappedDataPoints();
                }
            });
    }

    selectedDataPoints(dataPoint: DataPointsSelection): void {

        this.changeDetectorRef.detectChanges();

        if (!this.studyTypeTable) {
            this.selectedDataPointsArray = [];
        }

        const dataPointSelectionExists = this.selectedDataPointsArray.find((selectedData): boolean =>
            selectedData.dataPointsGroupKey === dataPoint.dataPointsGroupKey);
        if (!dataPointSelectionExists) {
            this.selectedDataPointsArray.push(dataPoint);
        } else {
            this.selectedDataPointsArray = this.selectedDataPointsArray.map((dataPointSelection): DataPointsSelection =>
                dataPointSelection.dataPointsGroupKey === dataPoint.dataPointsGroupKey
                    ? { ...dataPointSelection, value: dataPoint.value }
                    : dataPointSelection).sort((a) => a.dataPointsGroupKey === 'popular' ? -1 : 0);
        }
    }

    initDataPointsMapping(): void {
        this.changeDetectorRef.detectChanges();

        let controls = Object.keys(this.dataPoints.controls || {});

        if (controls.length) {
            controls = [];
        }

        // add the datapoint controls ONLY if they're not yet present
        if (!controls.length) {

            const dataPoints = this.fb.group({}, { validators: CustomValidators.atLeastOneChecked() });
            const perXOptions = this.fb.group({});

            this.dataPointsMapped = this.reorderMappedDataPoints();

            this.dataPointsData?.perOptions.forEach((option): void => {
                perXOptions.setControl(option.code, this.fb.control(false));
            });

            this.dataPointsMapped.forEach((dataPointGroup): void => {
                const groupDataPoints = this.fb.group({});

                dataPointGroup.dataPointsData
                    .forEach((dpd) => groupDataPoints.setControl(dpd.code, this.fb.control(false)));

                dataPoints.setControl(dataPointGroup.code, groupDataPoints);
            });

            (this.form.get('queryRequest') as FormGroup)?.setControl('dataPoints', dataPoints, { emitEvent: false });
            (this.form.get('queryRequest') as FormGroup)?.setControl('queryPerXOptions', perXOptions, { emitEvent: false });
        }
    }

    reorderMappedDataPoints(): DataPointsData[] {
        return new DisplayOrderPipe().transform(this.dataPointsMapped)
            .map((dataPointsData): DataPointsData => ({
                ...dataPointsData,
                dataPointsData: this.studyTypeTable
                    ? dataPointsData.dataPointsData.filter((dataPoint): boolean => dataPoint.tabularVisible)
                        .sort((a, b): number => (a.isEnabled > b.isEnabled) ? -1 : (a.isEnabled < b.isEnabled) ? 1 : 0)
                    : dataPointsData.dataPointsData.filter((dataPoint): boolean =>
                        dataPoint.availableTypes.length > 0 && dataPoint.availableTypes.includes(this.studyType.value))
                        .sort((a, b): number => (a.isEnabled > b.isEnabled) ? -1 : (a.isEnabled < b.isEnabled) ? 1 : 0),
            }));
    }

    basicMappedDataPoints(): DataPointsData[] {
        return this.dataPointsMapped.map((dataPoints) => {
            const dataPointsData = dataPoints.dataPointsData.filter((dataPoint) => dataPoint.visibleInBasicQueryBuilder || !dataPoint.visibleInBasicQueryBuilder && !dataPoint.isEnabled)

            return { ...dataPoints, dataPointsData }
        })
    }
}
