import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TooltipsService {

    private storage: Storage = window.localStorage;

    private storageKey = 'rdc-tooltips';

    constructor() {
        // turn them on by default for new users
        if (this.storage.getItem(this.storageKey) === null) {
            this.enabled = true;
        }
    }

    get enabled(): boolean {
        return this.storage.getItem(this.storageKey) === 'true';
    }

    set enabled(enabled: boolean) {
        this.storage.setItem(this.storageKey, String(enabled));
    }

    get disabled(): boolean {
        return this.storage.getItem(this.storageKey) !== 'true';
    }
}
