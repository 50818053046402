<header class="quick-launcher-header">
    <h4>{{ data.title }}</h4>

    <button
        rdcAppsButton
        rdcAppsIconButton
        rdcAppsDialogItem
        display="compact"
        rdcAppsTooltip
        tooltipPosition="right"
        [tooltipText]="'Close'"
        [dialogCloseIconBtn]="true"
    >
        <rdc-apps-icon size="sm">close</rdc-apps-icon>
    </button>
</header>

<section class="quick-launcher-section">
    <a
        rdcAppsButton
        rdcAppsDialogItem
        display="compact"
        colour="primary"
        class="quick-launcher-new-query"
        (click)="onNewQuery(true)"
    >
        <span>Basic</span>
    </a>

    <a
        rdcAppsButton
        rdcAppsDialogItem
        display="compact"
        colour="primary"
        class="quick-launcher-new-query"
        (click)="onNewQuery()"
    >
        <span *ngIf="data.queryCategory !== 'table'">New {{ data.queryCategory }}</span>
        <span *ngIf="data.queryCategory === 'table'">Extended</span>
    </a>
</section>


<div class="quick-launcher-queries">
    <rdc-apps-compact-saved-queries-menu
        [queries]="savedQueries$ | async"
        [showActive]="true"
        [limitHeight]="false">
    </rdc-apps-compact-saved-queries-menu>
</div>
