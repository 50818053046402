<nav class="breadcrumb">
    <ul>
        <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs; let last = last; let first = first;" [ngClass]="{ 'active': last }">
            <a
                *ngIf="!last else lastBreadcrumb"
                class="breadcrumb-link"
                [routerLink]="breadcrumb.url"
                (mouseenter)="setBg(first)" (mouseleave)="removeBg()"
            >

                <ng-container *ngIf="breadcrumb.icon">
                    <ng-container *ngIf="first">
                        <rdc-apps-icon [rdcIcon]="breadcrumb.icon" size="sm"></rdc-apps-icon>
                        <ng-container *ngIf="!first">{{ breadcrumb.label }}</ng-container>
                    </ng-container>

                    <ng-container *ngIf="!first">
                        <div class="breadcrumb-container">
                            <div #iconBgWrapper class="breadcrumb-icon-wrapper">
                                <rdc-apps-icon [rdcIcon]="breadcrumb.icon" size="xxs"></rdc-apps-icon>
                            </div>
                            <ng-container *ngIf="!first">{{ breadcrumb.label }}</ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </a>

            <rdc-apps-icon class="breadcrumb-chevron" *ngIf="!last">chevron_right</rdc-apps-icon>

            <ng-template #lastBreadcrumb>
                <ng-container *ngIf="breadcrumb.icon">
                    <div class="breadcrumb-container">
                        <div class="breadcrumb-icon-wrapper" [attr.style]="'background:' + breadcrumbIconBgColour + ''">
                            <rdc-apps-icon [rdcIcon]="breadcrumb.icon" size="xxs"></rdc-apps-icon>
                        </div>
                        <span>{{ breadcrumb.label }}</span>
                    </div>
                </ng-container>

                <span
                    *ngIf="breadcrumb.toolTipText"
                    class="breadcrumb-last-item"
                    rdcAppsTooltip
                    tooltipPosition="right"
                    [tooltipOptions]="{ maxWidth: '350px' }"
                    [tooltipText]="breadcrumb.toolTipText"
                >
                    {{ breadcrumb.label }}
                    <rdc-apps-icon size="xxxxs">info</rdc-apps-icon>
                </span>

                <span *ngIf="!breadcrumb.toolTipText && !breadcrumb.icon">
                    {{ breadcrumb.label }}
                </span>
            </ng-template>
        </li>
    </ul>

</nav>
