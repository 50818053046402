import { Subject, takeUntil, tap } from 'rxjs';

import { Injectable, OnDestroy, Optional } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { DispatchedAction } from '@rdc-apps/rdc-shared/src/lib/data-access/models';


@Injectable({ providedIn: 'root' })
export abstract class RdcComponentUtils implements OnDestroy {

    protected constructor(
        @Optional() private actions$?: Actions
    ) {}

    protected componentDestroyed$: Subject<void> = new Subject<void>();

    protected onActions<T>(
        actions: DispatchedAction<T>[],
        callback: (action: T) => void
    ): void {
        if (!this.actions$) {
            // eslint-disable-next-line no-console
            return console.error('Provide "Actions" in the constructor & super() to use the onActions method');
        }

        this.actions$
            .pipe(
                ofType(...actions),
                takeUntil(this.componentDestroyed$),
                tap((action: T) => callback(action))
            ).subscribe();
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

}
