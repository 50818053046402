<ng-container *ngIf="controls.length">
    <div class="multi-select-checkbox" [formGroup]="form">
        <div class="multi-select-checkbox-button-container" [formGroupName]="givenFormGroupName">
            <div class="multi-select-checkbox-toggle">
                <label class="rdc-checkbox">
                    <input
                        type="checkbox"
                        class="indeterminate"
                        [checked]="allChecked(controlFormGroup.key, controls)"
                        (change)="selectUnselectAll(controlFormGroup.key, controls)"
                    >
                    <span>{{ groupName }}</span>
                </label>
            </div>

            <div class="multi-select-checkbox-wrapper">
                <div class="multi-select-checkbox-group" [formGroupName]="controlFormGroup.key">
                    <ng-container *ngFor="let control of controls">
                        <div class="multi-select-checkbox-container">
                            <label class="rdc-checkbox">
                                <input type="checkbox" [formControlName]="control.key">
                                <span rdcAppsTooltip
                                      [tooltipText]="dataPointDescriptions.get(control.key) || 'No description'"
                                      [tooltipDelay]="250"
                                      [tooltipDisabled]="tooltips.disabled"
                                >{{ getFullName(control.key) }}</span>
                            </label>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>


