import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AnimationsModule } from '@rdc-apps/rdc-shared/src/lib/animations';
import { TooltipModule } from '@rdc-apps/rdc-shared/src/lib/directives/tooltip';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { SidebarComponent } from './sidebar.component';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        AnimationsModule,
        TooltipModule,
        RouterLinkActive,
        NgScrollbarModule,
        RouterLink,
    ],
    declarations: [
        SidebarComponent,
    ],
    exports: [
        SidebarComponent,
    ],
})
export class SidebarModule {}
