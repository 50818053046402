import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DefaultPreferencesEffects } from './+state/default-preferences.effects';
import * as fromDefaultPreferences from './+state/default-preferences.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromDefaultPreferences.DEFAULT_PREFERENCES_FEATURE_KEY,
            fromDefaultPreferences.defaultPreferencesReducer
        ),
        EffectsModule.forFeature([ DefaultPreferencesEffects ]),
    ],
})
export class DefaultPreferencesStoreModule {}
