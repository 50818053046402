import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, ValidatorFn } from "@angular/forms";
import { QueryBuilderType } from "@rdc-apps/rdc-apex/src/lib/shared/data-access/models";
import {
    DataPointsEntity,
    QbOutputSummaryGroup,
    SummaryOutputRepoItem
} from "@rdc-apps/rdc-apex/src/lib/shared/data-access/store/data-points";
import { DropdownOptionsForSummaryPipe } from "@rdc-apps/rdc-apex/src/lib/shared/pipes/dropdown-options-for-summary";
import { DisplayOrderPipe } from "@rdc-apps/rdc-apex/src/lib/shared/pipes/summary-group-order";
import { CustomValidators } from "@rdc-apps/rdc-shared/src/lib/custom-validators";
import { RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

import { QbUserDefaultsService } from './qb-user-defaults.service';

@Injectable({ providedIn: 'root' })
export class QbSetControlsService {

    constructor(private qbUserDefaultsService: QbUserDefaultsService, private fb: FormBuilder) {}

    outputGroups(queryType: QueryBuilderType, dataPointsData: DataPointsEntity | null | undefined): QbOutputSummaryGroup[] {
        const outputGroups = [ ...JSON.parse(JSON.stringify(dataPointsData?.outputGroups || [])) ]
        const groupToAddTo = outputGroups.find(({ id }) => id === '508a53fb-8cbb-43e0-95f5-fd9a4618c184');

        if (queryType === 'table') {
            groupToAddTo?.outputs?.push({
                datapointCodes: [],
                id: 'non-db',
                code: 'includeOnlyCompleteData',
                label: 'Include only complete data',
                displayOrder: 0,
            });
        }

        return outputGroups;
    }

    setControlsFormGroup(formGroup: FormGroup, dataPointsData: DataPointsEntity | null | undefined, groupArr: QbOutputSummaryGroup[] = [], key: 'summaries' | 'outputs'): FormGroup {

        const controlsFormGroup = new FormGroup({});

        groupArr.forEach((group) => {

            group[key]?.forEach((item: SummaryOutputRepoItem) => {

                if (item.code === 'operatingCarrier' && !formGroup.get('filters.schedulesFilters.franchisePartners')?.value) {
                    (formGroup.get('summaries') as FormGroup)?.removeControl(item.code as never);
                    (formGroup.get('adjustments') as FormGroup)?.removeControl(item.code as never);
                }

                // if it requires one or more data points to be checked
                if (item.datapointCodes.length) {
                    // for each required data point
                    item.datapointCodes.every((dpc, index) => {

                        // loop through the data point groups
                        for (const dpg of dataPointsData?.datapointsGroups || []) {
                            // check if the data point is present and checked
                            if (formGroup.get(`dataPoints.${ dpg.code }.${ dpc }`)?.value || key === 'outputs') {

                                const { validator, options } = this.validatorAndOptionsForControl(item, dataPointsData);

                                // then we can add the option
                                controlsFormGroup.addControl(item.code, this.fb.control(
                                    this.qbUserDefaultsService.getPreferenceValue(item.code) || options[0] || false,
                                    validator
                                ));

                                return false;
                            }
                        }

                        // if we got to the end and the required option is not checked, remove it if it.
                        if (index + 1 === item.datapointCodes.length) {
                            controlsFormGroup.removeControl(item.code as never);
                        }

                        return true;
                    });

                    // bespoke behavior
                    if (item.code === 'operatingCarrier' && !formGroup.get('filters.schedulesFilters.franchisePartners')?.value) {
                        controlsFormGroup.removeControl(item.code as never);
                    }
                } else { // it's always present
                    const { validator, options } = this.validatorAndOptionsForControl(item, dataPointsData);

                    if (!controlsFormGroup.get(item.code)) {

                        controlsFormGroup.addControl(item.code, this.fb.control(
                            this.qbUserDefaultsService.getPreferenceValue(item.code) || options[0] || false,
                            validator
                        ));

                    }

                }
            });
        });

        return controlsFormGroup;
    }

    validatorAndOptionsForControl(item: QbOutputSummaryGroup, dataPointsData: DataPointsEntity | null | undefined): { validator: ValidatorFn | []; options: string[] } {

        let options: RepoItem<string>[];
        let mappedOrderedOptions: string[];

        if (item.code === 'currency') {
            options = dataPointsData?.dropdowns.currencies || [];

            mappedOrderedOptions = new DisplayOrderPipe()
                .transform(options)
                .map((opt) => opt.code);

            return {
                validator: mappedOrderedOptions.length ? CustomValidators.inArray(mappedOrderedOptions) : [],
                options: [ 'GBP' ], // default currency, will be set by user eventually but still need a default.
            };
        }

        options = new DropdownOptionsForSummaryPipe()
            .transform(item, dataPointsData?.dropdowns.dropdownOptionGroups);

        mappedOrderedOptions = new DisplayOrderPipe()
            .transform(options)
            .map((opt) => opt.code);

        // some are checkboxes, so have no options, so can be falsey
        return {
            validator: [],
            options: mappedOrderedOptions,
        };
    }
}
