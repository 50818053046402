import { Component, Input } from '@angular/core';

@Component({
    selector: 'rdc-apps-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: [ './empty-state.component.scss' ],
})
export class EmptyStateComponent {

    @Input() title!: string;
    @Input() copy!: string;

    readonly IMAGE_SRC = '/assets/rdc-apex/images/apex-no-data.svg';

}
