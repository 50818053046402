import { Observable } from 'rxjs';

import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { QbUserDefaultsService } from "@rdc-apps/rdc-apex/src/lib/query-builder/utilities";
import {
    APP_EXPORTING_STUDY, displayOptionsSelections,
} from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import { ExportForm, ExportFormValue } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { QueryService } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/services';
import { QueryResultSet } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/store/query';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { getIsAppLoading } from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';
import { RdcComponentUtils } from "@rdc-apps/rdc-shared/src/lib/utilities";

export interface ExportInputs<T = QueryResultSet> {
    context: 'queryBuilder' | 'applet',
    export: 'tabular' | 'chart',
    exportFormats: string[],
    queryResults?: T;
    templateId?: string;
    basicBuilder?: boolean;
}

@Component({
    selector: 'rdc-apps-datatable-export',
    templateUrl: './datatable-export.component.html',
    styleUrls: [ './datatable-export.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class DatatableExportComponent extends RdcComponentUtils implements OnInit {

    @Output() export: EventEmitter<ExportFormValue> = new EventEmitter<ExportFormValue>();

    loading$!: Observable<boolean>;

    tableExportTypes: Partial<RepoItem<string>>[] = [
        { label: 'CSV', code: 'csv' },
        { label: 'TSV', code: 'tsv' },
    ];

    chartExportTypes: Partial<RepoItem<string>>[] = [
        { label: 'PNG', code: 'image/png' },
        { label: 'JPEG', code: 'image/jpeg' },
        { label: 'SVG', code: 'image/svg+xml' },
        { label: 'PDF', code: 'application/pdf' },
    ];

    displayOptionsSelections = displayOptionsSelections;

    displayOptions = new FormGroup({
        selection: new FormControl('both', [
            Validators.required,
        ]),
    });

    exportTypes: Partial<RepoItem<string>>[] = [];

    exportForm: FormGroup<ExportForm> = new FormGroup({
        export: new FormControl('tabular', { nonNullable: true, validators: Validators.required }),
        exportType: new FormControl('csv', {
            validators: [ Validators.required ],
            nonNullable: true,
        }),
        includeExtendedData: new FormControl(false, { validators: Validators.required, nonNullable: true }),
        includeIATACodes: new FormControl(true, { validators: Validators.required, nonNullable: true }),
        includeDisplayNames: new FormControl(false, { validators: Validators.required, nonNullable: true }),
    });

    constructor(
        @Inject(DIALOG_DATA) public data: ExportInputs,
        public queryService: QueryService,
        private cdr: ChangeDetectorRef,
        private store$: Store,
        private userDefaultsService: QbUserDefaultsService,
    ) {
        super();
    }

    exportFormats: string[] = [];

    ngOnInit(): void {

        const displayCodesDefault = this.userDefaultsService.getDisplayCodeBasedOnPreferences();

        this.exportFormats = this.data.exportFormats || [];

        this.exportForm.patchValue({ exportType: this.data.export });

        this.loading$ = this.store$.select(getIsAppLoading(APP_EXPORTING_STUDY));

        this.exportForm.get('export')?.valueChanges.subscribe((type) => {

            if (type === 'tabular') {
                this.exportTypes = this.tableExportTypes.slice();
            } else {
                this.exportTypes = this.chartExportTypes.slice();
            }

            this.cdr.detectChanges();

            this.exportForm.patchValue({ exportType: this.exportTypes[0].code });
        });

        this.displayOptions.controls.selection.valueChanges
            .subscribe((selection) => {
                switch (selection) {
                    case 'nameOnly': {
                        this.exportForm.patchValue({ includeIATACodes: false, includeDisplayNames: true });
                        break;
                    }
                    case 'codeOnly': {
                        this.exportForm.patchValue({ includeIATACodes: true, includeDisplayNames: false });
                        break;
                    }
                    default: {
                        this.exportForm.patchValue({ includeIATACodes: true, includeDisplayNames: true });
                    }
                }
            });

        switch (this.data.context) {

            case 'queryBuilder': {
                // configure query builder setup export

                // now subscription set up, set the appropriate value based on the study. (sub will update the checkboxes)
                const adjustments: { iataCodes?: boolean; shouldDisplayNames?: boolean } = this.data.queryResults?.studyModel?.queryRequest?.adjustments || {};

                if(adjustments.iataCodes && adjustments.shouldDisplayNames) {
                    this.displayOptions.patchValue({ selection: 'both' });
                }

                if(!adjustments.iataCodes && adjustments.shouldDisplayNames) {
                    this.displayOptions.patchValue({ selection: 'nameOnly' });
                }

                if(adjustments.iataCodes && !adjustments.shouldDisplayNames) {
                    this.displayOptions.patchValue({ selection: 'codeOnly' });
                }

                break;
            }
            default: {
                //
            }

        }

        this.exportForm.patchValue(this.data);

        this.displayOptions.patchValue({ selection: displayCodesDefault });
    }

    onSubmit(): void {

        if (this.exportForm.invalid) {
            return;
        }

        this.export.emit(this.exportForm.getRawValue());
    }
}
