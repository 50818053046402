import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/header';
import { ButtonModule } from '@rdc-apps/rdc-shared/src/lib/directives/button';
import { IconModule } from '@rdc-apps/rdc-shared/src/lib/ui/icon';
import { ValidatedControlModule } from '@rdc-apps/rdc-shared/src/lib/ui/validated-control';

import { AccountComponent } from './account.component';

@NgModule({
    imports: [
        CommonModule,
        ValidatedControlModule,
        ReactiveFormsModule,
        HeaderModule,
        ButtonModule,
        IconModule,
    ],
    declarations: [ AccountComponent ],
    exports: [
        AccountComponent,
    ],
})
export class AccountModule {}
