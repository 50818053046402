export interface SideNavItem {
    label: string;
    route: string[];
    icon: string;
    activeIcon?: string;
    cloudType: string;
    content: string;
    active: boolean;
    onboarded?: boolean;
}

export interface MappedSideNav {
    cloud: string;
    onboarded: boolean;
}
