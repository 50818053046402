import { animate, style, transition, trigger } from '@angular/animations';

export const slideInOutAnimationSpeed = 500;

export const slideInOutAnimation = trigger('slideInOut', [
    transition(':enter', [
        style({
            height: 0,
            opacity: 0,
            margin: 0,
        }),
        animate(`${slideInOutAnimationSpeed}ms ease-in-out`, style({
            height: '*',
            opacity: 1,
            margin: '*',
        })),
    ]),
    transition(':leave', [
        animate(`${slideInOutAnimationSpeed}ms ease-in-out`, style({
            height: 0,
            opacity: 0,
            margin: 0,
        })),
    ]),
]);
