<ng-container>

    <!-- actual control that stores the value code -->
    <ng-container [formGroup]="group()">
        <input type="hidden" [formControlName]="controlName">
    </ng-container>

    <ng-container *ngIf="filterable else default">
        <div *ngIf="maskSelected || storedSelection.value.selected else showInput" class="icon-input clear-selected">
            <button
                class="select-menu-button"
                rdcAppsButton
                rdcAppsIconButton
                [ngClass]="display"
                [disabled]="disabled || !!controlGroup.get(controlName)?.disabled"
                (click)="onReSelect()">
                <span>{{ storedSelection.value.selected?.label || maskSelected?.label }}</span>
            </button>
            <rdc-apps-icon [ngClass]="display">cancel</rdc-apps-icon>
        </div>

        <ng-template #showInput>
            <div class="icon-input"
                 [formGroup]="filterText">
                <input
                    #textTrigger
                    type="text"
                    class="select-filter-input"
                    formControlName="input"
                    autocomplete="off"
                    [ngClass]="display"
                    [placeholder]="placeholder"
                    [class.ng-invalid]="((controlGroup.get(controlName)?.touched && controlGroup.get(controlName)?.invalid) || forceInvalid) && showValidation"
                    (focus)="onDialogOnFocus(textTrigger)"
                    (click)="onDialogOnFocus(textTrigger)"
                    (keydown.arrowDown)="onEnterModalSelection(textTrigger)"
                    (keydown.tab)="dialogRef?.close()"
                    (input)="onDialogOnInput(textTrigger)">
                <rdc-apps-icon
                    *ngIf="!textTrigger.value"
                    [ngClass]="display">
                    search
                </rdc-apps-icon>
                <rdc-apps-icon
                    *ngIf="textTrigger.value"
                    class="select-clear-filter"
                    [ngClass]="display"
                    (click)="onClearFilter()">
                    cancel
                </rdc-apps-icon>
            </div>
        </ng-template>

    </ng-container>

    <ng-template #default>
        <div class="icon-input">
            <button
                class="select-menu-button"
                #selectTrigger
                rdcAppsButton
                [disabled]="disabled || !!controlGroup.get(controlName)?.disabled"
                [ngClass]="display"
                [class.ng-invalid]="((controlGroup.get(controlName)?.touched && controlGroup.get(controlName)?.invalid) || forceInvalid) && showValidation"
                (keydown.arrowDown)="onDialog(selectTrigger)"
                (click)="onDialog(selectTrigger, false)">
                <span>{{ maskSelected?.label || storedSelection.value.selected?.label || placeholder }}</span>
            </button>
            <rdc-apps-icon [ngClass]="display">expand_more</rdc-apps-icon>
        </div>
    </ng-template>

</ng-container>


<ng-template #template>

    <!-- when on initial focus show a static option to enable a selection (built for chart series comparison)-->
    <ng-container *ngIf="staticSelection">
        <div class="no-animate cdk-menu rdc-new-menu">
            <span *ngIf="filterHelp" class="filter-help">{{ filterHelp }}</span>
            <button class="rdc-context-menu-item" (click)="onItemSelected(staticOption)">
                <span>{{ staticOption.label }}</span>
            </button>
        </div>
    </ng-container>

    <ng-container *ngIf="((options || []) | rdcSelectFilter : (filterText.value.input || '') : filterable : filterMode) as selectableOptions">

        <ng-container *ngIf="selectableOptions | removeEmptyCategories as selectableOptions">

            <cdk-virtual-scroll-viewport
                *ngIf="displayScrollViewport(selectableOptions, filterText.value.input || '') || !filterOnEnteredCharacters"
                #vsViewport
                appendOnly
                [style.height.px]="selectableOptions | vsHeight : 250 : filterHelpHeight : vsViewport"
                [itemSize]="32">

                <div #selectListMenu cdkMenu class="no-animate rdc-new-menu" (focus)="focusFirstItem(selectListMenu)">

                        <span *ngIf="filterHelp && selectableOptions.length" class="filter-help">{{ filterHelp }}</span>

                        <ng-container *cdkVirtualFor="let option of selectableOptions; let i = index">

                                <ng-container [ngSwitch]="true">

                                    <div *ngSwitchCase="!!option['options']?.length">
                                        <span class="rdc-select-category-name">{{ option.label || 'unknown' | uppercase }}</span>
                                        <button
                                            *ngFor="let subOpt of option['options']"
                                            rdcAppsContextMenuItem
                                            cdkMenuItem
                                            cdkMenuitemTypeaheadLabel="*"
                                            [disabled]="subOpt.disabled"
                                            [cdkMenuItemDisabled]="subOpt.disabled"
                                            [class.active]="controlGroup.value[controlName] === option[selectProperty]"
                                            (click)="onItemSelected(subOpt)">
                                            <span>{{ subOpt.label }}</span>
                                        </button>
                                    </div>

                                    <button
                                        *ngSwitchDefault
                                        rdcAppsContextMenuItem
                                        cdkMenuItem
                                        cdkMenuitemTypeaheadLabel="*"
                                        [disabled]="option.disabled"
                                        [cdkMenuItemDisabled]="option.disabled"
                                        [class.active]="controlGroup.value[controlName] === option[selectProperty]"
                                        (click)="onItemSelected(option)">
                                        <span>{{ option.label }}</span>
                                    </button>

                                </ng-container>

                            </ng-container>

                            <div *ngIf="!selectableOptions.length" class="no-results">
                                <span *ngIf="filterable else emptySelect">No matches found</span>
                                <ng-template #emptySelect>
                                    <span>No options available</span>
                                </ng-template>
                            </div>

                </div>

            </cdk-virtual-scroll-viewport>

        </ng-container>
    </ng-container>
</ng-template>
